// Function component (presentational):

import React from 'react'

import _ from 'lodash'

import { decimalCommaFormatter } from '../../utils/misc'
import { categorizeMood } from '../../utils/mood'

import L10nLink from '../shared/navigation/L10nLink'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'

import MoodColumn from './MoodColumn'

import Icon, {
	LineChartOutlined,
	LoadingOutlined,
	SmileOutlined,
	PlusOutlined,
} from '@ant-design/icons'
import { ReactComponent as Mind } from '../../assets/icons/lotus.svg'
import { Button, Skeleton, Descriptions, Typography, Progress } from 'antd'
import BlockRoundButton from '../shared/styledComponents/button/BlockRoundButton'

const { Text } = Typography

const MoodTracking = ({
	fromDeck,
	isLoading,
	data,
	showNewMood,
	periodMoodResponses,
	moodCurrentValue,
	disabled,
	language,
	t,
	isDarkTheme,
}) => {
	const ratingsItems = [
		{
			key: 'currentValue',
			label: t('mood:ratings.currentValue.label'),
			children: !moodCurrentValue ? t('mood:ratings.currentValue.unavailable') : moodCurrentValue,
			span: 2,
		},
	]

	const ratingsCurrentPerception = moodCurrentValue && categorizeMood(moodCurrentValue)

	const daysCount =
		(periodMoodResponses && _.uniq(periodMoodResponses.map((qr) => qr.log.date)).length) ?? 0

	const currentPerceptionItem = {
		key: 'currentPerception',
		label: t('mood:ratings.currentPerception.label'),
		children: ratingsCurrentPerception && (
			<Text mark={daysCount <= 1}>
				{t(`replies:attributes.moodRatingValues.${ratingsCurrentPerception}.label`)}
			</Text>
		),
		span: 2,
	}
	moodCurrentValue && ratingsItems.push(currentPerceptionItem)

	const dailyCheckUpChild = (
		<span
			style={{
				width: '100%',
				display: 'flex',
				// justifyContent: 'space-between',
				alignItems: 'center',
				gap: 8,
			}}
		>
			<Progress
				percent={(daysCount / 7) * 100}
				// size="small"
				showInfo={false}
				steps={7}
				style={{
					// maxWidth: 'calc(368px / 2 - 16px)',
					marginBottom: 0,
					marginRight: 0,
				}}
			/>
			<span style={{ flexShrink: 0 }}>{`${daysCount}/7`}</span>
		</span>
	)

	const dailyCheckUpItem = {
		key: 'dailyCheckUp',
		label: t('mood:ratings.dailyCheckUp.label'),
		children: dailyCheckUpChild,
		span: 2,
	}
	if (moodCurrentValue || daysCount >= 1) {
		ratingsItems.push(dailyCheckUpItem)
	}

	const ratingsAvgValue =
		periodMoodResponses && _.round(_.mean(periodMoodResponses.map((qr) => +qr.replies[0].value)), 1)

	const avgValueItem = {
		key: 'avgValue',
		label: t('mood:ratings.avgValue.label', { daysCount }),
		children: decimalCommaFormatter(ratingsAvgValue, language),
		span: 2,
	}

	const ratingsAvgPerception = ratingsAvgValue && categorizeMood(ratingsAvgValue)

	const avgPerceptionItem = {
		key: 'avgPerception',
		label: t('mood:ratings.avgPerception.label'),
		children: ratingsAvgPerception && (
			<Text mark>{t(`replies:attributes.moodRatingValues.${ratingsAvgPerception}.label`)}</Text>
		),
		span: 2,
	}

	if ((moodCurrentValue && daysCount > 1) || (!moodCurrentValue && daysCount >= 1)) {
		ratingsItems.push(avgValueItem, avgPerceptionItem)
	}

	const renderAddMood = () => (
		<BlockRoundButton
			type={!moodCurrentValue ? 'primary' : 'default'}
			onClick={showNewMood}
			disabled={disabled}
		>
			{renderLabel({
				icon: <SmileOutlined />,
				label: t('mood:actions.addMood'),
				extra: <PlusOutlined />,
			})}
		</BlockRoundButton>
	)

	return (
		<>
			<Card
				title={renderLabel({
					icon: fromDeck ? (
						<L10nLink to={{ pathname: 'profile', search: '?section=moods' }}>
							<Button shape="circle" icon={<Icon component={Mind} />} />
						</L10nLink>
					) : (
						<Icon component={Mind} />
					),
					label: t('mood:ratings.title'),
					extra: isLoading ? <LoadingOutlined /> : null,
				})}
				style={{ height: '100%' }}
			>
				<Skeleton title={false} loading={isLoading} paragraph={{ rows: 5 }} active></Skeleton>

				{!isLoading && (
					<Descriptions
						column={2}
						items={ratingsItems}
						style={{ marginBottom: fromDeck ? 8 : undefined }}
					/>
				)}

				{fromDeck && renderAddMood()}
			</Card>

			{!fromDeck && (
				<Card
					title={renderLabel({
						icon: <LineChartOutlined />,
						label: t('mood:trackRecord.title'),
						extra: isLoading ? <LoadingOutlined /> : null,
					})}
				>
					<MoodColumn data={data} t={t} isDarkTheme={isDarkTheme} />

					{renderAddMood()}
				</Card>
			)}
		</>
	)
}

export default MoodTracking
