// Function component (container, using React, Redux, React Router, Auth0 & i18next hooks):

// React Effect hook.
import React, { useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hook for routing.
import { useLocation, useNavigate } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'
import dayjs from 'dayjs'

import { convertKgToLb, convertCmToFt } from '../../utils/numbers'

import { accountableTypes } from '../../utils/yupSchema/accountsSchema'
import {
	digestions,
	hydrations,
	excretions,
	exertions,
} from '../../utils/yupSchema/bodyMassesSchema'
import { sleepings } from '../../utils/yupSchema/bodyHeightsSchema'

import { dateFormat } from '../../utils/dates'
import { nestify } from '../../utils/api'
import { prefixPath } from '../../utils/l10n'

import { bodyMassesIndex } from '../../ducks/bodyMasses'
import { bodyHeightsIndex } from '../../ducks/bodyHeights'
import { toggleQuickMeasurement, toggleNewMeasurement } from '../../ducks/measurement'
import { bodyMeasurementsCreate } from '../../ducks/bodyMeasurements'
import { speakWithSound } from '../../ducks/libra'
import { selectDay } from '../../ducks/journal'

import NewMeasurement from './NewMeasurement'

const NewMeasurementContainer = () => {
	const { focus, isNewMeasurementOpen, isQuickAction, isSI } = useSelector(
		(state) => state.measurement,
	)

	const { currentJournalId: journalId, currentDay } = useSelector((state) => state.journal)
	const currentDate = dayjs(currentDay, dateFormat)
	const isToday = dayjs(currentDate).isToday()

	const { currentAccountableType, currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const person = currentAccountableType === accountableTypes[0] && currentAccount.accountable
	const personId = person?.id
	const birthDay = currentAccount?.accountable?.birthDate
	const birthDate = birthDay && dayjs(birthDay)

	const { getAccessTokenSilently } = useAuth0()

	const dispatch = useDispatch()

	const {
		i18n: { language },
		t,
	} = useTranslation([
		'measurement',
		// 'bodyMeasurements',
		'logs',
		'bodyMasses',
		'bodyHeights',
		'bodyMassIndices',
		'validation',
	])

	const bMs = build(apiData, 'bodyMass')
	const bodyMasses = bMs
		?.filter(
			(bm) =>
				bm.bodyMeasurement.state === 'actual' &&
				dayjs(bm.bodyMeasurement.log.date) <= currentDate &&
				bm.bodyMeasurement.log.journal.id === journalId,
		)
		?.sort(
			(a, b) =>
				dayjs(b.bodyMeasurement.log.date) > dayjs(a.bodyMeasurement.log.date) ||
				b.bodyMeasurement.log.id - a.bodyMeasurement.log.id,
		)
	const latestBodyMass = bodyMasses?.[0]
	const latestBmValue = latestBodyMass?.value

	const shouldListBodyMasses = personId && !latestBmValue
	useEffect(() => {
		if (!shouldListBodyMasses) return

		const listBodyMasses = async () => {
			const accessToken = await getAccessTokenSilently()

			return dispatch(
				bodyMassesIndex(
					language,
					accessToken,
					{ of: personId, onOrBefore: currentDay, onlyActual: true },
					{ size: 1 },
				),
			)
		}

		listBodyMasses()
	}, [shouldListBodyMasses, personId, currentDay, getAccessTokenSilently, language, dispatch])

	const bHs = build(apiData, 'bodyHeight')
	const bodyHeights = bHs
		?.filter(
			(bm) =>
				bm.bodyMeasurement.state === 'actual' &&
				dayjs(bm.bodyMeasurement.log.date) <= currentDate &&
				bm.bodyMeasurement.log.journal.id === journalId,
		)
		?.sort(
			(a, b) =>
				dayjs(b.bodyMeasurement.log.date) > dayjs(a.bodyMeasurement.log.date) ||
				b.bodyMeasurement.log.id - a.bodyMeasurement.log.id,
		)
	const latestBodyHeight = bodyHeights?.[0]
	const latestBhValue = latestBodyHeight?.value

	const shouldListBodyHeights = personId && !latestBhValue
	useEffect(() => {
		if (!shouldListBodyHeights) return

		const listBodyHeights = async () => {
			const accessToken = await getAccessTokenSilently()

			return dispatch(
				bodyHeightsIndex(
					language,
					accessToken,
					{ of: personId, onOrBefore: currentDay, onlyActual: true },
					{ size: 1 },
				),
			)
		}

		listBodyHeights()
	}, [shouldListBodyHeights, personId, currentDay, getAccessTokenSilently, language, dispatch])

	const isBodyMass = focus === 'bodyMass'
	const isBodyHeight = focus === 'bodyHeight'

	const beforeAccountCreation =
		currentAccount && currentDay && currentDate < dayjs(currentAccount.creationDate)

	const withBodyMass = isBodyMass || beforeAccountCreation
	const withBmValue = withBodyMass && latestBmValue
	const withBodyHeight = isBodyHeight || beforeAccountCreation
	const withBhValue = withBodyHeight && latestBhValue

	const initialValues = {
		log: { journalId, date: currentDay },
		bodyMass: {
			value: withBmValue ? latestBmValue : '',
			valueInLb: withBmValue ? convertKgToLb(latestBmValue) : '',
			digestion: digestions[0],
			hydration: hydrations[0],
			excretion: excretions[0],
			exertion: exertions[0],
		},
		bodyHeight: {
			value: withBhValue ? latestBhValue : '',
			valueInFt: withBhValue ? convertCmToFt(latestBhValue) : '',
			sleeping: sleepings[0],
		},
		bodyMassIndex: { value: '' },
	}

	let { pathname, search } = useLocation()
	const currentPath = `${pathname}${search}`

	const navigate = useNavigate()

	const navToProfileMeasurements = () => {
		const newPath = prefixPath('/profile?section=measurements', language)

		currentPath !== newPath && navigate(newPath)
	}

	const hideNewMeasurement = () =>
		dispatch(isQuickAction ? toggleQuickMeasurement() : toggleNewMeasurement())

	const endSubmit = () => {
		hideNewMeasurement()
		navToProfileMeasurements()
	}

	const createBodyMeasurement = async (bodyMeasurementAttrs) => {
		const accessToken = await getAccessTokenSilently()

		// Comply with Rails nested attributes.
		const nestedBodyMeasurementAttrs = ['log', 'bodyMass', 'bodyHeight']
		const nestifiedBodyMeasurementAttrs = nestify(bodyMeasurementAttrs, nestedBodyMeasurementAttrs)

		return dispatch(bodyMeasurementsCreate(language, accessToken, nestifiedBodyMeasurementAttrs))
	}

	const libraStart = (path, l10nKeys = {}, speech = '') =>
		dispatch(speakWithSound({ path, speech: speech || t(path, l10nKeys), language }))

	return (
		<NewMeasurement
			isBodyMass={isBodyMass}
			isBodyHeight={isBodyHeight}
			beforeAccountCreation={beforeAccountCreation}
			isNewMeasurementOpen={isNewMeasurementOpen}
			isQuickAction={isQuickAction}
			isToday={isToday}
			hideNewMeasurement={hideNewMeasurement}
			birthDate={birthDate}
			selectDay={(d) => dispatch(selectDay(d))}
			latestBmValue={latestBmValue}
			latestBhValue={latestBhValue}
			initialValues={initialValues}
			createBodyMeasurement={createBodyMeasurement}
			endSubmit={endSubmit}
			libraStart={libraStart}
			isSI={isSI}
			language={language}
			t={t}
		/>
	)
}

export default NewMeasurementContainer
