import styled from 'styled-components'

import { Timeline as AntTimeline } from 'antd'

const Timeline = styled(AntTimeline)`
	padding-top: 11px;
	padding-left: 11px;

	margin-top: 4px;

	// .ant-timeline-item:last-of-type {
	.ant-timeline-item-last {
		padding-bottom: 0;
	}
`

export default Timeline
