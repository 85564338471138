// Function component (container, using Redux, React Router, i18next & React Responsive hooks):

import React from 'react'
// Redux hook to extract data from the Redux store state.
import { useSelector } from 'react-redux'
// React Router hooks for routing.
import { useMatch, useNavigate } from 'react-router-dom'
// i18next hook for localization (via React Context).
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
import { useMediaQuery } from 'react-responsive'

import deviceMinWidth from '../../styles/deviceMinWidth'

import Nav from './Nav'

const NavContainer = ({
	withNavApp = true,
	withNavUser = true,
	sleekNav = false,
	spreadNavUser = false,
	isHub = false,
}) => {
	const { isDashboardOpen } = useSelector((state) => state.dashboard)

	let { params: { navKey = '' } = {} } = useMatch('/:locale/:navKey') || {}

	const navigate = useNavigate()

	const { t } = useTranslation([
		'nav',
		'auth',
		'preference',
		// 'l10n'
	])

	const isTablet = useMediaQuery({
		// query: '(min-width: 768px)'
		query: `${deviceMinWidth.tablet}`,
	})

	const withSideDash = isDashboardOpen && isTablet && !sleekNav

	const isMobileM = useMediaQuery({
		// query: '(min-width: 320px)'
		query: `${deviceMinWidth.mobileM}`,
	})
	const isTabletL = useMediaQuery({
		// query: '(min-width: 896px)'
		query: `${deviceMinWidth.tabletL}`,
	})
	// const withHover = useMediaQuery({
	// 	query: '(hover: hover) and (pointer: fine)',
	// })

	return (
		<Nav
			withNavApp={withNavApp}
			withNavUser={withNavUser}
			sleekNav={sleekNav}
			spreadNavUser={spreadNavUser}
			isHub={isHub}
			isDashboardOpen={isDashboardOpen}
			withSideDash={withSideDash}
			navKey={navKey}
			navigate={navigate}
			t={t}
			isMobileM={isMobileM}
			isTabletL={isTabletL}
			// withHover={withHover}
		/>
	)
}

export default NavContainer
