// Function component (presentational):

import React from 'react'

import StyledLibraAvatar from './LibraAvatarStyles'

const LibraAvatar = ({ renderAssistantAvatar, showNewChat }) => (
	<StyledLibraAvatar onClick={showNewChat}>{renderAssistantAvatar()}</StyledLibraAvatar>
)

export default LibraAvatar
