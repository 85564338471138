// Function component (presentational):

import React from 'react'

import Card from '../shared/styledComponents/card/Card'

import { Segmented } from 'antd'

const FieldSegmented = ({ field, handleChange, t }) => (
	<Card style={{ margin: '0 8px 8px 8px' }}>
		<Segmented
			options={[
				{ value: 'nutrition', label: t('field:nutrition.short') },
				{ value: 'mind', label: t('field:mind.short') },
				{ value: 'fitness', label: t('field:fitness.short') },
			]}
			value={field}
			block
			// size="large"
			onChange={handleChange}
		/>
	</Card>
)

export default FieldSegmented
