import styled from 'styled-components'

import { Form } from 'formik-antd'

const { Item } = Form

const FormItem = styled(Item)`
	margin-bottom: 16px;

	.ant-form-item-explain {
		padding-left: 8px;
	}

	.ant-radio-button-wrapper-in-form-item {
		height: ${(props) => (props.$inlineRadios ? '100%' : undefined)};
		width: ${(props) => (props.$inlineRadios ? `calc(100% / ${props.$inlineRadios})` : undefined)};
	}
	.ant-radio-button-wrapper {
		padding-inline: ${(props) => (props.$inlineRadios ? 'unset' : undefined)};
	}

	.ant-input-number-group-addon {
		padding: 0 8px 0 9px;
	}
	.ant-input-number-handler-wrap {
		width: 32px;
	}

	.ant-checkbox-group-item {
		display: ${(props) => (props.$columnCheckboxes ? 'flex' : undefined)};
		flex-direction: ${(props) => (props.$columnCheckboxes ? 'column' : undefined)};
		align-items: ${(props) => (props.$columnCheckboxes ? 'center' : undefined)};
	}

	.ant-input-affix-wrapper-lg,
	.ant-input-group-addon {
		border-radius: 24px;
	}
	.ant-input-affix-wrapper-lg {
		padding: 7px;
	}
	.ant-input-prefix {
		margin-inline-end: 8px;
	}
	.ant-input-suffix {
		margin-inline-start: 8px;
	}
	.ant-input-group-addon {
		padding: 0 7px;

		// background: ${(props) => props.theme.colorPrimary};
	}
`

export default FormItem
