// Function component (presentational):

import React from 'react'

import { disabledDate } from '../../utils/yupSchema/logsSchema'

// import { renderCalendarLabel } from '../shared/other/renderLabel'

import DateContainer from '../date/DateContainer'

import AntCalendar from '../shared/styledComponents/Calendar'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

const Calendar = ({
	isCalendarOpen,
	currentDate,
	birthDate,
	handleSelect,
	hideCalendar,
	// t,
	isLarge,
}) => {
	// const renderTitle = () => renderCalendarLabel(t('journal:calendar.title'))
	const renderTitle = () => (
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
			<DateContainer />
		</div>
	)

	const renderBody = () => (
		<AntCalendar
			fullscreen={isLarge}
			value={currentDate}
			disabledDate={(current) => disabledDate(current, birthDate)}
			onSelect={handleSelect}
		/>
	)

	return (
		<DrawerOrModalContainer
			isOpen={isCalendarOpen}
			hide={hideCalendar}
			renderTitle={renderTitle}
			renderBody={renderBody}
			onlyModal
			fromTop={0}
			staysRight={true}
			size={isLarge && 'fullscreen'}
		/>
	)
}

export default Calendar
