// Function component (container, using Redux, React Router & React Responsive hooks):

import React from 'react'
// Redux hook to extract data from the Redux store state.
import { useSelector } from 'react-redux'
// React Router hooks for routing.
import { useMatch } from 'react-router-dom'
// React Responsive hook for responsive web design.
import { useMediaQuery } from 'react-responsive'

import deviceMinWidth from '../../../styles/deviceMinWidth'

import DrawerOrModal from './DrawerOrModal'

const DrawerOrModalContainer = ({
	id,
	isOpen,
	mask = true,
	canClose = true,
	hide,
	renderTitle,
	renderBody,
	onlyDrawer = false,
	onlyModal = false,
	fromTop,
	fromBottom,
	staysRight,
	placement,
	side,
	size,
	fromLibra = false,
}) => {
	const renderNull = () => null

	const { isDashboardOpen } = useSelector((state) => state.dashboard)

	const isMobileXL = useMediaQuery({
		// query: '(min-width: 512px)'
		query: `${deviceMinWidth.mobileXL}`,
	})

	const isTablet = useMediaQuery({
		// query: '(min-width: 768px)'
		query: `${deviceMinWidth.tablet}`,
	})

	let { params: { navKey = '' } = {} } = useMatch('/:locale/:navKey') || {}
	const inOnboarding = navKey === 'onboarding'

	const withSideDash = isDashboardOpen && isTablet && !inOnboarding

	const isLaptop = useMediaQuery({
		// query: '(min-width: 1024px)'
		query: `${deviceMinWidth.laptop}`,
	})

	const isLaptopML = useMediaQuery({
		// query: '(min-width: 1152px)'
		query: `${deviceMinWidth.laptopML}`,
	})

	return (
		<DrawerOrModal
			id={id}
			isOpen={isOpen}
			mask={mask}
			canClose={canClose}
			hide={hide}
			renderTitle={renderTitle ?? renderNull}
			renderBody={renderBody}
			onlyDrawer={onlyDrawer}
			onlyModal={onlyModal}
			fromTop={fromTop}
			fromBottom={fromBottom}
			staysRight={staysRight}
			placement={placement}
			side={side}
			size={size}
			isMobileXL={isMobileXL}
			isTablet={isTablet}
			withSideDash={withSideDash}
			isLaptop={isLaptop}
			isLaptopML={isLaptopML}
		/>
	)
}

export default DrawerOrModalContainer
