// Function component (presentational):

import React from 'react'

import { Formik } from 'formik'
import {
	Form,
	// Radio,
	InputNumber,
	DatePicker,
	SubmitButton,
} from 'formik-antd'

import dayjs from 'dayjs'
import _ from 'lodash'

import firstObjectiveFormSchema from '../../utils/yupSchema/firstObjectiveFormSchema'
import newObjectiveFormSchema from '../../utils/yupSchema/newObjectiveFormSchema'
import { objectiveKinds, disabledDate } from '../../utils/yupSchema/objectivesSchema'
import { maxAge } from '../../utils/yupSchema/peopleSchema'

import { decimalCommaFormatter, decimalCommaParser } from '../../utils/misc'
import { convertKgToLb, convertLbToKg } from '../../utils/numbers'
import { transformInvalidAttrErrors, duplicateInvalidAttrErrors } from '../../utils/api'

import SystemContainer from '../system/SystemContainer'

import FormItem from '../shared/styledComponents/form/FormItem'
// import { radioGroupStyle } from '../shared/styledComponents/form/RadioGroup'
import RadioGroup from '../shared/styledComponents/form/RadioGroup'
import RadioButton from '../shared/styledComponents/form/RadioButton'
import { inputNumberStyle } from '../shared/styledComponents/form/InputNumber'
import { datePickerStyle } from '../shared/styledComponents/form/DatePicker'

import renderLabel, { renderWeightLabel, renderDateLabel } from '../shared/other/renderLabel'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'
import SubmitContent from '../shared/styledComponents/form/SubmitContent'

import { AimOutlined, InfoCircleOutlined, DownOutlined, RightOutlined } from '@ant-design/icons'
import { App as AntApp, Space } from 'antd'

// const { Group: RadioGroup, Button: RadioButton } = Radio

const ObjectiveForm = ({
	initialValues,
	isFirst,
	birthDate,
	createObjective,
	endSubmit,
	libraStart,
	isSI,
	language,
	t,
}) => {
	const { message } = AntApp.useApp()

	const {
		bodyMeasurement: {
			bodyMass: { value: bmValue, valueInLb: bmValueInLb },
		},
	} = initialValues

	const handleSubmit = async (values, { setErrors }) => {
		const { objective: objectiveAttrs } = values
		const attrs = isFirst ? objectiveAttrs : values

		try {
			const { response } = await createObjective(attrs)

			if (response) {
				const objective = Object.values(response.objective)[0].attributes
				const { effortLabel: effort } = objective

				message.success(t('objective:form.message.success'))

				libraStart('objective:form.speech.effort', { effort })

				endSubmit(values)
			}
		} catch (e) {
			if (e.errors?.[0]?.source.pointer === '/data/relationships/change') {
				message.warning(t('objective:form.message.delayWarning'))
				return
			}

			let errors = transformInvalidAttrErrors(e.errors)

			const { endDate } = errors

			if (endDate?.length) {
				let endDateErrorMsg = endDate[0].split(';')
				const endDateValidation = _.trim(endDateErrorMsg.shift()) + '.'
				const endDateSpeech = _.capitalize(_.trim(endDateErrorMsg.join('.')))
				errors.endDate = [endDateValidation]

				// Arbitrary intent...
				endDateSpeech && libraStart('objective:form.speech.error', {}, endDateSpeech)
			}

			if (!isSI) {
				errors = duplicateInvalidAttrErrors(
					errors,
					'bodyMeasurement.bodyMass.valueInLb',
					'bodyMeasurement.bodyMass.value',
				)

				const bmValueInLbError = errors.bodyMeasurement.bodyMass.valueInLb[0].replace(
					bmValue.toFixed(1),
					bmValueInLb.toFixed(1),
				)
				errors.bodyMeasurement.bodyMass.valueInLb = [bmValueInLbError]
			}

			const errs = isFirst ? { objective: errors } : errors

			// Async (server-side) validation.
			setErrors(errs)
		}
	}

	const validationSchema = isFirst ? firstObjectiveFormSchema(t) : newObjectiveFormSchema(t)

	const namePrefix = isFirst ? 'objective.' : ''

	const desiredBodyMassLabel = renderWeightLabel(t('bodyMasses:attributes.desiredValue.label'))

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({
				values: {
					objective: { objectiveKind: firstObjectiveKind } = {},
					objectiveKind: newObjectiveKind,
				},
				setFieldValue,
			}) => {
				const objectiveKind = firstObjectiveKind ?? newObjectiveKind
				const isLose = objectiveKind === objectiveKinds[1]
				const isGain = objectiveKind === objectiveKinds[2]
				const isChange = isLose || isGain

				return (
					<Form
						// id="objective-form"
						layout="vertical"
						colon={false}
						requiredMark="optional"
					>
						<FormItem
							name={`${namePrefix}objectiveKind`}
							label={renderLabel({
								icon: <AimOutlined />,
								label: t('objectives:attributes.objectiveKind.label'),
							})}
							required
						>
							<RadioGroup
								name={`${namePrefix}objectiveKind`}
								// onChange={(e) => {
								// 	if (e.target.value === objectiveKinds[0]) {
								// 		setFieldValue(`${namePrefix}endDate`, undefined)
								// 	}
								// }}
								// style={radioGroupStyle}
							>
								<Space direction="vertical">
									<RadioButton value={objectiveKinds[1]}>
										{t(`objectives:attributes.objectiveKinds.${objectiveKinds[1]}.label`)}
									</RadioButton>
									<RadioButton value={objectiveKinds[0]}>
										{t(`objectives:attributes.objectiveKinds.${objectiveKinds[0]}.label`)}
									</RadioButton>
									<RadioButton value={objectiveKinds[2]}>
										{t(`objectives:attributes.objectiveKinds.${objectiveKinds[2]}.label`)}
									</RadioButton>
								</Space>
							</RadioGroup>
						</FormItem>

						<FormItem
							name={`${namePrefix}bodyMeasurement.bodyMass.value`}
							label={desiredBodyMassLabel}
							required
							hidden={!isChange || !isSI}
						>
							<InputNumber
								name={`${namePrefix}bodyMeasurement.bodyMass.value`}
								placeholder={t('bodyMasses:attributes.desiredValue.placeholder')}
								min={isGain ? bmValue : 0}
								max={isLose ? bmValue : undefined}
								step={0.5}
								formatter={(v) => decimalCommaFormatter(v, language)}
								parser={(v) => decimalCommaParser(v, language)}
								suffix="kg"
								// changeOnWheel
								onChange={(v) =>
									v &&
									setFieldValue(`${namePrefix}bodyMeasurement.bodyMass.valueInLb`, convertKgToLb(v))
								}
								style={inputNumberStyle}
							/>
						</FormItem>
						<FormItem
							name={`${namePrefix}bodyMeasurement.bodyMass.valueInLb`}
							label={desiredBodyMassLabel}
							required
							hidden={!isChange || isSI}
						>
							<InputNumber
								name={`${namePrefix}bodyMeasurement.bodyMass.valueInLb`}
								placeholder={t('bodyMasses:attributes.desiredValueInLb.placeholder')}
								min={isGain ? bmValueInLb : 0}
								max={isLose ? bmValueInLb : undefined}
								step={1}
								formatter={(v) => decimalCommaFormatter(v, language)}
								parser={(v) => decimalCommaParser(v, language)}
								suffix="lb"
								// changeOnWheel
								onChange={(v) =>
									v &&
									setFieldValue(`${namePrefix}bodyMeasurement.bodyMass.value`, convertLbToKg(v))
								}
								style={inputNumberStyle}
							/>
						</FormItem>

						<FormItem
							name={`${namePrefix}endDate`}
							label={renderDateLabel(t('objectives:attributes.endDate.label'))}
							hidden={!isChange}
							tooltip={{
								icon: <InfoCircleOutlined />,
								title: t('objectives:attributes.endDate.tooltip'),
							}}
						>
							<DatePicker
								name={`${namePrefix}endDate`}
								format="l"
								placeholder={t('objectives:attributes.endDate.placeholder')}
								disabledDate={disabledDate}
								minDate={dayjs()}
								maxDate={birthDate && birthDate.add(maxAge, 'y')}
								style={datePickerStyle}
							/>
						</FormItem>

						<SubmitWrapper $step="first">
							{isChange && isFirst && (
								<SubmitContent>
									<SystemContainer />
								</SubmitContent>
							)}

							<SubmitButton
								// htmlType="submit"
								shape="circle"
								icon={isFirst ? <DownOutlined /> : <RightOutlined />}
							/>
						</SubmitWrapper>
					</Form>
				)
			}}
		</Formik>
	)
}

export default ObjectiveForm
