import { createSlice } from '@reduxjs/toolkit'
// import { createAppSlice } from '../store/createAppSlice'

const initialState = {
	isSI: '',
	focus: 'none',
	isNewMeasurementOpen: false,
	isQuickAction: false,
}

const measurement = createSlice({
	name: 'measurement',
	initialState: initialState,
	reducers: {
		// ‘Mutating’ the state is possible thanks to immer (shipped with Redux Toolkit).
		initSI(state, action) {
			state.isSI = action.payload
		},
		toggleSI(state) {
			state.isSI = !state.isSI
		},
		setFocus(state, action) {
			state.focus = action.payload
		},
		toggleMeasurement(state) {
			state.isNewMeasurementOpen = !state.isNewMeasurementOpen
		},
		toggleQuickMeasurement(state) {
			state.isNewMeasurementOpen = !state.isNewMeasurementOpen
			state.isQuickAction = !state.isQuickAction
		},
	},
})

export default measurement
export const { reducer: measurementReducer, actions: measurementActions } = measurement
export const { initSI, toggleSI, setFocus, toggleMeasurement, toggleQuickMeasurement } =
	measurementActions

export const toggleNewBodyMass = () => (dispatch) => {
	dispatch(setFocus('bodyMass'))
	dispatch(toggleMeasurement())
}
export const toggleQuickBodyMass = () => (dispatch) => {
	dispatch(setFocus('bodyMass'))
	dispatch(toggleQuickMeasurement())
}
export const toggleNewBodyHeight = () => (dispatch) => {
	dispatch(setFocus('bodyHeight'))
	dispatch(toggleMeasurement())
}
export const toggleNewMeasurement = () => (dispatch) => {
	dispatch(setFocus('none'))
	dispatch(toggleMeasurement())
}
