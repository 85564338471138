import * as yup from 'yup'

export const searchValidation = (t) => {
	// const label = t('library:form.search.label')

	// return yup.string().required(t('validation:mixed.required', { label }))
	return yup.string()
}

const searchFormSchema = (t) =>
	yup.object().shape({
		search: searchValidation(t),
	})

export default searchFormSchema
