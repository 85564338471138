import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import dayjs from 'dayjs'
// import 'dayjs/min/locales'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/en-ca'
import 'dayjs/locale/en-au'
import 'dayjs/locale/en-nz'
import 'dayjs/locale/en-in'
import 'dayjs/locale/fr'
import 'dayjs/locale/fr-ca'
import 'dayjs/locale/fr-ch'
// import 'dayjs/locale/es'
// import 'dayjs/locale/es-us'
// import 'dayjs/locale/es-mx'
// import 'dayjs/locale/de'
// import 'dayjs/locale/de-at'
// import 'dayjs/locale/de-ch'
// import 'dayjs/locale/pt'
// import 'dayjs/locale/pt-br'
// import 'dayjs/locale/it'
// import 'dayjs/locale/it-ch'
// import 'dayjs/locale/da'
// import 'dayjs/locale/cs'

// import advancedFormat from 'dayjs/plugin/advancedFormat'
import calendar from 'dayjs/plugin/calendar'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
// import weekOfYear from 'dayjs/plugin/weekOfYear'
// import weekYear from 'dayjs/plugin/weekYear'
// import weekday from 'dayjs/plugin/weekday'

import { supportedLocaleStandards, defaultLocale } from './i18nConfig'

// dayjs.extend(advancedFormat)
dayjs.extend(calendar)
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)
dayjs.extend(isToday)
dayjs.extend(relativeTime) // For the `humanize` function.
dayjs.extend(utc)
// dayjs.extend(weekOfYear)
// dayjs.extend(weekYear)
// dayjs.extend(weekday)

i18n
	// Load translation using http from `/public/locales`.
	.use(Backend)
	// Detect user language.
	.use(LanguageDetector)
	// Pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// Init i18next.
	.init({
		// Currently also supporting varieties...
		nonExplicitWhitelist: supportedLocaleStandards,
		fallbackLng: defaultLocale,
		// Careful in production...
		debug: true,

		interpolation: {
			// Not needed for React as it escapes by default.
			escapeValue: false,
			format: function (value, format, lng) {
				if (format === 'uppercase') return value.toUpperCase()
				if (value instanceof Date) return dayjs(value).format(format)
				return value
			},
		},
	})

// Listen to the change language event to keep Moment.js locale in sync.
i18n.on('languageChanged', (newLocale) => {
	dayjs.locale(newLocale)
})

export default i18n
