import styled from 'styled-components'

import deviceMinWidth from '../../styles/deviceMinWidth'

export const Thread = styled.div`
	height: 100vh;
	@supports (height: 100dvh) {
		height: 100dvh;
	}
	width: calc(min(100vw, 384px));

	@media ${deviceMinWidth.mobileXL} {
		width: calc(min(100vw, 768px));
	}

	display: flex;
	flex-direction: column;
	gap: 8px;

	overflow: auto;

	margin: -100vh auto 0;
	@supports (height: 100dvh) {
		margin: -100dvh auto 0;
	}
	padding: calc(64px + env(safe-area-inset-top)) 8px calc(8px + env(safe-area-inset-bottom));
`
