import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

const path = '/recipes'

export const recipesIndex =
	(locale, accessToken, filter = {}, page = {}) =>
	(dispatch) => {
		const endpoint = localizePath(locale, path)
		const options = initRequestOptions({ accessToken })
		const params = { filter, page }

		return dispatch(fetchLibraDietApiData({ endpoint, options, params }))
	}

export const recipesShow = (locale, accessToken, id) => (dispatch) => {
	const endpoint = localizePath(locale, `${path}/${id}`)
	const options = initRequestOptions({ accessToken })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}
