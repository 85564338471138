import { Capacitor } from '@capacitor/core'

const isNative = Capacitor.isNativePlatform()

const PACKAGE_ID = 'app.libradiet'

export const domain = process.env.REACT_APP_AUTH0_DOMAIN

export const redirectUri = `${
	isNative ? `${PACKAGE_ID}://${domain}/capacitor/${PACKAGE_ID}` : process.env.REACT_APP_HOST_URL
}/callback`

const authConfig = {
	domain,
	clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
	audience: process.env.REACT_APP_AUTH0_AUDIENCE,
	redirectUri,
}

export const { clientId, audience } = authConfig
