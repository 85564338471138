// Function component (stateful & container, using React, Redux, React Router, Auth0 & i18next hooks):

// React State & Effect hooks.
import React, { useState, useMemo, useEffect, useCallback } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hook for routing.
import { useLocation, useNavigate } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'
import dayjs from 'dayjs'

import { schedulableTypes } from '../../utils/yupSchema/eventsSchema'

import { defaultMealKindId, cupValueInMl, cupValueInFlOz } from '../../utils/meal'
import { dateFormat } from '../../utils/dates'
import { nestify } from '../../utils/api'
import { prefixPath } from '../../utils/l10n'

import { clearNewEvent } from '../../ducks/event'
import { mealInstancesIndex } from '../../ducks/mealInstances'
import { eventsUpdate, eventsCreate } from '../../ducks/events'
import { selectDay } from '../../ducks/journal'
import { toggleDashboard, startDashboardUpdate } from '../../ducks/dashboard'
import { changeField } from '../../ducks/account'

import NewMealEvent from './NewMealEvent'

const NewMealEventStatefulContainer = () => {
	const {
		isNewEventOpen,
		foodId,
		physicalState,
		isQuickAction,
		isSimple,
		mealKindId: requestedMealKindId,
		eventId,
		schedulableId,
		parentId,
		name,
		isHot,
	} = useSelector((state) => state.event)
	const isNewMealEventOpen = isNewEventOpen && foodId

	const apiData = useSelector((state) => state.apiData)

	const food = foodId && build(apiData, 'food', foodId)

	const foodDensityValue = food?.foodDensity?.value
	const density = foodDensityValue && +foodDensityValue
	const isLiquid = (food?.physicalState ?? physicalState) === 'liquid'
	const asVolume = density || isLiquid

	const [measure, setMeasure] = useState('')

	const isFoodItem = food?.foodable?.type === 'foodItem'
	const isPackSize = (fpId) => isFoodItem && fpId === food?.foodPortion?.id
	const portions = food?.foodPortions
		?.map(({ id, name, value }) => ({
			id,
			isPackSize: isPackSize(id),
			name,
			value: +value,
		}))
		?.reverse()

	const anyPortions = portions?.length

	const withCup = !anyPortions && isLiquid

	const initialMeasure = withCup ? 'cup' : anyPortions ? `portion-${portions[0].id}` : 'amount'
	const withInitialServing = initialMeasure === 'cup'
	const initialServing = withInitialServing ? 1 : ''
	const initialMass = withInitialServing ? cupValueInMl : ''
	const initialMassInOz = withInitialServing ? cupValueInFlOz : ''

	const isSleek = isQuickAction || isSimple

	const shouldSetInitialMeasure = !measure && (food || isSleek)
	useEffect(() => {
		shouldSetInitialMeasure && setMeasure(initialMeasure)
	}, [shouldSetInitialMeasure, initialMeasure, setMeasure])

	const shouldResetInitialMeasure = isQuickAction && measure && measure !== 'cup'
	useEffect(() => {
		shouldResetInitialMeasure && setMeasure('cup')
	}, [shouldResetInitialMeasure, setMeasure])

	const { currentJournalId: journalId, currentDay } = useSelector((state) => state.journal)
	const currentDate = dayjs(currentDay, dateFormat)
	const isToday = dayjs(currentDate).isToday()

	const { isDashboardOpen } = useSelector((state) => state.dashboard)

	const {
		i18n: { language },
		t,
	} = useTranslation([
		'event',
		'events',
		'mealKinds',
		'dishes',
		'nutritionFacts',
		'l10n',
		'validation',
	])

	const quickDrink = isSleek && asVolume
	const mealKindId = requestedMealKindId || defaultMealKindId(quickDrink)

	const logs = build(apiData, 'log', null, { includeType: true })
	const miLogs = logs?.filter(
		(l) =>
			l.journal.id === journalId && l.date === currentDay && l.loggable.type === 'mealInstance',
	)
	const events = miLogs?.map((l) => ({
		id: l.loggable.event.id,
		mealId: l.loggable.event.schedulable.id,
		mealKindId: l.loggable.event.schedulable.mealKind.id,
	}))
	const findEventByMealKindId = (mkId) => events?.find((e) => e.mealKindId === mkId)
	const id = eventId || (findEventByMealKindId(mealKindId)?.id ?? '')
	const mealId = schedulableId || (findEventByMealKindId(mealKindId)?.mealId ?? '')

	const initialValues = useMemo(
		() => ({
			id,
			startDate: currentDay,
			schedulableType: schedulableTypes[1],
			schedulable: {
				id: mealId,
				mealKindId,
				parentId,
				dishes: [
					{
						foodId,
						mass: initialMass,
						massInOz: initialMassInOz,
						volume: '',
						volumeInFlOz: '',
						serving: initialServing,
						servingSizeId: '',
						fixed: isSleek,
					},
				],
			},
			recurrence: '{}',
			journalId,
		}),
		[
			id,
			currentDay,
			mealId,
			mealKindId,
			parentId,
			foodId,
			initialMass,
			initialMassInOz,
			initialServing,
			isSleek,
			journalId,
		],
	)

	const { currentAccountId, field } = useSelector((state) => state.account)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const birthDay = currentAccount?.accountable?.birthDate
	const birthDate = birthDay && dayjs(birthDay)

	const { getAccessTokenSilently } = useAuth0()

	const dispatch = useDispatch()

	const searchEventForMealKindId = useCallback(
		async (mealKindId, day = currentDay) => {
			if (!journalId) return

			// const e = findEventByMealKindId(mealKindId)
			// if (e && Object.values(e).length) {
			// 	return e
			// }

			try {
				const accessToken = await getAccessTokenSilently()

				const r = await dispatch(
					mealInstancesIndex(language, accessToken, {
						of: journalId,
						on: day,
						status: 'accepted',
						forMealKind: mealKindId,
					}),
				)
				const { response: { event, meal } = {} } = r

				if (event && meal) {
					return { id: Object.values(event)[0].id, mealId: Object.values(meal)[0].id }
				}
			} catch (e) {
				// throw e
			}
		},
		[journalId, currentDay, getAccessTokenSilently, language, dispatch],
	)

	const shouldSearchMeal = isNewMealEventOpen && mealKindId && !mealId
	useEffect(() => {
		if (!shouldSearchMeal) return

		const searchEventAndUpdateInitialValues = async () => {
			const r = await searchEventForMealKindId(mealKindId)
			const { id, mealId } = r || {}

			if (id && mealId) {
				initialValues.id = id
				initialValues.schedulable.id = mealId
			}
		}

		searchEventAndUpdateInitialValues()
	}, [shouldSearchMeal, searchEventForMealKindId, mealKindId, initialValues])

	const createOrUpdateEvent = async ({ id, ...eventAttrs }) => {
		const accessToken = await getAccessTokenSilently()

		// Comply with Rails nested attributes.
		const nestedEventAttrs = ['schedulable', 'dishes']
		const nestifiedEventAttrs = nestify(eventAttrs, nestedEventAttrs)

		return dispatch(
			id
				? eventsUpdate(language, accessToken, nestifiedEventAttrs, id)
				: eventsCreate(language, accessToken, nestifiedEventAttrs),
		)
	}

	const updateDash = () => {
		!isDashboardOpen && dispatch(toggleDashboard())
		dispatch(startDashboardUpdate())
	}

	const hideNewEvent = () => dispatch(clearNewEvent())

	let { pathname, search } = useLocation()
	const currentPath = `${pathname}${search}`

	const navigate = useNavigate()

	const navToJournalAllNutrition = () => {
		const newPath = prefixPath('/journal?section=allNutrition', language)

		// Temporary fix...
		field !== 'nutrition' && dispatch(changeField('nutrition'))

		newPath !== currentPath && navigate(newPath)
	}

	const endSubmit = () => {
		hideNewEvent()
		navToJournalAllNutrition()

		updateDash()
	}

	const { isSI } = useSelector((state) => state.measurement)

	return (
		<NewMealEvent
			isNewMealEventOpen={isNewMealEventOpen}
			isQuickAction={isQuickAction}
			isSimple={isSimple}
			name={name ?? food?.name}
			isHot={isHot}
			density={density}
			isLiquid={isLiquid}
			asVolume={asVolume}
			measure={measure}
			setMeasure={setMeasure}
			portions={portions}
			isToday={isToday}
			initialValues={initialValues}
			birthDate={birthDate}
			selectDay={(d) => dispatch(selectDay(d))}
			searchEventForMealKindId={searchEventForMealKindId}
			createOrUpdateEvent={createOrUpdateEvent}
			hideNewEvent={hideNewEvent}
			endSubmit={endSubmit}
			isSI={isSI}
			t={t}
			language={language}
		/>
	)
}

export default NewMealEventStatefulContainer
