// Function component (presentational):

import React from 'react'

import dayjs from 'dayjs'
import _ from 'lodash'

import { Formik } from 'formik'
import {
	Form,
	// Radio,
	// Checkbox,
	TimePicker,
	SubmitButton,
} from 'formik-antd'

import timeUseFormSchema from '../../utils/yupSchema/timeUseFormSchema'
import { dotwOptions, disabledTime } from '../../utils/yupSchema/repliesSchema'
// import { ynValues } from '../../utils/yupSchema/repliesSchema'

import { midnight, timeFormat } from '../../utils/dates'
import { transformRoutineReplies } from '../../utils/lifestyle'
import { transformInvalidAttrErrors } from '../../utils/api'

import FormItem from '../shared/styledComponents/form/FormItem'
import { radioGroupStyle } from '../shared/styledComponents/form/RadioGroup'
import RadioGroup from '../shared/styledComponents/form/RadioGroup'
import RadioButton from '../shared/styledComponents/form/RadioButton'
import { dotwCheckboxGroupStyle } from '../shared/styledComponents/form/DotwCheckboxGroup'
import CheckboxGroup from '../shared/styledComponents/form/DotwCheckboxGroup'
import { timePickerStyle } from '../shared/styledComponents/form/TimePicker'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'
import SubmitContent from '../shared/styledComponents/form/SubmitContent'

import renderQuestionLabel from './renderQuestionLabel'

import { DownOutlined } from '@ant-design/icons'
import { App as AntApp, Segmented } from 'antd'

// const { Group: RadioGroup, Button: RadioButton } = Radio
// const { Group: CheckboxGroup } = Checkbox

const TimeUseForm = ({
	initialValues,
	isOnboarding,
	timeUseTitles,
	isPalResponse,
	timeUseReplies,
	createQuestionnaireResponse,
	isPersonalized,
	goGeneric,
	endSubmit,
	libraStart,
	t,
}) => {
	const { message } = AntApp.useApp()

	const handleSubmit = async (values, { setErrors }) => {
		const [, tValues] = values
		const { replies: tReplies } = tValues

		const {
			2: { value: workValue },
			3: { value: transportValue },
			4: { value: sleepValue },
		} = tReplies

		const workDd = workValue ? dayjs(workValue) : midnight
		const transportDd = dayjs(transportValue)
		const sleepDd = dayjs(sleepValue)

		const workMinutes = workDd.diff(midnight, 'm')
		const transportMinutes = transportDd.diff(midnight, 'm')
		const sleepMinutes = sleepDd.diff(midnight, 'm')
		const sumMinutes = workMinutes + transportMinutes + sleepMinutes
		const residualMinutes = 1440 - 180 - sumMinutes

		if (sumMinutes > 1440) {
			return message.error(t('lifestyle:timeUseForm.message.24hError'))
		} else if (residualMinutes < 0) {
			return libraStart('lifestyle:timeUseForm.speech.limitedLeisure')
		}

		if (_.isEqual(tReplies, timeUseReplies)) {
			message.warning(t('lifestyle:timeUseForm.message.unchangedWarning'))

			endSubmit(values)
			return
		}

		const replies = transformRoutineReplies(tReplies)
		const attrs = _.merge({}, tValues, { replies })

		try {
			const { response } = await createQuestionnaireResponse(attrs)

			if (response) {
				message.success(t('lifestyle:timeUseForm.message.success'))

				endSubmit(values)
			}
		} catch (e) {
			const errors = transformInvalidAttrErrors(e.errors)
			const q2Errors = [null, errors]

			// Async (server-side) validation.
			setErrors(q2Errors)

			const { replies = [] } = errors

			if (replies.length && !replies[0].value) {
				message.error(replies[0])
			}
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={timeUseFormSchema(t)}
			onSubmit={handleSubmit}
		>
			{({
				values,
				values: {
					1: {
						replies: {
							0: { value: workYn },
						},
					},
				},
				setFieldValue,
			}) => {
				const isWorking = workYn === 'yes'

				return (
					<Form
						// id="time-use-form"
						layout="vertical"
						colon={false}
					>
						<FormItem
							name="[1].replies[0].value"
							label={renderQuestionLabel(timeUseTitles[0])}
							help={false} // Temporary...
						>
							<RadioGroup
								name="[1].replies[0].value"
								onChange={(e) => {
									if (e.target.value === 'no') {
										setFieldValue('[1].replies[1].value', [])
										setFieldValue('[1].replies[2].value', null)

										setFieldValue('[2].replies[0].value', 'no')
										setFieldValue('[2].replies[3].value', 'no')
									}
								}}
								style={radioGroupStyle}
							>
								<RadioButton value={'no'}>{t('replies:attributes.ynValues.no.label')}</RadioButton>
								<RadioButton value={'yes'}>
									{t('replies:attributes.ynValues.yes.label')}
								</RadioButton>
							</RadioGroup>
						</FormItem>

						<FormItem
							name="[1].replies[1].value"
							label={renderQuestionLabel(timeUseTitles[1])}
							hidden={!isWorking}
							$columnCheckboxes
						>
							<CheckboxGroup
								name="[1].replies[1].value"
								options={dotwOptions(t)}
								style={dotwCheckboxGroupStyle}
							/>
						</FormItem>

						<FormItem
							name="[1].replies[2].value"
							label={renderQuestionLabel(timeUseTitles[2])}
							hidden={!isWorking}
						>
							<TimePicker
								name="[1].replies[2].value"
								placeholder={t('replies:attributes.ddValue.placeholder')}
								format={timeFormat}
								minuteStep={5}
								changeOnScroll
								disabledTime={() => disabledTime()}
								showNow={false}
								needConfirm={false}
								style={timePickerStyle}
							/>
						</FormItem>

						<FormItem name="[1].replies[3].value" label={renderQuestionLabel(timeUseTitles[3])}>
							<TimePicker
								name="[1].replies[3].value"
								placeholder={t('replies:attributes.ddValue.placeholder')}
								format={timeFormat}
								minuteStep={5}
								changeOnScroll
								disabledTime={() => disabledTime()}
								showNow={false}
								needConfirm={false}
								style={timePickerStyle}
							/>
						</FormItem>

						<FormItem name="[1].replies[4].value" label={renderQuestionLabel(timeUseTitles[4])}>
							<TimePicker
								name="[1].replies[4].value"
								placeholder={t('replies:attributes.ddValue.placeholder')}
								format={timeFormat}
								minuteStep={5}
								changeOnScroll
								disabledTime={() => disabledTime()}
								showNow={false}
								needConfirm={false}
								style={timePickerStyle}
							/>
						</FormItem>

						<SubmitWrapper $step="first">
							<SubmitContent>
								<Segmented
									options={[
										{ value: true, label: t('lifestyle:formSwitch.personalized') },
										{ value: false, label: t('lifestyle:formSwitch.generic') },
									]}
									value={isPersonalized}
									size="small"
									onChange={() => goGeneric(values)}
								/>
							</SubmitContent>

							<SubmitButton
								// htmlType="submit"
								shape="circle"
								icon={<DownOutlined />}
								disabled={!isOnboarding && !isPalResponse && !timeUseReplies}
							/>
						</SubmitWrapper>
					</Form>
				)
			}}
		</Formik>
	)
}

export default TimeUseForm
