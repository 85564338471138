import _ from 'lodash'

export const evalBmi = (bmValue, bhValue) => _.round(+bmValue / (+bhValue / 100) ** 2, 1)

export const categorizeBmi = (bmiValue) => {
	switch (true) {
		case bmiValue < 16:
			return 'severelyUnderweight'
		case bmiValue < 18.5:
			return 'underweight'
		case bmiValue < 25:
			return 'normal'
		case bmiValue < 30:
			return 'overweight'
		case bmiValue < 35:
			return 'obeseClass1'
		case bmiValue < 40:
			return 'obeseClass2'
		case bmiValue >= 40:
			return 'obeseClass3'
		default:
			return 'uncategorized'
	}
}
