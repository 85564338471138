import { createSlice } from '@reduxjs/toolkit'
// import { createAppSlice } from '../store/createAppSlice'

const initialState = {
	isDashboardOpen: '',
	shouldDashboardUpdate: false,
	areFieldsVisible: true,
	isNutritionVisible: false,
	isMindVisible: false,
	isFitessVisible: false,
}

const dashboard = createSlice({
	name: 'dashboard',
	initialState: initialState,
	reducers: {
		// ‘Mutating’ the state is possible thanks to immer (shipped with Redux Toolkit).
		toggleDashboard(state) {
			state.isDashboardOpen = !state.isDashboardOpen
		},
		startDashboardUpdate(state) {
			state.shouldDashboardUpdate = true
		},
		endDashboardUpdate(state) {
			state.shouldDashboardUpdate = false
		},
		toggleFields(state) {
			state.areFieldsVisible = !state.areFieldsVisible
		},
		toggleNutrition(state) {
			state.isNutritionVisible = !state.isNutritionVisible
		},
		toggleMind(state) {
			state.isMindVisible = !state.isMindVisible
		},
		toggleFitness(state) {
			state.isFitnessVisible = !state.isFitnessVisible
		},
	},
})

export default dashboard
export const { reducer: dashboardReducer, actions: dashboardActions } = dashboard
export const {
	toggleDashboard,
	startDashboardUpdate,
	endDashboardUpdate,
	toggleFields,
	toggleNutrition,
	toggleMind,
	toggleFitness,
} = dashboardActions
