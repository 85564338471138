import * as yup from 'yup'

import logsSchema from './logsSchema'
import repliesSchema from './repliesSchema'

const questionnaireResponsesSchema = (t, birthDate = null) =>
	yup.object().shape({
		log: logsSchema(t, birthDate, false),
		replies: yup.array().of(repliesSchema(t)),
	})

export default questionnaireResponsesSchema
