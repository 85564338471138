// Function component (container, using React, Redux, Auth0 & i18next hooks):

// React State & Effect hooks.
import React, { useState, useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'
import dayjs from 'dayjs'

import { accountableTypes } from '../../utils/yupSchema/accountsSchema'

import { getDatesBetween, dateFormat, liteCalendarL10n } from '../../utils/dates'
import { convertKgToLb, convertCmToFt } from '../../utils/numbers'

import { peopleShow } from '../../ducks/people'
import { bodyMeasurementsIndex } from '../../ducks/bodyMeasurements'
import { toggleNewBodyMass, toggleNewBodyHeight } from '../../ducks/measurement'

import MeasurementTracking from './MeasurementTracking'

const MeasurementTrackingStatefulContainer = ({ fromDeck }) => {
	const { currentAccountableType, currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const person = currentAccountableType === accountableTypes[0] && currentAccount.accountable
	const personId = person?.id
	const birthDay = person?.birthDate
	const birthDate = birthDay && dayjs(birthDay)

	const { getAccessTokenSilently } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['measurement', 'objectives', 'bodyMasses', 'bodyHeights', 'bodyMassIndices'])

	const dispatch = useDispatch()

	const { currentDay, currentJournalId: journalId } = useSelector((state) => state.journal)
	const currentDate = dayjs(currentDay, dateFormat)

	const beforeAccountCreation =
		currentAccount && currentDay && currentDate < dayjs(currentAccount.creationDate)

	const bMs = build(apiData, 'bodyMass')
	const bodyMasses = bMs
		?.filter(
			(bm) =>
				bm.bodyMeasurement.state === 'actual' && bm.bodyMeasurement.log.journal.id === journalId,
		)
		?.sort(
			(a, b) =>
				dayjs(b.bodyMeasurement.log.date) > dayjs(a.bodyMeasurement.log.date) ||
				a.bodyMeasurement.log.id - b.bodyMeasurement.log.id,
		)
	const latestBodyMass = bodyMasses?.[0]
	const latestBmValue = latestBodyMass?.value

	const objective = person?.objective

	const shouldViewPerson = !objective?.objectiveKind
	useEffect(() => {
		if (!shouldViewPerson) return

		const viewPerson = async (persId) => {
			try {
				const accessToken = await getAccessTokenSilently()

				const r = await dispatch(peopleShow(language, accessToken, persId))
				return r
			} catch (e) {
				// throw e
			}
		}

		viewPerson(personId)
	}, [shouldViewPerson, getAccessTokenSilently, language, dispatch, personId])

	const [measurementKind, setMeasurementKind] = useState('bodyMass')
	const handleChange = (v) => setMeasurementKind(v)

	const [missingDays, setMissingDays] = useState([])
	const [unavailableDays, setUnavailableDays] = useState([])

	const before = 6
	const startDate = currentDate.subtract(before, 'd')
	const after = 0
	const endDate = currentDate.add(after, 'd')
	const dates = getDatesBetween(startDate, endDate)
	const days = dates.map((d) => d.format(dateFormat))

	const bMts = build(apiData, 'bodyMeasurement', null)
	const measurements = bMts
		?.filter((m) => m.log?.journal?.id === journalId)
		?.sort((a, b) => dayjs(b.log.date) > dayjs(a.log.date) || a.log.id - b.log.id)

	useEffect(() => {
		const availableDays =
			measurements?.map((m) => m.log.date).filter((v, i, a) => a.indexOf(v) === i) || []

		const addMissingDays = (date) =>
			setMissingDays((dates) =>
				!availableDays.includes(date) &&
				!unavailableDays.includes(date) &&
				dates.indexOf(date) === -1
					? [...dates, date]
					: dates,
			)

		days.map((d) => addMissingDays(d))
	}, [days, measurements, unavailableDays])

	const shouldListBodyMeasurements = journalId && missingDays.length
	useEffect(() => {
		if (!shouldListBodyMeasurements) return

		const addUnavailableDays = (dates) => {
			const concat = [...unavailableDays, ...dates].filter((v, i, a) => a.indexOf(v) === i)
			setUnavailableDays(concat)
		}

		const listBodyMeasurements = async () => {
			const accessToken = await getAccessTokenSilently()
			const { response } = await dispatch(
				bodyMeasurementsIndex(language, accessToken, {
					of: journalId,
					onOrAfter: missingDays[0],
					onOrBefore: missingDays[missingDays.length - 1],
				}),
			)

			if (response) {
				setMissingDays([])

				const { log } = response

				const returnedDates =
					(log &&
						Object.values(log)
							.map((l) => l.attributes.date)
							.filter((v, i, a) => a.indexOf(v) === i)) ||
					[]
				const diff = missingDays.filter((d) => !returnedDates.includes(d))
				diff.length && addUnavailableDays(diff)
			}
		}

		listBodyMeasurements()
	}, [
		shouldListBodyMeasurements,
		journalId,
		missingDays,
		unavailableDays,
		getAccessTokenSilently,
		language,
		dispatch,
	])

	const actualMeasurements = measurements?.filter((m) => m.state === 'actual')
	const actualBodyMasses = actualMeasurements?.map((m) => m.bodyMass)?.filter(Boolean)
	const actualBodyHeights = actualMeasurements?.map((m) => m.bodyHeight)?.filter(Boolean)
	const actualBMIs = actualMeasurements?.map((m) => m.bodyMassIndex)?.filter(Boolean)
	const projectedMeasurements = measurements?.filter((m) => m.state === 'projected')
	const projectedBodyMasses = projectedMeasurements?.map((m) => m.bodyMass)?.filter(Boolean)

	const isMaintain = objective?.objectiveKind === 'maintain'
	const objectiveStartDay = objective?.startDate
	const objectiveStartDate = objectiveStartDay && dayjs(objectiveStartDay)
	const initialBmValue = objective?.initialBodyMeasurement?.bodyMass?.value

	const liteDateFormat = (date) => date.calendar(null, liteCalendarL10n(t))

	const findByDay = (bpms, date) =>
		bpms?.findLast(({ bodyMeasurement: { log: { date: day } = {} } = {} }) =>
			dayjs(day).isSame(date),
		)

	const actualBmData = dates.map((d) => {
		const bm = findByDay(actualBodyMasses, d)

		return {
			date: liteDateFormat(d),
			value: bm ? +bm.value : null,
			state: t('measurement:line.series.actual'),
		}
	})
	const projectedBmData = dates.map((d) => {
		const bm = findByDay(projectedBodyMasses, d)
		const withInitialBm = isMaintain && objectiveStartDay && dayjs(d).isAfter(objectiveStartDate)

		return {
			date: liteDateFormat(d),
			value: withInitialBm ? +initialBmValue : bm ? +bm.value : null,
			state: t('measurement:line.series.projected'),
		}
	})

	const latestBmDay = latestBodyMass?.bodyMeasurement?.log?.date
	const latestBmDate = latestBmDay && dayjs(latestBmDay)
	const assumedBmData = dates.map((d) => {
		const withAssumedBm = dayjs(d).isAfter(latestBmDate)

		return {
			date: liteDateFormat(d),
			value: withAssumedBm ? +latestBmValue : null,
			state: t('measurement:line.series.assumed'),
		}
	})

	const withAssumedBm = latestBmDay && dayjs(days[0]).isAfter(latestBmDate)

	const actualOrAssumedBmData = actualBodyMasses?.length
		? actualBmData
		: withAssumedBm
			? assumedBmData
			: []
	const bmData = [...actualOrAssumedBmData, ...projectedBmData]

	const bhData = dates.map((d) => {
		const bh = findByDay(actualBodyHeights, d)

		return {
			date: liteDateFormat(d),
			value: bh ? +bh.value : null,
			state: t('measurement:line.series.actual'),
		}
	})

	const bmiData = dates.map((d) => {
		const bmi = findByDay(actualBMIs, d)

		return {
			date: liteDateFormat(d),
			value: bmi ? +bmi.value : null,
			state: t('measurement:line.series.actual'),
		}
	})

	const baseData = (() => {
		switch (measurementKind) {
			case 'bodyMass':
				return bmData
			case 'bodyHeight':
				return bhData
			case 'bodyMassIndex':
				return bmiData
			default:
				return []
		}
	})()

	const convertedData = baseData.map((d) =>
		d.value
			? {
					...d,
					value: measurementKind === 'bodyMass' ? convertKgToLb(d.value) : convertCmToFt(d.value),
				}
			: d,
	)

	const { isSI } = useSelector((state) => state.measurement)

	const data = !isSI && measurementKind !== 'bodyMassIndex' ? convertedData : baseData

	const r = 0.05
	const dataValues = data.map((d) => d.value).filter(Boolean)
	const dataMin = dataValues.length ? Math.min(...dataValues) : null
	const min = dataMin && Math.floor(dataMin * (1 - r))
	const dataMax = dataValues.length ? Math.max(...dataValues) : null
	const max = dataMax && Math.ceil(dataMax * (1 + r))

	const isLoadingData = dataValues.length === 0 || days.every((d) => !unavailableDays.includes(d))
	const isLoading = isLoadingData && !beforeAccountCreation

	const handleBmClick = () => dispatch(toggleNewBodyMass())

	const handleBmBhClick = () => {
		switch (measurementKind) {
			case 'bodyMass':
				return handleBmClick()
			case 'bodyHeight':
				return dispatch(toggleNewBodyHeight())
			default:
				return null
		}
	}

	const periodBodyMasses = actualBodyMasses?.filter((bm) =>
		days.includes(bm.bodyMeasurement?.log?.date),
	)
	const currentBodyMass = findByDay(periodBodyMasses, currentDay)
	const bmCurrentValue = currentBodyMass?.value

	const disabledMeasurement = !birthDay || !currentDate.isBetween(birthDate, dayjs())
	const disabled = isLoading || disabledMeasurement

	const { isDarkTheme } = useSelector((state) => state.appearance)

	return (
		<MeasurementTracking
			fromDeck={fromDeck}
			measurementKind={measurementKind}
			handleChange={handleChange}
			data={data}
			min={min}
			max={max}
			isLoading={isLoading}
			handleBmClick={handleBmClick}
			handleBmBhClick={handleBmBhClick}
			periodBodyMasses={periodBodyMasses}
			bmCurrentValue={bmCurrentValue}
			disabled={disabled}
			language={language}
			t={t}
			isDarkTheme={isDarkTheme}
		/>
	)
}

export default MeasurementTrackingStatefulContainer
