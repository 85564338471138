import styled from 'styled-components'

import deviceMinWidth from '../../../../styles/deviceMinWidth'

import { Layout } from 'antd'

const { Content } = Layout

const PageWrapper = styled(Content).attrs((props) => ({
	// Defining dynamic props.
	$withPadding: props.$withPadding ?? true,
}))`
	min-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
	@supports (height: 100dvh) {
		min-height: calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
	}

	display: flex;
	flex-direction: column;
	// gap: 16px;

	margin-top: -64px;

	padding-bottom: ${(props) => (props.$withPadding ? '72px' : undefined)};
	// @media ${deviceMinWidth.tabletL} {
	// 	padding-bottom: ${(props) => (props.$withPadding ? 'unset' : undefined)};
	// }

	> div:first-of-type:not(.illustration) {
		margin-top: calc(80px + env(safe-area-inset-top));
	}
`

export default PageWrapper
