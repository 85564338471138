import styled from 'styled-components'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'

const StyledUserControls = styled(FloatMenu).attrs((props) => ({
	// Defining static props.
	$top: 0,
	$left: 'calc(100vw - min(100vw, 384px) - env(safe-area-inset-right))',
	$withSideDash: props.$withSideDash,
}))`
	z-index: 1001;
`

export default StyledUserControls
