// Function component (presentational):

import React from 'react'

import { colors } from '../../styles/colors'

import renderLabel from '../shared/other/renderLabel'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import { DashboardHeader, DashboardBody } from './DashboardStyles'
import DashboardSider from './DashboardSider'

import Icon, {
	DashboardOutlined,
	MenuUnfoldOutlined,
	CloseOutlined,
	FireOutlined,
	LeftOutlined,
	DownOutlined,
} from '@ant-design/icons'
import { ReactComponent as Wellness } from '../../assets/icons/circle.svg'
import { ReactComponent as Nutrition } from '../../assets/icons/water-drop.svg'
import { ReactComponent as Mind } from '../../assets/icons/lotus.svg'
import { Button, Progress, Row, Col } from 'antd'
import TextCircleButton from '../shared/styledComponents/button/TextCircleButton'

const Dashboard = ({
	fromDeck,
	isDashboardOpen,
	hideDashboard,
	navToProfileNutritionTargets,
	navToProfileLifestyle,
	navToProfileMoods,
	areFieldsVisible,
	toggleFields,
	isNutritionVisible,
	toggleNutrition,
	isMindVisible,
	toggleMind,
	isFitnessVisible,
	toggleFitness,
	data,
	t,
	isTablet,
}) => {
	const renderTitle = () =>
		renderLabel({
			icon: <DashboardOutlined />,
			label: t('dashboard:pane.defaultTitle'),
			extra: (
				<TextCircleButton
					icon={isTablet ? <MenuUnfoldOutlined /> : <CloseOutlined />}
					onClick={hideDashboard}
				/>
			),
		})

	const renderHeader = () => <DashboardHeader>{renderTitle()}</DashboardHeader>

	const render360Progress = () => (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				// padding: fromDeck ? '16px' : undefined
			}}
		>
			<Progress type="circle" percent={data.wellnessPct} size={112} />
		</div>
	)

	const renderMiniBody = () => render360Progress()

	const collapseIcon = (isVisible, isSmall = true) =>
		!isVisible ? (
			<LeftOutlined style={{ fontSize: isSmall && '12px' }} />
		) : (
			<DownOutlined style={{ fontSize: isSmall && '12px' }} />
		)

	const renderBody = () => (
		<DashboardBody $isTablet={isTablet}>
			<div style={{ marginBottom: areFieldsVisible && 16 }}>
				<div style={{ marginBottom: 16, fontSize: '16px', fontWeight: 600 }}>
					{renderLabel({
						icon: <Icon component={Wellness} />,
						label: t('dashboard:libra360.title'),
						extra: (
							<TextCircleButton
								icon={collapseIcon(areFieldsVisible, false)}
								onClick={toggleFields}
							/>
						),
					})}
				</div>

				{render360Progress()}
			</div>

			{areFieldsVisible && (
				<>
					<div style={{ marginBottom: 16 }}>
						<div style={{ marginBottom: 4 }}>
							{renderLabel({
								icon: (
									<Button
										shape="circle"
										icon={<Icon component={Nutrition} />}
										onClick={navToProfileNutritionTargets}
									/>
								),
								label: t('dashboard:libra360.nutrition.title'),
								extra: (
									<TextCircleButton
										icon={collapseIcon(isNutritionVisible)}
										onClick={toggleNutrition}
									/>
								),
							})}
						</div>

						<Progress
							percent={data.nutritionPct}
							status="active"
							strokeColor={colors.meal}
							style={{ lineHeight: '32px' }}
						/>

						{isNutritionVisible && (
							<>
								<Row style={{ lineHeight: '32px' }}>
									<Col span={12}>{t('dashboard:libra360.nutrition.hydration')}</Col>
									<Col span={12} style={{ paddingRight: 4 }}>
										<Progress
											percent={data.hydrationPct}
											size="small"
											status="active"
											strokeColor={colors.meal}
										/>
									</Col>
								</Row>

								<Row style={{ lineHeight: '32px' }}>
									<Col span={12}>{t('dashboard:libra360.nutrition.eating')}</Col>
									<Col span={12} style={{ paddingRight: 4 }}>
										<Progress
											percent={data.eatingPct}
											size="small"
											status="active"
											strokeColor={colors.meal}
										/>
									</Col>
								</Row>
							</>
						)}
					</div>

					<div style={{ marginBottom: 16 }}>
						<div style={{ marginBottom: 4 }}>
							{renderLabel({
								icon: (
									<Button shape="circle" icon={<FireOutlined />} onClick={navToProfileLifestyle} />
								),
								label: t('dashboard:libra360.fitness.title'),
								extra: (
									<TextCircleButton icon={collapseIcon(isFitnessVisible)} onClick={toggleFitness} />
								),
							})}
						</div>

						<Progress
							percent={data.fitnessPct}
							status="active"
							strokeColor={colors.activity}
							style={{ lineHeight: '32px' }}
						/>

						{isFitnessVisible && (
							<>
								<Row style={{ lineHeight: '32px' }}>
									<Col span={12}>{t('dashboard:libra360.fitness.sleeping')}</Col>
									<Col span={12} style={{ paddingRight: 4 }}>
										<Progress
											percent={data.sleepingPct}
											size="small"
											status="active"
											strokeColor={colors.activity}
										/>
									</Col>
								</Row>

								<Row style={{ lineHeight: '32px' }}>
									<Col span={12}>{t('dashboard:libra360.fitness.active')}</Col>
									<Col span={12} style={{ paddingRight: 4 }}>
										<Progress
											percent={data.activePct}
											size="small"
											status="active"
											strokeColor={colors.activity}
										/>
									</Col>
								</Row>
							</>
						)}
					</div>

					<div>
						<div style={{ marginBottom: 4 }}>
							{renderLabel({
								icon: (
									<Button
										shape="circle"
										icon={<Icon component={Mind} />}
										onClick={navToProfileMoods}
									/>
								),
								label: t('dashboard:libra360.mind.title'),
								extra: <TextCircleButton icon={collapseIcon(isMindVisible)} onClick={toggleMind} />,
							})}
						</div>

						<Progress
							percent={data.mindPct}
							status="active"
							strokeColor={colors.practice}
							style={{ lineHeight: '32px' }}
						/>

						{isMindVisible && (
							<>
								<Row style={{ lineHeight: '32px' }}>
									<Col span={12}>{t('dashboard:libra360.mind.checkUp')}</Col>
									<Col span={12} style={{ paddingRight: 4 }}>
										<Progress
											percent={data.checkUpPct}
											size="small"
											status="active"
											strokeColor={colors.practice}
										/>
									</Col>
								</Row>

								<Row style={{ lineHeight: '32px' }}>
									<Col span={12}>{t('dashboard:libra360.mind.mindfulness')}</Col>
									<Col span={12} style={{ paddingRight: 4 }}>
										<Progress
											percent={data.mindfulnessPct}
											size="small"
											status="active"
											strokeColor={colors.practice}
										/>
									</Col>
								</Row>
							</>
						)}
					</div>
				</>
			)}
		</DashboardBody>
	)

	return fromDeck ? (
		renderMiniBody()
	) : !isTablet ? (
		<DrawerOrModalContainer
			isOpen={isDashboardOpen}
			hide={hideDashboard}
			renderTitle={renderTitle}
			renderBody={renderBody}
			onlyModal
			fromTop={0}
		/>
	) : (
		<DashboardSider isOpen={isDashboardOpen} renderHeader={renderHeader} renderBody={renderBody} />
	)
}

export default Dashboard
