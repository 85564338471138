import { configureStore } from '@reduxjs/toolkit'
// import { setupListeners } from '@reduxjs/toolkit/query'

import rootReducer from './rootReducer'

import api from '../middleware/api'

const middleware = [api]
if (process.env.NODE_ENV === 'development') {
	const { logger } = require('redux-logger')

	middleware.push(logger)
}

const configureAppStore = (preloadedState) => {
	const store = configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
		preloadedState,
	})

	// Configure listeners using the provided defaults.
	// Optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors...
	// setupListeners(store.dispatch)

	return store
}

export default configureAppStore
