// Function component (container, using Redux & i18next hooks):

import React from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// i18next hook for localization (via React Context).
import { useTranslation } from 'react-i18next'

import { toggleSI } from '../../ducks/measurement'

import SystemSegmented from './SystemSegmented'

const SystemContainer = () => {
	const dispatch = useDispatch()

	const { isSI } = useSelector((state) => state.measurement)

	const { t } = useTranslation('preference')

	return <SystemSegmented handleChange={() => dispatch(toggleSI())} isSI={isSI} t={t} />
}

export default SystemContainer
