import { createSlice } from '@reduxjs/toolkit'
// import { createAppSlice } from '../store/createAppSlice'

const initialState = {
	isNewChatOpen: false,
	content: '',
	isAnswering: false,
}

const assistance = createSlice({
	name: 'assistance',
	initialState: initialState,
	reducers: {
		// ‘Mutating’ the state is possible thanks to immer (shipped with Redux Toolkit).
		toggleNewChat(state) {
			state.isNewChatOpen = !state.isNewChatOpen
		},
		showNewChat(state) {
			state.isNewChatOpen = true
		},
		setContent(state, action) {
			state.content = action.payload
		},
		resetContent(state) {
			state.content = ''
		},
		setAnswering(state, action) {
			state.isAnswering = action.payload
		},
	},
})

export default assistance
export const { reducer: assistanceReducer, actions: assistanceActions } = assistance
export const { toggleNewChat, showNewChat, setContent, resetContent, setAnswering } =
	assistanceActions
