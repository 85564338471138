// Function component (container, using Redux & i18next hooks):

import React from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import { toggleNewEvent, toggleAddActivity, setActivity } from '../../ducks/event'

import AddActivity from './AddActivity'

const AddActivityContainer = () => {
	const { isAddActivityOpen } = useSelector((state) => state.event)

	const dispatch = useDispatch()

	const { t } = useTranslation('event')

	const hideAddActivity = () => dispatch(toggleAddActivity())

	const handleClick = (name, activityKindId) => {
		hideAddActivity()

		dispatch(setActivity({ name, activityKindId }))
		dispatch(toggleNewEvent())
	}

	return (
		<AddActivity
			isAddActivityOpen={isAddActivityOpen}
			hideAddActivity={hideAddActivity}
			handleClick={handleClick}
			t={t}
		/>
	)
}

export default AddActivityContainer
