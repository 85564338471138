import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

const path = '/profiles'

export const profilesCheck = (locale, accessToken, profileAttrs) => (dispatch) => {
	const endpoint = localizePath(locale, `${path}/check`)
	const method = 'POST'
	const profile = { profile: profileAttrs }
	const options = initRequestOptions({ method, accessToken, data: profile })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}
