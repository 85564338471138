// Function component (container, using React, Redux, Auth0 & i18next hooks):

// React State & Effect hooks.
import React, { useState, useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'
import dayjs from 'dayjs'

import { accountableTypes } from '../../utils/yupSchema/accountsSchema'
import { maxAge } from '../../utils/yupSchema/peopleSchema'

import { getDatesBetween, dateFormat } from '../../utils/dates'

import { physicalActivityLevelsIndex } from '../../ducks/physicalActivityLevels'
import { peopleShow } from '../../ducks/people'

import Lifestyle from './Lifestyle'

const LifestyleStatefulContainer = ({ fromDeck }) => {
	const { currentAccountableType, currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const person = currentAccountableType === accountableTypes[0] && currentAccount.accountable
	const personId = person?.id
	const birthDay = person?.birthDate
	const birthDate = birthDay && dayjs(birthDay)

	const { getAccessTokenSilently } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['lifestyle', 'physicalActivityLevels'])

	const dispatch = useDispatch()

	const { currentDay, currentJournalId: journalId } = useSelector((state) => state.journal)
	const currentDate = dayjs(currentDay, dateFormat)

	const beforeAccountCreation =
		currentAccount && currentDate && currentDate < dayjs(currentAccount.creationDate)

	const before = 6
	const startDate = currentDate.subtract(before, 'd')
	const startDay = startDate.format(dateFormat)
	const dates = getDatesBetween(startDate, currentDate)
	const days = dates.map((d) => d.format(dateFormat))

	const pALs = build(apiData, 'physicalActivityLevel')
	const physicalActivityLevels = pALs?.filter(
		(pal) => pal.expenditure.report.journal.id === journalId,
	)
	const periodPALs = physicalActivityLevels?.filter((pal) =>
		days.includes(pal.expenditure.report.startDate),
	)
	const currentPAL = periodPALs?.find(
		({
			expenditure: {
				report: { startDate },
			},
		}) => dayjs(startDate).isSame(currentDay, 'd'),
	)
	const palCurrentValue = currentPAL?.value

	const isLoading = !palCurrentValue && !beforeAccountCreation

	const shouldListPALs = personId && !periodPALs?.length && !beforeAccountCreation
	useEffect(() => {
		if (!shouldListPALs) return

		const listPhysicalActivityLevels = async () => {
			const accessToken = await getAccessTokenSilently()

			return dispatch(
				physicalActivityLevelsIndex(language, accessToken, {
					of: journalId,
					onOrAfter: startDay,
					onOrBefore: currentDay,
					frequency: 'daily',
				}),
			)
		}

		listPhysicalActivityLevels()
	}, [shouldListPALs, journalId, startDay, currentDay, getAccessTokenSilently, language, dispatch])

	const journal = journalId && build(apiData, 'journal', journalId)
	const routineResponse = journal?.routineResponse

	const shouldViewPerson = !routineResponse
	useEffect(() => {
		if (!shouldViewPerson) return

		const viewPerson = async (persId) => {
			try {
				const accessToken = await getAccessTokenSilently()

				const r = await dispatch(peopleShow(language, accessToken, persId))
				return r
			} catch (e) {
				// throw e
			}
		}

		viewPerson(personId)
	}, [shouldViewPerson, getAccessTokenSilently, language, dispatch, personId])

	const [isNewRoutineOpen, setNewRoutineHabitsOpen] = useState(false)
	const toggleNewRoutine = () => setNewRoutineHabitsOpen((v) => !v)

	const disabledRoutine =
		!birthDay || !currentDate.isBetween(dayjs().subtract(1, 'd'), birthDate.add(maxAge, 'y'))
	const disabled = isLoading || disabledRoutine

	return (
		<Lifestyle
			fromDeck={fromDeck}
			periodPALs={periodPALs}
			palCurrentValue={palCurrentValue}
			isLoading={isLoading}
			routineResponse={routineResponse}
			isNewRoutineOpen={isNewRoutineOpen}
			toggleNewRoutine={toggleNewRoutine}
			disabled={disabled}
			language={language}
			t={t}
		/>
	)
}

export default LifestyleStatefulContainer
