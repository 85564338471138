// Function component (stateful & container, using React, Redux, Auth0 & i18next hooks):

import React from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import { today } from '../../utils/dates'
import { convertKgToLb } from '../../utils/numbers'

import { objectiveKinds } from '../../utils/yupSchema/objectivesSchema'

import { nestify } from '../../utils/api'

import { objectivesCreate } from '../../ducks/objectives'
import { speakWithSound } from '../../ducks/libra'

import NewObjective from './NewObjective'

const NewObjectiveContainer = ({
	isNewObjectiveOpen,
	hideNewObjective,
	personId,
	objective,
	latestBmValue,
	birthDate,
}) => {
	const { getAccessTokenSilently } = useAuth0()

	const dispatch = useDispatch()

	const {
		i18n: { language },
		t,
	} = useTranslation(['bodyMasses', 'objective', 'objectives', 'validation'])

	const objectiveKind = objective?.objectiveKind
	const wasLose = objectiveKind === objectiveKinds[1]
	const wasGain = objectiveKind === objectiveKinds[2]
	const desiredBmValue = objective?.bodyMeasurement?.bodyMass?.value

	const defaultObjectiveKind =
		wasLose && latestBmValue > desiredBmValue
			? objectiveKinds[1]
			: wasGain && latestBmValue < desiredBmValue
				? objectiveKinds[2]
				: objectiveKinds[0]

	const initialValues = {
		personId,
		objectiveKind: defaultObjectiveKind,
		startDate: today,
		bodyMeasurement: {
			bodyMass: {
				value: latestBmValue ?? '',
				valueInLb: convertKgToLb(latestBmValue),
			},
		},
		endDate: null,
	}

	const endSubmit = (_values) => hideNewObjective()

	const createObjective = async (objectiveAttrs) => {
		const accessToken = await getAccessTokenSilently()

		// Comply with Rails nested attributes.
		const nestedObjectiveAttrs = ['bodyMeasurement', 'bodyMass']
		const nestifiedObjectiveAttrs = nestify(objectiveAttrs, nestedObjectiveAttrs)

		return dispatch(objectivesCreate(language, accessToken, nestifiedObjectiveAttrs))
	}

	const libraStart = (path, l10nKeys = {}, speech = '') =>
		dispatch(speakWithSound({ path, speech: speech || t(path, l10nKeys), language }))

	const { isSI } = useSelector((state) => state.measurement)

	return (
		<NewObjective
			isNewObjectiveOpen={isNewObjectiveOpen}
			hideNewObjective={hideNewObjective}
			birthDate={birthDate}
			initialValues={initialValues}
			endSubmit={endSubmit}
			createObjective={createObjective}
			libraStart={libraStart}
			isSI={isSI}
			language={language}
			t={t}
		/>
	)
}

export default NewObjectiveContainer
