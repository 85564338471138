import styled from 'styled-components'

import MenuItem from './MenuItem'
import InlineMenuItem from './InlineMenuItem'
import SubMenu from './SubMenu'

import { Menu } from 'antd'

const NavMenu = styled(Menu).attrs((props) => ({
	// Defining dynamic props.
	mode: props.mode ?? 'horizontal',
}))`
	// width: 100%;
	// width: auto
	height: 64px;

	display: flex;

	border-bottom: 1px solid transparent;

	background: transparent;

	// box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
	// 	0 9px 28px 8px rgb(0 0 0 / 5%);

	${MenuItem}, ${InlineMenuItem}, ${SubMenu} {
		width: 64px;

		// margin: 0 !important;

		padding: 0 !important;
	}

	${SubMenu} > .ant-menu-submenu-title {
		width: 100%;
		height: 100%;
	}

	${MenuItem}, ${InlineMenuItem}, ${SubMenu} > .ant-menu-submenu-title {
		display: flex;
		justify-content: center;
		align-items: center;

		> .ant-menu-title-content {
			width: 100%;

			position: absolute;
			bottom: 2px;
			left: 0;

			margin: 0;

			line-height: calc(16px - 2px);
			font-size: 0.75em;
			text-align: center;
		}

		:not(:hover) > .ant-menu-title-content {
			color: transparent;
		}
	}

	${InlineMenuItem} {
		padding-left: 0 !important;

		> .ant-menu-title-content {
			justify-content: center;
		}
	}
`

export default NavMenu
