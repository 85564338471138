import enUS from 'antd/locale/en_US'
import enGB from 'antd/locale/en_GB'
import frFR from 'antd/locale/fr_FR'
import frCA from 'antd/locale/fr_CA'
import frBE from 'antd/locale/fr_BE'
// import esES from 'antd/locale/es_ES'
// import deDE from 'antd/locale/de_DE'
// import ptPT from 'antd/locale/pt_PT'
// import ptBR from 'antd/locale/pt_BR'
// import itIT from 'antd/locale/it_IT'
// import daDK from 'antd/locale/da_DK'
// import csCZ from 'antd/locale/cs_CZ'

export const localeFile = {
	en: enUS,
	'en-US': enUS,
	'en-GB': enGB,
	'en-CA': enUS,
	'en-AU': enUS,
	'en-NZ': enUS,
	'en-IN': enUS,
	fr: frFR,
	'fr-FR': frFR,
	'fr-CA': frCA,
	'fr-CH': frFR,
	'fr-BE': frBE,
	// es: esES,
	// 'es-ES': esES,
	// 'es-US': esES,
	// 'es-MX': esES,
	// 'es-AR': esES,
	// 'es-CO': esES,
	// 'es-CL': esES,
	// de: deDE,
	// 'de-DE': deDE,
	// 'de-AT': deDE,
	// 'de-CH': deDE,
	// pt: ptPT,
	// 'pt-PT': ptPT,
	// 'pt-BR': ptBR,
	// it: itIT,
	// 'it-CH': itIT,
	// da: daDK,
	// cz: csCZ,
}
