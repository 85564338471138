import * as yup from 'yup'

import dayjs from 'dayjs'

import { joinWithQuotesAndAmpersand } from '../misc'

import motherhoodsSchema from './motherhoodsSchema'

export const genders = ['female', 'male']

export const genderValidation = (t) => {
	const label = t('people:attributes.gender.label')
	const valuesLabels = genders.map((v) => t(`people:attributes.genders.${v}.label`))
	const valuesLabelsList = joinWithQuotesAndAmpersand(valuesLabels)

	return yup
		.mixed()
		.oneOf(genders, t('validation:mixed.oneOf', { label, values: valuesLabelsList }))
		.required(t('validation:mixed.required', { label }))
}

// User/Account maximum age in years.
export const maxAge = 123
export const minDate = dayjs().subtract(maxAge, 'y')

// A person under 13 cannot be a User (create an account),
// but her Account could be managed by an over 13 User...
// Account minimum age in months.
const minAge = 0
export const maxDate = dayjs().add(-minAge, 'M').endOf('d')
// User minimum age in years.
export const userMinAge = 13
export const userMaxDate = dayjs().add(-userMinAge, 'y')

export const disabledDate = (current) => {
	// Cannot select days after today.
	return current >= maxDate
}

const birthDateValidation = (t, isFirstAccount) => {
	const label = t('people:attributes.birthDate.label')

	return yup
		.date()
		.min(minDate, t('people:attributes.birthDate.validation.min', { age: maxAge }))
		.max(
			isFirstAccount ? userMaxDate : maxDate,
			isFirstAccount
				? t('people:attributes.birthDate.validation.userMax', { age: userMinAge })
				: t('people:attributes.birthDate.validation.max'),
		)
		.typeError(t('validation:date.typeError', { label }))
		.required(t('validation:mixed.required', { label }))
}

const peopleSchema = (t, isFirstAccount) =>
	yup.object().shape({
		gender: genderValidation(t),
		birthDate: birthDateValidation(t, isFirstAccount),
		motherhoods: yup.array().of(motherhoodsSchema(t)),
	})

export default peopleSchema
