import { createSlice } from '@reduxjs/toolkit'
// import { createAppSlice } from '../store/createAppSlice'
import _ from 'lodash'
import normalize from 'json-api-normalizer'
import { PollyClient, SynthesizeSpeechCommand } from '@aws-sdk/client-polly'

import { CALL_API } from '../middleware/api'
import { initRequestOptions, snakify, nestedURLSearchParams } from '../utils/api'

const initialState = {
	meta: {},
}

const apiData = createSlice({
	name: 'apiData',
	initialState: initialState,
	reducers: {
		// ‘Mutating’ the state is possible thanks to immer (shipped with Redux Toolkit).
		pending(state, action) {
			state.meta = { [action.payload]: { loading: true } }
		},
		fulfilled(state, action) {
			return _.merge(
				{},
				state,
				_.merge({}, action.response, {
					meta: { [action.payload]: { loading: false, success: true } },
				}),
			)
		},
		rejected(state, action) {
			state.meta = { [action.payload]: { loading: false, success: false } }
		},
		received(state, action) {
			_.merge(state, action.payload)
		},
	},
})

export default apiData
export const { reducer: apiDataReducer, actions: apiDataActions } = apiData
export const { pending, fulfilled, rejected, received } = apiDataActions

export const normalizeReceived = (data) => (dispatch) => dispatch(received(normalize(data)))

export const fetchLibraDietApiData = ({
	endpoint,
	options = initRequestOptions(),
	params = {},
} = {}) => {
	const searchParams = Object.keys(params).length ? nestedURLSearchParams(snakify(params)) : params

	return {
		[CALL_API]: {
			endpoint: Object.keys(params).length ? `${endpoint}?${searchParams}` : endpoint,
			options,
		},
	}
}

const awsConfig = {
	credentials: {
		accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
		secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
	},
	region: 'us-east-1',
}

export const fetchAmazonPollySynthesizeSpeechApiData = (params) => {
	const client = new PollyClient(awsConfig)
	const command = new SynthesizeSpeechCommand(params)

	return {
		[CALL_API]: {
			endpoint: 'polly.eu-west-3.amazonaws.com',
			aws: { client, command },
			apiSpecs: {
				proxied: false,
				// type: 'audio',
				naming: 'PascalCase',
				dataFormat: 'speechSynthesis',
			},
		},
	}
}

export const fetchIpGeolocationApiData = () => {
	const endpoint = new URL('https://ipgeolocation.abstractapi.com/v1')
	const params = { api_key: process.env.REACT_APP_ABSTRACT_IP_GEOLOCATION_API_KEY }
	endpoint.search = new URLSearchParams(params).toString()

	return {
		[CALL_API]: {
			endpoint: endpoint.toJSON(),
			apiSpecs: { proxied: false, naming: 'snake_case', dataFormat: 'geolocation' },
		},
	}
}
