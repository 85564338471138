import { createSlice } from '@reduxjs/toolkit'
// import { createAppSlice } from '../store/createAppSlice'

const initialState = {
	isNewEventOpen: false,
	isAddMealOpen: false,
	isAddActivityOpen: false,
	isAddPracticeOpen: false,
	isQuickAction: false,
	isSimple: false,
	eventId: '',
	name: '',
	schedulableId: '',
	parentId: '',
	mealKindId: '',
	foodId: '',
	physicalState: '',
	isHot: false,
	activityKindId: '',
}

const event = createSlice({
	name: 'event',
	initialState,
	reducers: {
		// ‘Mutating’ the state is possible thanks to immer (shipped with Redux Toolkit).
		toggleNewEvent(state) {
			state.isNewEventOpen = !state.isNewEventOpen
		},
		toggleQuickEvent(state) {
			state.isNewEventOpen = !state.isNewEventOpen
			state.isQuickAction = !state.isQuickAction
		},
		toggleAddMeal(state) {
			state.isAddMealOpen = !state.isAddMealOpen
		},
		toggleAddActivity(state) {
			state.isAddActivityOpen = !state.isAddActivityOpen
		},
		toggleAddPractice(state) {
			state.isAddPracticeOpen = !state.isAddPracticeOpen
		},
		setMeal(state, action) {
			state.isSimple = action.payload.isSimple ?? false
			state.eventId = action.payload.eventId ?? ''
			state.name = action.payload.name
			state.schedulableId = action.payload.schedulableId ?? ''
			state.parentId = action.payload.parentId ?? ''
			state.mealKindId = action.payload.mealKindId ?? ''
			state.foodId = action.payload.foodId ?? ''
			state.physicalState = action.payload.physicalState ?? ''
			state.isHot = action.payload.isHot ?? false
		},
		setActivity(state, action) {
			state.name = action.payload.name
			state.activityKindId = action.payload.activityKindId ?? ''
		},
		reset(state) {
			state.isQuickAction = false
			state.isSimple = false
			state.eventId = ''
			state.name = ''
			state.schedulableId = ''
			state.parentId = ''
			state.mealKindId = ''
			state.foodId = ''
			state.physicalState = ''
			state.activityKindId = ''
		},
	},
})

export default event
export const { reducer: eventReducer, actions: eventActions } = event
export const {
	toggleNewEvent,
	toggleQuickEvent,
	toggleAddMeal,
	toggleAddActivity,
	toggleAddPractice,
	setMeal,
	setActivity,
	reset,
} = eventActions

export const clearNewEvent = () => (dispatch) => {
	dispatch(toggleNewEvent())
	dispatch(reset())
}
