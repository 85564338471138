import * as yup from 'yup'

import peopleSchema from './peopleSchema'

const accountPersonFormSchema = (t, isFirst) =>
	yup.object().shape({
		account: yup.object().shape({
			accountable: peopleSchema(t, isFirst),
		}),
	})

export default accountPersonFormSchema
