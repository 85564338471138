import Card from '../styledComponents/card/Card'

import { Skeleton } from 'antd'

const renderSkeletonCard = () => (
	<Card title={<Skeleton title paragraph={false} active />}>
		<Skeleton title={false} paragraph active />
	</Card>
)

export default renderSkeletonCard
