// Function component (container, using Redux & i18next hooks):

import React from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import { dateFormat } from '../../utils/dates'

import { toggleAddPractice } from '../../ducks/event'
import { toggleNewBodyMass } from '../../ducks/measurement'
import { toggleNewMood } from '../../ducks/mood'
import { toggleNewPractice } from '../../ducks/practice'

import AddPractice from './AddPractice'

const AddPracticeContainer = () => {
	const { isAddPracticeOpen } = useSelector((state) => state.event)

	const { currentDay } = useSelector((state) => state.journal)
	const currentDate = dayjs(currentDay, dateFormat)
	const isAfterToday = dayjs(currentDate).isAfter(dayjs(), 'd')

	const dispatch = useDispatch()

	const { t } = useTranslation('event')

	const hideAddPractice = () => dispatch(toggleAddPractice())

	const handleNewWeightClick = () => {
		hideAddPractice()
		dispatch(toggleNewBodyMass())
	}

	const handleNewMoodClick = () => {
		hideAddPractice()
		dispatch(toggleNewMood())
	}

	const handleNewPracticeClick = () => {
		hideAddPractice()
		dispatch(toggleNewPractice())
	}

	const handleClick = (name, foodId, physicalState, isHot) => {
		hideAddPractice()

		// dispatch(setMeal({ isSimple: true, name, foodId, physicalState, isHot }))
		// dispatch(toggleNewEvent())
	}

	return (
		<AddPractice
			isAddPracticeOpen={isAddPracticeOpen}
			isAfterToday={isAfterToday}
			hideAddPractice={hideAddPractice}
			handleNewWeightClick={handleNewWeightClick}
			handleNewMoodClick={handleNewMoodClick}
			handleNewPracticeClick={handleNewPracticeClick}
			handleClick={handleClick}
			t={t}
		/>
	)
}

export default AddPracticeContainer
