// Function component (presentational):

import React from 'react'

import LayoutWrapper from '../shared/styledComponents/page/LayoutWrapper'
import PageWrapper from '../shared/styledComponents/page/PageWrapper'

// import ...
import NavContainer from '../nav/NavContainer'
import LibraContainer from '../libra/LibraStatefulContainer'

import AssistanceContainer from './AssistanceStatefulContainer'

import { Layout } from 'antd'

const AssistanceView = () => (
	<LayoutWrapper style={{ paddingBottom: 'unset' }}>
		<Layout>
			<NavContainer sleekNav />

			<PageWrapper $withPadding={false}>
				<AssistanceContainer />
			</PageWrapper>

			<LibraContainer />
		</Layout>
	</LayoutWrapper>
)

export default AssistanceView
