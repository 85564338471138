// Function component (presentational):

import React from 'react'

import { nutritionalComponentCategories } from '../../utils/yupSchema/nutritionalComponentsSchema'

import Card from '../shared/styledComponents/card/Card'

import Collapse from '../shared/styledComponents/collapse/Collapse'

import renderLabel, { renderNutritionLabel } from '../shared/other/renderLabel'

import { renderCategoryTable } from './renderCategoryTable'
import { buildCategoryItem } from './buildCategoryItem'

import { BuildOutlined } from '@ant-design/icons'

const NutritionFactsCollapse = ({ measure, isSI, dataSource, t }) => {
	const breakdownCategories = nutritionalComponentCategories.slice(1)
	const filteredCategories = breakdownCategories.filter((category) => dataSource(category))

	const items = filteredCategories.flatMap((category) => {
		if (!dataSource(category).length) {
			return []
		} else {
			return buildCategoryItem({ key: 'ncmComposition', t, measure, isSI, dataSource, category })
		}
	})

	const hasBreakdownData = filteredCategories.length !== 0

	return (
		<>
			<Card title={renderNutritionLabel(t('nutrition:proximate.title'))} style={{ height: '100%' }}>
				{renderCategoryTable({
					key: 'ncmComposition',
					t,
					measure,
					isSI,
					dataSource,
					category: 'proximate',
				})}
			</Card>

			{hasBreakdownData && (
				<Card
					title={renderLabel({
						icon: <BuildOutlined />,
						label: t('nutrition:breakdown.title'),
					})}
					$withCollapse
					style={{ height: '100%' }}
				>
					<Collapse items={items} defaultActiveKey={[]} ghost />
				</Card>
			)}
		</>
	)
}

export default NutritionFactsCollapse
