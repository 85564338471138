// Function component (container, using Redux & i18next hooks):

import React from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// i18next hook for localization (via React Context).
import { useTranslation } from 'react-i18next'

import { toggleOSTheme, toggleDarkTheme } from '../../ducks/appearance'

import ThemeOption from './ThemeOption'

// export const themes = ['light', 'dark', 'oS']

const ThemeContainer = ({ theme }) => {
	const dispatch = useDispatch()

	const { isOSTheme, isDarkTheme } = useSelector((state) => state.appearance)

	const isDarkOSTheme = window.matchMedia('(prefers-color-scheme:dark)').matches

	const currentTheme = isOSTheme ? 'oS' : isDarkTheme ? 'dark' : 'light'
	const isCurrentOSTheme = currentTheme === 'oS'

	const shouldSkip = (newTheme) =>
		newTheme === currentTheme ||
		(newTheme === 'light' && isCurrentOSTheme && !isDarkOSTheme) ||
		(newTheme === 'dark' && isCurrentOSTheme && isDarkOSTheme)

	const handleSelect = (newTheme) => {
		if (shouldSkip(newTheme)) return

		dispatch(toggleOSTheme())
		newTheme !== 'oS' && dispatch(toggleDarkTheme())
	}

	const { t } = useTranslation('preference')

	return (
		<ThemeOption theme={theme} disabled={shouldSkip(theme)} handleSelect={handleSelect} t={t} />
	)
}

export default ThemeContainer
