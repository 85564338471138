// Function component (presentational):

// import React, { useRef } from 'react'
import React from 'react'

import { dateFormat } from '../../utils/dates'

import Carousel from '../shared/styledComponents/Carousel'

import JournalTimeline from './JournalTimeline'

const JournalCarousel = ({
	daysBefore,
	slidesToShow,
	dates,
	// next,
	// prev,
	showCalendar,
	journalId,
	unavailableDays,
	logs,
	field,
	handleClick,
	t,
}) => {
	const dailyEntryLogs = (date) => logs && Object.values(logs).filter((l) => date.isSame(l.date))

	const isLoadingData = (date) =>
		dailyEntryLogs(date) &&
		dailyEntryLogs(date).length === 0 &&
		!unavailableDays.includes(date.format(dateFormat))

	// const carousel = useRef()

	// const handleChange = (currentIndex, nextIndex) => {
	// 	if (nextIndex > currentIndex) {
	// 		next()
	// 	} else if (nextIndex < currentIndex) {
	// 		prev()
	// 	}
	// }

	return (
		<Carousel
			// ref={carousel}
			initialSlide={daysBefore}
			slidesToShow={slidesToShow}
			infinite={false}
			// swipe={false}
			// beforeChange={handleChange}
			dots={false}
			// dotPosition="top"
			// centerMode={true}
		>
			{dates?.map((d) => (
				<JournalTimeline
					key={d.format(dateFormat)}
					showCalendar={showCalendar}
					date={d}
					dailyEntryLogs={dailyEntryLogs(d)}
					isLoading={journalId && isLoadingData(d)}
					field={field}
					handleClick={handleClick}
					t={t}
				/>
			))}
		</Carousel>
	)
}

export default JournalCarousel
