import { combineSlices } from '@reduxjs/toolkit'

import { appearanceReducer as appearance } from '../ducks/appearance'
import { libraReducer as libra } from '../ducks/libra'
import { assistanceReducer as assistance } from '../ducks/assistance'
import { apiDataReducer as apiData } from '../ducks/apiData'
import { accountReducer as account } from '../ducks/account'
import { journalReducer as journal } from '../ducks/journal'
import { dashboardReducer as dashboard } from '../ducks/dashboard'
import { measurementReducer as measurement } from '../ducks/measurement'
import { eventReducer as event } from '../ducks/event'
import { practiceReducer as mood } from '../ducks/mood'
import { practiceReducer as practice } from '../ducks/practice'

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore it is no longer needed to call `combineReducers`.
const rootReducer = combineSlices({
	appearance,
	libra,
	assistance,
	apiData,
	account,
	journal,
	dashboard,
	measurement,
	event,
	mood,
	practice,
})

export default rootReducer
