// Function component (presentational):

import React from 'react'

import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'

import { normalize } from '../../utils/misc'

import searchFormSchema from '../../utils/yupSchema/searchFormSchema'

import FormItem from '../shared/styledComponents/form/FormItem'

// import renderLabel from '../shared/other/renderLabel'

import L10nLink from '../shared/navigation/L10nLink'

import { FilterOutlined, ScanOutlined, SearchOutlined } from '@ant-design/icons'
import { AutoComplete } from 'antd'
import TextRoundButton from '../shared/styledComponents/button/TextRoundButton'

const SearchForm = ({
	initialValues,
	options,
	scope,
	loading,
	setSearchTerm,
	// searchData,
	t,
}) => {
	const handleSubmit = ({ search }) => {
		setSearchTerm(search)

		// try {
		// 	searchData(search)
		// } catch (e) {
		// 	throw e
		// }
	}

	const prefix = (
		<TextRoundButton
			icon={<FilterOutlined />}
			// onClick={showFilters}
			disabled
		/>
	)
	const suffix = scope === 'foodItem' && (
		<L10nLink to="/scanner">
			<TextRoundButton icon={<ScanOutlined />} />
		</L10nLink>
	)

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={searchFormSchema(t)}
			onSubmit={handleSubmit}
		>
			{({ values: { search } }) => (
				<Form
					// id="search-form"
					layout="vertical"
					colon={false}
				>
					<FormItem
						name="search"
						// label={renderLabel({ label: t('library:form.search.label') })}
						hasFeedback={false}
						style={{ marginBottom: 0 }}
					>
						<AutoComplete
							options={options}
							filterOption={(input, option) =>
								normalize(option?.value ?? '').includes(normalize(input))
							}
							onSelect={(value) => handleSubmit({ search: value })}
							// size="large"
							style={{ height: '100%' }}
						>
							<Input
								name="search"
								placeholder={t(`library:form.search.placeholder.${scope}`)}
								size="large"
								allowClear
								prefix={prefix}
								suffix={suffix}
								addonAfter={
									<SubmitButton
										// htmlType="submit"
										shape="circle"
										icon={<SearchOutlined />}
										loading={loading}
										disabled={!search}
										ghost
										style={{ border: 'none' }}
									/>
								}
							/>
						</AutoComplete>
					</FormItem>
				</Form>
			)}
		</Formik>
	)
}

export default SearchForm
