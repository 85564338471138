import { createSlice } from '@reduxjs/toolkit'
// import { createAppSlice } from '../store/createAppSlice'

const initialState = {
	isOSTheme: true,
	isDarkTheme: false,
}

const appearance = createSlice({
	name: 'appearance',
	initialState: initialState,
	reducers: {
		// ‘Mutating’ the state is possible thanks to immer (shipped with Redux Toolkit).
		toggleOSTheme(state) {
			state.isOSTheme = !state.isOSTheme
		},
		toggleDarkTheme(state) {
			state.isDarkTheme = !state.isDarkTheme
		},
	},
})

export default appearance
export const { reducer: appearanceReducer, actions: appearanceActions } = appearance
export const { toggleOSTheme, toggleDarkTheme } = appearanceActions
