import renderLabel from '../shared/other/renderLabel'

import Icon from '@ant-design/icons'
import { ReactComponent as Activity } from '../../assets/icons/footprints.svg'
import { ReactComponent as Sleeping } from '../../assets/icons/sleeping-bubble.svg'

const buildActivityItem = ({ handleClick, t, key, id }) => {
	const name = t(`event:addActivity.${key}`)

	return {
		key: `activity-kind-id-${id}`,
		icon: <Icon component={id === '244' ? Sleeping : Activity} />,
		label: renderLabel({
			label: <span onClick={() => handleClick(name, id)}>{name}</span>,
		}),
	}
}

export const buildNappingItem = (handleClick, t) =>
	buildActivityItem({ handleClick, t, key: 'napping', id: '244' })

export const buildTvWatchingItem = (handleClick, t) =>
	buildActivityItem({ handleClick, t, key: 'tvWatching', id: '237' })

export const buildCookingItem = (handleClick, t) =>
	buildActivityItem({ handleClick, t, key: 'cooking', id: '146' })

export const buildCleaningItem = (handleClick, t) =>
	buildActivityItem({ handleClick, t, key: 'cleaning', id: '137' })

export const buildWalkingItem = (handleClick, t) =>
	buildActivityItem({ handleClick, t, key: 'walking', id: '673' })

export const buildBicyclingItem = (handleClick, t) =>
	buildActivityItem({ handleClick, t, key: 'bicycling', id: '5' })

export const buildJoggingItem = (handleClick, t) =>
	buildActivityItem({ handleClick, t, key: 'jogging', id: '473' })

export const buildStairClimbingItem = (handleClick, t) =>
	buildActivityItem({ handleClick, t, key: 'stairClimbing', id: '666' })

export const buildSquattingItem = (handleClick, t) =>
	buildActivityItem({ handleClick, t, key: 'squatting', id: '40' })

export const buildYogaPracticingItem = (handleClick, t) =>
	buildActivityItem({ handleClick, t, key: 'yogaPracticing', id: '67' })

export const buildStretchingItem = (handleClick, t) =>
	buildActivityItem({ handleClick, t, key: 'stretching', id: '56' })
