import { domToReact } from 'html-react-parser'

export const replaceTags = (nodeOptions) => ({
	replace: ({ type, name, attribs: { class: classAttr } = {}, children }) => {
		for (const { tagName, className, component: Component } of nodeOptions) {
			if (type === 'tag' && name === tagName && (classAttr === className || !className)) {
				return <Component>{domToReact(children, replaceTags(nodeOptions))}</Component>
			}
		}
	},
})
