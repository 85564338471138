import styled from 'styled-components'

import { Typography } from 'antd'

const { Text } = Typography

export const AccountTitle = styled(Text).attrs(() => ({
	// Defining static props.
	type: 'secondary',
}))`
	width: auto;

	position: absolute;
	top: 16px;
	left: 70px;

	z-index: 1;

	padding: 2px 0;

	font-size: 12px;
`
