import styled from 'styled-components'

import Wrapper from './Wrapper'
import Title from '../text/Title'

const TitleWrapper = styled(Wrapper)`
	min-height: 64px;

	// flex-direction: column;
	justify-content: center;
	align-items: center;
	// gap: 16px;

	padding: 0 16px;

	text-align: center;

	// .ant-typography {
	// 	margin-bottom: 0;
	// }
	> ${Title} {
		margin-bottom: 0;
	}

	z-index: 2;
`

export default TitleWrapper
