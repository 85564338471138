// Function HOC/Higher Order Component
// wrapping App components to use ActionCable
// (container, using Auth0 hook):

import React, { createContext, useState, useEffect } from 'react'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'

import ActionCable from 'actioncable'

export const CableContext = createContext()

const CableProvider = ({ children }) => {
	const actionCableUrl = `${
		process.env.NODE_ENV === 'production'
			? 'wss://' + process.env.REACT_APP_LIBRA_DIET_API_HOST
			: 'ws://localhost:4000'
	}/cable`

	const { isAuthenticated, getAccessTokenSilently } = useAuth0()

	const [token, setToken] = useState('')

	const shouldSetToken = isAuthenticated && !token
	useEffect(() => {
		if (!shouldSetToken) return

		const getAndSetToken = async () => {
			const accessToken = await getAccessTokenSilently()

			accessToken && setToken(accessToken)
		}

		getAndSetToken()
	}, [shouldSetToken, getAccessTokenSilently])

	const CableApp = {}
	if (token) {
		const urlWithToken = actionCableUrl + `?token=${token}`
		CableApp.cable = ActionCable.createConsumer(urlWithToken)
	}

	return <CableContext.Provider value={CableApp.cable}>{children}</CableContext.Provider>
}

export default CableProvider
