// Function component:

import React from 'react'

import NavDate from './NavDate'

const NavDateContainer = ({ sleekNav, isDashboardOpen, isMobileM }) => (
	<NavDate sleekNav={sleekNav} isDashboardOpen={isDashboardOpen} isMobileM={isMobileM} />
)

export default NavDateContainer
