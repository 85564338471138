// Function component (presentational):

// React State hook.
import React, { useState } from 'react'

import dayjs from 'dayjs'
import _ from 'lodash'

import { Formik } from 'formik'
import {
	Form,
	// Radio,
	DatePicker,
	SubmitButton,
} from 'formik-antd'

import accountPersonFormSchema from '../../utils/yupSchema/accountPersonFormSchema'
import {
	genders,
	disabledDate as disabledBirthDate,
	minDate as minBirthDate,
	maxDate as maxBirthDate,
} from '../../utils/yupSchema/peopleSchema'
import {
	disabledDate as disabledDueDate,
	minDate as minDueDate,
	maxDate as maxDueDate,
} from '../../utils/yupSchema/motherhoodsSchema'

import { dateFormat } from '../../utils/dates'
import { transformInvalidAttrErrors } from '../../utils/api'

import FormItem from '../shared/styledComponents/form/FormItem'
import RadioGroup from '../shared/styledComponents/form/RadioGroup'
import RadioButton from '../shared/styledComponents/form/RadioButton'
import { radioGroupStyle } from '../shared/styledComponents/form/RadioGroup'
import { datePickerStyle } from '../shared/styledComponents/form/DatePicker'

import { renderUserLabel, renderCheckLabel, renderDateLabel } from '../shared/other/renderLabel'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'

import {
	InfoCircleOutlined,
	ExclamationCircleOutlined,
	UpOutlined,
	DownOutlined,
} from '@ant-design/icons'
import { App as AntApp, Switch, Button } from 'antd'

// const { Group: RadioGroup, Button: RadioButton } = Radio

const PersonForm = ({ initialValues, isFirst, goBack, createUserAccount, endSubmit, t }) => {
	const [pregnantOrLactating, setPregnantOrLactating] = useState(false)
	const toggleMotherhood = () => setPregnantOrLactating((v) => !v)

	const { message } = AntApp.useApp()

	const handleSubmit = async (values, { setErrors }) => {
		const {
			account: {
				accountable: {
					birthDate,
					motherhoods: {
						0: { dueDate },
					},
				},
			},
		} = values

		const birthDay = dayjs(birthDate).format(dateFormat)
		const dueDay = dueDate ? dayjs(dueDate).format(dateFormat) : ''
		const attrs = _.merge({}, values, {
			account: { accountable: { birthDate: birthDay, motherhoods: [{ dueDate: dueDay }] } },
		})

		try {
			const { response } = await createUserAccount(attrs)

			if (response) {
				message.success(t('account:personForm.message.success'))

				endSubmit(values)
			}
		} catch (e) {
			const errors = transformInvalidAttrErrors(e.errors)

			// Async (server-side) validation.
			setErrors(errors)
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={accountPersonFormSchema(t, isFirst)}
			onSubmit={handleSubmit}
		>
			{({
				values,
				values: {
					account: {
						accountable: { gender },
					},
				},
				setFieldValue,
			}) => {
				const isFemale = gender === genders[0]

				return (
					<Form
						// id="account-person-form"
						layout="vertical"
						colon={false}
					>
						<FormItem
							name="account.accountable.gender"
							label={renderUserLabel(t('people:attributes.gender.label'))}
							tooltip={{
								icon: <InfoCircleOutlined />,
								title: t('people:attributes.gender.tooltip'),
							}}
						>
							<RadioGroup
								name="account.accountable.gender"
								onChange={(e) => {
									if (e.target.value === 'no') {
										setFieldValue('account.accountable.motherhoods[0].dueDate', null)
									}
								}}
								style={radioGroupStyle}
							>
								<RadioButton value={'female'}>
									{t('people:attributes.genders.female.label')}
								</RadioButton>
								<RadioButton value={'male'}>
									{t('people:attributes.genders.male.label')}
								</RadioButton>
							</RadioGroup>
						</FormItem>

						{isFemale && (
							<div style={{ marginBottom: 16 }}>
								{renderCheckLabel(
									t('account:personForm.pregnantOrLactating.label'),
									<Switch
										checked={pregnantOrLactating}
										onChange={toggleMotherhood}
										style={{ marginRight: 5 }}
									/>,
								)}
							</div>
						)}

						<FormItem
							name="account.accountable.motherhoods[0].dueDate"
							label={renderDateLabel(t('motherhoods:attributes.dueDate.label'))}
							hidden={!isFemale || !pregnantOrLactating}
						>
							<DatePicker
								name="account.accountable.motherhoods[0].dueDate"
								format="l"
								disabledDate={disabledDueDate}
								minDate={minDueDate}
								maxDate={maxDueDate}
								style={datePickerStyle}
							/>
						</FormItem>

						<FormItem
							name="account.accountable.birthDate"
							label={renderDateLabel(t('people:attributes.birthDate.label'))}
							tooltip={
								isFirst && {
									icon: <ExclamationCircleOutlined />,
									title: t('people:attributes.birthDate.tooltip'),
								}
							}
						>
							<DatePicker
								name="account.accountable.birthDate"
								format="l"
								disabledDate={disabledBirthDate}
								minDate={minBirthDate}
								maxDate={maxBirthDate}
								// allowClear={false}
								style={datePickerStyle}
							/>
						</FormItem>

						<SubmitWrapper>
							<Button shape="circle" icon={<UpOutlined />} onClick={() => goBack(values)} />

							<SubmitButton
								// htmlType="submit"
								shape="circle"
								icon={<DownOutlined />}
							/>
						</SubmitWrapper>
					</Form>
				)
			}}
		</Formik>
	)
}

export default PersonForm
