// Function component (container, using Redux & i18next hooks):

import React from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
import { useMediaQuery } from 'react-responsive'

import build from 'redux-object'
import dayjs from 'dayjs'

import { dateFormat } from '../../utils/dates'

import { selectDay, toggleCalendar } from '../../ducks/journal'

import Calendar from './Calendar'

const CalendarContainer = () => {
	const { currentDay, isCalendarOpen } = useSelector((state) => state.journal)
	const currentDate = dayjs(currentDay, dateFormat)

	const { currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const birthDay = currentAccount?.accountable?.birthDate
	const birthDate = birthDay && dayjs(birthDay)

	const { t } = useTranslation('journal')

	const dispatch = useDispatch()

	const handleSelect = (date) => {
		const day = date.format(dateFormat)
		day !== currentDay && dispatch(selectDay(day))
	}

	const isLarge = useMediaQuery({
		query: '(min-width: 481px) and (min-height: 854px)',
	})

	return (
		<Calendar
			isCalendarOpen={isCalendarOpen}
			currentDate={currentDate}
			birthDate={birthDate}
			handleSelect={handleSelect}
			hideCalendar={() => dispatch(toggleCalendar())}
			t={t}
			isLarge={isLarge}
		/>
	)
}

export default CalendarContainer
