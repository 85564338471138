import styled from 'styled-components'

import { Layout } from 'antd'

const StyledLayout = styled(Layout)`
	height: 100vh;
	@supports (height: 100dvh) {
		height: 100dvh;
	}

	display: flex;
	justify-content: center;
	align-items: center;
`

export default StyledLayout
