// Function component (presentational):

import React from 'react'

// import AntDrawer from '../styledComponents/Drawer'

import { Drawer as AntDrawer } from 'antd'

const Drawer = ({
	isOpen,
	renderTitle,
	canClose,
	handleClose,
	// size,
	isTablet,
	// isLaptopML,
	withSideDash,
	placement = 'bottom',
	side,
	renderBody,
}) => {
	// const isLarge = size === 'large'
	// const canBeLarge = (isLarge && isTablet && !withSideDash) || (isLarge && isLaptopML)

	// const width = canBeLarge ? 768 : 384

	const isPlacementTop = placement === 'top'
	const isPlacementBottom = placement === 'bottom'
	const isSideRight = side === 'right'
	const isSideLeft = side === 'left'

	const marginRight =
		isPlacementTop && isSideRight && isTablet
			? withSideDash
				? 'calc(384px + env(safe-area-inset-right))'
				: 'env(safe-area-inset-right)'
			: (isPlacementBottom || isPlacementTop) && withSideDash
				? '50vw'
				: 'auto'

	const marginLeft = isSideLeft && isTablet ? 'env(safe-area-inset-left)' : 'auto'

	return (
		<AntDrawer
			// id="drawer"
			open={isOpen}
			title={renderTitle()}
			closable={false}
			maskClosable={canClose}
			keyboard={canClose}
			onClose={handleClose}
			// footer={null}
			placement={placement}
			// width={width}
			style={{ marginRight, marginLeft }}
		>
			{renderBody()}
		</AntDrawer>
	)
}

export default Drawer
