// Function component (container, using React, Redux, Auth0 & i18next hooks):

// React State & Effect hooks.
import React, { useState, useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'
import dayjs from 'dayjs'

import { accountableTypes } from '../../utils/yupSchema/accountsSchema'

import { moodQuestionnaireId } from '../../utils/mood'
import { getDatesBetween, dateFormat, liteCalendarL10n } from '../../utils/dates'

import { questionnaireResponsesIndex } from '../../ducks/questionnaireResponses'
import { toggleNewMood } from '../../ducks/mood'

import MoodTracking from './MoodTracking'

const MoodTrackingStatefulContainer = ({ fromDeck }) => {
	const [missingDays, setMissingDays] = useState([])
	const [unavailableDays, setUnavailableDays] = useState([])

	const { currentDay, currentJournalId: journalId } = useSelector((state) => state.journal)
	const currentDate = dayjs(currentDay, dateFormat)

	const before = 6
	const startDate = currentDate.subtract(before, 'd')
	const after = 0
	const endDate = currentDate.add(after, 'd')
	const dates = getDatesBetween(startDate, endDate)
	const days = dates.map((d) => d.format(dateFormat))

	const { currentAccountableType, currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const person = currentAccountableType === accountableTypes[0] && currentAccount.accountable
	const birthDay = person?.birthDate
	const birthDate = birthDay && dayjs(birthDay)

	const beforeAccountCreation =
		currentAccount && currentDay && currentDate < dayjs(currentAccount.creationDate)

	const qRs = build(apiData, 'questionnaireResponse')
	const moodResponses = qRs
		?.filter(
			(qr) => qr.log.journal?.id === journalId && qr.questionnaire.id === moodQuestionnaireId,
		)
		?.sort((a, b) => dayjs(b.log.date) > dayjs(a.log.date) || a.log.id - b.log.id)

	useEffect(() => {
		const availableDays =
			moodResponses?.map((m) => m.log.date).filter((v, i, a) => a.indexOf(v) === i) || []

		const addMissingDays = (date) =>
			setMissingDays((dates) =>
				!availableDays.includes(date) &&
				!unavailableDays.includes(date) &&
				dates.indexOf(date) === -1
					? [...dates, date]
					: dates,
			)

		days.map((d) => addMissingDays(d))
	}, [days, moodResponses, unavailableDays])

	const { getAccessTokenSilently } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['mood', 'replies'])

	const dispatch = useDispatch()

	const shouldListMoods = journalId && missingDays.length
	useEffect(() => {
		if (!shouldListMoods) return

		const addUnavailableDays = (dates) => {
			const concat = [...unavailableDays, ...dates].filter((v, i, a) => a.indexOf(v) === i)
			setUnavailableDays(concat)
		}

		const listMoods = async () => {
			const accessToken = await getAccessTokenSilently()
			const { response } = await dispatch(
				questionnaireResponsesIndex(language, accessToken, {
					about: moodQuestionnaireId,
					of: journalId,
					onOrAfter: missingDays[0],
					onOrBefore: missingDays[missingDays.length - 1],
				}),
			)

			if (response) {
				setMissingDays([])

				const { log } = response

				const returnedDates =
					(log &&
						Object.values(log)
							.map((l) => l.attributes.date)
							.filter((v, i, a) => a.indexOf(v) === i)) ||
					[]
				const diff = missingDays.filter((d) => !returnedDates.includes(d))
				diff.length && addUnavailableDays(diff)
			}
		}

		listMoods()
	}, [
		shouldListMoods,
		journalId,
		missingDays,
		unavailableDays,
		getAccessTokenSilently,
		language,
		dispatch,
	])

	const liteDateFormat = (date) => date.calendar(null, liteCalendarL10n(t))

	const findByDate = (qrs, date) =>
		qrs?.findLast(({ log: { date: day } }) => dayjs(day).isSame(date))

	const data = dates.map((d) => {
		const qr = findByDate(moodResponses, d)

		return {
			date: liteDateFormat(d),
			moodRating: qr ? +qr.replies[0].value : null,
		}
	})

	const dataValues = data.map((d) => d.moodRating).filter(Boolean)
	const isLoadingData = dataValues.length === 0 || days.every((d) => !unavailableDays.includes(d))
	const isLoading = isLoadingData && !beforeAccountCreation

	const periodMoodResponses = moodResponses?.filter((qr) => days.includes(qr.log.date))
	const currentMoodResponse = findByDate(periodMoodResponses, currentDay)
	const moodCurrentValue = currentMoodResponse && currentMoodResponse.replies[0].value

	const disabledMood = !birthDay || !currentDate.isBetween(birthDate, dayjs())
	const disabled = isLoading || disabledMood

	const { isDarkTheme } = useSelector((state) => state.appearance)

	return (
		<MoodTracking
			fromDeck={fromDeck}
			data={data}
			isLoading={isLoading}
			showNewMood={() => dispatch(toggleNewMood())}
			periodMoodResponses={periodMoodResponses}
			moodCurrentValue={moodCurrentValue}
			disabled={disabled}
			language={language}
			t={t}
			isDarkTheme={isDarkTheme}
		/>
	)
}

export default MoodTrackingStatefulContainer
