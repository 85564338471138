import styled from 'styled-components'

import { Carousel as AntCarousel } from 'antd'

const Carousel = styled(AntCarousel)`
	padding: 8px 0;

	.slick-list {
		max-width: ${(props) => `calc(384px * ${props.slidesToShow})`};
		margin: auto;
	}

	.slick-slide {
		max-width: 384px;
	}

	.ant-card-head {
		text-align: center;
	}
`

export default Carousel
