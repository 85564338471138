// Function component (stateful & container, using React, Redux, Auth0 & i18next hooks):

// React State & Effect hooks.
import React, { useState, useMemo, useEffect, useCallback } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization (via React Context).
import { useTranslation } from 'react-i18next'

import build from 'redux-object'
import _ from 'lodash'

import { lifestyles } from '../../utils/yupSchema/physicalActivityLevelsSchema'

import {
	palQuestionnaireId,
	timeUseQuestionnaireId,
	exertionQuestionnaireId,
} from '../../utils/lifestyle'
import { setDateFromTime, today } from '../../utils/dates'
import { nestify } from '../../utils/api'

import { questionnaireResponsesCreate } from '../../ducks/questionnaireResponses'
import { questionnairesShow } from '../../ducks/questionnaires'
import { speakWithSound, repeat } from '../../ducks/libra'

import NewRoutine from './NewRoutine'

const NewRoutineStatefulContainer = ({
	isOnboarding,
	isNewRoutineOpen,
	close,
	routineResponse,
	palAvgLifestyle,
}) => {
	const [step, setStep] = useState(1)
	const nextStep = () => setStep((prev) => prev + 1)
	const prevStep = () => setStep((prev) => prev - 1)

	const [pausedStep, setPausedStep] = useState(1)

	const backStep = () => setStep(pausedStep)
	const quickStep = () => {
		setPausedStep(step)
		setStep(0)
	}

	const apiData = useSelector((state) => state.apiData)

	const { currentJournalId: journalId } = useSelector((state) => state.journal)

	const timeUseQuestionnaire = build(apiData, 'questionnaire', timeUseQuestionnaireId)
	const timeUseQuestions = timeUseQuestionnaire?.questions
	const timeUseTitles = timeUseQuestions?.map((q) => q.titleInHtml)
	const exertionQuestionnaire = build(apiData, 'questionnaire', exertionQuestionnaireId)
	const exertionQuestions = exertionQuestionnaire?.questions
	const exertionTitles = exertionQuestions?.map((q) => q.titleInHtml)

	const { getAccessTokenSilently } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['lifestyle', 'replies', 'physicalActivityLevels', 'validation'])

	const dispatch = useDispatch()

	const viewQuestionnaire = useCallback(
		async (questionnaireId) => {
			try {
				const accessToken = await getAccessTokenSilently()

				const r = await dispatch(questionnairesShow(language, accessToken, questionnaireId))
				return r
			} catch (e) {
				// throw e
			}
		},
		[getAccessTokenSilently, language, dispatch],
	)

	const shouldViewTimeUseQuestionnaire = !timeUseQuestionnaire
	useEffect(() => {
		shouldViewTimeUseQuestionnaire && viewQuestionnaire(timeUseQuestionnaireId)
	}, [shouldViewTimeUseQuestionnaire, viewQuestionnaire])

	const shouldViewActivitisQuestionnaire = timeUseQuestionnaire && !exertionQuestionnaire
	useEffect(() => {
		shouldViewActivitisQuestionnaire && viewQuestionnaire(exertionQuestionnaireId)
	}, [shouldViewActivitisQuestionnaire, viewQuestionnaire])

	const buildReplyFromQ = (q, v = undefined) => {
		const kind = _.camelCase(q.replyKind)
		const value =
			kind === 'daysOfTheWeek'
				? v?.length
					? v.split(',')
					: []
				: kind === 'dailyDuration'
					? v
						? setDateFromTime(v)
						: null
					: v

		return { questionId: q.id, kind, value, parentQuestionId: q.parent?.id ?? '' }
	}

	const buildReply = ({ question, value }) => question && buildReplyFromQ(question, value)

	const routineFirstReply = routineResponse?.replies?.[0]
	const isPalResponse = routineFirstReply?.question?.replyKind === 'lifestyle'
	const exertionResponse = !isPalResponse ? routineResponse : undefined
	const timeUseResponse = exertionResponse?.timeUseResponse

	const timeUseReplies = timeUseResponse && timeUseResponse.replies.map((r) => buildReply(r))
	const exertionReplies = exertionResponse && exertionResponse.replies.map((r) => buildReply(r))

	const initialValues = useMemo(() => {
		const date = today
		const replyInitialValues = {
			questionId: '',
			kind: '',
			value: undefined,
			parentQuestionId: '',
		}

		return [
			{
				questionnaireId: palQuestionnaireId,
				log: { journalId, date },
				replies: [{ questionId: 1, kind: 'lifestyle', value: '' }],
			},
			{
				questionnaireId: timeUseQuestionnaireId,
				log: { journalId, date },
				replies: timeUseReplies ?? Array(5).fill(replyInitialValues),
			},
			{
				questionnaireId: exertionQuestionnaireId,
				log: { journalId, date },
				replies: exertionReplies ?? Array(16).fill(replyInitialValues),
			},
		]
	}, [journalId, timeUseReplies, exertionReplies])

	const [formData, setFormData] = useState(initialValues)

	const goBack = (values) => {
		setFormData(values)
		prevStep()
	}

	const goGeneric = (values) => {
		setFormData(values)
		quickStep()
	}
	const goPersonalized = (values) => {
		setFormData(values)
		backStep()
	}

	const endSubmit = (values) => {
		setFormData(values)
		step !== 1 ? close() : nextStep()
	}

	const updateInitialReplies = useCallback(
		(replies, i) => {
			const data = [{}, {}, {}]
			data[i] = { replies }
			const updatedInitialValues = _.merge([], [...formData], data)

			setFormData(updatedInitialValues)
		},
		[formData],
	)

	const withInitialPalReply = formData[0].replies[0].value

	const shouldUpdateInitialPalReply = (isOnboarding || palAvgLifestyle) && !withInitialPalReply
	useEffect(() => {
		const pReplies = [
			{ questionId: 1, kind: 'lifestyle', value: isOnboarding ? lifestyles[1] : palAvgLifestyle },
		]

		shouldUpdateInitialPalReply && updateInitialReplies(pReplies, 0)
	}, [shouldUpdateInitialPalReply, updateInitialReplies, isOnboarding, palAvgLifestyle])

	const withInitialTimeUseReplies = formData[1].replies[0].questionId
	const timeUseRepliesFromQ = timeUseQuestions?.map((q) => buildReplyFromQ(q))
	const tReplies = isOnboarding || isPalResponse ? timeUseRepliesFromQ : timeUseReplies

	const shouldUpdateInitialTimeUseReplies =
		(((isOnboarding || isPalResponse) && timeUseQuestions) || timeUseResponse) &&
		!withInitialTimeUseReplies
	useEffect(() => {
		shouldUpdateInitialTimeUseReplies && updateInitialReplies(tReplies, 1)
	}, [shouldUpdateInitialTimeUseReplies, updateInitialReplies, tReplies])

	const withInitialExertionReplies = formData[2].replies[0].questionId
	const exertionRepliesFromQ = exertionQuestions?.map((q) => buildReplyFromQ(q))
	const eReplies = isOnboarding || isPalResponse ? exertionRepliesFromQ : exertionReplies

	const shouldUpdateInitialExertionReplies =
		(((isOnboarding || isPalResponse) && exertionQuestions) || exertionResponse) &&
		!withInitialExertionReplies
	useEffect(() => {
		shouldUpdateInitialExertionReplies && updateInitialReplies(eReplies, 2)
	}, [shouldUpdateInitialExertionReplies, updateInitialReplies, eReplies])

	const createQuestionnaireResponse = async (questionnaireResponseAttrs) => {
		const accessToken = await getAccessTokenSilently()

		// Comply with Rails nested attributes.
		const nestedQuestionnaireResponseAttrs = ['log', 'replies']
		const nestifiedQuestionnaireResponseAttrs = nestify(
			questionnaireResponseAttrs,
			nestedQuestionnaireResponseAttrs,
		)

		return dispatch(
			questionnaireResponsesCreate(language, accessToken, nestifiedQuestionnaireResponseAttrs),
		)
	}

	const { speech } = useSelector((state) => state.libra)

	const libraStart = (path, opts = {}) => {
		const newSpeech = t(path)

		if (newSpeech === speech) return dispatch(repeat())

		dispatch(speakWithSound({ path, speech: newSpeech, ...opts, language }))
	}

	return (
		<NewRoutine
			isOnboarding={isOnboarding}
			isNewRoutineOpen={isNewRoutineOpen}
			hideNewRoutine={close}
			step={step}
			isPalResponse={isPalResponse}
			palLifestyle={isPalResponse ? routineFirstReply?.value : undefined}
			palAvgLifestyle={palAvgLifestyle}
			timeUseReplies={timeUseReplies}
			withInitialTimeUseReplies={withInitialTimeUseReplies}
			exertionReplies={exertionReplies}
			withInitialExertionReplies={withInitialExertionReplies}
			formData={formData}
			goBack={goBack}
			goGeneric={goGeneric}
			goPersonalized={goPersonalized}
			endSubmit={endSubmit}
			timeUseTitles={timeUseTitles}
			exertionTitles={exertionTitles}
			createQuestionnaireResponse={createQuestionnaireResponse}
			libraStart={libraStart}
			t={t}
		/>
	)
}

export default NewRoutineStatefulContainer
