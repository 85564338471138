import * as yup from 'yup'

import objectivesSchema from './objectivesSchema'

const firstObjectiveFormSchema = (t) =>
	yup.object().shape({
		objective: objectivesSchema(t),
	})

export default firstObjectiveFormSchema
