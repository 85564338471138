// Function component (presentational):

import React from 'react'

import NavButton from '../shared/styledComponents/button/NavButton'

import { AudioOutlined, EditOutlined, ClearOutlined } from '@ant-design/icons'

import StyledUserControls from './UserControlsStyles'

const UserControls = ({
	withSideDash,
	isListening,
	handleListening,
	showNewChat,
	transcript,
	resetTranscript,
	t,
}) => {
	const renderSpeaking = () => (
		<NavButton icon={<AudioOutlined />} onClick={handleListening}>
			{t('nav:libraMenu.actions.speak')}
		</NavButton>
	)

	const renderTyping = () => (
		<NavButton icon={<EditOutlined />} onClick={showNewChat}>
			{t('nav:libraMenu.actions.type')}
		</NavButton>
	)

	const renderClear = () => (
		<NavButton icon={<ClearOutlined />} onClick={resetTranscript}>
			{t('libra:reset.label')}
		</NavButton>
	)

	return (
		<StyledUserControls $withSideDash={withSideDash}>
			{!isListening ? renderSpeaking() : renderTyping()}
			{isListening && transcript && renderClear()}
		</StyledUserControls>
	)
}

export default UserControls
