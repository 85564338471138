// Function component (presentational):

import React from 'react'

import _ from 'lodash'
import dayjs from 'dayjs'
import parse from 'html-react-parser'

import { decimalCommaFormatter } from '../../utils/misc'
import { categorizeLifestyle } from '../../utils/lifestyle'

import L10nLink from '../shared/navigation/L10nLink'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'

import NewRoutineContainer from './NewRoutineStatefulContainer'

import { FireOutlined, LoadingOutlined, ScheduleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Skeleton, Descriptions, Typography } from 'antd'
import BlockRoundButton from '../shared/styledComponents/button/BlockRoundButton'

const { Text } = Typography

const Lifestyle = ({
	fromDeck,
	isLoading,
	palCurrentValue,
	periodPALs,
	routineResponse,
	isNewRoutineOpen,
	toggleNewRoutine,
	disabled,
	language,
	t,
}) => {
	const palItems = [
		{
			key: 'currentValue',
			label: t('lifestyle:physicalActivityLevel.currentValue.label'),
			children: !palCurrentValue
				? t('lifestyle:physicalActivityLevel.currentValue.unavailable')
				: decimalCommaFormatter(palCurrentValue, language),
			span: 2,
		},
	]

	const palCurrentLifestyle = palCurrentValue && categorizeLifestyle(palCurrentValue)

	const daysCount =
		(periodPALs && _.uniq(periodPALs.map((pal) => pal.expenditure.report.startDate)).length) ?? 0

	const currentLifestyleItem = {
		key: 'currentLifestyle',
		label: t('lifestyle:physicalActivityLevel.currentLifestyle.label'),
		children: palCurrentLifestyle && (
			<Text mark={daysCount <= 1}>
				{t(`physicalActivityLevels:attributes.lifestyles.${palCurrentLifestyle}.label`)}
			</Text>
		),
		span: 2,
	}
	palCurrentValue && palItems.push(currentLifestyleItem)

	const palAvgValue = periodPALs && _.round(_.mean(periodPALs.map((pal) => +pal.value)), 2)

	const avgValueItem = {
		key: 'avgValue',
		label: t('lifestyle:physicalActivityLevel.avgValue.label', { daysCount }),
		children: decimalCommaFormatter(palAvgValue, language),
		span: 2,
	}

	const palAvgLifestyle = palAvgValue && categorizeLifestyle(palAvgValue)

	const avgLifestyleItem = {
		key: 'avgLifestyle',
		label: t('lifestyle:physicalActivityLevel.avgLifestyle.label'),
		children: palAvgLifestyle && (
			<Text mark>{t(`physicalActivityLevels:attributes.lifestyles.${palAvgLifestyle}.label`)}</Text>
		),
		span: 2,
	}

	if ((palCurrentValue && daysCount > 1) || (!palCurrentValue && daysCount >= 1)) {
		palItems.push(avgValueItem, avgLifestyleItem)
	}

	const isPalResponse = routineResponse?.replies?.[0]?.question?.replyKind === 'lifestyle'

	const routineDay = routineResponse?.log?.date
	const routineDate = routineDay && dayjs(routineDay).format('LL')

	return (
		<>
			<Card
				title={renderLabel({
					icon: fromDeck ? (
						<L10nLink to={{ pathname: 'profile', search: '?section=lifestyle' }}>
							<Button shape="circle" icon={<FireOutlined />} />
						</L10nLink>
					) : (
						<FireOutlined />
					),
					label: t('lifestyle:physicalActivityLevel.title'),
					extra: isLoading ? <LoadingOutlined /> : null,
				})}
				style={{ height: '100%' }}
			>
				<Skeleton loading={isLoading} title={false} paragraph={{ rows: 4 }} active></Skeleton>

				{!isLoading && <Descriptions column={2} items={palItems} />}
			</Card>

			{!fromDeck && (
				<Card
					title={renderLabel({
						icon: <ScheduleOutlined />,
						label: t('lifestyle:routine.title'),
						extra: !routineResponse ? <LoadingOutlined /> : null,
					})}
					style={{ height: '100%' }}
				>
					{routineResponse && (
						<div style={{ padding: '5px 8px' }}>
							<Text>
								{parse(
									isPalResponse
										? t('lifestyle:routine.generic.label')
										: t('lifestyle:routine.personalized.label', { date: routineDate }),
								)}
							</Text>
						</div>
					)}

					<BlockRoundButton
						type={isPalResponse ? 'primary' : 'default'}
						onClick={toggleNewRoutine}
						disabled={disabled}
						style={{ marginTop: !routineResponse ? undefined : 8 }}
					>
						{renderLabel({
							icon: <ScheduleOutlined />,
							label: t('lifestyle:actions.addRoutine'),
							extra: <PlusOutlined />,
						})}
					</BlockRoundButton>
				</Card>
			)}

			<NewRoutineContainer
				isNewRoutineOpen={isNewRoutineOpen}
				close={toggleNewRoutine}
				routineResponse={routineResponse}
				palAvgLifestyle={palAvgLifestyle}
			/>
		</>
	)
}

export default Lifestyle
