// Function component (presentational):

// import React, { Suspense } from 'react'
import React from 'react'

import PageIllustration from '../shared/styledComponents/page/PageIllustration'
import PageAction from '../shared/styledComponents/page/PageAction'

import Card from '../shared/styledComponents/card/Card'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'
import NavButton from '../shared/styledComponents/button/NavButton'

// import renderSkeletonCard from '../shared/other/renderSkeletonCard'

import Tabs from '../shared/styledComponents/tabs/Tabs'
// import TabPaneWrapper from '../shared/styledComponents/tabs/TabPaneWrapper'

import pattern from '../../assets/patterns/abstract-1.jpg'

import Icon from '@ant-design/icons'
import { ReactComponent as Torch } from '../../assets/icons/torch.svg'

import Reader from './ScannerStyles'

const Profile = ({
	section,
	handleSectionChange,
	// toggleTorch,
	t,
	isDarkTheme,
}) => {
	const items = [
		{
			key: 'history',
			label: t('scanner:sections.history.title'),
			// children: (
			// 	<TabPaneWrapper key="measurements-pane">
			// 		<Suspense fallback={renderSkeletonCard()}>
			// 			<ScanHistoryContainer />
			// 		</Suspense>
			// 	</TabPaneWrapper>
			// ),
		},
		{
			key: 'popular',
			label: t('scanner:sections.popular.title'),
			// children: (
			// 	<TabPaneWrapper key="measurements-pane">
			// 		<Suspense fallback={renderSkeletonCard()}>
			// 			<ScanPopularContainer />
			// 		</Suspense>
			// 	</TabPaneWrapper>
			// ),
			disabled: true,
		},
	]

	return (
		<>
			<PageIllustration $src={pattern} $isDarkTheme={isDarkTheme} />

			<PageAction style={{ position: 'relative' }}>
				<Card $size={'wide'} style={{ marginTop: -80 }}>
					<Reader id="reader"></Reader>
				</Card>

				<FloatMenu style={{ position: 'absolute', bottom: 0, margin: '0 8px 8px 8px' }}>
					<NavButton
						icon={<Icon component={Torch} />}
						// onClick={toggleTorch}
						disabled
					>
						{t('scanner:actions.turnOnTorch')}
					</NavButton>
				</FloatMenu>
			</PageAction>

			<Tabs activeKey={section} items={items} onChange={handleSectionChange}></Tabs>
		</>
	)
}

export default Profile
