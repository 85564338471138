// Function component (container, using React, Redux, React Router & i18next hooks):

// React Effect hook.
import React, { useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hooks for routing.
import { useMatch } from 'react-router-dom'
// i18next hook for localization (via React Context).
import { useTranslation } from 'react-i18next'

import build from 'redux-object'

import {
	selectAccountAndJournal,
	showInitAccounts,
	toggleAccounts,
	toggleAccountsWithNew,
} from '../../ducks/account'

import { App as AntApp } from 'antd'

import Accounts from './Accounts'

const AccountsContainer = () => {
	const {
		isAccountsOpen,
		isAccountsWithNew: withNew,
		currentAccountId,
		accountIds,
	} = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const accounts =
		accountIds.length !== 0 && build(apiData, 'account', accountIds, { includeType: true })

	const { t } = useTranslation('account')

	const dispatch = useDispatch()

	let { params: { navKey = '' } = {} } = useMatch('/:locale/:navKey') || {}
	const inOnboarding = navKey === 'onboarding'

	const shouldSelectAccountAndJournal =
		accounts && !currentAccountId && !inOnboarding && !isAccountsOpen
	useEffect(() => {
		if (!shouldSelectAccountAndJournal) return

		if (Object.values(accounts).length === 1) {
			const {
				id: accountId,
				accountableType,
				accountable: {
					journal: { id: journalId },
				},
			} = Object.values(accounts)[0]

			dispatch(selectAccountAndJournal({ accountId, accountableType, journalId }))
		} else {
			dispatch(showInitAccounts())
		}
	}, [shouldSelectAccountAndJournal, accounts, dispatch])

	const hideAccounts = () => dispatch(toggleAccounts())

	const { message } = AntApp.useApp()

	const handleAccountSelect = (id) => {
		if (id === currentAccountId) return

		const account = build(apiData, 'account', id)
		const {
			accountableType,
			accountable: {
				journal: { id: journalId },
			},
			username,
		} = account

		dispatch(selectAccountAndJournal({ accountId: id, accountableType, journalId }))

		hideAccounts()
		!withNew && dispatch(toggleAccountsWithNew())

		message.success(
			t(`account:accounts.message.${!withNew ? 'success' : 'altSuccess'}`, { username }),
		)
	}

	return (
		<Accounts
			isAccountsOpen={isAccountsOpen}
			withNew={withNew}
			hideAccounts={hideAccounts}
			currentAccountId={currentAccountId}
			accounts={accounts}
			handleAccountSelect={handleAccountSelect}
			t={t}
		/>
	)
}

export default AccountsContainer
