import _ from 'lodash'

export const localizePath = (locale, path) => {
	let localizedPath = ''
	if (locale) {
		localizedPath += `/${locale}`
	}
	// Using the `trim()` method from the lodash library
	// to remove leading and trailing specified character ('/') from string.
	return (localizedPath += `/${_.trim(path, '/')}`)
}

const appendAttributes = (key) => `${key}Attributes`

const nestifyAttributes = (attrsArray) => (_value, key) =>
	attrsArray.includes(key) ? appendAttributes(key) : key

const snakey = (_value, key) => _.snakeCase(key)
const camelkey = (_value, key) => _.camelCase(key)

const mapKeysDeep = (obj, cb) => {
	if (_.isArray(obj)) {
		return obj.map((innerObj) => mapKeysDeep(innerObj, cb))
	} else if (_.isObject(obj)) {
		return _.mapValues(_.mapKeys(obj, cb), (val) => mapKeysDeep(val, cb))
	} else {
		return obj
	}
}

// Transform selected keys by appending `Attributes`.
export const nestify = (attr, nestedAttrs) => mapKeysDeep(attr, nestifyAttributes(nestedAttrs))

// Transform keys (from camelCase) to snake_case.
export const snakify = (object) => mapKeysDeep(object, snakey)
// Transform keys (from snake_case) to camelCase.
export const camelify = (object) => mapKeysDeep(object, camelkey)

export const initRequestOptions = ({ method = 'GET', accessToken = '', data } = {}) => {
	const options = {
		// *GET, POST, PATCH/PUT & DELETE HTTP verbs.
		method: method,
		headers: {
			'Content-Type': 'application/json',
			// Comply with the JSON:API specification.
			// 'Content-Type': 'application/vnd.api+json'
		},
	}

	if (accessToken) {
		options.headers['Authorization'] = `Bearer ${accessToken}`
	}

	if (data) {
		// Comply with Rails snake_case attributes.
		const snakifiedData = snakify(data)
		// Body data type must match 'Content-Type' header.
		options['body'] = JSON.stringify(snakifiedData)
	}

	return options
}

export const defaultApiSpecs = { proxied: true, naming: 'camelCase', dataFormat: 'JSON:API' }

export const transformInvalidAttrErrors = (errors) => {
	let invalidAttrErrors = {}
	errors.forEach((error) => {
		const {
			status,
			source: { pointer },
			detail,
		} = error
		// Invalid status code.
		if (status === '422') {
			const path = pointer.split('/').slice(-1)[0]

			const concatMsg = (newMsg) => {
				const msg = _.get(invalidAttrErrors, path, [])
				msg.push(`${newMsg}\n`)
				return msg
			}
			_.set(invalidAttrErrors, path, concatMsg(detail))
		}
	})
	return invalidAttrErrors
}

export const duplicateInvalidAttrErrors = (errors, path, sourcePath) => {
	const value = _.get(errors, sourcePath)
	_.set(errors, path, value)
	return errors
}

export const readStream = async (stream) => {
	const reader = stream.getReader()

	let buffer = []

	while (true) {
		const { value, done } = await reader.read()
		if (done) break

		buffer.push(value)
	}

	if (buffer.length) {
		const blob = new Blob(buffer, { type: 'audio/mp3' })
		const url = URL.createObjectURL(blob)
		return url
	}
}

export const nestedURLSearchParams = (obj, prefix) => {
	let str = [],
		p
	for (p in obj) {
		if (obj.hasOwnProperty(p)) {
			let k = prefix ? prefix + '[' + p + ']' : p,
				v = obj[p]
			str.push(
				v !== null && typeof v === 'object'
					? nestedURLSearchParams(v, k)
					: encodeURIComponent(k) + '=' + encodeURIComponent(v),
			)
		}
	}
	return str.join('&')
}
