import { createSlice } from '@reduxjs/toolkit'
// import { createAppSlice } from '../store/createAppSlice'

const initialState = {
	isNewPracticeOpen: false,
	questionnaireId: '',
}

const practice = createSlice({
	name: 'practice',
	initialState: initialState,
	reducers: {
		// ‘Mutating’ the state is possible thanks to immer (shipped with Redux Toolkit).
		toggleNewPractice(state) {
			state.isNewPracticeOpen = !state.isNewPracticeOpen
		},
		setPractice(state, action) {
			state.questionnaireId = action.questionnaireId
		},
	},
})

export default practice
export const { reducer: practiceReducer, actions: practiceActions } = practice
export const { toggleNewPractice, setPractice } = practiceActions
