import * as yup from 'yup'

import { convertGToOz, convertMlToFlOz } from '../numbers'

// Maximum mass in g.
export const maxMass = 100000
// Maximum mass in oz.
export const maxMassInOz = convertGToOz(maxMass)
// Maximum volume in g.
export const maxVolume = 100000
// Maximum volume in oz.
export const maxVolumeInFlOz = convertMlToFlOz(maxVolume)

const massValidation = (t) => {
	const label = t('dishes:attributes.mass.label')

	return (
		yup
			.number()
			.positive(t('validation:number.positive', { label }))
			.max(maxMass, t('dishes:attributes.mass.validation.max', { mass: maxMass }))
			.nullable()
			// .typeError(t('validation:number.typeError', { label }))
			.required(t('validation:mixed.required', { label }))
	)
}

const massInOzValidation = (t) => {
	const label = t('dishes:attributes.massInOz.label')

	return (
		yup
			.number()
			.positive(t('validation:number.positive', { label }))
			.max(maxMassInOz, t('dishes:attributes.massInOz.validation.max', { mass: maxMassInOz }))
			.nullable()
			// .typeError(t('validation:number.typeError', { label }))
			.required(t('validation:mixed.required', { label }))
	)
}

const volumeValidation = (t) => {
	const label = t('dishes:attributes.volume.label')

	return yup
		.number()
		.positive(t('validation:number.positive', { label }))
		.max(maxVolume, t('dishes:attributes.volume.validation.max', { volume: maxVolume }))
		.nullable()
	// .typeError(t('validation:number.typeError', { label }))
	// .required(t('validation:mixed.required', { label }))
}

const volumeInFlOzValidation = (t) => {
	const label = t('dishes:attributes.volume.label')

	return yup
		.number()
		.positive(t('validation:number.positive', { label }))
		.max(maxVolumeInFlOz, t('dishes:attributes.volume.validation.max', { volume: maxVolume }))
		.nullable()
	// .typeError(t('validation:number.typeError', { label }))
	// .required(t('validation:mixed.required', { label }))
}

const servingValidation = (t) => {
	const label = t('dishes:attributes.serving.label')

	return yup.number().positive(t('validation:number.positive', { label })).nullable()
}

const dishesSchema = (t) =>
	yup.object().shape({
		mass: massValidation(t),
		massInOz: massInOzValidation(t),
		volume: volumeValidation(t),
		volumeInFlOz: volumeInFlOzValidation(t),
		serving: servingValidation(t),
	})

export default dishesSchema
