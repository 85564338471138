// Function component (presentational):

import React from 'react'

import { getInitials } from '../../utils/misc'

import { Avatar as AntAvatar } from 'antd'

import { UserOutlined } from '@ant-design/icons'

const Avatar = ({ src, label, size }) => (
	<AntAvatar src={src} icon={!label && <UserOutlined />} size={size}>
		{label && getInitials(label)}
	</AntAvatar>
)

export default Avatar
