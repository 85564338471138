import styled from 'styled-components'

import { Checkbox } from 'formik-antd'

const StyledCheckbox = styled(Checkbox)`
	// padding-left: 8px;
`

export default StyledCheckbox

export const checkboxStyle = { paddingLeft: 8 }
