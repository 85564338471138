import { colors } from '../../styles/colors'
import { miscStyles } from '../../styles/misc'

const token = {
	colorPrimary: colors.primary,
	glassBackdropFilter: miscStyles.glassBackdropFilter,
}

export const lightToken = {
	...token,
	colorTextBase: colors.libraNight,
	glassBackgroundColor: miscStyles.lightGlassBackgroundColor,
	backgroundColor: miscStyles.lightBackgroundColor,
}
export const darkToken = {
	...token,
	glassBackgroundColor: miscStyles.darkGlassBackgroundColor,
	backgroundColor: miscStyles.darkBackgroundColor,
	// boxShadow: miscStyles.darkBoxShadow,
}

const components = {
	// Button: {
	// 	dangerColor: colors.primary,
	// },
	Menu: {
		itemBorderRadius: 20,
	},
	DatePicker: {
		paddingInline: 9,
		borderRadius: 16,
		borderRadiusLG: 20,
		borderRadiusSM: 14,
	},
	// TimePicker: {
	// 	borderRadius: 16,
	// 	borderRadiusLG: 20,
	// 	borderRadiusSM: 14,
	// },
	Radio: {
		borderRadius: 16,
		borderRadiusSM: 12,
		borderRadiusLG: 20,
	},
	InputNumber: {
		borderRadius: 16,
	},
	Message: {
		borderRadiusLG: 20,
		paddingXS: 4,
	},
	Segmented: {
		borderRadius: 16,
		borderRadiusSM: 14,
		borderRadiusXS: 10,
		borderRadiusLG: 22,
	},
	Input: {
		borderRadius: 16,
		borderRadiusLG: 20,
	},
	Card: {
		paddingLG: 8,
		borderRadiusLG: 24,
	},
	Tag: {
		borderRadiusSM: 11,
	},
	Collapse: {
		headerPadding: '9px 10px',
		contentPadding: 0,
		borderRadiusLG: 16,
	},
	Table: {
		headerBorderRadius: 16,
	},
	Timeline: {
		itemPaddingBottom: 11,
	},
	Select: {
		borderRadius: 16,
		borderRadiusLG: 20,
		borderRadiusSM: 16,
	},
	// Typography: {
	// 	colorLink: colors.primary,
	// 	colorLinkActive: colors.primary,
	// 	colorLinkHover: colors.primary,
	// },
	Steps: {
		margin: 8,
	},
	Progress: {
		defaultColor: colors.primary,
		colorSuccess: colors.libraStar,
	},
}

export const lightComponents = {
	...components,
	Image: {
		colorBgMask: miscStyles.lightGlassBackgroundColor,
	},
}
export const darkComponents = {
	...components,
	Image: {
		colorBgMask: miscStyles.darkGlassBackgroundColor,
	},
}

const mask = {
	WebkitBackdropFilter: miscStyles.glassBackdropFilter,
	backdropFilter: miscStyles.glassBackdropFilter,
}

const lightMask = {
	...mask,
	backgroundColor: miscStyles.lightGlassBackgroundColor,
}
const darkMask = {
	...mask,
	backgroundColor: miscStyles.darkGlassBackgroundColor,
}

const drawerHeader = {
	padding: 8,
	marginBottom: 8,
	borderRadius: 24,
	WebkitBackdropFilter: miscStyles.glassBackdropFilter,
	backdropFilter: miscStyles.glassBackdropFilter,
	borderBottom: 'none',
}

const lightDrawerHeader = {
	...drawerHeader,
	backgroundColor: miscStyles.lightGlassBackgroundColor,
}
const darkDrawerHeader = {
	...drawerHeader,
	backgroundColor: miscStyles.darkGlassBackgroundColor,
}

const footer = {
	padding: 8,
	marginTop: 8,
	borderRadius: 24,
	backdropFilter: miscStyles.glassBackdropFilter,
	'webkit-backdropFilter': miscStyles.glassBackdropFilter,
	borderTop: 'none',
}

const lightFooter = {
	...footer,
	background: miscStyles.lightGlassBackgroundColor,
}
const darkFooter = {
	...footer,
	background: miscStyles.darkGlassBackgroundColor,
}

const drawerStyles = {
	wrapper: {
		height: 'unset',
		boxShadow: 'none',
	},
	content: {
		maxWidth: 384,
		padding: 8,
		// marginLeft: 'auto',
		borderRadius: 24,
		background: 'none',
	},
	body: {
		padding: 0,
		borderRadius: 24,
		maxHeight: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 72px)',
	},
}

export const lightDrawerStyles = {
	mask: lightMask,
	header: lightDrawerHeader,
	...drawerStyles,
	footer: lightFooter,
}
export const darkDrawerStyles = {
	mask: darkMask,
	header: darkDrawerHeader,
	...drawerStyles,
	footer: darkFooter,
}

const modalHeader = {
	maxWidth: 368,
	// marginRight: 'auto',
	marginLeft: 'auto',
	padding: 8,
	borderRadius: 24,
	WebkitBackdropFilter: miscStyles.glassBackdropFilter,
	backdropFilter: miscStyles.glassBackdropFilter,
}

const lightModalHeader = {
	...modalHeader,
	backgroundColor: miscStyles.lightGlassBackgroundColor,
}
const darkModalHeader = {
	...modalHeader,
	backgroundColor: miscStyles.darkGlassBackgroundColor,
}

const modalStyles = {
	wrapper: {},
	content: {
		padding: 8,
		borderRadius: 24,
		background: 'none',
		boxShadow: 'none',
	},
	body: {
		maxHeight: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 72px)',
		overflow: 'auto',
		borderRadius: 24,
	},
}

export const lightModalStyles = {
	mask: lightMask,
	header: lightModalHeader,
	...modalStyles,
	footer: lightFooter,
}
export const darkModalStyles = {
	mask: darkMask,
	header: darkModalHeader,
	...modalStyles,
	footer: darkFooter,
}
