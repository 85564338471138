// Function component (presentational):

import React from 'react'

import _ from 'lodash'

import { Formik } from 'formik'
import { Form, DatePicker, Select, InputNumber, Checkbox, SubmitButton } from 'formik-antd'

import mealEventFormSchema from '../../utils/yupSchema/mealEventFormSchema'
import { disabledDate } from '../../utils/yupSchema/logsSchema'
import { maxAge } from '../../utils/yupSchema/peopleSchema'

import { cupValueInMl, cupValueInFlOz } from '../../utils/meal'
import { dateFormat } from '../../utils/dates'
import { convertGToOz, convertOzToG, convertMlToFlOz, convertFlOzToMl } from '../../utils/numbers'
import { decimalCommaFormatter, decimalCommaParser } from '../../utils/misc'
import { transformInvalidAttrErrors } from '../../utils/api'

import FormItem from '../shared/styledComponents/form/FormItem'
import { datePickerStyle } from '../shared/styledComponents/form/DatePicker'
import { inputNumberStyle } from '../shared/styledComponents/form/InputNumber'
import { checkboxStyle } from '../shared/styledComponents/form/Checkbox'

import renderLabel, { renderDateLabel, renderWeightLabel } from '../shared/other/renderLabel'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'

import {
	ClockCircleOutlined,
	CalculatorOutlined,
	// PieChartOutlined,
	RightOutlined,
} from '@ant-design/icons'
import { App as AntApp, Select as AntSelect } from 'antd'

const MealEventForm = ({
	initialValues,
	birthDate,
	isQuickAction,
	isSimple,
	isToday,
	selectDay,
	density,
	isLiquid,
	asVolume,
	measure,
	setMeasure,
	portions,
	searchEventForMealKindId,
	createOrUpdateEvent,
	endSubmit,
	isSI,
	language,
	t,
}) => {
	const isAmount = measure === 'amount'
	const isCup = measure === 'cup'
	const measureSplit = measure.split('-')
	const isPortion = measureSplit[0] === 'portion'
	const isCupOrPortion = isCup || isPortion

	const { message } = AntApp.useApp()

	const handleSubmit = async (values, { setErrors }) => {
		let attrs = { ...values }

		if (isCup) {
			attrs = _.merge({}, values, { schedulable: { dishes: { 0: { serving: '' } } } })
		}

		try {
			const { response } = await createOrUpdateEvent(attrs)

			if (response) {
				message.success(t('event:mealEventForm.message.success'))

				endSubmit()
			}
		} catch (e) {
			const errors = transformInvalidAttrErrors(e.errors)

			// Async (server-side) validation.
			setErrors(errors)
		}
	}

	const isLite = isQuickAction || isSimple
	const isSleek = isQuickAction && isToday

	const mealKindOptions = [
		{
			label: t('event:mealEventForm.mealKind.currentMealPattern'),
			options: [
				{ value: '1', label: t('mealKinds:data.1.attributes.label') },
				{ value: '3', label: t('mealKinds:data.3.attributes.label') },
				{ value: '4', label: t('mealKinds:data.4.attributes.label') },
				{ value: '5', label: t('mealKinds:data.5.attributes.label') },
			],
		},
		{
			label: t('event:mealEventForm.mealKind.otherMeals'),
			options: [
				{ value: '2', label: t('mealKinds:data.2.attributes.label') },
				{ value: '6', label: t('mealKinds:data.6.attributes.label') },
			],
		},
	]

	const amountUnit = asVolume && !isSI ? 'fl. oz.' : asVolume ? 'ml' : !isSI ? 'oz' : 'g'
	const measureOptions = [
		{
			value: 'amount',
			label: t('nutritionFacts:settings.measure.amount', { unit: amountUnit }),
		},
	]

	const anyPortions = portions?.length

	const buildPortionOption = ({ id, isPackSize, name: portionName }) => ({
		value: `portion-${id}`,
		label: t(`nutritionFacts:settings.measure.${isPackSize ? 'pack' : 'serving'}`, { portionName }),
	})
	const buildPortionOptions = () => anyPortions && portions.map((fp) => buildPortionOption(fp))
	anyPortions && measureOptions.push(...buildPortionOptions())

	const cupUnit = !isSI ? '8 fl. oz.' : '240 ml'
	const buildCupOption = () => ({
		value: `cup`,
		label: t('nutritionFacts:settings.measure.cup', { unit: cupUnit }),
	})
	!anyPortions && isLiquid && measureOptions.push(buildCupOption())

	const isSingleMeasure = measureOptions.length === 1

	const d = density ?? 1
	const cupValueInG = cupValueInMl * d
	const cupValueInOz = cupValueInFlOz * d

	const findPortion = (id) => portions.find((fp) => fp.id === id)

	const massLabel = renderWeightLabel(t('dishes:attributes.mass.label'))
	const volumeLabel = renderWeightLabel(t('dishes:attributes.volume.label'))

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={mealEventFormSchema(t, birthDate)}
			onSubmit={handleSubmit}
		>
			{({
				values: {
					schedulable: { mealKindId },
				},
				setFieldValue,
			}) => (
				<Form
					// id="meal-event-form"
					layout="vertical"
					colon={false}
				>
					<FormItem
						name="startDate"
						label={renderDateLabel(t('events:attributes.startDate.label'))}
						hidden={isSleek}
					>
						<DatePicker
							name="startDate"
							format="l"
							disabledDate={(current) => disabledDate(current, birthDate)}
							minDate={birthDate}
							maxDate={birthDate && birthDate.add(maxAge, 'y')}
							onChange={async (d) => {
								if (!d) return

								const day = d.format(dateFormat)

								selectDay(day)

								const r = await searchEventForMealKindId(mealKindId, day)
								const { id, mealId } = r || {}

								if (id && mealId) {
									setFieldValue('id', id)
									setFieldValue('schedulable.id', mealId)
								}
							}}
							variant="filled"
							style={datePickerStyle}
						/>
					</FormItem>

					<FormItem
						name="schedulable.mealKindId"
						label={renderLabel({
							icon: <ClockCircleOutlined />,
							label: t('event:mealEventForm.mealKind.label'),
						})}
						hidden={isLite}
					>
						<Select
							name="schedulable.mealKindId"
							options={mealKindOptions}
							onChange={async (v) => {
								const r = await searchEventForMealKindId(v)
								const { id, mealId } = r || {}

								if (id && mealId) {
									setFieldValue('id', id)
									setFieldValue('schedulable.id', mealId)
								}
							}}
							variant="filled"
						/>
					</FormItem>

					{!isSingleMeasure && (
						<>
							{!isQuickAction && (
								<div style={{ paddingBottom: 8 }}>
									{renderLabel({
										icon: <CalculatorOutlined />,
										label: t('nutritionFacts:settings.measure.label'),
									})}
								</div>
							)}
							{!isSleek && (
								<AntSelect
									options={measureOptions}
									value={measure}
									onChange={(v) => {
										setMeasure(v)

										const defaultPack = 0.5
										const defaultServing = 1

										const mSplit = v.split('-')
										if (v === 'cup') {
											setFieldValue('schedulable.dishes[0].serving', defaultServing)

											const m = defaultServing * cupValueInG
											const mInOz = defaultServing * cupValueInOz
											setFieldValue('schedulable.dishes[0].mass', m)
											setFieldValue('schedulable.dishes[0].massInOz', mInOz)

											if (density) {
												setFieldValue('schedulable.dishes[0].volume', cupValueInMl)
												setFieldValue('schedulable.dishes[0].volumeInFlOz', cupValueInFlOz)
											}
										} else if (mSplit[0] === 'portion') {
											const portionId = mSplit[1]
											const portion = findPortion(portionId)
											const defaultDose = portion.isPackSize ? defaultPack : defaultServing
											setFieldValue('schedulable.dishes[0].serving', defaultDose)
											setFieldValue('schedulable.dishes[0].servingSizeId', portionId)

											const m = defaultDose * portion.value
											const mInOz = convertGToOz(m)
											setFieldValue('schedulable.dishes[0].mass', m)
											setFieldValue('schedulable.dishes[0].massInOz', mInOz)
										} else {
											setFieldValue('schedulable.dishes[0].serving', '')
											setFieldValue('schedulable.dishes[0].servingSizeId', '')
										}
									}}
									disabled={isQuickAction && measure === 'cup'}
									variant="filled"
									style={{ width: '100%', marginBottom: 8 }}
								/>
							)}
						</>
					)}

					<FormItem
						name="schedulable.dishes[0].mass"
						label={isSingleMeasure && massLabel}
						hidden={!isAmount || density || !isSI}
					>
						<InputNumber
							name="schedulable.dishes[0].mass"
							placeholder={t(`dishes:attributes.${isLiquid ? 'volume' : 'mass'}.placeholder`)}
							min={0}
							step={10}
							formatter={(v) => decimalCommaFormatter(v, language)}
							parser={(v) => decimalCommaParser(v, language)}
							// suffix="g"
							suffix={isLiquid ? 'ml' : 'g'}
							// changeOnWheel
							onChange={(v) => {
								const mInOz = convertGToOz(v)
								setFieldValue('schedulable.dishes[0].massInOz', mInOz)
								setFieldValue('schedulable.dishes[0].volume', '')
								setFieldValue('schedulable.dishes[0].serving', '')
								setFieldValue('schedulable.dishes[0].servingSizeId', '')
							}}
							style={inputNumberStyle}
						/>
					</FormItem>
					<FormItem
						name="schedulable.dishes[0].massInOz"
						label={isSingleMeasure && massLabel}
						hidden={!isAmount || density || isSI}
					>
						<InputNumber
							name="schedulable.dishes[0].massInOz"
							placeholder={t(
								`dishes:attributes.${isLiquid ? 'volumeInFlOz' : 'massInOz'}.placeholder`,
							)}
							min={0}
							step={0.25}
							formatter={(v) => decimalCommaFormatter(v, language)}
							parser={(v) => decimalCommaParser(v, language)}
							// suffix="oz"
							suffix={isLiquid ? 'fl. oz.' : 'oz'}
							// changeOnWheel
							onChange={(v) => {
								const m = convertOzToG(v)
								setFieldValue('schedulable.dishes[0].mass', m)
								setFieldValue('schedulable.dishes[0].volume', '')
								setFieldValue('schedulable.dishes[0].serving', '')
								setFieldValue('schedulable.dishes[0].servingSizeId', '')
							}}
							style={inputNumberStyle}
						/>
					</FormItem>

					<FormItem
						name="schedulable.dishes[0].volume"
						label={isSingleMeasure && volumeLabel}
						hidden={!isAmount || !density || !isSI}
					>
						<InputNumber
							name="schedulable.dishes[0].volume"
							placeholder={t('dishes:attributes.volume.placeholder')}
							min={0}
							step={10}
							formatter={(v) => decimalCommaFormatter(v, language)}
							parser={(v) => decimalCommaParser(v, language)}
							suffix="ml"
							// changeOnWheel
							onChange={(v) => {
								const m = v * density
								const mInOz = convertGToOz(m)
								const vInFlOz = convertMlToFlOz(v)
								setFieldValue('schedulable.dishes[0].mass', m)
								setFieldValue('schedulable.dishes[0].massInOz', mInOz)
								setFieldValue('schedulable.dishes[0].volumeInFlOz', vInFlOz)
							}}
							style={inputNumberStyle}
						/>
					</FormItem>
					<FormItem
						name="schedulable.dishes[0].volumeInFlOz"
						label={isSingleMeasure && volumeLabel}
						hidden={!isAmount || !density || isSI}
					>
						<InputNumber
							name="schedulable.dishes[0].volumeInFlOz"
							placeholder={t('dishes:attributes.volumeInFlOz.placeholder')}
							min={0}
							step={2.5}
							formatter={(v) => decimalCommaFormatter(v, language)}
							parser={(v) => decimalCommaParser(v, language)}
							suffix="fl. oz."
							// changeOnWheel
							onChange={(vInFlOz) => {
								const v = convertFlOzToMl(vInFlOz)
								const m = v * density
								const mInOz = convertGToOz(m)
								setFieldValue('schedulable.dishes[0].mass', m)
								setFieldValue('schedulable.dishes[0].massInOz', mInOz)
								setFieldValue('schedulable.dishes[0].volume', v)
							}}
							style={inputNumberStyle}
						/>
					</FormItem>

					<FormItem
						name="schedulable.dishes[0].serving"
						// label={renderLabel({
						// 	icon: <PieChartOutlined />,
						// 	label: t('dishes:attributes.serving.label'),
						// })}
						hidden={!isCupOrPortion}
					>
						<InputNumber
							name="schedulable.dishes[0].serving"
							// addonBefore={<PieChartOutlined />}
							placeholder={t('dishes:attributes.serving.placeholder')}
							min={0}
							step={0.25}
							formatter={(v) => decimalCommaFormatter(v, language)}
							parser={(v) => decimalCommaParser(v, language)}
							// changeOnWheel
							onChange={(v) => {
								let m = 0
								let mInOz = 0

								if (isCup) {
									m = v * cupValueInG
									mInOz = v * cupValueInOz
								} else {
									const portionId = measureSplit[1]
									const portion = findPortion(portionId)
									m = v * portion.value
									mInOz = convertGToOz(m)
								}

								setFieldValue('schedulable.dishes[0].mass', m)
								setFieldValue('schedulable.dishes[0].massInOz', mInOz)
							}}
							style={inputNumberStyle}
						/>
					</FormItem>

					{!isLite && (
						<FormItem name="schedulable.dishes[0].fixed">
							<Checkbox name="schedulable.dishes[0].fixed" style={checkboxStyle}>
								{t('dishes:attributes.fixed.label')}
							</Checkbox>
						</FormItem>
					)}

					<SubmitWrapper $step="first">
						<SubmitButton
							// htmlType="submit"
							shape="circle"
							icon={<RightOutlined />}
						/>
					</SubmitWrapper>
				</Form>
			)}
		</Formik>
	)
}

export default MealEventForm
