// import styled from 'styled-components'

// import { appPadding, mobileMLNavMenuWidth, mobileMLNavMenuPadding } from '../../styles/sizes'
// import deviceMinWidth from '../../styles/deviceMinWidth'

// import { LibraAffixButton } from './LibraAffixStyles'

// const StyledLibraAvatar = styled(LibraAffixButton)`
// 	,
// 	&.ant-btn-loading {
// 		bottom: ${appPadding};
// 		left: ${appPadding};

// 		@media ${deviceMinWidth.mobileML} {
// 			left: ${(props) =>
// 				props.dashboard
// 					? `calc((100vw - 2 * ${mobileMLNavMenuWidth}) / 2 + ${appPadding})`
// 					: `calc(${mobileMLNavMenuPadding} + ${appPadding})`};
// 		}

// 		@media ${deviceMinWidth.laptop} {
// 			left: ${appPadding};
// 		}
// 	}
// `

// export default StyledLibraAvatar

import styled from 'styled-components'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'

const StyledLibraAvatar = styled(FloatMenu).attrs(() => ({
	// Defining static props.
	$bottom: 0,
	$left: 0,
}))`
	z-index: 1001;
`

export default StyledLibraAvatar
