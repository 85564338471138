// Function component (container, using React, Redux, React Router & i18next hooks):

// React Effect hook.
import React, { useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hook for routing.
import { useSearchParams } from 'react-router-dom'
// i18next hook for localization (via React Context).
import { useTranslation } from 'react-i18next'

import { changeField } from '../../ducks/account'

import FieldSegmented from './FieldSegmented'

export const fields = ['nutrition', 'mind', 'fitness']

const FieldContainer = ({ field: givenField }) => {
	let [searchParams] = useSearchParams()

	const paramsField = searchParams.get('field')

	const initialField = givenField || paramsField || fields[0]

	const { field } = useSelector((state) => state.account)

	const dispatch = useDispatch()

	const shouldInitField = field === ''
	useEffect(() => {
		shouldInitField && dispatch(changeField(initialField))
	}, [shouldInitField, initialField, dispatch])

	const handleChange = (value) => dispatch(changeField(value))

	const { t } = useTranslation('field')

	return <FieldSegmented field={field} handleChange={handleChange} t={t} />
}

export default FieldContainer
