import styled from 'styled-components'

export const SliderWrapper = styled.span`
	height: 30px;
	display: flex;

	background: ${(props) => props.theme.colorBgContainer};
	border-width: 1px;
	border-style: solid;
	border-color: ${(props) => props.theme.colorBorder};

	border-radius: 16px;

	.anticon {
		margin: 8px 8px;
	}
	.anticon:first-child {
		color: ${(props) => (props.$isPositive ? props.theme.colorTextTertiary : undefined)};
	}
	.anticon:last-child {
		color: ${(props) => (!props.$isPositive ? props.theme.colorTextTertiary : undefined)};
	}
	.ant-slider {
		width: 100%;
		margin: auto 7px;
	}
`
