import styled from 'styled-components'

import { Radio } from 'formik-antd'

const { Group } = Radio

const RadioGroup = styled(Group)`
	// display: flex;
	// justify-content: center;
	// flex-wrap: wrap;
`

export default RadioGroup

export const radioGroupStyle = { display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }
