import * as yup from 'yup'

export const keyValidation = (t) => {
	const label = t('subscribers:attributes.key.label')

	return yup.string().required(t('validation:mixed.required', { label }))
}

// const subscribersSchema = (t) =>
// 	yup.object().shape({
// 		key: keyValidation(t),
// 	})

// export default subscribersSchema
