import styled from 'styled-components'

const StyledScanner = styled.div`
	height: auto;
	max-height: 240px;

	overflow: hidden;

	border-radius: 24px;

	> video {
		// border-radius: 24px;
	}
`

export default StyledScanner
