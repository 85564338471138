// Function component (stateful & container, using React, Redux, React Router, Auth0, i18next & React Responsive hooks):

// React State & Effect hooks.
import React, { useState, useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hook for routing.
import { useParams, useSearchParams } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
import { useMediaQuery } from 'react-responsive'

import build from 'redux-object'
import dayjs from 'dayjs'

import { accountableTypes } from '../../utils/yupSchema/accountsSchema'
import { entryKinds } from '../../utils/yupSchema/logsSchema'

import { getDatesBetween, dateFormat } from '../../utils/dates'

import { logsIndex } from '../../ducks/logs'
import { prevDay, nextDay, toggleCalendar, selectDay } from '../../ducks/journal'
import { toggleAccounts } from '../../ducks/account'
import { toggleAddMeal, toggleAddActivity, toggleAddPractice } from '../../ducks/event'

import deviceMinWidth from '../../styles/deviceMinWidth'

import Journal from './Journal'

const JournalStatefulContainer = () => {
	let { username } = useParams()
	// const isPrivate = !username

	const apiData = useSelector((state) => state.apiData)

	const paramsAccount = username && build(apiData, 'account', username)
	const paramsAccountId = paramsAccount?.id

	const { currentAccountId, accountIds, field } = useSelector((state) => state.account)
	const accountId = paramsAccountId || currentAccountId
	const isManaged = accountIds.includes(accountId)

	const account = accountId && build(apiData, 'account', accountId)
	const isPerson = account?.accountableType === accountableTypes[0]
	const profile = account?.profile
	const name = (isPerson ? profile?.givenName : profile?.name) ?? account?.username

	const isNutrition = field === 'nutrition'
	const isMind = field === 'mind'
	const isFitness = field === 'fitness'

	const nutritionSections = ['allNutrition', 'meals', 'collation']
	const mindSections = ['allMind', 'selfCare', 'introspection']
	const fitnessSections = ['allFitness', 'routine', 'workouts']

	let [searchParams, setSearchParams] = useSearchParams()

	const paramsSection = searchParams.get('section')
	const fallbackSection = (() => {
		switch (field) {
			case 'nutrition':
				return nutritionSections[0]
			case 'mind':
				return mindSections[0]
			case 'fitness':
				return fitnessSections[0]
			default:
				return nutritionSections[0]
		}
	})()
	const initialSection = paramsSection ?? fallbackSection

	const [section, setSection] = useState(initialSection)
	const handleSectionChange = (key) => setSection(key)

	const implicitField = (() => {
		switch (true) {
			case nutritionSections.includes(section):
				return 'nutrition'
			case mindSections.includes(section):
				return 'mind'
			case fitnessSections.includes(section):
				return 'fitness'
			default:
				return undefined
		}
	})()

	const shouldReinitializeSection = field && field !== implicitField
	useEffect(() => {
		shouldReinitializeSection && setSection(fallbackSection)
	}, [shouldReinitializeSection, fallbackSection])

	const shouldSetSearchParams = section !== paramsSection
	useEffect(() => {
		shouldSetSearchParams && setSearchParams({ section })
	}, [shouldSetSearchParams, section, setSearchParams])

	const { currentJournalId: journalId, currentDay } = useSelector((state) => state.journal)
	const currentDate = dayjs(currentDay, dateFormat)

	const { isDashboardOpen } = useSelector((state) => state.dashboard)

	const logs = build(apiData, 'log', null, { includeType: true })
	const entryLogs = logs?.filter(
		(l) => l.journal.id === journalId && entryKinds.includes(l.loggable.type),
	)

	const fieldKinds = (() => {
		switch (field) {
			case 'nutrition':
				return ['mealInstance']
			case 'fitness':
				return ['activityInstance']
			default:
				return ['bodyMeasurement', 'questionnaireResponse']
		}
	})()

	const filteredLogs =
		entryLogs &&
		Object.values(entryLogs).filter(
			({ loggable }) => fieldKinds.includes(loggable.type) && loggable.state !== 'projected',
		)

	const isTablet = useMediaQuery({
		// query: '(min-width: 768px)'
		query: `${deviceMinWidth.tablet}`,
	})
	const isLaptopML = useMediaQuery({
		// query: '(min-width: 1152px)'
		query: `${deviceMinWidth.laptopML}`,
	})
	const isLaptopXL = useMediaQuery({
		// query: '(min-width: 1536px)'
		query: `${deviceMinWidth.laptopXL}`,
	})
	const isDesktopS = useMediaQuery({
		// query: '(min-width: 1920px)'
		query: `${deviceMinWidth.desktopS}`,
	})
	const isDesktopSM = useMediaQuery({
		// query: '(min-width: 2304px)'
		query: `${deviceMinWidth.desktopSM}`,
	})
	const isDesktopXL = useMediaQuery({
		// query: '(min-width: 2688px)'
		query: `${deviceMinWidth.desktopXL}`,
	})

	const daysBefore = 1
	const startDate = currentDate.subtract(daysBefore, 'd')
	const daysAfter = isDesktopS ? 5 : isLaptopXL ? 4 : isLaptopML ? 3 : isTablet ? 2 : 1
	// const daysAfter = 5
	const daysAfterWhenDashboard = isTablet && isDashboardOpen ? daysAfter - 1 : daysAfter
	const endDate = currentDate.add(daysAfterWhenDashboard, 'd')
	const dates = getDatesBetween(startDate, endDate)
	const days = dates.map((d) => d.format(dateFormat))

	const [missingDays, setMissingDays] = useState([])
	const [unavailableDays, setUnavailableDays] = useState([])

	useEffect(() => {
		const availableDays =
			(entryLogs &&
				Object.values(entryLogs)
					.map((l) => l.date)
					.filter((v, i, a) => a.indexOf(v) === i)) ||
			[]

		const addMissingDate = (date) =>
			setMissingDays((dates) =>
				!availableDays.includes(date) &&
				!unavailableDays.includes(date) &&
				dates.indexOf(date) === -1
					? [...dates, date]
					: dates,
			)

		days.map((d) => addMissingDate(d))
	}, [days, entryLogs, unavailableDays])

	const { getAccessTokenSilently } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['journal', 'l10n'])

	const dispatch = useDispatch()

	const shouldListLogs = journalId && missingDays.length !== 0
	useEffect(() => {
		if (!shouldListLogs) return

		const addUnavailableDays = (dates) => {
			const concat = [...unavailableDays, ...dates].filter((v, i, a) => a.indexOf(v) === i)
			setUnavailableDays(concat)
		}

		const listLogs = async () => {
			const accessToken = await getAccessTokenSilently()

			const { response } = await dispatch(
				logsIndex(language, accessToken, {
					of: journalId,
					onOrAfter: missingDays[0],
					onOrBefore: missingDays[missingDays.length - 1],
					onlyEntries: true,
				}),
			)

			if (response) {
				setMissingDays([])

				const { log } = response

				const returnedDates =
					(log &&
						Object.values(log)
							.map((l) => l.attributes.date)
							.filter((v, i, a) => a.indexOf(v) === i)) ||
					[]
				const diff = missingDays.filter((d) => !returnedDates.includes(d))
				diff.length && addUnavailableDays(diff)
			}
		}

		listLogs()
	}, [
		shouldListLogs,
		journalId,
		missingDays,
		getAccessTokenSilently,
		language,
		dispatch,
		unavailableDays,
	])

	const { isDarkTheme } = useSelector((state) => state.appearance)

	const slidesToShow = isDesktopXL
		? 7
		: isDesktopSM
			? 6
			: isDesktopS
				? 5
				: isLaptopXL
					? 4
					: isLaptopML
						? 3
						: isTablet
							? 2
							: 1

	const slidesToShowWhenDashboard = isTablet && isDashboardOpen ? slidesToShow - 1 : slidesToShow

	const handleClick = (date) => {
		const day = date.format(dateFormat)
		day !== currentDay && dispatch(selectDay(day))

		switch (field) {
			case 'nutrition':
				return dispatch(toggleAddMeal())
			case 'mind':
				return dispatch(toggleAddPractice())
			case 'fitness':
				return dispatch(toggleAddActivity())
			default:
				return null
		}
	}

	return (
		<Journal
			isManaged={isManaged}
			name={name}
			showAccounts={() => dispatch(toggleAccounts())}
			field={field}
			isNutrition={isNutrition}
			isMind={isMind}
			isFitness={isFitness}
			section={section}
			handleSectionChange={handleSectionChange}
			implicitField={implicitField}
			journalId={journalId}
			unavailableDays={unavailableDays}
			logs={filteredLogs}
			daysBefore={daysBefore}
			dates={dates}
			prev={() => dispatch(prevDay())}
			next={() => dispatch(nextDay())}
			showCalendar={() => dispatch(toggleCalendar())}
			isDashboardOpen={isDashboardOpen}
			slidesToShow={slidesToShowWhenDashboard}
			handleClick={handleClick}
			t={t}
			isDarkTheme={isDarkTheme}
		/>
	)
}

export default JournalStatefulContainer
