// Function component (presentational):

import React from 'react'

import { targetNCCategories } from '../../utils/yupSchema/nutritionalComponentsSchema'

import L10nLink from '../shared/navigation/L10nLink'

import Card from '../shared/styledComponents/card/Card'

import Collapse from '../shared/styledComponents/collapse/Collapse'

import renderLabel from '../shared/other/renderLabel'

import { renderCategoryTable } from './renderCategoryTable'
import { buildCategoryItem } from './buildCategoryItem'

import Icon, { LoadingOutlined, BuildOutlined } from '@ant-design/icons'
import { ReactComponent as Nutrition } from '../../assets/icons/water-drop.svg'
import { Button } from 'antd'

const NutritionTargetsCollapse = ({ fromDeck, isSI, dataSource, t }) => {
	const breakdownCategories = targetNCCategories.slice(1)
	const filteredCategories = breakdownCategories.filter((category) => dataSource(category))

	const items = filteredCategories.flatMap((category) => {
		if (!dataSource(category).length) {
			return []
		} else {
			return buildCategoryItem({ key: 'ncmTarget', t, isSI, dataSource, category })
		}
	})

	const hasProximateData = dataSource('proximate')?.length !== 0
	const hasBreakdownData = filteredCategories.length !== 0

	return (
		<>
			<Card
				title={renderLabel({
					icon: fromDeck ? (
						<L10nLink to={{ pathname: 'profile', search: '?section=nutritionTargets' }}>
							<Button shape="circle" icon={<Icon component={Nutrition} />} />
						</L10nLink>
					) : (
						<Icon component={Nutrition} />
					),
					label: t('nutrition:proximate.title'),
					extra: !dataSource('proximate') ? <LoadingOutlined /> : null,
				})}
				style={{ height: '100%' }}
			>
				{renderCategoryTable({ key: 'ncmTarget', t, isSI, dataSource, category: 'proximate' })}
			</Card>

			{!fromDeck && hasProximateData && hasBreakdownData && (
				<Card
					title={renderLabel({
						icon: <BuildOutlined />,
						label: t('nutrition:breakdown.title'),
					})}
					$withCollapse
					style={{ height: '100%' }}
				>
					<Collapse items={items} defaultActiveKey={[]} ghost />
				</Card>
			)}
		</>
	)
}

export default NutritionTargetsCollapse
