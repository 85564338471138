import * as yup from 'yup'

const contentValidation = (t) => {
	const label = t('chats:attributes.content.label')

	return yup.string().required(t('validation:mixed.required', { label }))
}

const chatsSchema = (t) =>
	yup.object().shape({
		content: contentValidation(t),
	})

export default chatsSchema
