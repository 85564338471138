import styled from 'styled-components'

import RoundButton from './RoundButton'

const BlockRoundButton = styled(RoundButton).attrs((props) => ({
	// Defining static props.
	block: true,

	// Defining dynamic props.
	type: props.type ?? 'primary',
}))`
	padding: 0;

	.block-label {
		width: 100%;
	}
`

export default BlockRoundButton
