// Function component (presentational):

import React from 'react'

import { renderNutritionLabel } from '../shared/other/renderLabel'

import InlineMenu from '../shared/styledComponents/menu/InlineMenu'

import L10nLink from '../shared/navigation/L10nLink'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import {
	buildWaterDrinkingItem,
	buildTeaDrinkingItem,
	buildCoffeeDrinkingItem,
} from './buildMealItems'

import { buildScanItem } from '../scanner/buildScanItem'

import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import TextCircleButton from '../shared/styledComponents/button/TextCircleButton'

const AddMeal = ({ isAddMealOpen, hideAddMeal, handleClick, t }) => {
	const renderTitle = () =>
		renderNutritionLabel(
			t('event:addMeal.title'),
			<TextCircleButton icon={<CloseOutlined />} onClick={hideAddMeal} />,
		)

	const items = []

	const waterDrikingItem = buildWaterDrinkingItem(handleClick, t)
	const teaDrikingItem = buildTeaDrinkingItem(handleClick, t)
	const coffeeDrikingItem = buildCoffeeDrinkingItem(handleClick, t)

	const dividerItem = { type: 'divider' }

	const scanItem = buildScanItem(t)

	const searchStandardFoodItem = {
		key: 'search-standard-food',
		icon: <SearchOutlined />,
		label: (
			<div onClick={hideAddMeal}>
				<L10nLink to={{ pathname: '/library', search: '?scope=standardFood' }}>
					{t('event:addMeal.searchStandardFood')}
				</L10nLink>
			</div>
		),
	}

	items.push(
		waterDrikingItem,
		teaDrikingItem,
		coffeeDrikingItem,
		dividerItem,
		scanItem,
		dividerItem,
		searchStandardFoodItem,
	)

	const renderBody = () => <InlineMenu items={items} />

	return (
		<DrawerOrModalContainer
			isOpen={isAddMealOpen}
			hide={hideAddMeal}
			renderTitle={renderTitle}
			renderBody={renderBody}
		/>
	)
}

export default AddMeal
