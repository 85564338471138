// Function component (presentational):

import React from 'react'

import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'

import chatFormSchema from '../../utils/yupSchema/chatFormSchema'

import { transformInvalidAttrErrors } from '../../utils/api'

import FormItem from '../shared/styledComponents/form/FormItem'

// import renderLabel from '../shared/other/renderLabel'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'

import {
	// EditOutlined,
	RightOutlined,
} from '@ant-design/icons'
import { App as AntApp } from 'antd'

const ChatForm = ({
	initialValues,
	fromThread,
	inputRef,
	syncContent,
	isAnswering,
	createChat,
	endSubmit,
	t,
}) => {
	const { message } = AntApp.useApp()

	const handleSubmit = async (values, { resetForm, setErrors }) => {
		try {
			const { response } = await createChat(values)

			if (response) {
				message.success(t('assistance:form.message.success'))

				resetForm()
				endSubmit()
			}
		} catch (e) {
			const errors = transformInvalidAttrErrors(e.errors)

			// Async (server-side) validation.
			setErrors(errors)
		}
	}

	const handleOnChange = ({ target: { value } }) => syncContent(value)

	const sharedProps = {
		name: 'content',
		placeholder: t('chats:attributes.content.placeholder'),
		onBlur: handleOnChange,
	}

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={chatFormSchema(t)}
			onSubmit={handleSubmit}
		>
			<Form
				// id="chat-form"
				layout="vertical"
				colon={false}
			>
				<div style={{ display: fromThread && 'flex' }}>
					<FormItem
						name="content"
						hasFeedback={false}
						// label={renderLabel({
						// 	icon: <EditOutlined />,
						// 	label: t('chats:attributes.content.label'),
						// })}
						help={fromThread && null}
						style={{
							width: '100%',
							marginBottom: fromThread && 0,
						}}
					>
						{fromThread ? (
							<Input {...sharedProps} />
						) : (
							<Input.TextArea
								{...sharedProps}
								ref={inputRef}
								// autoFocus
								allowClear
								autoSize={{ minRows: 2 }}
							/>
						)}
					</FormItem>

					<SubmitWrapper
						$step="first"
						style={{ marginTop: fromThread && 'auto', marginLeft: fromThread && 8 }}
					>
						<SubmitButton
							// htmlType="submit"
							shape="circle"
							icon={<RightOutlined />}
							loading={isAnswering ? true : undefined}
						/>
					</SubmitWrapper>
				</div>
			</Form>
		</Formik>
	)
}

export default ChatForm
