import styled from 'styled-components'

import { Menu } from 'antd'

const { Item } = Menu

const InlineMenuItem = styled(Item)`
	&.ant-menu-item {
		width: 100%;

		display: flex;
		align-items: center;

		margin-bottom: 8px;

		padding-left: 16px !important;

		// > .anticon {
		// 	width: 32px;
		// }
		// > .ant-btn {
		// 	flex-shrink: 0;
		// }
		// > .anticon,
		// > .ant-btn,
		// > .ant-avatar {
		// 	margin-right: 16px;
		// }

		> .ant-menu-title-content {
			display: flex;
			flex-grow: 1;
			justify-content: space-between;
			align-items: center;

			margin-left: 16px;
		}

		> .ant-menu-title-content > .ant-switch {
			margin-right: 5px;
		}
	}
`

export default InlineMenuItem
