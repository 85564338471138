// Function component (stateful & container, using React, Redux, React Router, Auth0, i18next & React Responsive hooks):

// React State & Effect hooks.
import React, { useState, useEffect, useCallback } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hook for routing.
import { useSearchParams } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
// import { useMediaQuery } from 'react-responsive'

import build from 'redux-object'

import { gaEvents } from '../../utils/gaEvents'

import { standardFoodsIndex } from '../../ducks/standardFoods'
import { foodItemsIndex } from '../../ducks/foodItems'
import { recipesIndex } from '../../ducks/recipes'
import { questionnairesIndex } from '../../ducks/questionnaires'
import { activityKindsIndex } from '../../ducks/activityKinds'
import { toggleNewEvent, setMeal, setActivity } from '../../ducks/event'

// import deviceMinWidth from '../../styles/deviceMinWidth'

import L10nLink from '../shared/navigation/L10nLink'

import Library from './Library'

import Icon, {
	BarcodeOutlined,
	FireOutlined,
	QuestionOutlined,
	PlusOutlined,
} from '@ant-design/icons'
// import { ReactComponent as Nutrition } from '../../assets/icons/water-drop.svg'
import { ReactComponent as Liquid } from '../../assets/icons/water-glass.svg'
import { ReactComponent as Solid } from '../../assets/icons/fork-and-knife.svg'
import { ReactComponent as Recipe } from '../../assets/icons/saucepan.svg'
// import { ReactComponent as Meal } from '../../assets/icons/tray.svg'
// import { ReactComponent as Mind } from '../../assets/icons/lotus.svg'
import { Avatar, Image, Button } from 'antd'

const LibraryStatefulContainer = () => {
	let [searchParams, setSearchParams] = useSearchParams()

	const paramsSearch = searchParams.get('search')

	const [searchTerm, setSearchTerm] = useState(paramsSearch ?? '')
	const [pageNumber, setPageNumber] = useState(0)
	const [pageCount, setPageCount] = useState(0)

	const initialValues = { search: searchTerm }

	const { field } = useSelector((state) => state.account)
	const isNutrition = field === 'nutrition'
	const isMind = field === 'mind'
	const isFitness = field === 'fitness'

	const nutritionScopes = ['standardFood', 'foodItem', 'recipe', 'meal', 'mealProgram']
	const mindScopes = ['introspection', 'introspectionProgram', 'meditation']
	const fitnessScopes = ['activityKind', 'workout', 'fitnessProgram', 'fitnessTest']

	const fallbackScope = (() => {
		switch (field) {
			case 'nutrition':
				return nutritionScopes[0]
			case 'mind':
				return mindScopes[0]
			case 'fitness':
				return fitnessScopes[0]
			default:
				return nutritionScopes[0]
		}
	})()

	const paramsScope = searchParams.get('scope')
	const initialScope = paramsScope ?? fallbackScope

	const [scope, setScope] = useState(initialScope)
	const handleSectionChange = (key) => setScope(key)

	const implicitField = (() => {
		switch (true) {
			case nutritionScopes.includes(scope):
				return 'nutrition'
			case mindScopes.includes(scope):
				return 'mind'
			case fitnessScopes.includes(scope):
				return 'fitness'
			default:
				return undefined
		}
	})()

	const shouldReinitializeSearchAndScope = field && field !== implicitField
	useEffect(() => {
		if (!shouldReinitializeSearchAndScope) return

		setScope(fallbackScope)
		searchTerm && setSearchTerm('')
	}, [shouldReinitializeSearchAndScope, fallbackScope, searchTerm])

	const shouldSetSearchParams = (searchTerm && searchTerm !== paramsSearch) || scope !== paramsScope
	useEffect(() => {
		shouldSetSearchParams && setSearchParams({ search: searchTerm, scope })
	}, [shouldSetSearchParams, searchTerm, scope, setSearchParams])

	const dispatch = useDispatch()

	const {
		i18n: { language },
		t,
	} = useTranslation(['library', 'validation'])

	const apiData = useSelector((state) => state.apiData)

	const {
		user: { sub: auth0Uid },
		getAccessTokenSilently,
	} = useAuth0()

	// const user = auth0Uid && build(apiData, 'user', auth0Uid)
	const users = build(apiData, 'user')
	const user = users?.find((u) => u.auth0Uid === auth0Uid)
	const standardFoodSearchHistory = user?.standardFoodSearchHistory ?? ''
	const foodItemSearchHistory = user?.foodItemSearchHistory ?? ''
	const recipeSearchHistory = user?.recipeSearchHistory ?? ''
	const activityKindSearchHistory = user?.activityKindSearchHistory ?? ''
	const questionnaireSearchHistory = user?.questionnaireSearchHistory ?? ''
	const searchHistory = (() => {
		switch (scope) {
			case 'standardFood':
				return standardFoodSearchHistory
			case 'foodItem':
				return foodItemSearchHistory
			case 'recipe':
				return recipeSearchHistory
			case 'introspection':
				return questionnaireSearchHistory
			case 'activityKind':
				return activityKindSearchHistory
			default:
				return ''
		}
	})()
	const options =
		(searchHistory.length && searchHistory.split(',').map((s) => ({ value: s }))) ?? []

	const [loading, setLoading] = useState(false)

	const [standardFoodIds, setStandardFoodIds] = useState([])
	const [foodItemIds, setFoodItemIds] = useState([])
	const [recipeIds, setRecipeIds] = useState([])
	const [introspectionIds, setIntrospectionIds] = useState([])
	const [activityKindIds, setActivityKindIds] = useState([])

	const handleFoodClick = (foodId) => {
		dispatch(setMeal({ foodId }))
		dispatch(toggleNewEvent())
	}
	const handleQuestionnaireClick = (_questionnaireId) => {
		// dispatch(setQuestionnaire({ questionnaireId }))
		// dispatch(toggleNewPractice())
	}
	const handleActivityClick = (activityKindId) => {
		dispatch(setActivity({ activityKindId }))
		dispatch(toggleNewEvent())
	}

	const standardFoods = build(apiData, 'standardFood', standardFoodIds)
	const standardFoodData =
		standardFoods?.map(
			({ id, food: { physicalState, image, name: title, id: foodId, description } }) => ({
				type: 'standardFood',
				id,
				avatar: (
					<Avatar
						icon={<Icon component={physicalState === 'liquid' ? Liquid : Solid} />}
						src={image?.url && <Image src={image?.url} />}
						size={44}
						shape="square"
					/>
				),
				title: <L10nLink to={`/library/standard-food/${id}`}>{title}</L10nLink>,
				extra: (
					<Button shape="circle" icon={<PlusOutlined />} onClick={() => handleFoodClick(foodId)} />
				),
				// category,
				description:
					title !== description ? (
						<L10nLink to={`/library/standard-food/${id}`}>{description}</L10nLink>
					) : null,
			}),
		) ?? []

	const foodItems = build(apiData, 'foodItem', foodItemIds)
	const foodItemData =
		foodItems?.map(({ id, food: { image, name: title, id: foodId, description } }) => ({
			type: 'foodItem',
			id,
			avatar: (
				<Avatar
					icon={<BarcodeOutlined />}
					src={image?.url && <Image src={image?.url} />}
					size={44}
					shape="square"
				/>
			),
			title: <L10nLink to={`/library/food-item/${id}`}>{title}</L10nLink>,
			extra: (
				<Button shape="circle" icon={<PlusOutlined />} onClick={() => handleFoodClick(foodId)} />
			),
			description:
				title !== description ? (
					<L10nLink to={`/library/food-item/${id}`}>{description}</L10nLink>
				) : null,
		})) ?? []

	const recipes = build(apiData, 'recipe', recipeIds)
	const recipeData =
		recipes?.map(({ id, food: { image, name: title, id: foodId, description } }) => ({
			type: 'recipe',
			id,
			avatar: (
				<Avatar
					icon={<Icon component={Recipe} />}
					src={image?.url && <Image src={image?.url} />}
					size={44}
					shape="square"
				/>
			),
			title: <L10nLink to={`/library/recipe/${id}`}>{title}</L10nLink>,
			extra: (
				<Button shape="circle" icon={<PlusOutlined />} onClick={() => handleFoodClick(foodId)} />
			),
			description:
				title !== description ? (
					<L10nLink to={`/library/recipe/${id}`}>{description}</L10nLink>
				) : null,
		})) ?? []

	const introspections = build(apiData, 'questionnaire', introspectionIds)
	const introspectionData =
		introspections?.map(({ id, name: title, description }) => ({
			// type: 'questionnaire',
			id,
			avatar: <Avatar icon={<QuestionOutlined />} size={44} shape="square" />,
			title,
			extra: (
				<Button
					shape="circle"
					icon={<PlusOutlined />}
					onClick={() => handleQuestionnaireClick(id)}
				/>
			),
			// category,
			description,
		})) ?? []

	const activityKinds = build(apiData, 'activityKind', activityKindIds)
	const activityKindData =
		activityKinds?.map(({ id, activityCategory: { image }, name: title, description }) => ({
			// type: 'activityKind',
			id,
			avatar: (
				<Avatar
					icon={<FireOutlined />}
					src={image?.url && <Image src={image?.url} />}
					size={44}
					shape="square"
				/>
			),
			title,
			extra: (
				<Button shape="circle" icon={<PlusOutlined />} onClick={() => handleActivityClick(id)} />
			),
			// category,
			description: title !== description ? description : null,
		})) ?? []

	const dataSource = (() => {
		switch (scope) {
			case 'standardFood':
				return standardFoodData
			case 'foodItem':
				return foodItemData
			case 'recipe':
				return recipeData
			case 'introspection':
				return introspectionData
			case 'activityKind':
				return activityKindData
			default:
				return []
		}
	})()

	const searchData = useCallback(
		async (search, page = 1) => {
			if (pageNumber === page) return

			gaEvents.search(search, scope, language.split('-')[0])

			if (!['standardFood', 'foodItem', 'recipe', 'introspection', 'activityKind'].includes(scope))
				return

			if (page === 1) {
				setPageNumber(0)
				setPageCount(0)
			}
			setLoading(true)

			const accessToken = await getAccessTokenSilently()

			const { response } = await dispatch(
				(() => {
					switch (scope) {
						case 'standardFood':
							return standardFoodsIndex(
								language,
								accessToken,
								{ search },
								{ size: 12, number: page },
							)
						case 'foodItem':
							return foodItemsIndex(language, accessToken, { search }, { size: 12, number: page })
						case 'recipe':
							return recipesIndex(language, accessToken, { search }, { size: 12, number: page })
						case 'introspection':
							return questionnairesIndex(
								language,
								accessToken,
								{ search, onlyMind: true },
								{ size: 12, number: page },
							)
						case 'activityKind':
							return activityKindsIndex(
								language,
								accessToken,
								{ search },
								{ size: 12, number: page },
							)
						default:
							return null
					}
				})(),
			)

			if (response) {
				const q = Object.values(response.meta)[0]

				const {
					meta: { pages },
				} = q
				setPageNumber(page)
				setPageCount(pages)
				setLoading(false)

				const ids = q.data.map((o) => o.id)

				const addStandardFoodIds = (newIds) => {
					if (newIds.every((id) => standardFoodIds.includes(id))) return

					const concat = [...standardFoodIds, ...newIds].filter((v, i, a) => a.indexOf(v) === i)
					setStandardFoodIds(concat)
				}
				const addIntrospectionIds = (newIds) => {
					if (newIds.every((id) => introspectionIds.includes(id))) return

					const concat = [...introspectionIds, ...newIds].filter((v, i, a) => a.indexOf(v) === i)
					setIntrospectionIds(concat)
				}
				const addActivityKindIds = (newIds) => {
					if (newIds.every((id) => activityKindIds.includes(id))) return

					const concat = [...activityKindIds, ...newIds].filter((v, i, a) => a.indexOf(v) === i)
					setActivityKindIds(concat)
				}

				switch (scope) {
					case 'standardFood':
						addStandardFoodIds(ids)
						break
					case 'foodItem':
						setFoodItemIds(ids)
						break
					case 'recipe':
						setRecipeIds(ids)
						break
					case 'introspection':
						addIntrospectionIds(ids)
						break
					case 'activityKind':
						addActivityKindIds(ids)
						break
					default:
						break
				}
			}
		},
		[
			pageNumber,
			setLoading,
			getAccessTokenSilently,
			scope,
			language,
			dispatch,
			standardFoodIds,
			introspectionIds,
			activityKindIds,
		],
	)

	const searchMoreData = () => searchData(searchTerm, pageNumber + 1)

	const shouldSearchData =
		searchTerm && searchTerm === paramsSearch && scope === paramsScope && pageNumber === 0
	useEffect(() => {
		shouldSearchData && searchData(searchTerm)
	}, [shouldSearchData, searchTerm, searchData])

	// const isLaptop = useMediaQuery({
	// 	// query: '(min-width: 1024px)'
	// 	query: `${deviceMinWidth.laptop}`,
	// })

	const { isDarkTheme } = useSelector((state) => state.appearance)

	return (
		<Library
			setSearchTerm={setSearchTerm}
			pageNumber={pageNumber}
			pageCount={pageCount}
			scope={scope}
			handleSectionChange={handleSectionChange}
			implicitField={implicitField}
			isNutrition={isNutrition}
			isMind={isMind}
			isFitness={isFitness}
			initialValues={initialValues}
			options={options}
			loading={loading}
			searchData={searchData}
			searchMoreData={searchMoreData}
			dataSource={dataSource}
			t={t}
			// isLaptop={isLaptop}
			isDarkTheme={isDarkTheme}
		/>
	)
}

export default LibraryStatefulContainer
