import styled from 'styled-components'

const ItemLabel = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;
`

export default ItemLabel
