import * as yup from 'yup'

import dayjs from 'dayjs'

// Baby’s age when lactating (in months).
const maxAge = 12
export const minDate = dayjs().subtract(maxAge, 'M')

// Embryo/Fetus age when pregnant (in months).
const minAge = -9
export const maxDate = dayjs().add(-minAge, 'M')

export const disabledDate = (current) => {
	// Cannot select days after maxDate.
	return current >= maxDate
}

const dueDateValidation = (t) => {
	// const label = t('motherhoods:attributes.dueDate.label')

	return yup
		.date()
		.min(minDate, t('motherhoods:attributes.dueDate.validation.min', { age: maxAge }))
		.max(maxDate, t('motherhoods:attributes.dueDate.validation.max'))
		.nullable()
	// .typeError(t('validation:date.typeError', { label }))
	// .required(t('validation:mixed.required', { label }))
}

const motherhoodsSchema = (t) =>
	yup.object().shape({
		dueDate: dueDateValidation(t),
	})

export default motherhoodsSchema
