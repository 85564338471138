// Function component (presentational):

import React from 'react'

import SectionWrapper from '../shared/styledComponents/page/SectionWrapper'
import SectionTitle from '../shared/styledComponents/page/SectionTitle'
import ContentWrapper from '../shared/styledComponents/page/ContentWrapper'
// import Illustration from '../shared/styledComponents/Illustration'

import Title from '../shared/styledComponents/text/Title'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'

import access from '../../assets/illustrations/shared/access-key.png'

import { UserOutlined, RightOutlined } from '@ant-design/icons'
import BlockRoundButton from '../shared/styledComponents/button/BlockRoundButton'

const OnboardingWelcome = ({ firstName, nextStep, t }) => (
	<SectionWrapper
	// id="onboarding-welcome-wrapper"
	// $type="illustration"
	>
		<SectionTitle>
			<Title level={3}>{t('onboarding:welcome.title', { firstName })}</Title>
		</SectionTitle>

		<ContentWrapper style={{ alignItems: 'center' }}>
			<Card
				cover={
					<img src={access} alt={t('onboarding:welcome.illustration')} width="100%" height="100%" />
				}
				$withMeta
			>
				<Card.Meta
					title={
						<BlockRoundButton onClick={nextStep}>
							{renderLabel({
								icon: <UserOutlined />,
								label: t('onboarding:welcome.action'),
								extra: <RightOutlined />,
							})}
						</BlockRoundButton>
					}
				/>
			</Card>
		</ContentWrapper>
	</SectionWrapper>
)

export default OnboardingWelcome
