// import styled from 'styled-components'

// import {
// 	appPadding,
// 	mobileMLNavMenuWidth,
// 	mobileMLNavMenuPadding,
// 	mobileMLInnerCardWidth,
// } from '../../styles/sizes'
// import deviceMinWidth from '../../styles/deviceMinWidth'

// import { LibraAffixButton } from './LibraAffixStyles'

// const StyledUserAvatar = styled(LibraAffixButton)`
// 	,
// 	&.ant-btn-loading {
// 		top: ${appPadding};
// 		left: ${appPadding};

// 		@media ${deviceMinWidth.mobileML} {
// 			left: calc(${mobileMLNavMenuPadding} + ${appPadding});
// 		}

// 		@media (min-width: 512px) {
// 			left: ${appPadding};
// 		}

// 		@media ${deviceMinWidth.laptop} {
// 			left: unset;

// 			right: ${(props) =>
// 				props.dashboard
// 					? `calc(${mobileMLNavMenuWidth} + ${mobileMLInnerCardWidth} - ${appPadding})`
// 					: `calc(${mobileMLInnerCardWidth} - ${appPadding})`};
// 		}
// 	}
// `

// export default StyledUserAvatar

import styled from 'styled-components'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'

const StyledUserAvatar = styled(FloatMenu).attrs(() => ({
	// Defining static props.
	$top: 0,
	$right: 0,
}))`
	z-index: 1001;
`

export default StyledUserAvatar
