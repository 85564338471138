// Function component (presentational):

import React from 'react'

import PageTitle from '../shared/styledComponents/page/PageTitle'
import SectionWrapper from '../shared/styledComponents/page/SectionWrapper'
import SectionTitle from '../shared/styledComponents/page/SectionTitle'
import ContentWrapper from '../shared/styledComponents/page/ContentWrapper'

import Title from '../shared/styledComponents/text/Title'

import SubscriptionForm from './SubscriptionForm'

const Subscription = ({
	initialValues,
	createSubscriber,
	navToReferral,
	t,
	// isLaptop,
}) => (
	<>
		<PageTitle>
			<Title>{t('subscription:page.title')}</Title>
		</PageTitle>

		<SectionWrapper>
			<SectionTitle>
				<Title level={3}>{t('subscription:form.title')}</Title>
			</SectionTitle>

			<ContentWrapper style={{ padding: 16 }}>
				<SubscriptionForm
					initialValues={initialValues}
					createSubscriber={createSubscriber}
					navToReferral={navToReferral}
					t={t}
				/>
			</ContentWrapper>
		</SectionWrapper>
	</>
)

export default Subscription
