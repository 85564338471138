import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

const nestingPath = '/assistances/'
const path = '/chats'

export const chatsIndex =
	(locale, accessToken, assistanceId, page = {}) =>
	(dispatch) => {
		const endpoint = localizePath(locale, `${nestingPath}${assistanceId}${path}`)
		const options = initRequestOptions({ accessToken })
		const params = { page }

		return dispatch(fetchLibraDietApiData({ endpoint, options, params }))
	}

export const chatsCreate = (locale, accessToken, assistanceId, chatAttrs) => (dispatch) => {
	const endpoint = localizePath(locale, `${nestingPath}${assistanceId}${path}`)
	const method = 'POST'
	const chat = { chat: chatAttrs }
	const options = initRequestOptions({ method, accessToken, data: chat })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}
