// Function component (presentational):

import React from 'react'

import _ from 'lodash'

import { decimalCommaFormatter } from '../../utils/misc'

import L10nLink from '../shared/navigation/L10nLink'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'

import MeasurementLine from './MeasurementLine'

import Icon, {
	LoadingOutlined,
	LineChartOutlined,
	ColumnHeightOutlined,
	PlusOutlined,
} from '@ant-design/icons'
import { ReactComponent as Scale } from '../../assets/icons/scale.svg'
import { Button, Skeleton, Descriptions, Progress, Select } from 'antd'
import BlockRoundButton from '../shared/styledComponents/button/BlockRoundButton'

const MeasurementTracking = ({
	fromDeck,
	isLoading,
	measurementKind,
	handleChange,
	data,
	min,
	max,
	handleBmClick,
	handleBmBhClick,
	periodBodyMasses,
	bmCurrentValue,
	disabled,
	language,
	t,
	isDarkTheme,
}) => {
	const bodyMassesItems = [
		{
			key: 'currentValue',
			label: t('measurement:bodyMasses.currentValue.label'),
			children: !bmCurrentValue
				? t('measurement:bodyMasses.currentValue.unavailable')
				: decimalCommaFormatter(bmCurrentValue, language),
			span: 2,
		},
	]

	const daysCount =
		(periodBodyMasses &&
			_.uniq(periodBodyMasses.map((bm) => bm.bodyMeasurement.log.date)).length) ??
		0

	const dailyCheckUpChild = (
		<span
			style={{
				width: '100%',
				display: 'flex',
				// justifyContent: 'space-between',
				alignItems: 'center',
				gap: 8,
			}}
		>
			<Progress
				percent={(daysCount / 7) * 100}
				// size="small"
				showInfo={false}
				steps={7}
				style={{
					// maxWidth: 'calc(368px / 2 - 16px)',
					marginBottom: 0,
					marginRight: 0,
				}}
			/>
			<span style={{ flexShrink: 0 }}>{`${daysCount}/7`}</span>
		</span>
	)

	const dailyCheckUpItem = {
		key: 'dailyCheckUp',
		label: t('measurement:bodyMasses.dailyCheckUp.label'),
		children: dailyCheckUpChild,
		span: 2,
	}
	if (bmCurrentValue || daysCount >= 1) {
		bodyMassesItems.push(dailyCheckUpItem)
	}

	const bmAvgValue = _.round(_.mean(periodBodyMasses?.map((bm) => +bm.value)), 1)

	const avgValueItem = {
		key: 'avgValue',
		label: t('measurement:bodyMasses.avgValue.label', { daysCount }),
		children: decimalCommaFormatter(bmAvgValue, language),
		span: 2,
	}

	if ((bmCurrentValue && daysCount > 1) || (!bmCurrentValue && daysCount >= 1)) {
		bodyMassesItems.push(avgValueItem)
	}

	const options = [
		{ value: 'bodyMass', label: t('bodyMasses:attributes.value.label') },
		{ value: 'bodyHeight', label: t('bodyHeights:attributes.value.label') },
		{ value: 'bodyMassIndex', label: t('bodyMassIndices:attributes.value.label') },
	]

	const renderMeasurementKindIcon = () => {
		switch (measurementKind) {
			case 'bodyMass':
				return <Icon component={Scale} />
			default:
				return <ColumnHeightOutlined />
		}
	}

	const extra = isLoading ? <LoadingOutlined /> : null

	return (
		<>
			<Card
				title={renderLabel({
					icon: fromDeck ? (
						<L10nLink to={{ pathname: 'profile', search: '?section=measurements' }}>
							<Button shape="circle" icon={<Icon component={Scale} />} />
						</L10nLink>
					) : (
						<Icon component={Scale} />
					),
					label: t('measurement:bodyMasses.title'),
					extra,
				})}
				style={{ height: '100%' }}
			>
				<Skeleton title={false} loading={isLoading} paragraph={{ rows: 3 }} active></Skeleton>

				{!isLoading && (
					<Descriptions
						column={2}
						items={bodyMassesItems}
						style={{ marginBottom: fromDeck ? 8 : undefined }}
					/>
				)}

				{fromDeck && (
					<BlockRoundButton
						type={!bmCurrentValue ? 'primary' : 'default'}
						onClick={handleBmClick}
						disabled={disabled}
					>
						{renderLabel({
							icon: <Icon component={Scale} />,
							label: t('measurement:actions.addBodyMass'),
							extra: <PlusOutlined />,
						})}
					</BlockRoundButton>
				)}
			</Card>

			{!fromDeck && (
				<Card
					title={renderLabel({
						icon: <LineChartOutlined />,
						label: t('measurement:bmiTrackRecord.title'),
						extra,
					})}
				>
					<Select
						options={options}
						value={measurementKind}
						variant="borderless"
						onChange={handleChange}
						style={{ width: '100%', marginBottom: 8 }}
					/>

					<MeasurementLine data={data} min={min} max={max} t={t} isDarkTheme={isDarkTheme} />

					{measurementKind !== 'bodyMassIndex' && (
						<BlockRoundButton
							type={measurementKind === 'bodyMass' && !bmCurrentValue ? 'primary' : 'default'}
							onClick={handleBmBhClick}
							disabled={disabled}
						>
							{renderLabel({
								icon: renderMeasurementKindIcon(),
								label: t(`measurement:actions.add${_.upperFirst(measurementKind)}`),
								extra: <PlusOutlined />,
							})}
						</BlockRoundButton>
					)}
				</Card>
			)}
		</>
	)
}

export default MeasurementTracking
