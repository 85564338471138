import styled from 'styled-components'

import { miscStyles } from '../../styles/misc'

export const DashboardHeader = styled.div`
	// position: sticky;
	// top: 0;

	padding: 8px;

	margin-bottom: 8px;

	// z-index: 4;

	// border-radius: 24px;

	font-size: ${(props) => `${props.theme.fontSizeHeading5}px`};
	font-weight: ${(props) => props.theme.fontWeightStrong};
`

export const DashboardBody = styled.div`
	padding: 8px;

	// border-radius: 24px;

	background: ${(props) => (!props.$isTablet ? props.theme.glassBackgroundColor : undefined)};
	@supports not (
		(-webkit-backdrop-filter: ${miscStyles.glassBackdropFilter}) or
			(backdrop-filter: ${miscStyles.glassBackdropFilter})
	) {
		background: ${(props) => (!props.$isTablet ? props.theme.backgroundColor : undefined)};
	}

	-webkit-backdrop-filter: ${(props) =>
		!props.$isTablet ? props.theme.glassBackdropFilter : undefined};
	backdrop-filter: ${(props) => (!props.$isTablet ? props.theme.glassBackdropFilter : undefined)};
`
