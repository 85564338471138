import * as yup from 'yup'

import { keyValidation } from './subscribersSchema'

const referralFormSchema = (t) =>
	yup.object().shape({
		key: keyValidation(t),
	})

export default referralFormSchema
