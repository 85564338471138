// Function component (stateful & container, using React, Redux, React Router, Auth0, i18next & React Responsive hooks):

// React State & Effect hooks.
import React, { useContext, useState, useEffect, useCallback, useRef } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
import { useMediaQuery } from 'react-responsive'

import { CableContext } from '../../contexts/cable/cable'

import build from 'redux-object'

import { scrollToId } from '../../utils/window'
import { chunkMarkdown } from '../../utils/misc'

import { chatsIndex } from '../../ducks/chats'
import { normalizeReceived } from '../../ducks/apiData'
import { toggleNewChat, setAnswering } from '../../ducks/assistance'
import { speakWithSound } from '../../ducks/libra'

import deviceMinWidth from '../../styles/deviceMinWidth'

import Assistance from './Assistance'

const AssistanceStatefulContainer = () => {
	const { currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const assistanceId = currentAccount?.profile?.assistance?.id

	const { getAccessTokenSilently } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation('assistance')

	const [listedPages, setListedPages] = useState(0)
	const [pages, setPages] = useState(0)
	const unstartedList = listedPages === 0
	const endedList = listedPages !== 0 && listedPages === pages
	const [loading, setLoading] = useState(false)

	const dispatch = useDispatch()

	const writeChatRef = useRef()
	const scrollToBottom = (opts = { behavior: 'smooth' }) =>
		writeChatRef.current?.scrollIntoView(opts)

	const cs = build(apiData, 'chat')
	const chats = cs?.filter((c) => c.assistance.id === assistanceId)
	const earliestChatId = chats?.length && chats[0].id

	const listChats = useCallback(
		async (pageNumber = 1) => {
			if (endedList) return

			try {
				setLoading(true)

				const accessToken = await getAccessTokenSilently()

				const { response } = await dispatch(
					chatsIndex(language, accessToken, assistanceId, { number: pageNumber }),
				)

				if (response) {
					const q = Object.values(response.meta)[0]

					const {
						meta: { pages: updatedPages },
					} = q
					setListedPages(pageNumber)
					setPages(updatedPages)
					setLoading(false)

					pageNumber === 1 || !earliestChatId
						? scrollToBottom({ behavior: 'instant' })
						: scrollToId(earliestChatId)
				}
			} catch (e) {
				// throw e
			}
		},
		[endedList, assistanceId, getAccessTokenSilently, language, dispatch, earliestChatId],
	)

	const shouldListChats = assistanceId && unstartedList && !loading
	useEffect(() => {
		shouldListChats && listChats()
	}, [shouldListChats, listChats])

	const cable = useContext(CableContext)
	const subscriptions = cable?.subscriptions
	const connection = cable?.connection
	const isConnected = connection && !connection.disconnected

	const { speech, intent, sequenceNumber, isFirst } = useSelector((state) => state.libra)

	const isLibraChatIntent = intent === 'libra:chat'

	const [chunks, setChunks] = useState([])
	const chunksLength = chunks.length
	const [index, setIndex] = useState(0)
	const chunk = chunks[index]

	const shouldChangeIndex = isLibraChatIntent && sequenceNumber - 1 !== index
	useEffect(() => {
		shouldChangeIndex && setIndex(sequenceNumber - 1)
	}, [shouldChangeIndex, sequenceNumber, index])

	const libraStart = useCallback(
		(i = index, speech = chunk, sequenceEnd = chunksLength) =>
			dispatch(
				speakWithSound({
					path: `libra:chat.speech.${i + 1}`,
					speech,
					isFirst: i === 0,
					sequenceType: 'ordered',
					sequenceEnd,
					language,
					withMarkdown: true,
				}),
			),
		[index, chunk, chunksLength, language, dispatch],
	)

	const shouldLibraContinue = isLibraChatIntent && chunksLength && !isFirst && chunk !== speech
	useEffect(() => {
		shouldLibraContinue && libraStart()
	}, [shouldLibraContinue, libraStart])

	const initLibra = useCallback(
		(content) => {
			const cs = chunkMarkdown(content)
			setChunks(cs)

			libraStart(0, cs[0], cs.length)
		},
		[libraStart],
	)

	const shouldSubscribe = assistanceId && !isConnected && subscriptions
	useEffect(() => {
		if (!shouldSubscribe) return

		const connected = () => {
			console.log(`assistance_${assistanceId} connected!`)
		}

		const disconnected = () => {
			console.log(`assistance_${assistanceId} disconnected!`)
		}

		const received = (data) => {
			dispatch(normalizeReceived(data))

			dispatch(setAnswering(false))

			const {
				data: {
					// id,
					attributes: { origin, content },
				},
			} = data

			origin === 'assistant' && initLibra(content)

			// scrollToId(id, { behavior: 'smooth' })
		}

		// const channel = subscriptions.create(
		subscriptions.create(
			{ channel: 'AssistanceChannel', assistance_id: assistanceId },
			{ connected, disconnected, received },
		)

		// return () => {
		// 	channel.unsubscribe()
		// 	console.log(`assistance_${assistanceId} unsubscribed!`)
		// }
	}, [
		shouldSubscribe,
		subscriptions,
		language,
		assistanceId,
		getAccessTokenSilently,
		dispatch,
		initLibra,
	])

	const recallChats = () => listChats(listedPages + 1)

	const isMobileXL = useMediaQuery({
		// query: '(min-width: 512px)'
		query: `${deviceMinWidth.mobileXL}`,
	})

	const { isDarkTheme } = useSelector((state) => state.appearance)

	return (
		<Assistance
			unstartedList={unstartedList}
			endedList={endedList}
			loading={loading}
			writeChatRef={writeChatRef}
			chats={chats}
			showNewChat={() => dispatch(toggleNewChat())}
			recallChats={recallChats}
			t={t}
			isMobileXL={isMobileXL}
			isDarkTheme={isDarkTheme}
		/>
	)
}

export default AssistanceStatefulContainer
