const deviceViewportWidth = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '414px',
	tablet: '768px',
	laptop: '1024px',
	laptopL: '1440px',
	desktop: '2560px',
}
deviceViewportWidth['mobileML'] = `calc(${deviceViewportWidth.tablet} / 2)` // 384px
deviceViewportWidth['mobileXL'] = `calc(${deviceViewportWidth.laptop} / 2)` // 512px
deviceViewportWidth['tabletL'] =
	`calc((${deviceViewportWidth.tablet} + ${deviceViewportWidth.laptop}) / 2)` // 896px
deviceViewportWidth['laptopML'] = `calc(${deviceViewportWidth.mobileML} * 3)` // 1152px
deviceViewportWidth['laptopXL'] = `calc(${deviceViewportWidth.mobileML} * 4)` // 1536px
deviceViewportWidth['desktopS'] = `calc(${deviceViewportWidth.mobileML} * 5)` // 1920px
deviceViewportWidth['desktopSM'] = `calc(${deviceViewportWidth.mobileML} * 6)` // 2304px
deviceViewportWidth['desktopXL'] = `calc(${deviceViewportWidth.mobileML} * 7)` // 2688px
export { deviceViewportWidth }

const deviceMinWidth = {
	mobileS: `(min-width: ${deviceViewportWidth.mobileS})`,
	mobileM: `(min-width: ${deviceViewportWidth.mobileM})`,
	mobileML: `(min-width: ${deviceViewportWidth.mobileML})`,
	mobileXL: `(min-width: ${deviceViewportWidth.mobileXL})`,
	mobileL: `(min-width: ${deviceViewportWidth.mobileL})`,
	tablet: `(min-width: ${deviceViewportWidth.tablet})`,
	tabletL: `(min-width: ${deviceViewportWidth.tabletL})`,
	laptop: `(min-width: ${deviceViewportWidth.laptop})`,
	laptopML: `(min-width: ${deviceViewportWidth.laptopML})`,
	laptopL: `(min-width: ${deviceViewportWidth.laptopL})`,
	laptopXL: `(min-width: ${deviceViewportWidth.laptopXL})`,
	desktopS: `(min-width: ${deviceViewportWidth.desktopS})`,
	desktopSM: `(min-width: ${deviceViewportWidth.desktopSM})`,
	desktop: `(min-width: ${deviceViewportWidth.desktop})`,
	desktopXL: `(min-width: ${deviceViewportWidth.desktopXL})`,
}

export default deviceMinWidth
