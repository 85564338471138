// Function component (presentational):

import React from 'react'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import FormWrapper from '../shared/styledComponents/form/FormWrapper'

import renderLabel from '../shared/other/renderLabel'

import DailyWaterForm from './DailyWaterForm'

import { ScheduleOutlined } from '@ant-design/icons'

const DailyWater = ({ initialValues, createEvent, endSubmit, t }) => {
	const renderTitle = () =>
		renderLabel({ icon: <ScheduleOutlined />, label: t('event:dailyWaterForm.title') })

	const renderBody = () => (
		<FormWrapper>
			<DailyWaterForm
				initialValues={initialValues}
				createEvent={createEvent}
				endSubmit={endSubmit}
				t={t}
			/>
		</FormWrapper>
	)

	return (
		<DrawerOrModalContainer
			isOpen={true}
			canClose={false}
			renderTitle={renderTitle}
			renderBody={renderBody}
			// onlyModal
		/>
	)
}

export default DailyWater
