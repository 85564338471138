// Function component (presentational):

import React from 'react'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import FormWrapper from '../shared/styledComponents/form/FormWrapper'

import { renderMindLabel } from '../shared/other/renderLabel'

import MoodForm from './MoodForm'

import { CloseOutlined } from '@ant-design/icons'
import TextCircleButton from '../shared/styledComponents/button/TextCircleButton'

const NewMood = ({
	isOnboarding,
	fromLibra,
	isQuickAction,
	isToday,
	birthDate,
	selectDay,
	isNewMoodOpen,
	hideNewMood,
	initialValues,
	endSubmit,
	createQuestionnaireResponse,
	libraStart,
	t,
}) => {
	const renderTitle = () =>
		renderMindLabel(
			t('mood:form.title'),
			isOnboarding ? null : <TextCircleButton icon={<CloseOutlined />} onClick={hideNewMood} />,
		)

	const renderMoodForm = () => (
		<MoodForm
			initialValues={initialValues}
			isOnboarding={isOnboarding}
			fromLibra={fromLibra}
			isQuickAction={isQuickAction}
			isToday={isToday}
			birthDate={birthDate}
			selectDay={selectDay}
			endSubmit={endSubmit}
			createQuestionnaireResponse={createQuestionnaireResponse}
			libraStart={libraStart}
			t={t}
		/>
	)

	const renderBody = () => <FormWrapper>{renderMoodForm()}</FormWrapper>

	return fromLibra ? (
		renderMoodForm()
	) : (
		<DrawerOrModalContainer
			isOpen={isOnboarding || isNewMoodOpen}
			hide={hideNewMood}
			canClose={!isOnboarding}
			renderTitle={renderTitle}
			renderBody={renderBody}
			// onlyModal
		/>
	)
}

export default NewMood
