import renderLabel from '../shared/other/renderLabel'

import { SmileOutlined } from '@ant-design/icons'

export const buildMoodItem = (handleClick, t, disabled = false) => ({
	key: 'libra-menu-actions-add-mood',
	icon: <SmileOutlined />,
	label: renderLabel({
		label: <span onClick={disabled ? () => null : handleClick}>{t('mood:actions.addMood')}</span>,
	}),
	disabled,
})
