import styled from 'styled-components'

import { Layout } from 'antd'

import { mobileMLNavMenuWidth } from '../../../styles/sizes'
import deviceMinWidth from '../../../styles/deviceMinWidth'

const { Footer: AntFooter } = Layout

const Footer = styled(AntFooter)`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	margin-top: 64px;

	@media ${deviceMinWidth.mobileML} {
		width: ${mobileMLNavMenuWidth};
		margin-right: auto;
		margin-left: auto;
	}

	@media ${deviceMinWidth.laptop} {
		width: 100%;

		flex-direction: row-reverse;
	}

	padding: 0;

	> div {
		min-height: 64px;
		line-height: 32px;

		display: flex;
		justify-content: center;
		align-items: center;

		padding: 16px;
	}

	> div:not(#footer-legal) {
		@media ${deviceMinWidth.laptop} {
			align-items: flex-end;
		}
	}

	#footer-social {
		justify-content: space-between;
		align-items: center;

		@media ${deviceMinWidth.laptop} {
			width: 320px;

			justify-content: flex-end;
			gap: 32px;
		}
	}

	#footer-legal {
		display: flex;
		flex-wrap: wrap;

		@media ${deviceMinWidth.laptop} {
			flex-grow: 1;
		}
	}

	#footer-copyright {
		padding-left: 64px;
		padding-right: 64px;

		@media ${deviceMinWidth.laptop} {
			width: 320px;

			flex-shrink: 0;
			justify-content: flex-start;

			padding-right: 16px;
		}
	}
`

export default Footer
