// Function component (presentational):

import React from 'react'
// i18next hook for localization (via React Context).
import { useTranslation } from 'react-i18next'

import LayoutWrapper from '../shared/styledComponents/page/LayoutWrapper'
import PageWrapper from '../shared/styledComponents/page/PageWrapper'
import PageTitle from '../shared/styledComponents/page/PageTitle'
import SectionWrapper from '../shared/styledComponents/page/SectionWrapper'
import ContentWrapper from '../shared/styledComponents/page/ContentWrapper'
// import Illustration from '../shared/styledComponents/Illustration'

import NavContainer from '../nav/NavContainer'

import Title from '../shared/styledComponents/text/Title'

import Card from '../shared/styledComponents/card/Card'

import fourZeroFour from '../../assets/illustrations/notFound/four-zero-four.png'

const NotFoundView = () => {
	const { t } = useTranslation('notFound')

	return (
		<LayoutWrapper>
			<NavContainer sleekNav withNavUser={false} />

			<PageWrapper>
				<PageTitle>
					<Title>{/* {t('notFound:page.title')} */}</Title>
				</PageTitle>

				<SectionWrapper
				// id="not-found-content-wrapper"
				// $type="illustration"
				>
					<ContentWrapper style={{ alignItems: 'center' }}>
						<Card>
							<img
								src={fourZeroFour}
								alt={t('notFound:page.illustration')}
								width="100%"
								height="100%"
							/>
						</Card>
					</ContentWrapper>
				</SectionWrapper>
			</PageWrapper>
		</LayoutWrapper>
	)
}

export default NotFoundView
