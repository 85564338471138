// Function component (container, Redux, React Router, i18next & React Responsive hooks):

import React from 'react'
// Redux hook to dispatch actions.
import { useDispatch } from 'react-redux'
// React Router hook for routing.
import { useSearchParams, useNavigate } from 'react-router-dom'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
// import { useMediaQuery } from 'react-responsive'

import { nestify } from '../../utils/api'
import { prefixPath } from '../../utils/l10n'

import { subscribersCreate } from '../../ducks/subscribers'

// import deviceMinWidth from '../../styles/deviceMinWidth'

import Subscription from './Subscription'

const SubscriptionContainer = () => {
	let [searchParams] = useSearchParams()

	const paramsReferrerKey = searchParams.get('referrerKey')
	const referrerKey = paramsReferrerKey ?? ''

	const initialValues = {
		referrerKey,
		profile: { givenName: '', email: '' },
	}

	const {
		i18n: { language },
		t,
	} = useTranslation(['subscription', 'subscribers', 'profiles', 'validation'])

	const dispatch = useDispatch()

	const createSubscriber = async (subscriberAttrs) => {
		// Comply with Rails nested attributes.
		const nestedSubscriberAttrs = ['profile']
		const nestifiedSubscriberAttrs = nestify(subscriberAttrs, nestedSubscriberAttrs)

		return dispatch(subscribersCreate(language, nestifiedSubscriberAttrs))
	}

	const navigate = useNavigate()

	const navToReferral = (subscriberKey) =>
		navigate(prefixPath(`/referral?subscriberKey=${subscriberKey}`, language))

	// const isLaptop = useMediaQuery({
	// 	// query: '(min-width: 1024px)'
	// 	query: `${deviceMinWidth.laptop}`,
	// })

	return (
		<Subscription
			initialValues={initialValues}
			createSubscriber={createSubscriber}
			navToReferral={navToReferral}
			t={t}
			// isLaptop={isLaptop}
		/>
	)
}

export default SubscriptionContainer
