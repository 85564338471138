import * as yup from 'yup'

import { ynValues, tYnValueLabel, tYnValuesLabelsList } from './repliesSchema'

const palFormSchema = (t) =>
	yup.object().shape({
		withDailyWater: yup
			.mixed()
			.oneOf(
				ynValues,
				t('validation:mixed.oneOf', { label: tYnValueLabel(t), values: tYnValuesLabelsList(t) }),
			)
			.required(t('validation:mixed.required', { label: tYnValueLabel(t) })),
		// withDailyWater: yup.string(),
	})

export default palFormSchema
