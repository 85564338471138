import { createSlice } from '@reduxjs/toolkit'
// import { createAppSlice } from '../store/createAppSlice'

const initialState = {
	isChecked: false,
	isNewMoodOpen: false,
	isQuickAction: false,
}

const practice = createSlice({
	name: 'practice',
	initialState: initialState,
	reducers: {
		// ‘Mutating’ the state is possible thanks to immer (shipped with Redux Toolkit).
		setChecked(state) {
			state.isChecked = true
		},
		toggleNewMood(state) {
			state.isNewMoodOpen = !state.isNewMoodOpen
		},
		toggleQuickMood(state) {
			state.isNewMoodOpen = !state.isNewMoodOpen
			state.isQuickAction = !state.isQuickAction
		},
	},
})

export default practice
export const { reducer: practiceReducer, actions: practiceActions } = practice
export const { setChecked, toggleNewMood, toggleQuickMood } = practiceActions
