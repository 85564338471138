import styled from 'styled-components'

import { miscStyles } from '../../../../styles/misc'

const FormWrapper = styled.div`
	padding: 8px;

	background: ${(props) => props.theme.glassBackgroundColor};
	@supports not (
		(-webkit-backdrop-filter: ${miscStyles.glassBackdropFilter}) or
			(backdrop-filter: ${miscStyles.glassBackdropFilter})
	) {
		background: ${(props) => props.theme.backgroundColor};
	}

	-webkit-backdrop-filter: ${(props) => props.theme.glassBackdropFilter};
	backdrop-filter: ${(props) => props.theme.glassBackdropFilter};
`

export default FormWrapper
