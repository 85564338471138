// Function component (presentational):

// React State hook.
import React, { useState } from 'react'

import dayjs from 'dayjs'

import { Formik } from 'formik'
import {
	Form,
	DatePicker,
	// Radio,
	InputNumber,
	SubmitButton,
} from 'formik-antd'

import firstMeasurementFormSchema from '../../utils/yupSchema/firstMeasurementFormSchema'
import newBodyMassFormSchema from '../../utils/yupSchema/newBodyMassFormSchema'
import newBodyHeightFormSchema from '../../utils/yupSchema/newBodyHeightFormSchema'
import newMeasurementFormSchema from '../../utils/yupSchema/newMeasurementFormSchema'
import { disabledDate } from '../../utils/yupSchema/logsSchema'
import { maxAge } from '../../utils/yupSchema/peopleSchema'
import {
	digestions,
	digestionKinds,
	hydrations,
	hydrationKinds,
	excretions,
	excretionKinds,
	exertions,
} from '../../utils/yupSchema/bodyMassesSchema'
import { sleepings } from '../../utils/yupSchema/bodyHeightsSchema'

import { dateFormat } from '../../utils/dates'
import { decimalCommaFormatter, decimalCommaParser } from '../../utils/misc'
import { convertLbToKg, convertKgToLb } from '../../utils/numbers'
import { convertFtToCm, convertCmToFt } from '../../utils/numbers'
import { evalBmi, categorizeBmi } from '../../utils/measurement'
import { transformInvalidAttrErrors } from '../../utils/api'

import SystemContainer from '../system/SystemContainer'

import FormItem from '../shared/styledComponents/form/FormItem'
import { datePickerStyle } from '../shared/styledComponents/form/DatePicker'
import { radioGroupStyle } from '../shared/styledComponents/form/RadioGroup'
import RadioGroup from '../shared/styledComponents/form/RadioGroup'
import RadioButton from '../shared/styledComponents/form/RadioButton'
import { inputNumberStyle } from '../shared/styledComponents/form/InputNumber'

import renderLabel, {
	renderDateLabel,
	renderCheckLabel,
	renderWeightLabel,
	renderStatureLabel,
} from '../shared/other/renderLabel'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'
import SubmitContent from '../shared/styledComponents/form/SubmitContent'

import { LineOutlined, DownOutlined, RightOutlined } from '@ant-design/icons'
import { App as AntApp, Switch } from 'antd'

// const { Group: RadioGroup, Button: RadioButton } = Radio

const MeasurementForm = ({
	initialValues,
	isFirst,
	isQuickAction,
	isToday,
	isBodyMass,
	isBodyHeight,
	beforeAccountCreation,
	latestBmValue,
	latestBhValue,
	createBodyMeasurement,
	endSubmit,
	birthDate,
	selectDay,
	isSI,
	language,
	t,
}) => {
	const [areBMConditionsVisible, setBMConditionsVisible] = useState(false)
	const toggleBMConditions = () => setBMConditionsVisible((v) => !v)

	const [areBHConditionsVisible, setBHConditionsVisible] = useState(false)
	const toggleBHConditions = () => setBHConditionsVisible((v) => !v)

	const { message } = AntApp.useApp()

	const handleSubmit = async (values, { setErrors }) => {
		const { bodyMeasurement: bodyMeasurementAttrs } = values
		const attrs = isFirst ? bodyMeasurementAttrs : values

		try {
			const { response } = await createBodyMeasurement(attrs)

			if (response) {
				message.success(t('measurement:form.message.success'))

				endSubmit(values)
			}
		} catch (e) {
			const errors = transformInvalidAttrErrors(e.errors)

			// Async (server-side) validation.
			setErrors(errors)
		}
	}

	const validationSchema = isFirst
		? firstMeasurementFormSchema(t)
		: isBodyMass && !beforeAccountCreation
			? newBodyMassFormSchema(t, birthDate)
			: isBodyHeight && !beforeAccountCreation
				? newBodyHeightFormSchema(t, birthDate)
				: newMeasurementFormSchema(t, birthDate)

	const namePrefix = isFirst ? 'bodyMeasurement.' : ''

	const bodyMassLabel = renderWeightLabel(t('bodyMasses:attributes.value.label'))
	const bodyHeightLabel = renderStatureLabel(t('bodyHeights:attributes.value.label'))

	const visibleLabel = t('measurement:form.conditions.visible')
	const hiddenLabel = t('measurement:form.conditions.hidden')

	const withBodyMass = isBodyMass || beforeAccountCreation
	const withBodyHeight = isBodyHeight || beforeAccountCreation

	const isLite = isFirst || isQuickAction
	const withBmConditions = !isLite && withBodyMass
	const visibleBMConditions = withBmConditions && areBMConditionsVisible
	const withBm = isFirst || withBodyMass
	const withBhConditions = !isFirst && withBodyHeight
	const visibleBHConditions = withBhConditions && areBHConditionsVisible
	const withBh = isFirst || withBodyHeight

	const isSleek = isLite && isToday

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({
				values: {
					bodyMeasurement: {
						bodyMass: { value: firstBmValue } = {},
						bodyHeight: { value: firstBhValue } = {},
						bodyMassIndex: { value: firstBmiValue } = {},
					} = {},
					bodyMass: { value: newBmValue } = {},
					bodyHeight: { value: newBhValue } = {},
					bodyMassIndex: { value: newBmiValue } = {},
				},
				errors: {
					bodyMeasurement: {
						bodyMass: { value: firstBmValueError } = {},
						bodyHeight: { value: firstBhValueError } = {},
						bodyMassIndex: { value: firstBmiValueError } = {},
					} = {},
					bodyMass: { value: newBmValueError } = {},
					bodyHeight: { value: newBhValueError } = {},
					bodyMassIndex: { value: newBmiValueError } = {},
				},
				setFieldValue,
			}) => {
				const bmValue = firstBmValue || newBmValue || latestBmValue
				const bhValue = firstBhValue || newBhValue || latestBhValue
				const bmiValue = firstBmiValue || newBmiValue
				const rangeKey = bmiValue && categorizeBmi(bmiValue)
				const rangeLabel = bmiValue && t(`bodyMassIndices:attributes.ranges.${rangeKey}.label`)

				const bmValueError = firstBmValueError || newBmValueError
				const bhValueError = firstBhValueError || newBhValueError
				const bmiValueError = firstBmiValueError || newBmiValueError

				return (
					<Form
						// id="measurement-form"
						layout="vertical"
						colon={false}
					>
						<FormItem
							name={`${namePrefix}log.date`}
							label={renderDateLabel(t('logs:attributes.date.label'))}
							hidden={isSleek}
						>
							<DatePicker
								name={`${namePrefix}log.date`}
								format="l"
								disabledDate={(current) => disabledDate(current, birthDate, dayjs())}
								minDate={birthDate}
								maxDate={birthDate && birthDate.add(maxAge, 'y')}
								onChange={(d) => d && selectDay(d.format(dateFormat))}
								variant="filled"
								style={datePickerStyle}
							/>
						</FormItem>

						{withBmConditions && (
							<div style={{ marginBottom: 16 }}>
								{renderCheckLabel(
									t('measurement:form.conditions.bodyMassLabel'),
									<Switch
										checked={areBMConditionsVisible}
										checkedChildren={visibleLabel}
										unCheckedChildren={hiddenLabel}
										onChange={toggleBMConditions}
										style={{ marginRight: 5 }}
									/>,
								)}
							</div>
						)}

						<FormItem
							name={`${namePrefix}bodyMass.digestion`}
							// label={renderCheckLabel(t('bodyMasses:attributes.digestion.label'))}
							style={{ marginBottom: 0 }}
							hidden={!visibleBMConditions}
						>
							<RadioGroup
								name={`${namePrefix}bodyMass.digestion`}
								size="small"
								style={radioGroupStyle}
							>
								<RadioButton value={digestions[0]}>
									{t(`bodyMasses:attributes.digestions.${digestionKinds[0]}.label`)}
								</RadioButton>
								<RadioButton value={digestions[1]}>
									{t(`bodyMasses:attributes.digestions.${digestionKinds[1]}.label`)}
								</RadioButton>
								<RadioButton value={digestions[2]}>
									{t(`bodyMasses:attributes.digestions.${digestionKinds[2]}.label`)}
								</RadioButton>
							</RadioGroup>
						</FormItem>
						<FormItem
							name={`${namePrefix}bodyMass.hydration`}
							// label={renderCheckLabel(t('bodyMasses:attributes.hydration.label'))}
							hidden={!visibleBMConditions}
							style={{ marginBottom: 0 }}
						>
							<RadioGroup
								name={`${namePrefix}bodyMass.hydration`}
								size="small"
								style={radioGroupStyle}
							>
								<RadioButton value={hydrations[0]}>
									{t(`bodyMasses:attributes.hydrations.${hydrationKinds[0]}.label`)}
								</RadioButton>
								<RadioButton value={hydrations[1]}>
									{t(`bodyMasses:attributes.hydrations.${hydrationKinds[1]}.label`)}
								</RadioButton>
							</RadioGroup>
						</FormItem>
						<FormItem
							name={`${namePrefix}bodyMass.excretion`}
							// label={renderCheckLabel(t('bodyMasses:attributes.excretion.label'))}
							hidden={!visibleBMConditions}
							style={{ marginBottom: 0 }}
						>
							<RadioGroup
								name={`${namePrefix}bodyMass.excretion`}
								size="small"
								style={radioGroupStyle}
							>
								<RadioButton value={excretions[0]}>
									{t(`bodyMasses:attributes.excretions.${excretionKinds[0]}.label`)}
								</RadioButton>
								<RadioButton value={excretions[1]}>
									{t(`bodyMasses:attributes.excretions.${excretionKinds[1]}.label`)}
								</RadioButton>
								<RadioButton value={excretions[2]}>
									{t(`bodyMasses:attributes.excretions.${excretionKinds[2]}.label`)}
								</RadioButton>
							</RadioGroup>
						</FormItem>
						<FormItem
							name={`${namePrefix}bodyMass.exertion`}
							// label={renderCheckLabel(t('bodyMasses:attributes.exertion.label'))}
							hidden={!visibleBMConditions}
							// style={{ marginBottom: 16 }}
						>
							<RadioGroup
								name={`${namePrefix}bodyMass.exertion`}
								size="small"
								style={radioGroupStyle}
							>
								<RadioButton value={exertions[0]}>
									{t(`bodyMasses:attributes.exertions.${exertions[0]}.label`)}
								</RadioButton>
								<RadioButton value={exertions[1]}>
									{t(`bodyMasses:attributes.exertions.${exertions[1]}.label`)}
								</RadioButton>
							</RadioGroup>
						</FormItem>

						<FormItem
							name={`${namePrefix}bodyMass.value`}
							label={!(isQuickAction && isToday) && bodyMassLabel}
							hidden={!withBm || !isSI}
						>
							<InputNumber
								name={`${namePrefix}bodyMass.value`}
								placeholder={t('bodyMasses:attributes.value.placeholder')}
								min={0}
								step={0.5}
								formatter={(v) => decimalCommaFormatter(v, language)}
								parser={(v) => decimalCommaParser(v, language)}
								suffix="kg"
								// changeOnWheel
								onChange={(v) => {
									v && setFieldValue(`${namePrefix}bodyMass.valueInLb`, convertKgToLb(v))

									if (v && bhValue) {
										setFieldValue(`${namePrefix}bodyMassIndex.value`, evalBmi(v, bhValue))
									}

									if (v && isFirst) {
										setFieldValue('objective.bodyMeasurement.bodyMass.value', v)
									}
								}}
								style={inputNumberStyle}
							/>
						</FormItem>
						<FormItem
							name={`${namePrefix}bodyMass.valueInLb`}
							label={bodyMassLabel}
							hidden={!withBm || isSI}
						>
							<InputNumber
								name={`${namePrefix}bodyMass.valueInLb`}
								placeholder={t('bodyMasses:attributes.valueInLb.placeholder')}
								min={0}
								step={1}
								formatter={(v) => decimalCommaFormatter(v, language)}
								parser={(v) => decimalCommaParser(v, language)}
								suffix="lb"
								// changeOnWheel
								onChange={(v) => {
									v && setFieldValue(`${namePrefix}bodyMass.value`, convertLbToKg(v))

									if (v && isFirst) {
										setFieldValue('objective.bodyMeasurement.bodyMass.valueInLb', v)
									}
								}}
								style={inputNumberStyle}
							/>
						</FormItem>

						{withBhConditions && (
							<div style={{ marginBottom: 16 }}>
								{renderCheckLabel(
									t('measurement:form.conditions.bodyHeightLabel'),
									<Switch
										checked={areBHConditionsVisible}
										checkedChildren={visibleLabel}
										unCheckedChildren={hiddenLabel}
										onChange={toggleBHConditions}
										style={{ marginRight: 5 }}
									/>,
								)}
							</div>
						)}

						<FormItem
							name={`${namePrefix}bodyHeight.sleeping`}
							// label={renderCheckLabel(t('bodyHeights:attributes.sleeping.label'))}
							hidden={!visibleBHConditions}
							// style={{ marginBottom: 16 }}
						>
							<RadioGroup
								name={`${namePrefix}bodyHeight.sleeping`}
								size="small"
								style={radioGroupStyle}
							>
								<RadioButton value={sleepings[0]}>
									{t(`bodyHeights:attributes.sleepings.${sleepings[0]}.label`)}
								</RadioButton>
								<RadioButton value={sleepings[1]}>
									{t(`bodyHeights:attributes.sleepings.${sleepings[1]}.label`)}
								</RadioButton>
							</RadioGroup>
						</FormItem>

						<FormItem
							name={`${namePrefix}bodyHeight.value`}
							label={bodyHeightLabel}
							hidden={!withBh || !isSI}
						>
							<InputNumber
								name={`${namePrefix}bodyHeight.value`}
								placeholder={t('bodyHeights:attributes.value.placeholder')}
								min={0}
								step={1}
								formatter={(v) => decimalCommaFormatter(v, language)}
								parser={(v) => decimalCommaParser(v, language)}
								suffix="cm"
								// changeOnWheel
								onChange={(v) => {
									v && setFieldValue(`${namePrefix}bodyHeight.valueInFt`, convertCmToFt(v))

									if (v && bmValue) {
										setFieldValue(`${namePrefix}bodyMassIndex.value`, evalBmi(bmValue, v))
									}
								}}
								style={inputNumberStyle}
							/>
						</FormItem>
						<FormItem
							name={`${namePrefix}bodyHeight.valueInFt`}
							label={bodyHeightLabel}
							hidden={!withBh || isSI}
						>
							<InputNumber
								name={`${namePrefix}bodyHeight.valueInFt`}
								placeholder={t('bodyHeights:attributes.valueInFt.placeholder')}
								min={0}
								step={0.1}
								formatter={(v) => decimalCommaFormatter(v, language)}
								parser={(v) => decimalCommaParser(v, language)}
								suffix="ft"
								// changeOnWheel
								onChange={(v) =>
									v && setFieldValue(`${namePrefix}bodyHeight.value`, convertFtToCm(v))
								}
								style={inputNumberStyle}
							/>
						</FormItem>

						<FormItem
							name={`${namePrefix}bodyMassIndex.value`}
							label={renderLabel({
								icon: <LineOutlined />,
								label: t('bodyMassIndices:attributes.value.label'),
							})}
							hidden={!bmiValue || bmiValue >= 1000 || bmValueError || bhValueError}
							help={
								bmiValueError
									? bmiValueError
									: bmiValue
										? t('bodyMassIndices:attributes.value.help', { rangeLabel })
										: null
							}
							validateStatus={
								bmiValueError ? 'error' : rangeKey === 'normal' ? 'success' : 'warning'
							}
							hasFeedback
						>
							<InputNumber
								name={`${namePrefix}bodyMassIndex.value`}
								formatter={(v) => decimalCommaFormatter(v, language)}
								parser={(v) => decimalCommaParser(v, language)}
								style={inputNumberStyle}
								readOnly
								variant="filled"
							/>
						</FormItem>

						<SubmitWrapper $step="first">
							{isFirst && (
								<SubmitContent>
									<SystemContainer />
								</SubmitContent>
							)}

							<SubmitButton
								// htmlType="submit"
								shape="circle"
								icon={isFirst ? <DownOutlined /> : <RightOutlined />}
							/>
						</SubmitWrapper>
					</Form>
				)
			}}
		</Formik>
	)
}

export default MeasurementForm
