// Function component (presentational):

import React from 'react'

import { Formik } from 'formik'
import {
	Form,
	// Radio,
	SubmitButton,
} from 'formik-antd'

import accountUserFormSchema from '../../utils/yupSchema/accountUserFormSchema'
import { accountableTypes, accountableKinds } from '../../utils/yupSchema/accountsSchema'

import FormItem from '../shared/styledComponents/form/FormItem'
import RadioGroup from '../shared/styledComponents/form/RadioGroup'
import RadioButton from '../shared/styledComponents/form/RadioButton'
import { radioGroupStyle } from '../shared/styledComponents/form/RadioGroup'

import { renderUserLabel } from '../shared/other/renderLabel'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'

import { DownOutlined } from '@ant-design/icons'

// const { Group: RadioGroup, Button: RadioButton } = Radio

const UserForm = ({ initialValues, endSubmit, t }) => {
	const handleSubmit = (values) => endSubmit(values)

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={accountUserFormSchema(t)}
			onSubmit={handleSubmit}
		>
			<Form
				// id="account-user-form"
				layout="vertical"
				colon={false}
			>
				<FormItem
					name="account.accountableType"
					label={renderUserLabel(t('accounts:attributes.accountableType.label'))}
				>
					<RadioGroup name="account.accountableType" style={radioGroupStyle}>
						<RadioButton value={accountableTypes[0]}>
							{t(`accounts:attributes.accountableTypes.${accountableKinds[0]}.label`)}
						</RadioButton>
						<RadioButton disabled value={accountableTypes[1]}>
							{t(`accounts:attributes.accountableTypes.${accountableKinds[1]}.label`)}
						</RadioButton>
					</RadioGroup>
				</FormItem>

				<SubmitWrapper $step="first">
					<SubmitButton
						// htmlType="submit"
						shape="circle"
						icon={<DownOutlined />}
					/>
				</SubmitWrapper>
			</Form>
		</Formik>
	)
}

export default UserForm
