// Function component (container, using React, Redux, Auth0 & i18next hooks):

// React State & Effect hooks.
import React, { useState, useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'
import dayjs from 'dayjs'

import { accountableTypes } from '../../utils/yupSchema/accountsSchema'
import { maxAge } from '../../utils/yupSchema/peopleSchema'

import { dateFormat } from '../../utils/dates'

import { bodyMassesIndex } from '../../ducks/bodyMasses'
import { peopleShow } from '../../ducks/people'

import Objective from './Objective'

const ObjectiveStatefulContainer = () => {
	const { currentAccountableType, currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const person = currentAccountableType === accountableTypes[0] && currentAccount.accountable
	const personId = person?.id
	const birthDay = person?.birthDate
	const birthDate = birthDay && dayjs(birthDay)

	const { getAccessTokenSilently } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['objective', 'objectives', 'bodyMasses'])

	const dispatch = useDispatch()

	const { currentDay, currentJournalId: journalId } = useSelector((state) => state.journal)
	const currentDate = dayjs(currentDay, dateFormat)

	const bMs = build(apiData, 'bodyMass')
	const bodyMasses = bMs
		?.filter(
			(bm) =>
				bm.bodyMeasurement.state === 'actual' && bm.bodyMeasurement.log.journal.id === journalId,
		)
		?.sort(
			(a, b) =>
				dayjs(b.bodyMeasurement.log.date) > dayjs(a.bodyMeasurement.log.date) ||
				a.bodyMeasurement.log.id - b.bodyMeasurement.log.id,
		)
	const latestBodyMass = bodyMasses?.[0]
	const latestBmValue = latestBodyMass?.value

	const shouldListBodyMasses = personId && !latestBmValue
	useEffect(() => {
		if (!shouldListBodyMasses) return

		const listBodyMasses = async () => {
			const accessToken = await getAccessTokenSilently()

			return dispatch(
				bodyMassesIndex(language, accessToken, { of: personId, onlyActual: true }, { size: 1 }),
			)
		}

		listBodyMasses()
	}, [shouldListBodyMasses, personId, getAccessTokenSilently, language, dispatch])

	const objective = person?.objective

	const shouldViewPerson = !objective?.objectiveKind && latestBmValue
	useEffect(() => {
		if (!shouldViewPerson) return

		const viewPerson = async (persId) => {
			try {
				const accessToken = await getAccessTokenSilently()

				const r = await dispatch(peopleShow(language, accessToken, persId))
				return r
			} catch (e) {
				// throw e
			}
		}

		viewPerson(personId)
	}, [shouldViewPerson, getAccessTokenSilently, language, dispatch, personId])

	const [isNewObjectiveOpen, setNewObjectiveOpen] = useState(false)
	const toggleNewObjective = () => setNewObjectiveOpen((v) => !v)

	const disabledObjective =
		!birthDay || !currentDate.isBetween(dayjs().subtract(1, 'd'), birthDate.add(maxAge, 'y'))

	const { isSI } = useSelector((state) => state.measurement)

	return (
		<Objective
			personId={personId}
			birthDate={birthDate}
			currentDate={currentDate}
			latestBmValue={latestBmValue}
			objective={objective}
			isNewObjectiveOpen={isNewObjectiveOpen}
			toggleNewObjective={toggleNewObjective}
			disabledObjective={disabledObjective}
			isSI={isSI}
			t={t}
		/>
	)
}

export default ObjectiveStatefulContainer
