// Function component (presentational):

import React from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization (via React Context).
import { useTranslation } from 'react-i18next'

import parse from 'html-react-parser'

import { gaEvents } from '../../utils/gaEvents'
import { signUp } from '../../utils/auth'

import { speakWithSound, repeat } from '../../ducks/libra'

import LayoutWrapper from '../shared/styledComponents/page/LayoutWrapper'
import PageWrapper from '../shared/styledComponents/page/PageWrapper'
import PageTitle from '../shared/styledComponents/page/PageTitle'
import SectionWrapper from '../shared/styledComponents/page/SectionWrapper'
import SectionTitle from '../shared/styledComponents/page/SectionTitle'
import ContentWrapper from '../shared/styledComponents/page/ContentWrapper'
import Footer from '../shared/styledComponents/Footer'

import NavContainer from '../nav/NavContainer'
import LibraContainer from '../libra/LibraStatefulContainer'

import Title from '../shared/styledComponents/text/Title'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'

import InlineInfo from '../shared/styledComponents/text/InlineInfo'

import L10nLink from '../shared/navigation/L10nLink'

// import woman from '../../assets/videos/about/woman-high-five.mov'
// import woman from '../../assets/illustrations/about/woman-high-five.jpg'
import holistic from '../../assets/illustrations/about/checklist.png'
import savings from '../../assets/illustrations/about/save-time-and-money.png'
import support from '../../assets/illustrations/about/follow-up.png'

import Icon, {
	KeyOutlined,
	RightOutlined,
	CheckOutlined,
	MailOutlined,
	FieldNumberOutlined,
	InstagramOutlined,
	YoutubeFilled,
	TwitterOutlined,
} from '@ant-design/icons'
import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg'
import { ReactComponent as Spotify } from '../../assets/icons/spotify.svg'
import { Typography, Divider, Button } from 'antd'
import BlockRoundButton from '../shared/styledComponents/button/BlockRoundButton'

import libradiet from '../../assets/logos/libra.diet-logo.type.png'

import { Hero } from './LandingStyles'

const { Text } = Typography

const LandingView = () => {
	const { isDarkTheme } = useSelector((state) => state.appearance)

	const {
		i18n: { language },
		t,
	} = useTranslation(['landing', 'libra'])

	const pt = (path) => parse(t(path))

	const { speech } = useSelector((state) => state.libra)

	const dispatch = useDispatch()

	const libraStart = (path) => {
		const newSpeech = t(path)

		if (newSpeech === speech) return dispatch(repeat())

		dispatch(speakWithSound({ path, speech: newSpeech, language }))
	}

	const handleExplanationClick = (key) => libraStart(`libra:explanation.speech.${key}`)

	const { loginWithRedirect } = useAuth0()

	const handleHeroCtaClick = () => {
		gaEvents.heroCta('/signup')
		signUp(loginWithRedirect)
	}

	const renderSolutionTitle = (key, withInfo = false) =>
		renderLabel({
			icon: <CheckOutlined />,
			label: (
				<>
					{pt(`landing:solution.${key}.title`)}
					{withInfo && <InlineInfo onClick={() => handleExplanationClick(key)} />}
				</>
			),
		})

	return (
		<LayoutWrapper>
			<NavContainer sleekNav spreadNavUser isHub />

			<PageWrapper $withPadding={false}>
				<PageTitle
					style={{
						filter: isDarkTheme ? 'invert(1)' : undefined,
					}}
				>
					{/* <Title>{pt('landing:page.title')}</Title> */}
					<img src={libradiet} alt="libra diet logotype" height="64px" />
				</PageTitle>

				<SectionWrapper
					// id="about-hero-wrapper"
					$type="fullscreen"
				>
					{/* <video loop autoPlay muted>
						<source src={woman} type="video/mp4" />
					</video> */}
					{/* <img src={woman} alt="Woman high five" /> */}

					<SectionTitle>
						<Hero>{pt('landing:hero.title')}</Hero>
					</SectionTitle>

					<ContentWrapper style={{ alignItems: 'center' }}>
						<Card>
							<BlockRoundButton onClick={handleHeroCtaClick}>
								{renderLabel({
									icon: <KeyOutlined />,
									label: t('landing:hero.cta'),
									extra: <RightOutlined />,
								})}
							</BlockRoundButton>
						</Card>
					</ContentWrapper>
				</SectionWrapper>

				<SectionWrapper id="about-solution-wrapper" $type="cards">
					<SectionTitle>
						<Title level={3}>{t('landing:solution.title')}</Title>
					</SectionTitle>

					<ContentWrapper>
						<Card
							cover={
								<img
									src={holistic}
									alt={t('landing:solution.libra360.illustration')}
									width="100%"
									height="100%"
								/>
							}
						>
							<Card.Meta title={renderSolutionTitle('libra360', true)} />

							<div style={{ padding: '5px 8px' }}>
								<Text>{pt('landing:solution.libra360.description')}</Text>
							</div>
						</Card>
						<Card
							cover={
								<img
									src={savings}
									alt={t('landing:solution.selfCare.illustration')}
									width="100%"
									height="100%"
								/>
							}
						>
							<Card.Meta title={renderSolutionTitle('selfCare')} />

							<div style={{ padding: '5px 8px' }}>
								<Text>{pt('landing:solution.selfCare.description')}</Text>
							</div>
						</Card>
						<Card
							cover={
								<img
									src={support}
									alt={t('landing:solution.followUp.illustration')}
									width="100%"
									height="100%"
								/>
							}
						>
							<Card.Meta title={renderSolutionTitle('followUp')} />

							<div style={{ padding: '5px 8px' }}>
								<Text>{pt('landing:solution.followUp.description')}</Text>
							</div>
						</Card>
					</ContentWrapper>
				</SectionWrapper>

				<SectionWrapper id="about-subscription-wrapper">
					<SectionTitle>
						<Title level={3}>{pt('landing:subscription.title')}</Title>
					</SectionTitle>

					<ContentWrapper style={{ alignItems: 'center' }}>
						<Card>
							<L10nLink to="/subscription">
								<BlockRoundButton>
									{renderLabel({
										icon: <MailOutlined />,
										label: t('landing:subscription.action'),
										extra: <RightOutlined />,
									})}
								</BlockRoundButton>
							</L10nLink>
						</Card>
					</ContentWrapper>
				</SectionWrapper>

				<SectionWrapper id="about-referral-wrapper">
					<SectionTitle>
						<Title level={3}>{t('landing:referral.title')}</Title>
					</SectionTitle>

					<ContentWrapper style={{ alignItems: 'center' }}>
						<Card>
							<L10nLink to="/referral">
								<BlockRoundButton type="default">
									{renderLabel({
										icon: <FieldNumberOutlined />,
										label: t('landing:referral.action'),
										extra: <RightOutlined />,
									})}
								</BlockRoundButton>
							</L10nLink>
						</Card>
					</ContentWrapper>
				</SectionWrapper>
			</PageWrapper>

			<Footer>
				<div id="footer-social">
					<Button
						shape="circle"
						icon={<Icon component={Facebook} />}
						href="https://lbr.ai/Facebook"
						target="_blank"
					/>
					<Button
						shape="circle"
						icon={<InstagramOutlined />}
						href={`https://lbr.ai/Instagram${language.slice(0, 2) === 'fr' ? 'Fr' : ''}`}
						target="_blank"
					/>
					<Button
						shape="circle"
						icon={<YoutubeFilled />}
						href="https://lbr.ai/YouTube"
						target="_blank"
					/>
					<Button
						shape="circle"
						icon={<Icon component={Spotify} />}
						href="https://lbr.ai/Spotify"
						target="_blank"
					/>
					<Button
						shape="circle"
						icon={<TwitterOutlined />}
						href={`https://lbr.ai/Twitter${language.slice(0, 2) === 'fr' ? 'Fr' : ''}`}
						target="_blank"
					/>
				</div>
				<div id="footer-legal">
					<L10nLink to="/terms-and-conditions">{t('landing:footer.legal.t&c')}</L10nLink>
					<Divider type="vertical" />
					<L10nLink to="/privacy-policy">{t('landing:footer.legal.privacyPolicy')}</L10nLink>
					<Divider type="vertical" />
					<a href={`mailto:contact${language.slice(0, 2) === 'fr' ? '.fr' : ''}@libra.diet`}>
						{t('landing:footer.legal.contact')}
					</a>
				</div>
				<div id="footer-copyright">© Libra & Co SAS, {new Date().getFullYear()}</div>
			</Footer>

			<LibraContainer />
		</LayoutWrapper>
	)
}

export default LandingView
