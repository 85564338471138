import styled from 'styled-components'

const Illustration = styled.div`
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;

	// > img {
	// 	background-color: white;
	// }
`

export default Illustration
