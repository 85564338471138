import styled from 'styled-components'

import { Typography } from 'antd'

const { Title: AntTitle } = Typography

const Title = styled(AntTitle)`
	&.ant-typography {
		line-height: 48px;

		font-family: 'Fredoka';
		// font-weight: 300;

		font-size: ${(props) => {
			switch (props.level) {
				case 2:
					return '40px' // ex 30px
				case 3:
					return '32px' // ex 24px
				case 4:
					return '27px' // ex 20px
				case 5:
					return '22px' // ex 16px
				default:
					return '48px' // ex 36px
			}
		}};

		// mark {
		// 	padding-left: 16px;
		// 	padding-right: 16px;

		// 	border-radius: 32px;
		// }
	}
`

export default Title
