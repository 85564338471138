// Function component (presentational):

import React from 'react'

import { accountableTypes, accountableKinds } from '../../utils/yupSchema/accountsSchema'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import FormWrapper from '../shared/styledComponents/form/FormWrapper'

import renderLabel from '../shared/other/renderLabel'

import UserForm from './UserForm'
import ProfileForm from './ProfileForm'
import PersonForm from './PersonForm'
// import BusinessForm from './BusinessForm'

import { UserAddOutlined } from '@ant-design/icons'

const NewAccount = ({
	isFirst,
	geolocatedCountryCode,
	countries,
	step,
	formData,
	goBack,
	endSubmit,
	checkProfile,
	createUserAccount,
	t,
}) => {
	const isPerson = formData.account.accountableType === accountableTypes[0]

	const renderTitle = () => {
		const key = (() => {
			switch (step) {
				case 1:
					return 'user'
				case 2:
					return 'profile'
				case 3:
					return isPerson ? accountableKinds[0] : accountableKinds[1]
				default:
					return ''
			}
		})()

		return renderLabel({ icon: <UserAddOutlined />, label: t(`account:${key}Form.title`) })
	}

	const renderForm = () => {
		switch (step) {
			case 1:
				return <UserForm initialValues={formData} endSubmit={endSubmit} t={t} />
			case 2:
				return (
					<ProfileForm
						initialValues={formData}
						geolocatedCountryCode={geolocatedCountryCode}
						countries={countries}
						goBack={goBack}
						endSubmit={endSubmit}
						checkProfile={checkProfile}
						t={t}
					/>
				)
			case 3:
				return isPerson ? (
					<PersonForm
						initialValues={formData}
						isFirst={isFirst}
						goBack={goBack}
						endSubmit={endSubmit}
						createUserAccount={createUserAccount}
						t={t}
					/>
				) : null
			default:
				return null
		}
	}

	const renderBody = () => <FormWrapper>{renderForm()}</FormWrapper>

	return (
		<DrawerOrModalContainer
			isOpen={true}
			canClose={false}
			renderTitle={renderTitle}
			renderBody={renderBody}
			// onlyModal
		/>
	)
}

export default NewAccount
