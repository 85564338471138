// Function component (container, using Redux & i18next hooks):

import React from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import { toggleNewEvent, toggleAddMeal, setMeal } from '../../ducks/event'

import AddMeal from './AddMeal'

const AddMealContainer = () => {
	const { isAddMealOpen } = useSelector((state) => state.event)

	const dispatch = useDispatch()

	const { t } = useTranslation('event')

	const hideAddMeal = () => dispatch(toggleAddMeal())

	const handleClick = (name, foodId, physicalState, isHot) => {
		hideAddMeal()

		dispatch(setMeal({ isSimple: true, name, foodId, physicalState, isHot }))
		dispatch(toggleNewEvent())
	}

	return (
		<AddMeal
			isAddMealOpen={isAddMealOpen}
			hideAddMeal={hideAddMeal}
			handleClick={handleClick}
			t={t}
		/>
	)
}

export default AddMealContainer
