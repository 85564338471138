import styled from 'styled-components'

import { Calendar as AntCalendar } from 'antd'

const Calendar = styled(AntCalendar)`
	.ant-picker-calendar-header {
		padding: 8px;
		// margin-bottom: 8px;
	}

	.ant-picker-panel {
		padding-right: ${(props) => (props.fullscreen ? '8px' : undefined)};
		padding-left: ${(props) => (props.fullscreen ? '8px' : undefined)};
	}
`

export default Calendar
