// Function HOC/Higher Order Component
// wrapping App components to set the current theme
// (container, using React & Redux hooks):

// React Effect hook.
import React, { useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'

import { ConfigProvider, theme } from 'antd'

import { toggleDarkTheme } from '../../ducks/appearance'

import {
	darkToken,
	lightToken,
	lightComponents,
	darkComponents,
	lightDrawerStyles,
	darkDrawerStyles,
	lightModalStyles,
	darkModalStyles,
} from './themeConfig'

import StyledTheme from './styledTheme'

const Theme = ({ children }) => {
	const dispatch = useDispatch()

	const { isOSTheme, isDarkTheme } = useSelector((state) => state.appearance)

	const isDarkOSTheme = window.matchMedia('(prefers-color-scheme:dark)').matches

	const currentTheme = isOSTheme ? 'oS' : isDarkTheme ? 'dark' : 'light'
	const isCurrentOSTheme = currentTheme === 'oS'

	const shouldChangeTheme = isCurrentOSTheme && isDarkOSTheme && !isDarkTheme
	useEffect(() => {
		shouldChangeTheme && dispatch(toggleDarkTheme())
	}, [shouldChangeTheme, dispatch])

	const token = isDarkTheme ? darkToken : lightToken

	const algorithm = isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm

	const components = isDarkTheme ? darkComponents : lightComponents

	const drawerStyles = isDarkTheme ? darkDrawerStyles : lightDrawerStyles

	const modalStyles = isDarkTheme ? darkModalStyles : lightModalStyles

	return (
		<ConfigProvider
			theme={{
				token,
				algorithm,
				components,
			}}
			drawer={{ styles: drawerStyles }}
			modal={{ styles: modalStyles }}
		>
			<StyledTheme>{children}</StyledTheme>
		</ConfigProvider>
	)
}

export default Theme
