// Function component (presentational):

import React from 'react'

import { colors } from '../../styles/colors'

import { Line } from '@ant-design/charts'

const MeasurementLine = ({
	data,
	min,
	max,
	// t,
	isDarkTheme,
}) => {
	let scale = { color: { range: [colors.primary, colors.libraStar] } }
	if (min && max) {
		scale = { ...scale, y: { domain: [min, max] } }
	}

	const config = {
		data,
		xField: 'date',
		yField: 'value',
		seriesField: 'state',
		colorField: 'state',
		shapeField: 'smooth',
		// xAxis: { title: { text: t('measurement:line.xAxis.date.label') } },
		// yAxis: { title: { text: t('measurement:line.yAxis.bodyMass.label') } },
		// axis: {
		// 	x: { title: t('measurement:line.xAxis.date.label') },
		// 	y: { title: t('measurement:line.yAxis.bodyMass.label') },
		// },
		connectNulls: { connect: true },
		// tooltip: { channel: 'y' },
		style: { lineWidth: 3 },
		scale,
		legend: { color: { layout: { justifyContent: 'center' } } },
		theme: isDarkTheme ? 'classicDark' : 'classic',
		height: 352,
	}

	return <Line className={'chart'} {...config} />
}

export default MeasurementLine
