// Function component (presentational):

import React from 'react'

import LayoutWrapper from '../shared/styledComponents/page/LayoutWrapper'
import PageWrapper from '../shared/styledComponents/page/PageWrapper'

import NavContainer from '../nav/NavContainer'

import SubscriptionConfirmationContainer from './SubscriptionConfirmationContainer'

const SubscriptionConfirmationView = () => (
	<LayoutWrapper>
		<NavContainer sleekNav withNavUser={false} />

		<PageWrapper>
			<SubscriptionConfirmationContainer />
		</PageWrapper>
	</LayoutWrapper>
)

export default SubscriptionConfirmationView
