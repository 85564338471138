import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

const path = '/subscribers'

export const subscribersShow = (locale, key) => (dispatch) => {
	const endpoint = localizePath(locale, `${path}/${key}`)

	return dispatch(fetchLibraDietApiData({ endpoint, options: initRequestOptions() }))
}

export const subscribersCreate = (locale, subscriberAttrs) => (dispatch) => {
	const endpoint = localizePath(locale, path)
	const method = 'POST'
	const data = { subscriber: subscriberAttrs }
	const options = initRequestOptions({ method, data })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}

export const subscribersConfirm = (locale, key) => (dispatch) => {
	const endpoint = localizePath(locale, `${path}/${key}/confirm`)
	const method = 'PATCH'
	const options = initRequestOptions({ method })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}
