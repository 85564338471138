// Function HOC/Higher Order Component
// wrapping Router components to set the current locale based on the active URI
// (container, using React, React Router & i18next hooks):

// React Effect hook.
import React, { useEffect } from 'react'
// React Router hook for routing.
import { useLocation, useNavigate } from 'react-router-dom'
// i18next hook for localization (via React Context).
import { useTranslation } from 'react-i18next'

import { ConfigProvider } from 'antd'

import { localeFile } from './l10nConfig'

import { prefixPath, replacePathLocale, getPathLocale } from '../../utils/l10n'

import { message } from 'antd'

const L10n = ({ children }) => {
	// let { locale } = useParams()

	let location = useLocation()

	const navigate = useNavigate()

	const {
		i18n,
		i18n: { language },
		t,
	} = useTranslation('l10n')

	const [messageApi, contextHolder] = message.useMessage()

	// Set the current locale based on the active URI.
	useEffect(() => {
		let { pathname, search, hash } = location
		const locale = getPathLocale(pathname)
		const currentFullPath = `${pathname}${search}${hash}`

		const setLocale = (newLocale, force = false) => {
			if (newLocale === undefined) {
				// messageApi.info({
				// 	content: t('l10n:locale.message.info', { fallbackLocale: language }),
				// 	key: 'localeUndefined',
				// })
				return
			} else if (newLocale === 'unknown') {
				navigate(prefixPath(currentFullPath, language), { replace: true })

				messageApi.error({
					content: t('l10n:locale.message.error', { fallbackLocale: language }),
					key: 'localeUnkown',
				})
				return
			} else if (newLocale === 'unsupported') {
				navigate(replacePathLocale(currentFullPath, language), { replace: true })

				messageApi.warning({
					content: t('l10n:locale.message.warning', { fallbackLocale: language }),
					key: 'localeUnsupported',
				})
				return
			} else if (force && newLocale !== language) {
				i18n.changeLanguage(newLocale, () =>
					messageApi.success({
						content: t('l10n:locale.message.success', { newLocale: newLocale }),
						key: 'localeChanged',
					}),
				)
				return
			}
		}

		setLocale(locale, true)

		return () => {
			setLocale(locale, locale !== language)
		}
	}, [location, navigate, i18n, language, t, messageApi])

	return (
		<>
			{contextHolder}

			<ConfigProvider
				locale={localeFile[language]}
				// direction="rtl"
			>
				{children}
			</ConfigProvider>
		</>
	)
}

export default L10n
