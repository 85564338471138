import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

const path = '/territories'

export const territoriesIndex = (locale, accessToken) => (dispatch) => {
	const endpoint = localizePath(locale, path)
	const options = initRequestOptions({ accessToken })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}
