// Function component (presentational):

import React from 'react'

import LayoutWrapper from '../shared/styledComponents/page/LayoutWrapper'
import PageWrapper from '../shared/styledComponents/page/PageWrapper'

import NavContainer from '../nav/NavContainer'
import LibraContainer from '../libra/LibraStatefulContainer'

import ReferralContainer from './ReferralContainer'

const ReferralView = () => (
	<LayoutWrapper>
		<NavContainer sleekNav />

		<PageWrapper>
			<ReferralContainer />
		</PageWrapper>

		<LibraContainer />
	</LayoutWrapper>
)

export default ReferralView
