// Function component (container, using Redux, React Router, Auth0 & i18next hooks):

// React State & Effect hooks.
// import React, { useContext, useRef, useEffect } from 'react'
import React, { useContext, useRef } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hook for routing.
import { useLocation, useNavigate } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import { CableContext } from '../../contexts/cable/cable'

import build from 'redux-object'

import { prefixPath } from '../../utils/l10n'
import { scrollToId } from '../../utils/window'

import { toggleNewChat, setContent, resetContent, setAnswering } from '../../ducks/assistance'
import { chatsCreate } from '../../ducks/chats'

import NewChat from './NewChat'

const NewChatContainer = ({ fromThread = false }) => {
	const { isNewChatOpen, content, isAnswering } = useSelector((state) => state.assistance)

	const dispatch = useDispatch()

	const initialValues = { content }

	const inputRef = useRef()

	// const shouldFocusInput = isNewChatOpen && inputRef.current
	// useEffect(() => {
	// 	shouldFocusInput && inputRef.current.focus({ cursor: 'end' })
	// }, [shouldFocusInput])

	const syncContent = (value) => dispatch(setContent(value))

	const {
		i18n: { language },
		t,
	} = useTranslation(['assistance', 'chats', 'validation'])

	const { getAccessTokenSilently } = useAuth0()

	const { currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const assistanceId = currentAccount?.profile?.assistance?.id

	const createChat = async (chatAttrs) => {
		const accessToken = await getAccessTokenSilently()

		return dispatch(chatsCreate(language, accessToken, assistanceId, chatAttrs))
	}

	const hideNewChat = () => dispatch(toggleNewChat())

	let { pathname, search } = useLocation()
	const currentPath = `${pathname}${search}`

	const navigate = useNavigate()

	const navToAssistance = () => {
		const newPath = prefixPath('/assistance', language)

		currentPath !== newPath ? navigate(newPath) : scrollToId('write-chat', { behavior: 'smooth' })
	}

	const cable = useContext(CableContext)
	const connection = cable?.connection
	const isConnected = connection && !connection.disconnected

	const endSubmit = () => {
		dispatch(resetContent())

		isConnected && dispatch(setAnswering(true))

		!fromThread && hideNewChat()
		navToAssistance()
	}

	return (
		<NewChat
			fromThread={fromThread}
			isNewChatOpen={isNewChatOpen}
			isAnswering={isAnswering}
			initialValues={initialValues}
			inputRef={inputRef}
			syncContent={syncContent}
			createChat={createChat}
			hideNewChat={hideNewChat}
			endSubmit={endSubmit}
			t={t}
		/>
	)
}

export default NewChatContainer
