// Function component (presentational):

import React from 'react'

import NavDateMenu from './NavDateMenu'

import StyledNavDate from './NavDateStyles'

const NavDate = ({ sleekNav, isDashboardOpen, isMobileM }) => (
	<StyledNavDate
	// id="nav-cal"
	>
		{!sleekNav && <NavDateMenu isDashboardOpen={isDashboardOpen} isMobileM={isMobileM} />}
	</StyledNavDate>
)

export default NavDate
