import styled from 'styled-components'

import { TimePicker } from 'formik-antd'

const StyledTimePicker = styled(TimePicker)`
	// width: 100%;
	// max-width: 362px;
	// display: flex;
	// margin: 0 auto;
`

export default StyledTimePicker

export const timePickerStyle = {
	width: '100%',
	maxWidth: '362px',
	display: 'flex', // Instead of `inline-flex`.
	margin: '0 auto',
}
