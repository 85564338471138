// Function component (presentational):

import React from 'react'

import { renderMindLabel } from '../shared/other/renderLabel'

import InlineMenu from '../shared/styledComponents/menu/InlineMenu'

import L10nLink from '../shared/navigation/L10nLink'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import { buildWeightItem } from '../measurement/buildWeightItem'
import { buildMoodItem } from '../mood/buildMoodItem'

import { CloseOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import TextCircleButton from '../shared/styledComponents/button/TextCircleButton'

const AddPractice = ({
	isAddPracticeOpen,
	hideAddPractice,
	isAfterToday,
	handleNewWeightClick,
	handleNewMoodClick,
	handleNewPracticeClick,
	t,
}) => {
	const renderTitle = () =>
		renderMindLabel(
			t('event:addPractice.title'),
			<TextCircleButton icon={<CloseOutlined />} onClick={hideAddPractice} />,
		)

	const items = []

	const weightItem = buildWeightItem(handleNewWeightClick, t, isAfterToday)

	const dividerItem = { type: 'divider' }

	const moodItem = buildMoodItem(handleNewMoodClick, t, isAfterToday)

	const selfExpressionItem = {
		key: 'self-expression',
		icon: <EditOutlined />,
		label: t('event:addPractice.selfExpression'),
		disabled: true,
	}

	const searchMeditationItem = {
		key: 'search-meditation',
		icon: <SearchOutlined />,
		label: (
			<div onClick={hideAddPractice}>
				<L10nLink to={{ pathname: '/library', search: '?scope=meditation' }}>
					{t('event:addPractice.searchMeditation')}
				</L10nLink>
			</div>
		),
	}

	items.push(
		weightItem,
		dividerItem,
		moodItem,
		dividerItem,
		selfExpressionItem,
		dividerItem,
		searchMeditationItem,
	)

	const renderBody = () => <InlineMenu items={items} />

	return (
		<DrawerOrModalContainer
			isOpen={isAddPracticeOpen}
			hide={hideAddPractice}
			renderTitle={renderTitle}
			renderBody={renderBody}
		/>
	)
}

export default AddPractice
