import dayjs from 'dayjs'

import { setDateFromTime } from './dates'

export const breakfastlId = '1'
export const morningSnackId = '2'
export const lunchId = '3'
export const afternoonTeaId = '4'
export const dinnerId = '5'
export const lateNightSnackId = '6'

const currentTime = dayjs()
export const defaultMealKindId = (quickDrink) => {
	switch (true) {
		case !quickDrink && currentTime.isBefore(setDateFromTime('09:00')):
			return breakfastlId
		case currentTime.isBefore(setDateFromTime('12:00')):
			return morningSnackId
		case !quickDrink && currentTime.isBefore(setDateFromTime('14:00')):
			return lunchId
		case !quickDrink && currentTime.isAfter(setDateFromTime('17:00')):
			return dinnerId
		case currentTime.isAfter(setDateFromTime('21:00')):
			return lateNightSnackId
		default:
			return afternoonTeaId
	}
}

export const waterFoodId = '6136'
export const teaFoodId = '5721'
export const coffeeFoodId = '4379'

export const cupValueInMl = 240
export const cupValueInFlOz = 8 // Volontarily not a perfect conversion!
