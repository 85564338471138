import styled from 'styled-components'

import { mobileMLNavMenuWidth } from '../../../../styles/sizes'
import deviceMinHeight from '../../../../styles/deviceMinHeight'
import deviceMinWidth from '../../../../styles/deviceMinWidth'

import Wrapper from './Wrapper'
import SectionTitle from './SectionTitle'
import RadioGroup from '../radio/RadioGroup'
import ContentWrapper from './ContentWrapper'
import Illustration from '../Illustration'

import Card from '../card/Card'

const SectionWrapper = styled(Wrapper)`
	height: ${(props) =>
		props.$type === 'fullscreen' ? 'calc(100vh - 144px - env(safe-area-inset-top))' : undefined};
	@supports (height: 100dvh) {
		height: ${(props) =>
			props.$type === 'fullscreen' ? 'calc(100dvh - 144px - env(safe-area-inset-top))' : undefined};
	}

	position: ${(props) =>
		props.$type === 'fullscreen' || props.$type === 'banner' ? 'relative' : undefined};

	flex-direction: column;
	align-items: center;
	gap: 16px;
	// flex-grow: ${(props) => (props.$type === 'illustration' ? 1 : undefined)};
	// @media ${deviceMinHeight.laptop} {
	// 	flex-grow: ${(props) => (props.$type === 'illustration' ? 'unset' : undefined)};
	// }

	padding-right: env(safe-area-inset-right);
	padding-left: env(safe-area-inset-left);

	> img,
	> video {
		height: ${(props) =>
			props.$type === 'fullscreen' ? '100vh' : props.$type === 'banner' ? '224px' : undefined};
		@supports (height: 100dvh) {
			height: ${(props) =>
				props.$type === 'fullscreen' ? '100dvh' : props.$type === 'banner' ? '224px' : undefined};
		}
		width: 100vw;

		position: absolute;
		// top: -160px;
		top: -128px;

		object-fit: cover;
	}

	> ${SectionTitle} {
		@media (min-height: 430px) {
			max-width: ${(props) => (props.$type === 'fullscreen' ? '384px' : undefined)};
		}

		@media (min-height: 480px) {
			padding-left: ${(props) => (props.$type === 'fullscreen' ? '16px' : undefined)};
			padding-right: ${(props) => (props.$type === 'fullscreen' ? '16px' : undefined)};
		}

		.ant-typography {
			@media (max-width: 375px) {
				font-size: ${(props) => (props.$type === 'fullscreen' ? '32px' : undefined)};
			}
		}
	}

	> ${RadioGroup} {
		width: ${(props) => (props.$type === 'nav' ? '100%' : undefined)};
		@media ${deviceMinHeight.mobileM} {
			width: ${(props) => (props.$type === 'nav' ? '384px' : undefined)};
		}
	}

	> ${ContentWrapper} {
		max-width: ${(props) =>
			props.$type === 'cards' || props.$type === 'article' ? '100%' : undefined};
		@media ${deviceMinWidth.mobileML} {
			max-width: ${(props) => (props.$type === 'article' ? mobileMLNavMenuWidth : undefined)};
		}
		@media ${deviceMinWidth.tablet} {
			max-width: ${(props) =>
				props.$type === 'article' ? `calc(${mobileMLNavMenuWidth} * 2)` : undefined};
		}

		flex-direction: ${(props) => (props.$type === 'cards' ? 'row' : undefined)};
		flex-wrap: ${(props) => (props.$type === 'cards' ? 'wrap' : undefined)};
		justify-content: ${(props) => (props.$type === 'cards' ? 'center' : undefined)};
		// gap: ${(props) => (props.$type === 'cards' ? '16px' : undefined)};

		margin-bottom: ${(props) => (props.$type === 'fullscreen' ? '80px' : undefined)};
		@supports (height: 100dvh) {
			margin-bottom: ${(props) =>
				props.$type === 'fullscreen' ? 'calc(80px + calc(100vh - 100dvh))' : undefined};
		}

		padding: ${(props) => (props.$type === 'cards' || props.$type === 'article' ? '0' : undefined)};

		@media ${deviceMinHeight.laptop} {
			margin-bottom: ${(props) => (props.$type === 'fullscreen' ? 'calc(50vh - 24px)' : undefined)};
			@supports (height: 100dvh) {
				margin-bottom: ${(props) =>
					props.$type === 'fullscreen' ? 'calc(50dvh - 24px)' : undefined};
			}
		}

		> ${Card} {
			width: ${(props) => (props.$type === 'article' ? '100%' : undefined)};
		}
	}

	${Illustration} > img {
		min-height: 216px;
		min-width: 216px;
		max-height: calc(100vh - 352px);
		max-width: calc(100vh - 352px);
		@supports (height: 100dvh) {
			max-height: calc(100dvh - 352px);
			max-width: calc(100dvh - 352px);
		}
	}
`

export default SectionWrapper
