// import styled from 'styled-components'

// import { appPadding, mobileMLNavMenuPadding, mobileMLInnerCardWidth } from '../../styles/sizes'
// import deviceMinWidth from '../../styles/deviceMinWidth'

// import { LibraAffixButton } from './LibraAffixStyles'

// const StyledLibraControls = styled(LibraAffixButton)`
// 	,
// 	&.ant-btn-loading {
// 		right: ${appPadding};
// 		bottom: ${appPadding};

// 		@media ${deviceMinWidth.mobileML} {
// 			right: calc(${mobileMLNavMenuPadding} + ${appPadding});
// 		}

// 		@media ${deviceMinWidth.laptop} {
// 			right: unset;
// 			left: calc(${mobileMLInnerCardWidth} - ${appPadding});
// 		}
// 	}
// `

// export default StyledLibraControls

import styled from 'styled-components'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'

const StyledLibraControls = styled(FloatMenu).attrs(() => ({
	// Defining static props.
	$bottom: 0,
	$right: 'calc(100vw - min(100vw, 384px) - env(safe-area-inset-left))',
}))`
	z-index: 1001;
`

export default StyledLibraControls
