// Function component (presentational):

import React from 'react'

import PageIllustration from '../shared/styledComponents/page/PageIllustration'
import PageAction from '../shared/styledComponents/page/PageAction'

import renderLabel from '../shared/other/renderLabel'

import Tabs from '../shared/styledComponents/tabs/Tabs'
// import TabPaneWrapper from '../shared/styledComponents/tabs/TabPaneWrapper'

import Card from '../shared/styledComponents/card/Card'

import FieldContainer from '../field/FieldContainer'

import JournalCarousel from './JournalCarousel'

import AvatarContainer from '../avatar/AvatarContainer'

import AddMealContainer from '../meal/AddMealContainer'
import AddPracticeContainer from '../practice/AddPracticeContainer'
import AddActivityContainer from '../activity/AddActivityContainer'

import pattern from '../../assets/patterns/abstract-3.jpg'

import {
	ScheduleOutlined,
	LoadingOutlined,
	UserSwitchOutlined,
	UsergroupAddOutlined,
} from '@ant-design/icons'
import { Avatar, Button } from 'antd'

import { AccountTitle } from '../account/AccountsStyles'

const Journal = ({
	isManaged,
	name,
	showAccounts,
	implicitField,
	showCalendar,
	field,
	isNutrition,
	isMind,
	isFitness,
	section,
	handleSectionChange,
	journalId,
	unavailableDays,
	prev,
	next,
	logs,
	daysBefore,
	dates,
	slidesToShow,
	handleClick,
	t,
	isDarkTheme,
}) => {
	const children = [
		<JournalCarousel
			key="agenda-pane"
			field={field}
			journalId={journalId}
			unavailableDays={unavailableDays}
			logs={logs}
			daysBefore={daysBefore}
			dates={dates}
			next={next}
			prev={prev}
			showCalendar={showCalendar}
			t={t}
			slidesToShow={slidesToShow}
			handleClick={handleClick}
		/>,
	]

	const items = []

	const allNutritionItem = {
		key: 'allNutrition',
		label: t('journal:sections.allNutrition.title'),
		children,
	}
	const mealsItem = { key: 'meals', label: t('journal:sections.meals.title'), disabled: true }
	const collationsItem = {
		key: 'collations',
		label: t('journal:sections.collations.title'),
		disabled: true,
	}
	isNutrition && items.push(allNutritionItem, mealsItem, collationsItem)

	const allMindItem = { key: 'allMind', label: t('journal:sections.allMind.title'), children }
	const selfCareItem = {
		key: 'selfCare',
		label: t('journal:sections.selfCare.title'),
		disabled: true,
	}
	const introspectionsItem = {
		key: 'introspections',
		label: t('journal:sections.introspections.title'),
		disabled: true,
	}
	isMind && items.push(allMindItem, selfCareItem, introspectionsItem)

	const allFitnessItem = {
		key: 'allFitness',
		label: t('journal:sections.allFitness.title'),
		children,
	}
	const routineItem = { key: 'routine', label: t('journal:sections.routine.title'), disabled: true }
	const workoutsItem = {
		key: 'workouts',
		label: t('journal:sections.workouts.title'),
		disabled: true,
	}
	isFitness && items.push(allFitnessItem, routineItem, workoutsItem)

	const avatar = journalId ? (
		<AvatarContainer size={60} />
	) : (
		<Avatar icon={<ScheduleOutlined />} size={60} />
	)
	const extra = !journalId ? (
		<LoadingOutlined />
	) : isManaged ? (
		<Button shape="circle" icon={<UserSwitchOutlined />} onClick={showAccounts} />
	) : (
		<Button shape="circle" icon={<UsergroupAddOutlined />} disabled />
	)

	const isPrivate = true

	const title = t(`journal:page.${isPrivate ? 'private' : 'public'}Title`)

	return (
		<>
			<PageIllustration $src={pattern} $isDarkTheme={isDarkTheme} />

			<PageAction style={{ position: 'relative' }}>
				{name && <AccountTitle>{title}</AccountTitle>}
				<Card $size={'wide'} $isAction $withBigAvatar>
					<Card.Meta avatar={avatar} title={renderLabel({ label: name ?? title, extra })} />
				</Card>

				<FieldContainer field={implicitField} />
			</PageAction>

			<Tabs activeKey={section} items={items} onChange={handleSectionChange}></Tabs>

			<AddMealContainer />
			<AddPracticeContainer />
			<AddActivityContainer />
		</>
	)
}

export default Journal
