// Function component (presentational):

import React from 'react'

import NavUserMenu from './NavUserMenu'

import StyledNavUser from './NavUserStyles'

const NavUser = ({
	withNavUser,
	sleekNav,
	spreadNavUser,
	inOnboarding,
	areSettingsOpen,
	toggleSettings,
	showAccounts,
	isAuthenticated,
	withAccounts,
	handleSignup,
	handleLogin,
	handleLogout,
	isDashboardOpen,
	withSideDash,
	toggleDashboard,
	t,
	isTabletL,
}) => (
	<StyledNavUser
	// id="nav-user"
	>
		{withNavUser && (
			<NavUserMenu
				sleekNav={sleekNav}
				spreadNavUser={spreadNavUser}
				inOnboarding={inOnboarding}
				areSettingsOpen={areSettingsOpen}
				toggleSettings={toggleSettings}
				showAccounts={showAccounts}
				isAuthenticated={isAuthenticated}
				withAccounts={withAccounts}
				handleSignup={handleSignup}
				handleLogin={handleLogin}
				handleLogout={handleLogout}
				isDashboardOpen={isDashboardOpen}
				withSideDash={withSideDash}
				toggleDashboard={toggleDashboard}
				t={t}
				isTabletL={isTabletL}
			/>
		)}
	</StyledNavUser>
)

export default NavUser
