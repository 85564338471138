// Function component (presentational):

import React from 'react'

import { Typography, Tag } from 'antd'

const { Text } = Typography

const ThemeOption = ({ theme, disabled, handleSelect, t }) => (
	<Text onClick={() => handleSelect(theme)} disabled={disabled} style={{ display: 'flex' }}>
		{t(`preference:theme.${theme}.label`)}
		{theme === 'dark' && (
			<Tag bordered={false} style={{ marginLeft: 8 }}>
				beta
			</Tag>
		)}
	</Text>
)

export default ThemeOption
