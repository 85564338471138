// Function component (presentational):

import React from 'react'

import {
	renderCalendarLabel,
	renderSleepingLabel,
	renderActivityLabel,
} from '../shared/other/renderLabel'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import FormWrapper from '../shared/styledComponents/form/FormWrapper'

import ActivityEventForm from './ActivityEventForm'

import { CloseOutlined } from '@ant-design/icons'
import { Typography, Divider } from 'antd'
import TextCircleButton from '../shared/styledComponents/button/TextCircleButton'

const { Text } = Typography

const NewActivityEvent = ({
	isNewActivityEventOpen,
	isQuickAction,
	activityKindId,
	name,
	isToday,
	initialValues,
	birthDate,
	selectDay,
	maxDuration,
	createEvent,
	hideNewEvent,
	endSubmit,
	t,
}) => {
	const renderTitle = () =>
		renderCalendarLabel(
			t('event:activityEventForm.title'),
			<TextCircleButton icon={<CloseOutlined />} onClick={hideNewEvent} />,
		)

	const label = <Text type="secondary">{name}</Text>

	const renderBody = () => (
		<FormWrapper>
			{activityKindId === '244' ? renderSleepingLabel(label) : renderActivityLabel(label)}

			<Divider style={{ margin: '8px 0' }} />

			<ActivityEventForm
				initialValues={initialValues}
				isQuickAction={isQuickAction}
				isToday={isToday}
				birthDate={birthDate}
				selectDay={selectDay}
				maxDuration={maxDuration}
				createEvent={createEvent}
				endSubmit={endSubmit}
				t={t}
			/>
		</FormWrapper>
	)

	return (
		<DrawerOrModalContainer
			isOpen={isNewActivityEventOpen}
			hide={hideNewEvent}
			renderTitle={renderTitle}
			renderBody={renderBody}
		/>
	)
}

export default NewActivityEvent
