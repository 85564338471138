import _ from 'lodash'

const LbToKg = 0.45359237

export const convertKgToLb = (v) => _.round(v / LbToKg, 1)

export const convertLbToKg = (v) => _.round(v * LbToKg, 1)

const FtToCm = 30.48

export const convertCmToFt = (v) => _.round(v / FtToCm, 1)

export const convertFtToCm = (v) => _.round(v * FtToCm, 1)

const OzToG = 28.3495

export const convertGToOz = (v) => _.round(v / OzToG, 1)

export const convertOzToG = (v) => _.round(v * OzToG, 1)

// const FlOzToMl = 29.5735
const FlOzToMlUSDA = 30

export const convertMlToFlOz = (v) => _.round(v / FlOzToMlUSDA, 1)

export const convertFlOzToMl = (v) => _.round(v * FlOzToMlUSDA, 1)

export const avg = (array) => array.reduce((a, b) => a + b, 0) / array.length
