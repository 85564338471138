import * as yup from 'yup'

import bodyMeasurementsSchema from './bodyMeasurementsSchema'

const firstMeasurementFormSchema = (t) =>
	yup.object().shape({
		bodyMeasurement: bodyMeasurementsSchema(t),
	})

export default firstMeasurementFormSchema
