// Function component (presentational):

import React from 'react'

import LayoutWrapper from '../shared/styledComponents/page/LayoutWrapper'
import PageWrapper from '../shared/styledComponents/page/PageWrapper'

import NavContainer from '../nav/NavContainer'
import LibraContainer from '../libra/LibraStatefulContainer'
// import DashboardContainer from '../dashboard/DashboardContainer'

import FoodItemContainer from './FoodItemStatefulContainer'

import { Layout } from 'antd'

const FoodItemView = () => (
	<LayoutWrapper>
		<Layout>
			<NavContainer sleekNav />

			<PageWrapper>
				<FoodItemContainer />
			</PageWrapper>

			<LibraContainer />
		</Layout>
	</LayoutWrapper>
)

export default FoodItemView
