import styled from 'styled-components'

import { Collapse as AntCollapse } from 'antd'

const Collapse = styled(AntCollapse)`
	.ant-collapse-expand-icon {
		padding-inline-end: 18px !important;
	}

	.ant-collapse-content-box {
		padding-block: 0 !important;
		margin-bottom: 8px;
	}

	.ant-pagination {
		margin: 16px 0 0 !important;
	}
`

export default Collapse
