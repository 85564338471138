// Function component (presentational):

import React from 'react'

import { Formik } from 'formik'
import {
	Form,
	// Radio,
	SubmitButton,
} from 'formik-antd'

import dailyWaterFormSchema from '../../utils/yupSchema/dailyWaterFormSchema'

// import { transformInvalidAttrErrors } from '../../utils/api'

import { renderDrinkLabel } from '../shared/other/renderLabel'

import FormItem from '../shared/styledComponents/form/FormItem'
import { radioGroupStyle } from '../shared/styledComponents/form/RadioGroup'
import RadioGroup from '../shared/styledComponents/form/RadioGroup'
import RadioButton from '../shared/styledComponents/form/RadioButton'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'

import { DownOutlined } from '@ant-design/icons'
import { App as AntApp } from 'antd'

// const { Group: RadioGroup, Button: RadioButton } = Radio

const DailyWaterForm = ({ initialValues, createEvent, endSubmit, t }) => {
	const { message } = AntApp.useApp()

	const handleSubmit = async (
		values,
		// { setErrors }
	) => {
		if (values.withDailyWater === 'no') {
			message.warning(t('event:dailyWaterForm.message.warning'))

			endSubmit()
			return
		}

		endSubmit()

		try {
			const { response } = await createEvent()

			if (response) {
				// message.success(t('event:dailyWaterForm.message.success'))
				// endSubmit(values)
			}
		} catch (e) {
			// const errors = transformInvalidAttrErrors(e.errors)
			// // Async (server-side) validation.
			// setErrors(errors)
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={dailyWaterFormSchema(t)}
			onSubmit={handleSubmit}
		>
			<Form
				// id="daily-water-form"
				layout="vertical"
				colon={false}
			>
				<FormItem
					name="withDailyWater"
					label={renderDrinkLabel(t('event:dailyWaterForm.commit.label'))}
				>
					<RadioGroup name="withDailyWater" style={radioGroupStyle}>
						<RadioButton value={'no'}>{t('replies:attributes.ynValues.no.label')}</RadioButton>
						<RadioButton value={'yes'}>{t('replies:attributes.ynValues.yes.label')}</RadioButton>
					</RadioGroup>
				</FormItem>

				<SubmitWrapper $step="first">
					<SubmitButton
						// htmlType="submit"
						shape="circle"
						icon={<DownOutlined />}
					/>
				</SubmitWrapper>
			</Form>
		</Formik>
	)
}

export default DailyWaterForm
