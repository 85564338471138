// Function component (container, using React, Redux, React Router, Auth0, i18next & React Responsive hooks):

// React Effect hooks.
import React, { useEffect, useCallback } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hook for routing.
import { useLocation, useNavigate } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
import { useMediaQuery } from 'react-responsive'

import build from 'redux-object'

import { prefixPath } from '../../utils/l10n'

import { accountableTypes } from '../../utils/yupSchema/accountsSchema'

import {
	toggleDashboard,
	endDashboardUpdate,
	toggleFields,
	toggleNutrition,
	toggleMind,
	toggleFitness,
} from '../../ducks/dashboard'
import { scoresIndex } from '../../ducks/scores'

import deviceMinWidth from '../../styles/deviceMinWidth'

import Dashboard from './Dashboard'

const DashboardContainer = ({ fromDeck }) => {
	const { currentAccountableType, currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const person = currentAccountableType === accountableTypes[0] && currentAccount.accountable
	const personId = person?.id

	const { currentDay } = useSelector((state) => state.journal)

	const {
		isDashboardOpen,
		shouldDashboardUpdate,
		areFieldsVisible,
		isNutritionVisible,
		isMindVisible,
		isFitnessVisible,
	} = useSelector((state) => state.dashboard)

	const isLaptopML = useMediaQuery({
		// query: '(min-width: 1152px)'
		query: `${deviceMinWidth.laptopML}`,
	})

	const isTablet = useMediaQuery({
		// query: '(min-width: 768px)'
		query: `${deviceMinWidth.tablet}`,
	})

	const dispatch = useDispatch()

	const toggleDash = useCallback(() => dispatch(toggleDashboard()), [dispatch])

	let { pathname, search } = useLocation()
	const currentPath = `${pathname}${search}`

	const navigate = useNavigate()

	const navToProfileNutritionTargets = () => {
		const newPath = prefixPath('/profile?section=nutritionTargets', language)

		newPath !== currentPath && navigate(newPath)
		!isTablet && toggleDash()
	}
	const navToProfileLifestyle = () => {
		const newPath = prefixPath('/profile?section=lifestyle', language)

		newPath !== currentPath && navigate(newPath)
		!isTablet && toggleDash()
	}
	const navToProfileMoods = () => {
		const newPath = prefixPath('/profile?section=moods', language)

		newPath !== currentPath && navigate(newPath)
		!isTablet && toggleDash()
	}

	const shouldToggleDash = isDashboardOpen === '' && isLaptopML
	useEffect(() => {
		shouldToggleDash && toggleDash()
	}, [shouldToggleDash, toggleDash])

	const { getAccessTokenSilently } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['dashboard', 'l10n', 'field'])

	const shouldListScores = (fromDeck || isDashboardOpen) && personId && currentDay
	const listScores = useCallback(async () => {
		if (!shouldListScores) return

		try {
			const accessToken = await getAccessTokenSilently()

			return dispatch(
				scoresIndex(language, accessToken, {
					of: personId,
					on: currentDay,
				}),
			)
		} catch (e) {
			// throw e
		}
	}, [shouldListScores, personId, currentDay, getAccessTokenSilently, language, dispatch])

	useEffect(() => {
		listScores()
	}, [listScores])

	useEffect(() => {
		if (!shouldDashboardUpdate) return

		const relistScores = async () => {
			const { response } = await listScores()

			response && dispatch(endDashboardUpdate())
		}

		relistScores()
	}, [shouldDashboardUpdate, listScores, dispatch])

	const ss = build(apiData, 'score')
	const scores = ss?.filter((s) => s.person.id === personId && s.date === currentDay)
	const hydrationScores = scores?.filter((s) => s.category === 'water')
	const hydrationPoints = hydrationScores?.reduce((n, { points }) => n + +points, 0) || 0
	const hydrationTopPoints = hydrationScores?.length && +hydrationScores?.[0]?.topPoints
	const hydrationPct = hydrationTopPoints
		? Math.round((hydrationPoints / hydrationTopPoints) * 100)
		: 0
	const eatingScores = scores?.filter((s) =>
		['energy', 'carbs', 'fat', 'protein', 'vitamin', 'mineral'].includes(s.category),
	)
	const eatingPoints = eatingScores?.reduce((n, { points }) => n + +points, 0) || 0
	const eatingTopPoints = 96
	const eatingPct = Math.round((eatingPoints / eatingTopPoints) * 100)
	const nutritionPoints = hydrationPoints + eatingPoints
	const nutritionPct = Math.round((nutritionPoints / 120) * 100)
	const sleepingScores = scores?.filter((s) => s.category === 'sleeping')
	const sleepingPoints = sleepingScores?.reduce((n, { points }) => n + +points, 0) || 0
	const sleepingTopPoints = sleepingScores?.length && +sleepingScores?.[0]?.topPoints
	const sleepingPct = sleepingTopPoints ? Math.round((sleepingPoints / sleepingTopPoints) * 100) : 0
	const activeScores = scores?.filter((s) => s.category === 'active')
	const activePoints = activeScores?.reduce((n, { points }) => n + +points, 0) || 0
	// const activeTopPoints = activeScores?.length && + activeScores?.[0]?.topPoints
	const activeTopPoints = 96
	const activePct = activeTopPoints ? Math.round((activePoints / activeTopPoints) * 100) : 0
	const fitnessPoints = sleepingPoints + activePoints
	const fitnessPct = Math.round((fitnessPoints / 120) * 100)
	const checkUpScores = scores?.filter((s) => s.category === 'check_up')
	const checkUpPoints = checkUpScores?.reduce((n, { points }) => n + +points, 0) || 0
	const checkUpTopPoints = checkUpScores?.length && +checkUpScores?.[0]?.topPoints
	const checkUpPct = checkUpTopPoints ? Math.round((checkUpPoints / checkUpTopPoints) * 100) : 0
	const mindfulnessScores = scores?.filter((s) =>
		['breathing', 'meditation', 'knowledge'].includes(s.category),
	)
	const mindfulnessPoints = mindfulnessScores?.reduce((n, { points }) => n + +points, 0) || 0
	const mindfulnessTopPoints = 96
	const mindfulnessPct = Math.round((mindfulnessPoints / mindfulnessTopPoints) * 100)
	const mindPoints = checkUpPoints + mindfulnessPoints
	const mindPct = mindPoints ? Math.round((mindPoints / 120) * 100) : 0
	const wellnessPoints = nutritionPoints + fitnessPoints + mindPoints
	const wellnessPct = wellnessPoints ? Math.round((wellnessPoints / 360) * 100) : 0
	const data = {
		wellnessPct,
		nutritionPct,
		hydrationPct,
		eatingPct,
		fitnessPct,
		sleepingPct,
		activePct,
		mindPct,
		checkUpPct,
		mindfulnessPct,
	}

	return (
		<Dashboard
			fromDeck={fromDeck}
			isDashboardOpen={isDashboardOpen}
			hideDashboard={toggleDash}
			navToProfileNutritionTargets={navToProfileNutritionTargets}
			navToProfileLifestyle={navToProfileLifestyle}
			navToProfileMoods={navToProfileMoods}
			areFieldsVisible={areFieldsVisible}
			toggleFields={() => dispatch(toggleFields())}
			isNutritionVisible={isNutritionVisible}
			toggleNutrition={() => dispatch(toggleNutrition())}
			isMindVisible={isMindVisible}
			toggleMind={() => dispatch(toggleMind())}
			isFitnessVisible={isFitnessVisible}
			toggleFitness={() => dispatch(toggleFitness())}
			data={data}
			t={t}
			isTablet={isTablet}
		/>
	)
}

export default DashboardContainer
