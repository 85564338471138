import styled from 'styled-components'

import { Layout } from 'antd'

import { miscStyles } from '../../../styles/misc'

const { Sider: AntSider } = Layout

const Sider = styled(AntSider)`
	// max-height: calc(100vh - 208px);

	// margin-top: 200px;
	// margin-right: ${(props) => (!props.collapsed ? '8px' : undefined)};
	// margin-bottom: 8px;
	// margin-left: ${(props) => (!props.collapsed ? '8px' : undefined)};

	position: fixed !important;
	top: calc(200px + env(safe-area-inset-top));
	right: calc(8px + env(safe-area-inset-right));
	bottom: calc(8px + env(safe-area-inset-bottom));

	z-index: 3;

	overflow: auto;

	border-radius: 24px;

	background: ${(props) => `${props.theme.glassBackgroundColor} !important`};
	@supports not (
		(-webkit-backdrop-filter: ${miscStyles.glassBackdropFilter}) or
			(backdrop-filter: ${miscStyles.glassBackdropFilter})
	) {
		background: ${(props) => `${props.theme.backgroundColor} !important`};
	}

	// Does not seem to be working...
	-webkit-backdrop-filter: ${(props) => props.theme.glassBackdropFilter};
	backdrop-filter: ${(props) => props.theme.glassBackdropFilter};

	box-shadow: ${(props) => props.theme.boxShadow};
`

export default Sider
