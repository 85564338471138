// Function component (presentational):

import React from 'react'

import {
	renderCalendarLabel,
	renderHotDrinkLabel,
	renderDrinkLabel,
	renderFoodLabel,
} from '../shared/other/renderLabel'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import FormWrapper from '../shared/styledComponents/form/FormWrapper'

import MealEventForm from './MealEventForm'

import { CloseOutlined } from '@ant-design/icons'
import { Typography, Divider } from 'antd'
import TextCircleButton from '../shared/styledComponents/button/TextCircleButton'

const { Text } = Typography

const NewMealEvent = ({
	isNewMealEventOpen,
	isQuickAction,
	isSimple,
	name,
	isHot,
	density,
	isLiquid,
	asVolume,
	measure,
	setMeasure,
	portions,
	isToday,
	initialValues,
	birthDate,
	selectDay,
	searchEventForMealKindId,
	createOrUpdateEvent,
	hideNewEvent,
	endSubmit,
	isSI,
	language,
	t,
}) => {
	const renderTitle = () =>
		renderCalendarLabel(
			t('event:mealEventForm.title'),
			<TextCircleButton icon={<CloseOutlined />} onClick={hideNewEvent} />,
		)

	const label = <Text type="secondary">{name}</Text>

	const renderBody = () => (
		<FormWrapper>
			{asVolume && isHot
				? renderHotDrinkLabel(label)
				: asVolume
					? renderDrinkLabel(label)
					: renderFoodLabel(label)}

			<Divider style={{ margin: '8px 0' }} />

			<MealEventForm
				initialValues={initialValues}
				isQuickAction={isQuickAction}
				isSimple={isSimple}
				density={density}
				isLiquid={isLiquid}
				asVolume={asVolume}
				measure={measure}
				setMeasure={setMeasure}
				portions={portions}
				isToday={isToday}
				birthDate={birthDate}
				selectDay={selectDay}
				searchEventForMealKindId={searchEventForMealKindId}
				createOrUpdateEvent={createOrUpdateEvent}
				endSubmit={endSubmit}
				isSI={isSI}
				language={language}
				t={t}
			/>
		</FormWrapper>
	)

	return (
		<DrawerOrModalContainer
			isOpen={isNewMealEventOpen}
			hide={hideNewEvent}
			renderTitle={renderTitle}
			renderBody={renderBody}
		/>
	)
}

export default NewMealEvent
