// Function component (container, using React, Redux, React Router, Auth0 & i18next hooks):

// React State & Effect hooks.
import React, { useState, useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import { signIn, signUp, signOut } from '../../utils/auth'
import { prefixPath } from '../../utils/l10n'

import { usersShow } from '../../ducks/users'
import { setAccountIds, toggleAccounts } from '../../ducks/account'
import { toggleDashboard } from '../../ducks/dashboard'

import { App as AntApp } from 'antd'

import NavUser from './NavUser'

const NavUserStatefulContainer = ({
	withNavUser,
	sleekNav,
	spreadNavUser,
	inOnboarding,
	navigate,
	isDashboardOpen,
	withSideDash,
	t,
	isTabletL,
}) => {
	const [areSettingsOpen, setSettingsOpen] = useState(false)
	const toggleSettings = () => setSettingsOpen((v) => !v)

	const dispatch = useDispatch()

	const showAccounts = () => {
		setSettingsOpen(false)
		dispatch(toggleAccounts())
	}

	const { currentAccountId, accountIds } = useSelector((state) => state.account)
	const withAccounts = accountIds.length > 1

	const {
		getAccessTokenSilently,
		user: { sub: auth0Uid } = {},
		loginWithRedirect,
		logout,
		isAuthenticated,
	} = useAuth0()

	const {
		i18n: { language },
	} = useTranslation()

	const { message } = AntApp.useApp()

	const shouldViewUser = withNavUser && isAuthenticated && accountIds.length === 0 && !inOnboarding
	useEffect(() => {
		if (!shouldViewUser) return

		const navToOnboarding = () =>
			navigate(prefixPath('/onboarding', language), { replace: true, state: { isFirst: true } })

		const viewUser = async () => {
			try {
				const accessToken = await getAccessTokenSilently()

				const {
					response: { account },
				} = await dispatch(usersShow(language, accessToken, auth0Uid))

				if (!account) return navToOnboarding()

				dispatch(setAccountIds(Object.keys(account)))
			} catch (e) {
				if (e.error === 'login_required' || e.error === 'consent_required')
					signIn(loginWithRedirect)
				message.error({ content: t('auth:login.message.error'), key: 'serverAuthError' })
				navigate(prefixPath('/', language), { replace: true })
				// throw e
			}
		}

		viewUser()
	}, [
		shouldViewUser,
		currentAccountId,
		getAccessTokenSilently,
		loginWithRedirect,
		language,
		dispatch,
		t,
		message,
		navigate,
		auth0Uid,
	])

	const handleSignup = () => {
		signUp(loginWithRedirect)
		areSettingsOpen && setSettingsOpen(false)
	}

	const handleLogin = () => {
		signIn(loginWithRedirect)
		areSettingsOpen && setSettingsOpen(false)
	}

	const handleLogout = () => {
		signOut(logout)
		areSettingsOpen && setSettingsOpen(false)
	}

	return (
		<NavUser
			withNavUser={withNavUser}
			sleekNav={sleekNav}
			spreadNavUser={spreadNavUser}
			inOnboarding={inOnboarding}
			areSettingsOpen={areSettingsOpen}
			toggleSettings={toggleSettings}
			showAccounts={showAccounts}
			isAuthenticated={isAuthenticated}
			withAccounts={withAccounts}
			handleSignup={handleSignup}
			handleLogin={handleLogin}
			handleLogout={handleLogout}
			isDashboardOpen={isDashboardOpen}
			withSideDash={withSideDash}
			toggleDashboard={() => dispatch(toggleDashboard())}
			t={t}
			isTabletL={isTabletL}
		/>
	)
}

export default NavUserStatefulContainer
