// Function component (presentational):

import React from 'react'

import { isIntentWithTitle } from '../../utils/libra'

import renderLabel from '../shared/other/renderLabel'

import LibraMenu from './LibraMenu'
import LibraActions from './LibraActions'
import LibraAvatar from './LibraAvatar'
import LibraBubble from './LibraBubble'
import LibraControls from './LibraControls'
import UserAvatar from './UserAvatar'
import UserControls from './UserControls'

import AccountsContainer from '../account/AccountsContainer'

import CalendarContainer from '../calendar/CalendarContainer'

import NewMeasurementContainer from '../measurement/NewMeasurementContainer'

import NewActivityEventContainer from '../activity/NewActivityEventStatefulContainer'
import NewMealEventContainer from '../meal/NewMealEventStatefulContainer'

import NewMoodContainer from '../mood/NewMoodContainer'

import NewChatContainer from '../assistance/NewChatContainer'

import { RedoOutlined, InfoCircleOutlined } from '@ant-design/icons'

import StyledLibra from './LibraStyles'

const Libra = ({
	areActionsOpen,
	toggleActions,
	checkToggleActions,
	withVoiceL10n,
	isPlaying,
	togglePlaying,
	isSpeaking,
	intentKey,
	intent,
	speech,
	iframeSrc,
	iframeOpts,
	imageSrc,
	isSpeechHidden,
	isAfterToday,
	withSideDash,
	speechCount,
	withVoice,
	isNewChatOpen,
	inAssistance,
	isConnected,
	isListening,
	isActuallyListening,
	withPrev,
	withNext,
	libraPrev,
	libraNext,
	libraStop,
	toggleVoice,
	renderAssistantAvatar,
	transcript,
	resetTranscript,
	handleQuickMealClick,
	handleQuickActivityClick,
	handleQuickWeightClick,
	handleQuickMoodClick,
	handleRepeat,
	handleListening,
	handleNewChatClick,
	dtBind,
	lpBind,
	t,
	// isLaptop,
}) => {
	const isOpen = isSpeaking || isListening

	const repeatTitle = () =>
		renderLabel({
			icon: <RedoOutlined />,
			label: t(`libra:repeat.title.${speechCount - 1}`),
		})

	const renderTitle = () => {
		if (isSpeaking && speechCount > 1 && speechCount <= 4) {
			return repeatTitle()
		} else if (isIntentWithTitle(intent)) {
			return renderLabel({
				icon: <InfoCircleOutlined />,
				label: t(`${intent}.title`),
			})
		}
	}

	return (
		<StyledLibra
		// id="libra"
		>
			<LibraMenu
				checkShowActions={checkToggleActions}
				renderAssistantAvatar={renderAssistantAvatar}
				inAssistance={inAssistance}
				isConnected={isConnected}
				dtBind={dtBind}
				lpBind={lpBind}
				t={t}
			/>
			<LibraActions
				areActionsOpen={areActionsOpen}
				hideActions={toggleActions}
				isAfterToday={isAfterToday}
				renderAssistantAvatar={renderAssistantAvatar}
				showQuickWeight={handleQuickWeightClick}
				showQuickMeal={handleQuickMealClick}
				showQuickActivity={handleQuickActivityClick}
				showQuickMood={handleQuickMoodClick}
				handleRepeat={handleRepeat}
				handleListening={handleListening}
				showNewChat={handleNewChatClick}
				isListening={isListening}
				inAssistance={inAssistance}
				t={t}
			/>
			{isSpeaking && (
				<LibraAvatar
					renderAssistantAvatar={renderAssistantAvatar}
					showNewChat={handleNewChatClick}
				/>
			)}
			{isSpeaking && (speech || iframeSrc || imageSrc) && (
				<LibraBubble
					isOpen={isOpen}
					hide={libraStop}
					renderTitle={renderTitle}
					phase="speaking"
					// mask={isSpeaking && (isActuallyListening || isNewChatOpen) ? false : true}
					mask={isSpeaking && isActuallyListening ? false : true}
					withSideDash={withSideDash}
					iframeSrc={iframeSrc}
					iframeOpts={iframeOpts}
					imageSrc={imageSrc}
					isSpeechHidden={isSpeechHidden}
					intent={intent}
					intentKey={intentKey}
				>
					{speech}
				</LibraBubble>
			)}
			{isSpeaking && (
				<LibraControls
					withVoiceL10n={withVoiceL10n}
					isPlaying={isPlaying}
					togglePlaying={togglePlaying}
					speech={speech}
					withVoice={withVoice}
					toggleVoice={toggleVoice}
					withPrev={withPrev}
					withNext={withNext}
					libraPrev={libraPrev}
					libraNext={libraNext}
				/>
			)}
			{(isActuallyListening || isNewChatOpen) && (
				<UserAvatar
					hide={isActuallyListening ? handleListening : handleNewChatClick}
					withSideDash={withSideDash}
				/>
			)}
			{isActuallyListening && (
				<LibraBubble
					isOpen={isOpen}
					hide={libraStop} // Seems to be called on Mobile...
					phase="listening"
					isSpeaking={isSpeaking}
					withSideDash={withSideDash}
					t={t}
				>
					{transcript}
				</LibraBubble>
			)}
			{(isActuallyListening || isNewChatOpen) && (
				<UserControls
					transcript={transcript}
					resetTranscript={resetTranscript}
					isListening={isListening}
					handleListening={handleListening}
					showNewChat={handleNewChatClick}
					withSideDash={withSideDash}
					t={t}
				/>
			)}

			<AccountsContainer />

			<CalendarContainer />

			<NewMeasurementContainer />

			<NewMealEventContainer />
			<NewActivityEventContainer />

			<NewMoodContainer />

			<NewChatContainer />
		</StyledLibra>
	)
}

export default Libra
