import styled from 'styled-components'

import { Radio } from 'antd'

import { buttonBorderRadius } from '../../../../styles/sizes'

import RadioButton from './RadioButton'

const { Group } = Radio

const RadioGroup = styled(Group)`
	width: 100%;

	display: flex;
	flex-direction: ${(props) => (props.direction === 'vertical' ? 'column' : undefined)};

	> ${RadioButton} {
		:first-child {
			margin-right: ${(props) => (props.direction === 'vertical' ? '16px' : undefined)};
			margin-bottom: ${(props) => (props.direction === 'vertical' ? '4px' : undefined)};

			padding-right: ${(props) =>
				props.direction === 'vertical' && props.boxicons ? '16px' : undefined};
			padding-left: ${(props) =>
				props.direction === 'vertical' && !props.boxicons ? '16px' : undefined};

			border-radius: ${buttonBorderRadius} 0 0 ${buttonBorderRadius};
		}

		:not(:first-child):not(:last-child) {
			margin-top: ${(props) => (props.direction === 'vertical' ? '4px' : undefined)};
			margin-right: ${(props) => (props.direction === 'vertical' ? '16px' : undefined)};
			margin-bottom: ${(props) => (props.direction === 'vertical' ? '4px' : undefined)};
			margin-left: ${(props) => (props.direction === 'vertical' ? '16px' : undefined)};
		}

		:last-child {
			margin-top: ${(props) => (props.direction === 'vertical' ? '4px' : undefined)};
			margin-left: ${(props) => (props.direction === 'vertical' ? '16px' : undefined)};

			padding-right: ${(props) =>
				props.direction === 'vertical' && !props.boxicons ? '16px' : undefined};
			padding-left: ${(props) =>
				props.direction === 'vertical' && props.boxicons ? '16px' : undefined};

			border-radius: 0 ${buttonBorderRadius} ${buttonBorderRadius} 0;
		}
	}
`

export default RadioGroup
