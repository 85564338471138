// Function component (presentational):

import React from 'react'

import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'

import referralFormSchema from '../../utils/yupSchema/referralFormSchema'

import FormItem from '../shared/styledComponents/form/FormItem'

import { renderKeyLabel } from '../shared/other/renderLabel'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'

import { RightOutlined } from '@ant-design/icons'

const ReferralForm = ({ initialValues, setSearchParams, t }) => {
	const handleSubmit = ({ key: subscriberKey }) => setSearchParams({ subscriberKey })

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={referralFormSchema(t)}
			onSubmit={handleSubmit}
		>
			<Form
				// id="referral-form"
				layout="vertical"
				colon={false}
			>
				<FormItem name="key" label={renderKeyLabel(t('subscribers:attributes.key.label'))}>
					<Input name="key" placeholder={t('subscribers:attributes.key.placeholder')} />
				</FormItem>

				<SubmitWrapper $step="first">
					<SubmitButton
						// htmlType="submit"
						shape="circle"
						icon={<RightOutlined />}
					/>
				</SubmitWrapper>
			</Form>
		</Formik>
	)
}

export default ReferralForm
