// Function component (container, using React, i18next & React Responsive hooks):

// React State & Effect hooks.
import React, { useState, useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector } from 'react-redux'
// React Router hook for routing.
import { useSearchParams, useNavigate } from 'react-router-dom'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
// import { useMediaQuery } from 'react-responsive'

import { Html5Qrcode } from 'html5-qrcode'

import { prefixPath } from '../../utils/l10n'
import { gaEvents } from '../../utils/gaEvents'

// import deviceMinWidth from '../../styles/deviceMinWidth'

import Scanner from './Scanner'

import { App as AntApp } from 'antd'

const ScannerStatefulContainer = () => {
	let [searchParams, setSearchParams] = useSearchParams()

	const fallbackSection = 'history'
	const paramsSection = searchParams.get('section')
	const initialSection = paramsSection ?? fallbackSection

	const [section, setSection] = useState(initialSection)
	const handleSectionChange = (key) => setSection(key)

	const shouldSetSearchParams = section !== initialSection
	useEffect(() => {
		shouldSetSearchParams && setSearchParams({ section })
	}, [shouldSetSearchParams, section, setSearchParams])

	const [cameras, setCameras] = useState([])

	const { message } = AntApp.useApp()

	let navigate = useNavigate()

	const {
		i18n: { language },
		t,
	} = useTranslation(['scanner'])

	useEffect(() => {
		if (!cameras.length) {
			Html5Qrcode.getCameras()
				.then((devices) => {
					if (devices && devices.length) {
						setCameras(devices)
					}
				})
				.catch((_e) => {
					message.warning({
						content: t('scanner:camera.message.error'),
						key: 'cameraError',
					})

					navigate(prefixPath(`/library?scope=foodItem`, language))
				})
		}
	}, [cameras, message, navigate, language, t])

	useEffect(() => {
		if (cameras.length) {
			let html5QrCode = new Html5Qrcode('reader')

			// const config = { fps: 20, qrbox: { width: 256, height: 256 } }
			const config = { fps: 20 }

			const onScanSuccess = (decodedText, decodedResult) => {
				const {
					result: {
						format: { formatName },
					},
				} = decodedResult

				gaEvents.scan(decodedText, formatName)

				const acceptedCodes = ['EAN_13', 'EAN_8', 'UPC_A', 'UPC_E']
				if (!acceptedCodes.includes(formatName)) {
					message.warning({
						content: t('scanner:gtin.message.warning'),
						key: 'gtinWarning',
					})
					return
				}

				navigate(prefixPath(`/library/food-item/${decodedText}`, language))
			}

			// const camera = isLaptop ? cameras[0].id : { facingMode: 'environment' }
			html5QrCode.start({ facingMode: 'environment' }, config, onScanSuccess)

			return () => html5QrCode.stop()
		}
	}, [cameras, message, language, navigate, t])

	// const isLaptop = useMediaQuery({
	// 	// query: '(min-width: 1024px)'
	// 	query: `${deviceMinWidth.laptop}`,
	// })

	const { isDarkTheme } = useSelector((state) => state.appearance)

	return (
		<Scanner
			section={section}
			handleSectionChange={handleSectionChange}
			isDarkTheme={isDarkTheme}
			t={t}
		/>
	)
}

export default ScannerStatefulContainer
