// Function component (presentational):

import React from 'react'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import FormWrapper from '../shared/styledComponents/form/FormWrapper'

import renderLabel from '../shared/other/renderLabel'

import MeasurementForm from './MeasurementForm'
import ObjectiveForm from '../objective/ObjectiveForm'

import { ExpandAltOutlined, AimOutlined } from '@ant-design/icons'

const FirstMeasurement = ({
	birthDate,
	step,
	formData,
	endSubmit,
	createBodyMeasurement,
	createObjective,
	libraStart,
	isSI,
	language,
	t,
}) => {
	const renderTitle = () => {
		switch (step) {
			case 1:
				return renderLabel({
					icon: <ExpandAltOutlined />,
					label: t('measurement:form.initialTitle'),
				})
			case 2:
				return renderLabel({ icon: <AimOutlined />, label: t('objective:form.title') })
			default:
				return ''
		}
	}

	const renderForm = () => {
		switch (step) {
			case 1:
				return (
					<MeasurementForm
						initialValues={formData}
						isFirst
						isToday
						birthDate={birthDate}
						endSubmit={endSubmit}
						createBodyMeasurement={createBodyMeasurement}
						isSI={isSI}
						language={language}
						t={t}
					/>
				)
			case 2:
				return (
					<ObjectiveForm
						initialValues={formData}
						isFirst
						birthDate={birthDate}
						endSubmit={endSubmit}
						createObjective={createObjective}
						libraStart={libraStart}
						isSI={isSI}
						language={language}
						t={t}
					/>
				)
			default:
				return null
		}
	}

	const renderBody = () => <FormWrapper>{renderForm()}</FormWrapper>

	return (
		<DrawerOrModalContainer
			isOpen={true}
			canClose={false}
			renderTitle={renderTitle}
			renderBody={renderBody}
			// onlyModal
		/>
	)
}

export default FirstMeasurement
