// Function component (presentational):

import React from 'react'

// import PageTitle from '../shared/styledComponents/page/PageTitle'

import FirstOnboarding from './FirstOnboarding'
import NewOnboarding from './NewOnboarding'
import NewAccountContainer from '../account/NewAccountStatefulContainer'
import OnboardingCheckpoint1 from './OnboardingCheckpoint1'
import FirstMeasurementContainer from '../measurement/FirstMeasurementStatefulContainer'
import OnboardingCheckpoint2 from './OnboardingCheckpoint2'
import NewRoutineContainer from '../lifestyle/NewRoutineStatefulContainer'
import OnboardingCheckpoint3 from './OnboardingCheckpoint3'
import DailyWaterContainer from '../meal/DailyWaterContainer'
import NewMoodContainer from '../mood/NewMoodContainer'
import OnboardingCompletion from './OnboardingCompletion'

import L10nNavigate from '../shared/navigation/L10nNavigate'

const Onboarding = ({
	isFirst,
	step,
	nextStep,
	firstName,
	libraStart,
	t,
	// isLaptop,
}) => {
	switch (step) {
		case 1:
			return isFirst ? (
				<FirstOnboarding nextStep={nextStep} firstName={firstName} libraStart={libraStart} t={t} />
			) : (
				<NewOnboarding nextStep={nextStep} libraStart={libraStart} t={t} />
			)
		case 2:
			return <NewAccountContainer isFirst={isFirst} close={nextStep} />
		case 3:
			return <OnboardingCheckpoint1 nextStep={nextStep} t={t} />
		case 4:
			return <FirstMeasurementContainer close={nextStep} />
		case 5:
			return <OnboardingCheckpoint2 nextStep={nextStep} t={t} />
		case 6:
			return <NewRoutineContainer isOnboarding close={nextStep} />
		case 7:
			return <OnboardingCheckpoint3 nextStep={nextStep} t={t} />
		case 8:
			return <DailyWaterContainer close={nextStep} />
		case 9:
			return <NewMoodContainer close={nextStep} />
		case 10:
			return <OnboardingCompletion nextStep={nextStep} t={t} />
		default:
			return <L10nNavigate to="/" />
	}
}

export default Onboarding
