// Function component (presentational):

import React from 'react'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'

import InputNumber from '../shared/styledComponents/form/InputNumber'

import NutritionFactsCollapse from './NutritionFactsCollapse'

// import { CitationContainer } from './NutritionFactsStyles'

import { ControlOutlined, CalculatorOutlined, FunnelPlotOutlined } from '@ant-design/icons'
import {
	Select,
	Segmented,
	// Typography
} from 'antd'

// const { Text } = Typography

const NutritionFacts = ({
	measure,
	isAmount,
	isCupOrPortion,
	handleMeasureChange,
	density,
	isLiquid,
	portions,
	mass,
	handleMassChange,
	massInOz,
	handleMassInOzChange,
	volume,
	handleVolumeChange,
	volumeInFlOz,
	handleVolumeInFlOzChange,
	serving,
	handleServingChange,
	isReduced,
	toggleDisplay,
	dataSource,
	// referenceCitation,
	isSI,
	t,
}) => {
	const asVolume = density || isLiquid
	const proportionUnit = asVolume ? 'ml' : 'g'
	const amountUnit = asVolume && !isSI ? 'fl. oz.' : asVolume ? 'ml' : !isSI ? 'oz' : 'g'

	const measureOptions = [
		{
			value: 'proportion',
			label: t('nutritionFacts:settings.measure.proportion', { unit: proportionUnit }),
		},
		{
			value: 'amount',
			label: t('nutritionFacts:settings.measure.amount', { unit: amountUnit }),
		},
	]

	const anyPortions = portions?.length

	const buildPortionOption = ({ id, isPackSize, name: portionName }) => ({
		value: `portion-${id}`,
		label: t(`nutritionFacts:settings.measure.${isPackSize ? 'pack' : 'serving'}`, { portionName }),
	})
	const buildPortionOptions = () => anyPortions && portions.map((fp) => buildPortionOption(fp))
	anyPortions && measureOptions.push(...buildPortionOptions())

	const cupUnit = !isSI ? '8 fl. oz.' : '240 ml'
	const buildCupOption = () => ({
		value: `cup`,
		label: t('nutritionFacts:settings.measure.cup', { unit: cupUnit }),
	})
	!anyPortions && isLiquid && measureOptions.push(buildCupOption())

	return (
		<>
			<Card
				title={renderLabel({
					icon: <ControlOutlined />,
					label: t('nutritionFacts:settings.title'),
				})}
				style={{ marginRight: 'calc((100% - 368px) / 2)', marginLeft: 'calc((100% - 368px) / 2)' }}
			>
				<div style={{ paddingBottom: 8 }}>
					{renderLabel({
						icon: <CalculatorOutlined />,
						label: t('nutritionFacts:settings.measure.label'),
					})}
				</div>
				<Select
					options={measureOptions}
					value={measure}
					onChange={handleMeasureChange}
					variant="filled"
					style={{ width: '100%', marginBottom: 8 }}
				/>

				{isAmount && !density && isSI && (
					<InputNumber
						placeholder={t('dishes:attributes.mass.placeholder')}
						value={mass}
						min={0}
						step={10}
						// suffix="g"
						suffix={isLiquid ? 'ml' : 'g'}
						// changeOnWheel
						onChange={handleMassChange}
						style={{ marginBottom: 8 }}
					/>
				)}
				{isAmount && !density && !isSI && (
					<InputNumber
						placeholder={t('dishes:attributes.massInOz.placeholder')}
						value={massInOz}
						min={0}
						step={0.25}
						// suffix="oz"
						suffix={isLiquid ? 'fl. oz.' : 'oz'}
						// changeOnWheel
						onChange={handleMassInOzChange}
						style={{ marginBottom: 8 }}
					/>
				)}

				{isAmount && density && isSI && (
					<InputNumber
						placeholder={t('dishes:attributes.volume.placeholder')}
						value={volume}
						min={0}
						step={10}
						suffix="ml"
						// changeOnWheel
						onChange={handleVolumeChange}
						style={{ marginBottom: 8 }}
					/>
				)}
				{isAmount && density && !isSI && (
					<InputNumber
						placeholder={t('dishes:attributes.volumeInFlOz.placeholder')}
						value={volumeInFlOz}
						min={0}
						step={0.25}
						suffix="fl. oz."
						// changeOnWheel
						onChange={handleVolumeInFlOzChange}
						style={{ marginBottom: 8 }}
					/>
				)}

				{isCupOrPortion && (
					<InputNumber
						placeholder={t('dishes:attributes.serving.placeholder')}
						value={serving}
						min={0}
						step={0.25}
						// changeOnWheel
						onChange={handleServingChange}
						style={{ marginBottom: 8 }}
					/>
				)}

				<div style={{ width: '100%', marginTop: 8 }}>
					{renderLabel({
						icon: <FunnelPlotOutlined />,
						label: t('nutritionFacts:settings.display.label'),
						extra: (
							<Segmented
								options={[
									{ value: true, label: t('nutritionFacts:settings.display.reduced') },
									{ value: false, label: t('nutritionFacts:settings.display.full') },
								]}
								value={isReduced}
								size="small"
								onChange={toggleDisplay}
								style={{ marginRight: '5px' }}
							/>
						),
					})}
				</div>

				{/* {referenceCitation && (
					<CitationContainer>
						<Text type="secondary" italic>
							{referenceCitation}
						</Text>
					</CitationContainer>
				)} */}
			</Card>

			<NutritionFactsCollapse measure={measure} dataSource={dataSource} isSI={isSI} t={t} />
		</>
	)
}

export default NutritionFacts
