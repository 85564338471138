// Function component (presentational):

import React from 'react'

import dayjs from 'dayjs'

import { convertKgToLb } from '../../utils/numbers'

import { objectiveKinds } from '../../utils/yupSchema/objectivesSchema'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'

import NewObjectiveContainer from './NewObjectiveContainer'

import { AimOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Skeleton, Descriptions, Progress, Typography } from 'antd'
import BlockRoundButton from '../shared/styledComponents/button/BlockRoundButton'

const { Text } = Typography

const Objective = ({
	latestBmValue,
	objective,
	currentDate,
	isNewObjectiveOpen,
	toggleNewObjective,
	disabledObjective,
	personId,
	birthDate,
	isSI,
	t,
}) => {
	const objectiveKind = objective?.objectiveKind
	const objectiveStartDay = objective?.startDate
	const objectiveStartDate = objectiveStartDay && dayjs(objectiveStartDay)
	const objectiveItems = [
		{
			key: 'objectiveKind',
			label: t('objectives:attributes.objectiveKind.label'),
			children: objectiveKind && t(`objectives:attributes.objectiveKinds.${objectiveKind}.label`),
			span: 2,
		},
	]

	const initialBmValue = +objective?.initialBodyMeasurement?.bodyMass?.value
	const initialBmValueInLb = convertKgToLb(initialBmValue)

	const desiredBmValue = +objective?.bodyMeasurement?.bodyMass?.value
	const desiredBmValueInLb = convertKgToLb(desiredBmValue)

	const wasMaintain = objectiveKind === objectiveKinds[0]
	const wasLose = objectiveKind === objectiveKinds[1]
	const wasGain = objectiveKind === objectiveKinds[2]

	const bmValueInLb = wasMaintain ? initialBmValueInLb : desiredBmValueInLb
	const bmValue = wasMaintain ? initialBmValue : desiredBmValue
	const bmValueLabel = !isSI ? `${bmValueInLb.toFixed()} lb` : `${+bmValue.toFixed()} kg`

	const latestBmValueDiff = initialBmValue - latestBmValue
	const bmValueDiff =
		(wasLose && latestBmValueDiff < 0) || (wasGain && latestBmValueDiff > 0)
			? 0
			: Math.abs(latestBmValueDiff)
	const bmValueChange = wasMaintain
		? initialBmValue * 0.1
		: Math.abs(initialBmValue - desiredBmValue)
	const bmPercent = (bmValueDiff / bmValueChange) * 100

	const bodyMassChild = (
		<span
			style={{
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				gap: 8,
			}}
		>
			<Progress
				percent={Math.abs(bmPercent)}
				// size="small"
				showInfo={false}
				style={{
					// maxWidth: 'calc(368px / 2 - 16px)',
					marginBottom: 0,
					marginRight: 0,
				}}
			/>
			<span style={{ flexShrink: 0 }}>{bmValueLabel}</span>
		</span>
	)

	const bodyMassItem = {
		key: 'bodyMass',
		label: t('bodyMasses:attributes.desiredValue.label'),
		children: bodyMassChild,
		span: 2,
	}
	if (wasMaintain || desiredBmValue) {
		objectiveItems.push(bodyMassItem)
	}

	const startDateItem = {
		key: 'startDate',
		label: t('objectives:attributes.startDate.label'),
		children: objectiveStartDate?.format('LL'),
		span: 2,
	}

	const effortItem = {
		key: 'effort',
		label: t('objectives:attributes.effort.label'),
		children: objective?.effortLabel,
		span: 2,
	}

	objectiveItems.push(startDateItem, effortItem)

	const objectiveEndDay = objective?.endDate
	const objectiveEndDate = objectiveEndDay && dayjs(objectiveEndDay)
	const endDateItem = {
		key: 'endDate',
		label: t('objectives:attributes.endDate.label'),
		children: <Text mark>{objectiveEndDate?.format('LL')}</Text>,
		span: 2,
	}
	objectiveEndDay && objectiveItems.splice(-1, 0, endDateItem)

	return (
		<>
			<Card
				title={renderLabel({
					icon: <AimOutlined />,
					label: t('objective:achievement.title'),
					extra: !objectiveKind ? <LoadingOutlined /> : null,
				})}
				style={{ height: '100%' }}
			>
				<Skeleton title={false} loading={!objectiveKind} paragraph active></Skeleton>

				{objectiveKind && <Descriptions column={2} items={objectiveItems} />}

				<BlockRoundButton
					type={objectiveEndDay && objectiveEndDate <= currentDate ? 'primary' : 'default'}
					onClick={toggleNewObjective}
					disabled={!objectiveKind || disabledObjective}
					style={{ marginTop: objectiveKind ? 8 : undefined }}
				>
					{renderLabel({
						icon: <AimOutlined />,
						label: t('objective:actions.addObjective'),
						extra: <PlusOutlined />,
					})}
				</BlockRoundButton>
			</Card>

			<NewObjectiveContainer
				latestBmValue={latestBmValue}
				objective={objective}
				isNewObjectiveOpen={isNewObjectiveOpen}
				hideNewObjective={toggleNewObjective}
				personId={personId}
				birthDate={birthDate}
			/>
		</>
	)
}

export default Objective
