// Function component (presentational):

import React from 'react'

import { Formik } from 'formik'
import { Form, DatePicker, TimePicker, SubmitButton } from 'formik-antd'

import dayjs from 'dayjs'
import _ from 'lodash'

import activityEventFormSchema from '../../utils/yupSchema/activityEventFormSchema'
import { disabledDate } from '../../utils/yupSchema/logsSchema'
import { maxAge } from '../../utils/yupSchema/peopleSchema'
import {
	disabledHours,
	disabledMinutes,
	disabledSeconds,
} from '../../utils/yupSchema/activitiesSchema'

import { midnight, dateFormat } from '../../utils/dates'
import { transformInvalidAttrErrors } from '../../utils/api'

import FormItem from '../shared/styledComponents/form/FormItem'
import { datePickerStyle } from '../shared/styledComponents/form/DatePicker'
import { timePickerStyle } from '../shared/styledComponents/form/TimePicker'

import renderLabel, { renderDateLabel } from '../shared/other/renderLabel'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'

import { FieldTimeOutlined, RightOutlined } from '@ant-design/icons'
import { App as AntApp } from 'antd'

const ActivityEventForm = ({
	initialValues,
	birthDate,
	maxDuration,
	isQuickAction,
	isToday,
	selectDay,
	createEvent,
	endSubmit,
	t,
}) => {
	const { message } = AntApp.useApp()

	const handleSubmit = async (values, { setErrors }) => {
		const {
			schedulable: { duration: time },
		} = values

		const duration = dayjs(time).diff(midnight, 's')

		const attrs = _.merge({}, values, { schedulable: { duration } })

		try {
			const { response } = await createEvent(attrs)

			if (response) {
				message.success(t('event:newActivityEventForm.message.success'))

				endSubmit()
			}
		} catch (e) {
			const errors = transformInvalidAttrErrors(e.errors)

			// Async (server-side) validation.
			setErrors(errors)
		}
	}

	const isSleek = isQuickAction && isToday

	const maxHour = maxDuration?.hours()
	const maxMinute = maxDuration?.minutes()
	const maxSecond = maxDuration?.seconds()

	const disabledTime = () => ({
		disabledHours: () => disabledHours(maxHour),
		disabledMinutes: (selectedHour) => disabledMinutes(selectedHour, maxHour, maxMinute),
		disabledSeconds: (selectedHour, selectedMinute) =>
			disabledSeconds(selectedHour, selectedMinute, maxHour, maxMinute, maxSecond),
	})

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={activityEventFormSchema(t, birthDate, maxDuration)}
			onSubmit={handleSubmit}
		>
			<Form
				// id="activity-event-form"
				layout="vertical"
				colon={false}
			>
				<FormItem
					name="startDate"
					label={renderDateLabel(t('events:attributes.startDate.label'))}
					hidden={isSleek}
				>
					<DatePicker
						name="startDate"
						format="l"
						disabledDate={(current) => disabledDate(current, birthDate)}
						minDate={birthDate}
						maxDate={birthDate && birthDate.add(maxAge, 'y')}
						onChange={(d) => d && selectDay(d.format(dateFormat))}
						variant="filled"
						style={datePickerStyle}
					/>
				</FormItem>

				<FormItem
					name="schedulable.duration"
					label={
						!isSleek &&
						renderLabel({
							icon: <FieldTimeOutlined />,
							label: t('activities:attributes.duration.label'),
						})
					}
				>
					<TimePicker
						name="schedulable.duration"
						placeholder={t('activities:attributes.duration.placeholder')}
						secondStep={15}
						changeOnScroll
						disabledTime={disabledTime}
						showNow={false}
						needConfirm={false}
						style={timePickerStyle}
					/>
				</FormItem>

				<SubmitWrapper $step="first">
					<SubmitButton
						// htmlType="submit"
						shape="circle"
						icon={<RightOutlined />}
					/>
				</SubmitWrapper>
			</Form>
		</Formik>
	)
}

export default ActivityEventForm
