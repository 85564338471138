import styled from 'styled-components'

const PageAction = styled.div`
	height: 176px;
	width: 100%;
	max-width: 384px;

	margin-top: -176px;
	margin-right: auto;
	margin-left: auto;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

export default PageAction
