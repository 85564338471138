// Function component (presentational):

// React Effect hook.
import React, { useEffect } from 'react'
// React Router hook for routing.
import { useParams } from 'react-router-dom'

import LoadingView from '../../loading/LoadingView'

const Redirect = ({ hostUrl, path, withLocale = false }) => {
	let { locale } = useParams()

	let url = hostUrl
	if (withLocale) {
		url += `/${locale}/`
	}
	url += path

	useEffect(() => {
		window.location.replace(url)
	}, [url])

	return <LoadingView />
}

export default Redirect
