import styled from 'styled-components'

import { Checkbox } from 'formik-antd'

const { Group } = Checkbox

const DotwCheckboxGroup = styled(Group)`
	// max-width: 352px;

	// display: flex;
	// justify-content: space-between;
	// column-gap: unset;

	// margin: 0 auto;

	// padding: 0 2.12px;

	// > .ant-checkbox-group-item {
	// 	display: flex;
	// 	flex-direction: column;
	// 	align-items: center;
	// }
`

export default DotwCheckboxGroup

export const dotwCheckboxGroupStyle = {
	maxWidth: 352,
	display: 'flex',
	justifyContent: 'space-between',
	columnGap: 'unset',
	margin: '0 auto',
	padding: '0 2.12px',
}
