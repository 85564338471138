// Function component (container, using React, Redux, React Router, i18next & React Responsive hooks):

// React Effect hook.
import React, { useEffect } from 'react'
// Redux hook to dispatch actions.
import { useDispatch } from 'react-redux'
// React Router hooks for routing.
import { useParams, useNavigate } from 'react-router-dom'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
// import { useMediaQuery } from 'react-responsive'

import { prefixPath } from '../../utils/l10n'

import { subscribersConfirm } from '../../ducks/subscribers'

// import deviceMinWidth from '../../styles/deviceMinWidth'

import PageWrapper from '../shared/styledComponents/page/PageWrapper'
import PageTitle from '../shared/styledComponents/page/PageTitle'
import SectionWrapper from '../shared/styledComponents/page/SectionWrapper'
import ContentWrapper from '../shared/styledComponents/page/ContentWrapper'
// import Illustration from '../shared/styledComponents/Illustration'

import Title from '../shared/styledComponents/text/Title'

import Card from '../shared/styledComponents/card/Card'

import access from '../../assets/illustrations/shared/access-key.png'

import { App as AntApp } from 'antd'

const SubscriptionConfirmationContainer = () => {
	let { key } = useParams()

	const {
		i18n: { language },
		t,
	} = useTranslation('subscriptionConfirmation')

	const dispatch = useDispatch()

	const { message } = AntApp.useApp()

	const navigate = useNavigate()

	useEffect(() => {
		const navToReferral = (subscriberKey) =>
			navigate(prefixPath(`/referral?subscriberKey=${subscriberKey}`, language))

		const navToSubscription = ({ content, key = 'idError' }) => {
			message.error({ content, key })

			navigate(prefixPath('/subscription', language), { replace: true })
		}

		const confirmSubscriber = async (subscriberKey) => {
			try {
				const response = await dispatch(subscribersConfirm(language, subscriberKey))

				if (response) {
					message.success(t('subscriptionConfirmation:page.message.success'))

					navToReferral(subscriberKey)
				}
			} catch (e) {
				const status = Object.values(e.errors)[0].status
				const content = Object.values(e.errors)[0].detail
				if (status === '404') {
					navToSubscription({ content })
				}
				// throw e
			}
		}

		confirmSubscriber(key)
	}, [language, dispatch, t, message, navigate, key])

	// const isLaptop = useMediaQuery({
	// 	// query: '(min-width: 1024px)'
	// 	query: `${deviceMinWidth.laptop}`,
	// })

	return (
		<PageWrapper>
			<PageTitle>
				<Title>{t('subscriptionConfirmation:page.title')}</Title>
			</PageTitle>

			<SectionWrapper
			// id="subscription-confirmation-content-wrapper"
			// $type="illustration"
			>
				<ContentWrapper style={{ alignItems: 'center' }}>
					<Card>
						<img
							src={access}
							alt={t('subscriptionConfirmation:page.illustration')}
							width="100%"
							height="100%"
						/>
					</Card>
				</ContentWrapper>
			</SectionWrapper>
		</PageWrapper>
	)
}

export default SubscriptionConfirmationContainer
