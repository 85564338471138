import * as yup from 'yup'

import _ from 'lodash'

import { joinWithQuotesAndAmpersand } from '../misc'
import { setDateFromTime } from '../dates'

import { lifestyles } from './physicalActivityLevelsSchema'

const lifestyleValues = lifestyles.slice(1, 4)

export const ynValues = ['no', 'yes']
const dotwValues = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
// const dotwLabelsMin = dayjs.weekdaysMin()
// export const ratingValues = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
export const ratingValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const dotwOptions = (t, enabledValues = dotwValues) => {
	const options = []
	dotwValues.map((v, _index) =>
		options.push({
			label: t(`replies:attributes.dotwValues.${v}.label`),
			// label: dotwLabelsMin[index],
			value: v,
			disabled: !enabledValues.includes(v),
		}),
	)
	return options
}

const minTime = setDateFromTime('00:10')
// const minHour = minTime.hour() // 0 (included)..
const minMinute = minTime.minute() // 10 (included).

const maxTime = setDateFromTime('16:00')
// const maxHour = maxTime.hour() // 16 (included).
// const maxMinute = maxTime.minute() // 0 (included).

const disabledHours = (maxH) => _.range(maxH + 1, 24)
const disabledMinutes = (selectedHour, maxH, maxMin) => {
	const maxMins = _.range(maxMin + 1, 60)

	if (selectedHour === 0) {
		const d = _.range(0, minMinute - 1)

		maxH === 0 && d.push(...maxMins) // Could deduplicate but doesn’t seem to be needed...

		return d
	} else if (selectedHour < maxH) {
		return []
	} else if (selectedHour === maxH) {
		return maxMins
	} else {
		return _.range(0, 60)
	}
}
export const disabledTime = (maxT = maxTime) => {
	const maxH = maxT.hour()
	const maxMin = maxT.minute()

	return {
		disabledHours: () => disabledHours(maxH),
		disabledMinutes: (selectedHour) => disabledMinutes(selectedHour, maxH, maxMin),
	}
}

export const tYnValueLabel = (t) => t('replies:attributes.ynValue.label')
const tYnValuesLabels = (t) => ynValues.map((v) => t(`replies:attributes.ynValues.${v}.label`))
export const tYnValuesLabelsList = (t) => joinWithQuotesAndAmpersand(tYnValuesLabels(t))

const valueValidation = (t) => {
	// const label = t('replies:attributes.value.label')
	const lifestyleLabel = t('physicalActivityLevels:attributes.lifestyle.label')
	const ynValueLabel = tYnValueLabel(t)
	const dotwValueLabel = t('replies:attributes.dotwValue.label')
	const ddValueLabel = t('replies:attributes.ddValue.label')
	const ratingValueLabel = t('replies:attributes.ratingValue.label')

	const lifestylesLabels = lifestyleValues.map((v) =>
		t(`physicalActivityLevels:attributes.lifestyles.${v}.label`),
	)
	const lifestylesLabelsList = joinWithQuotesAndAmpersand(lifestylesLabels)
	const dotwValuesLabels = dotwValues.map((v) => t(`replies:attributes.dotwValues.${v}.label`))
	const dotwValuesLabelsList = joinWithQuotesAndAmpersand(dotwValuesLabels)
	const ynValuesLabelsList = tYnValuesLabelsList(t)
	// const ratingValuesLabels = ratingValues.map((v) =>
	// 	t(`replies:attributes.ratingValues.${v}.label`)
	// )
	const ratingValuesLabelsList = joinWithQuotesAndAmpersand(ratingValues)

	return yup
		.mixed()
		.when('kind', {
			is: 'lifestyle',
			then: (schema) =>
				schema
					.oneOf(
						lifestyleValues,
						t('validation:mixed.oneOf', {
							label: lifestyleLabel,
							values: lifestylesLabelsList,
						}),
					)
					.required(t('validation:mixed.required', { label: lifestyleLabel })),
		})
		.when('kind', {
			is: 'yesNo',
			then: (schema) =>
				schema
					.oneOf(
						ynValues,
						t('validation:mixed.oneOf', { label: ynValueLabel, values: ynValuesLabelsList }),
					)
					.required(t('validation:mixed.required', { label: ynValueLabel })),
		})
		.when('kind', {
			is: 'daysOfTheWeek',
			then: () =>
				yup.array().of(
					yup.mixed().oneOf(
						dotwValues,
						t('validation:mixed.oneOf', {
							label: dotwValueLabel,
							values: dotwValuesLabelsList,
						}),
					),
				),
		})
		.when('kind', {
			is: 'dailyDuration',
			then: () =>
				yup
					.date()
					.min(minTime, t('replies:attributes.value.validation.min', { minutes: 10 }))
					.max(maxTime, t('replies:attributes.value.validation.max', { hours: 16 }))
					.nullable()
					.when('parentQuestionId', {
						is: '',
						then: (schema) =>
							schema.required(t('validation:mixed.required', { label: ddValueLabel })),
					}),
		})
		.when('kind', {
			is: 'rating',
			then: (schema) =>
				schema
					.oneOf(
						ratingValues,
						t('validation:mixed.oneOf', {
							label: ratingValueLabel,
							values: ratingValuesLabelsList,
						}),
					)
					.required(t('validation:mixed.required', { label: ratingValueLabel })),
		})
}

const repliesSchema = (t) =>
	yup.object().shape({
		value: valueValidation(t),
	})

export default repliesSchema
