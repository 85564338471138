import styled from 'styled-components'

import { mobileMLCenterNavWidth, mobileMLNavMenuWidth, cardBorderRadius } from '../../styles/sizes'
import deviceMinWidth from '../../styles/deviceMinWidth'

import StyledNavApp from './NavAppStyles'
import StyledNavUser from './NavUserStyles'
import StyledNavDate from './NavDateStyles'

import NavMenu from '../shared/styledComponents/menu/NavMenu'

import { Layout } from 'antd'

const { Header } = Layout

const StyledNav = styled(Header)`
	// height: auto;
	height: 64px;

	position: sticky;
	top: env(safe-area-inset-top);
	z-index: 3;

	display: flex;

	padding: unset;

	background: transparent;

	@media ${deviceMinWidth.mobileML} {
		${StyledNavDate} {
			width: ${mobileMLCenterNavWidth};

			flex-grow: unset;
		}

		${StyledNavApp},
		${StyledNavUser} {
			display: flex;
			flex-grow: 1;
		}
	}

	@media (min-width: 512px) {
		${StyledNavDate} {
			width: ${mobileMLNavMenuWidth};
		}
	}

	// @media ${deviceMinWidth.laptop} {
	// 	background: white;

	// 	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
	// 		0 9px 28px 8px rgb(0 0 0 / 5%);
	// }
`

export const TopNavMenu = styled(NavMenu)`
	width: 100%;

	border-radius: 0 0 ${cardBorderRadius} ${cardBorderRadius};

	> .ant-menu-item-selected {
		border-bottom: transparent !important;

		@media ${deviceMinWidth.laptop} {
			border-bottom: unset;
		}
	}

	@media ${deviceMinWidth.laptop} {
		// border-radius: unset;

		box-shadow: unset;
	}
`

export default StyledNav
