// Function component (stateful & container, using React, Redux, React Router, Auth0 & i18next hooks):

// React State & Effect hooks.
import React, { useState, useEffect, useCallback } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hook for routing.
import { useNavigate, useLocation } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'
import dayjs from 'dayjs'

import { schedulableTypes } from '../../utils/yupSchema/eventsSchema'

import { dateFormat, setDateFromTime, calendarL10n } from '../../utils/dates'
import { nestify } from '../../utils/api'
import { prefixPath } from '../../utils/l10n'

import { clearNewEvent } from '../../ducks/event'
import { activityInstancesIndex } from '../../ducks/activityInstances'
import { eventsCreate } from '../../ducks/events'
import { selectDay } from '../../ducks/journal'
import { toggleDashboard, startDashboardUpdate } from '../../ducks/dashboard'
import { speakWithSound } from '../../ducks/libra'
import { changeField } from '../../ducks/account'

import NewActivityEvent from './NewActivityEvent'

const NewActivityEventStatefulContainer = () => {
	const { isNewEventOpen, isQuickAction, activityKindId, name } = useSelector(
		(state) => state.event,
	)
	const isNewActivityEventOpen = isNewEventOpen && activityKindId

	const { currentDay, currentJournalId: journalId } = useSelector((state) => state.journal)
	const currentDate = dayjs(currentDay, dateFormat)
	const isToday = dayjs(currentDate).isToday()

	const { isDashboardOpen } = useSelector((state) => state.dashboard)

	const initialValues = {
		startDate: currentDay,
		schedulableType: schedulableTypes[0],
		schedulable: {
			activityKindId,
			duration: setDateFromTime('O:10'),
		},
		recurrence: '',
		journalId,
	}

	const { currentAccountId, field } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const birthDay = currentAccount?.accountable?.birthDate
	const birthDate = birthDay && dayjs(birthDay)

	const activityKind = activityKindId && build(apiData, 'activityKind', activityKindId)

	const [remainderDuration, setRemainderDuration] = useState()
	const maxDuration =
		typeof remainderDuration !== 'undefined'
			? dayjs.duration(remainderDuration, 'seconds')
			: undefined
	const humanizedDuration = maxDuration?.humanize()

	const { getAccessTokenSilently } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['event', 'events', 'activities', 'l10n', 'validation'])

	const dispatch = useDispatch()

	const humanizedDay = currentDate?.calendar(null, calendarL10n(t))

	const libraStart = useCallback(
		(path, l10nKeys = {}) =>
			dispatch(speakWithSound({ path, speech: t(path, l10nKeys), language })),
		[t, language, dispatch],
	)

	const shouldCheckRemainder = isNewActivityEventOpen && activityKindId
	useEffect(() => {
		const listActivityInstances = async () => {
			if (!shouldCheckRemainder || !journalId || !currentDay) return

			try {
				const accessToken = await getAccessTokenSilently()

				const { response } = await dispatch(
					activityInstancesIndex(language, accessToken, {
						of: journalId,
						on: currentDay,
						onlyRemainder: true,
					}),
				)

				if (response) {
					const { activityInstance } = response

					const duration =
						activityInstance && Object.values(activityInstance)[0].attributes.duration

					if (duration === 0) {
						libraStart('event:addActivity.speech.noRemainder', { day: humanizedDay })
						// dispatch(toggleNewEvent())
					}

					setRemainderDuration(duration)
				}
			} catch (e) {
				// throw e
			}
		}

		listActivityInstances()
	}, [
		shouldCheckRemainder,
		journalId,
		currentDay,
		getAccessTokenSilently,
		language,
		dispatch,
		humanizedDay,
		libraStart,
	])

	const shouldLibraStart = shouldCheckRemainder && remainderDuration
	useEffect(() => {
		shouldLibraStart &&
			libraStart('event:addActivity.speech.remainder', {
				day: humanizedDay,
				duration: humanizedDuration,
			})
	}, [shouldLibraStart, humanizedDuration, humanizedDay, libraStart])

	const createEvent = async (eventAttrs) => {
		const accessToken = await getAccessTokenSilently()

		// Comply with Rails nested attributes.
		const nestedEventAttrs = ['schedulable']
		const nestifiedEventAttrs = nestify(eventAttrs, nestedEventAttrs)

		return dispatch(eventsCreate(language, accessToken, nestifiedEventAttrs))
	}

	const updateDash = () => {
		!isDashboardOpen && dispatch(toggleDashboard())
		dispatch(startDashboardUpdate())
	}

	const hideNewEvent = () => dispatch(clearNewEvent())

	let { pathname, search } = useLocation()
	const currentPath = `${pathname}${search}`

	const navigate = useNavigate()

	const navToJournalAllFitness = () => {
		const newPath = prefixPath('/journal?section=allFitness', language)

		// Temporary fix...
		field !== 'nutrition' && dispatch(changeField('nutrition'))

		newPath !== currentPath && navigate(newPath)
	}

	const endSubmit = () => {
		hideNewEvent()
		navToJournalAllFitness()

		updateDash()
	}

	return (
		<NewActivityEvent
			isNewActivityEventOpen={isNewActivityEventOpen}
			isQuickAction={isQuickAction}
			activityKindId={activityKindId}
			name={name || activityKind?.name}
			isToday={isToday}
			initialValues={initialValues}
			birthDate={birthDate}
			selectDay={(d) => dispatch(selectDay(d))}
			maxDuration={maxDuration}
			createEvent={createEvent}
			hideNewEvent={hideNewEvent}
			endSubmit={endSubmit}
			t={t}
		/>
	)
}

export default NewActivityEventStatefulContainer
