// Function component (stateful & container, using Redux, Auth0 & i18next hooks):

import React from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import { schedulableTypes } from '../../utils/yupSchema/eventsSchema'

import { cupValueInMl, breakfastlId, waterFoodId } from '../../utils/meal'
import { nestify } from '../../utils/api'

import { eventsCreate } from '../../ducks/events'

import DailyWater from './DailyWater'

const DailyWaterContainer = ({ close }) => {
	const initialValues = { withDailWater: '' }

	const { getAccessTokenSilently } = useAuth0()

	const { currentDay, currentJournalId: journalId } = useSelector((state) => state.journal)

	const eventAttrs = {
		startDate: currentDay,
		schedulableType: schedulableTypes[1],
		schedulable: {
			mealKindId: breakfastlId,
			dishes: [
				{
					foodId: waterFoodId,
					mass: cupValueInMl,
					volume: cupValueInMl,
					// serving: 1,
					serving: '',
					// servingSizeId: '',
					fixed: true,
				},
			],
		},
		recurrence: JSON.stringify({ every: 'day' }),
		journalId,
	}

	const {
		i18n: { language },
		t,
	} = useTranslation(['event', 'replies', 'validation'])

	const dispatch = useDispatch()

	const createEvent = async () => {
		const accessToken = await getAccessTokenSilently()

		// Comply with Rails nested attributes.
		const nestedEventAttrs = ['schedulable', 'dishes']
		const nestifiedEventAttrs = nestify(eventAttrs, nestedEventAttrs)

		return dispatch(eventsCreate(language, accessToken, nestifiedEventAttrs))
	}

	return (
		<DailyWater initialValues={initialValues} createEvent={createEvent} endSubmit={close} t={t} />
	)
}

export default DailyWaterContainer
