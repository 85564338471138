// Function component (presentational):

import React from 'react'

import { renderFitnessLabel } from '../shared/other/renderLabel'

import InlineMenu from '../shared/styledComponents/menu/InlineMenu'

import L10nLink from '../shared/navigation/L10nLink'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import {
	buildNappingItem,
	buildTvWatchingItem,
	buildCookingItem,
	buildCleaningItem,
	buildWalkingItem,
	buildBicyclingItem,
	buildJoggingItem,
	buildStairClimbingItem,
	buildSquattingItem,
	buildYogaPracticingItem,
	buildStretchingItem,
} from './buildActivityItems'

import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import TextCircleButton from '../shared/styledComponents/button/TextCircleButton'

const AddActivity = ({ isAddActivityOpen, hideAddActivity, handleClick, t }) => {
	const renderTitle = () =>
		renderFitnessLabel(
			t('event:addActivity.title'),
			<TextCircleButton icon={<CloseOutlined />} onClick={hideAddActivity} />,
		)

	const items = []

	const nappingItem = buildNappingItem(handleClick, t)
	const tvWatchingItem = buildTvWatchingItem(handleClick, t)
	const cookingItem = buildCookingItem(handleClick, t)
	const cleaningItem = buildCleaningItem(handleClick, t)
	const walkingItem = buildWalkingItem(handleClick, t)
	const bicyclingItem = buildBicyclingItem(handleClick, t)
	const joggingItem = buildJoggingItem(handleClick, t)
	const stairClimbingItem = buildStairClimbingItem(handleClick, t)
	const squattingItem = buildSquattingItem(handleClick, t)
	const yogaPracticingItem = buildYogaPracticingItem(handleClick, t)
	const stretchingItem = buildStretchingItem(handleClick, t)

	const dividerItem = { type: 'divider' }

	const searchActivityKindItem = {
		key: 'search-activity-kind',
		icon: <SearchOutlined />,
		label: (
			<div onClick={hideAddActivity}>
				<L10nLink to={{ pathname: '/library', search: '?scope=activityKind' }}>
					{t('event:addActivity.searchActivityKind')}
				</L10nLink>
			</div>
		),
	}

	items.push(
		nappingItem,
		tvWatchingItem,
		cookingItem,
		cleaningItem,
		walkingItem,
		bicyclingItem,
		joggingItem,
		stairClimbingItem,
		squattingItem,
		yogaPracticingItem,
		stretchingItem,
		dividerItem,
		searchActivityKindItem,
	)

	const renderBody = () => <InlineMenu items={items} />

	return (
		<DrawerOrModalContainer
			isOpen={isAddActivityOpen}
			hide={hideAddActivity}
			renderTitle={renderTitle}
			renderBody={renderBody}
		/>
	)
}

export default AddActivity
