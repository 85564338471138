import parse from 'html-react-parser'

import { replaceTags } from '../../utils/html'

import PrimaryStrong from '../shared/styledComponents/text/PrimaryStrong'
import ActivityEm from '../shared/styledComponents/text/ActivityEm'

import renderLabel from '../shared/other/renderLabel'

import { QuestionOutlined } from '@ant-design/icons'

const renderQuestionLabel = (label) =>
	renderLabel({
		icon: <QuestionOutlined />,
		label: parse(
			label,
			replaceTags([
				{ tagName: 'strong', component: PrimaryStrong },
				{ tagName: 'em', className: 'activity', component: ActivityEm },
			]),
		),
	})

export default renderQuestionLabel
