import * as yup from 'yup'

import { startDateValidation } from './eventsSchema'
import { durationValidation } from './activitiesSchema'

const activityEventFormSchema = (t, birthDate, remainderDuration) =>
	yup.object().shape({
		startDate: startDateValidation(t, birthDate),
		schedulable: yup.object().shape({
			duration: durationValidation(t, remainderDuration),
		}),
	})

export default activityEventFormSchema
