// Function component (presentational):

import React from 'react'

import dayjs from 'dayjs'
import _ from 'lodash'

import { Formik } from 'formik'
import { Form, DatePicker, Slider, SubmitButton } from 'formik-antd'

import moodFormSchema from '../../utils/yupSchema/moodFormSchema'
import { disabledDate } from '../../utils/yupSchema/logsSchema'
import { maxAge } from '../../utils/yupSchema/peopleSchema'
// import { ratingValues } from '../../utils/yupSchema/repliesSchema'

import { categorizeMood } from '../../utils/mood'
import { dateFormat } from '../../utils/dates'
import { transformInvalidAttrErrors } from '../../utils/api'

import FormItem from '../shared/styledComponents/form/FormItem'
import { datePickerStyle } from '../shared/styledComponents/form/DatePicker'

import { SliderWrapper } from './MoodFormStyles'

import renderLabel, { renderDateLabel } from '../shared/other/renderLabel'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'

import { InfoCircleOutlined, FrownOutlined, SmileOutlined, RightOutlined } from '@ant-design/icons'
import { App as AntApp } from 'antd'

const MoodForm = ({
	initialValues,
	birthDate,
	isOnboarding,
	fromLibra,
	isQuickAction,
	isToday,
	selectDay,
	latestMood,
	createQuestionnaireResponse,
	endSubmit,
	libraStart,
	t,
}) => {
	const { message } = AntApp.useApp()

	const handleSubmit = async (values, { setErrors }) => {
		try {
			const { response } = await createQuestionnaireResponse(values)

			if (response) {
				message.success(t('mood:form.message.success'))

				const {
					replies: {
						0: { value: moodRating },
					},
				} = values

				const moodLevel = categorizeMood(moodRating)
				const sequenceEnd = 6
				const i = _.random(1, sequenceEnd)

				fromLibra &&
					libraStart(
						`mood:${moodLevel}Mood.speech.${i}`,
						{},
						{
							sequenceType: 'random',
							sequenceEnd,
							sequenceLimit: 1,
						},
					)

				endSubmit()
			}
		} catch (e) {
			const errors = transformInvalidAttrErrors(e.errors)

			// Async (server-side) validation.
			setErrors(errors)
		}
	}

	const isLite = isOnboarding || fromLibra || isQuickAction
	const isSleek = isLite && isToday

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={moodFormSchema(t, birthDate)}
			onSubmit={handleSubmit}
		>
			{({
				values: {
					replies: {
						0: { value: moodRating },
					},
				},
			}) => {
				const isPositive = moodRating >= 6

				return (
					<Form
						// id="mood-form"
						layout="vertical"
						colon={false}
					>
						<FormItem
							name="log.date"
							label={renderDateLabel(t('logs:attributes.date.label'))}
							hidden={isSleek}
						>
							<DatePicker
								name="log.date"
								format="l"
								disabledDate={(current) => disabledDate(current, birthDate, dayjs())}
								minDate={birthDate}
								maxDate={birthDate && birthDate.add(maxAge, 'y')}
								onChange={(d) => d && selectDay(d.format(dateFormat))}
								variant="filled"
								style={datePickerStyle}
							/>
						</FormItem>

						<FormItem
							name="replies[0].value"
							label={
								(!(fromLibra || isQuickAction) || !isToday) &&
								renderLabel({
									icon: <SmileOutlined />,
									label: t('replies:attributes.moodRatingValue.label'),
								})
							}
							tooltip={{
								icon: <InfoCircleOutlined />,
								title: t('replies:attributes.moodRatingValue.tooltip'),
							}}
						>
							<SliderWrapper $isPositive={isPositive}>
								<FrownOutlined />
								<Slider name="replies[0].value" min={1} max={10} />
								<SmileOutlined />
							</SliderWrapper>
						</FormItem>

						<SubmitWrapper $step="first">
							<SubmitButton
								// htmlType="submit"
								shape="circle"
								icon={<RightOutlined />}
								// disabled={!isOnboarding && !latestMood}
								disabled={moodRating === ''}
							/>
						</SubmitWrapper>
					</Form>
				)
			}}
		</Formik>
	)
}

export default MoodForm
