// Function component (presentational):

import React from 'react'

import AntModal from '../styledComponents/Modal'

// import { Modal as AntModal } from 'antd'

const Modal = ({
	id,
	isOpen,
	renderTitle,
	mask,
	canClose,
	handleCancel,
	size = 'default',
	isTablet,
	isLaptopML,
	withSideDash,
	fromTop,
	fromBottom,
	placement,
	staysRight = false,
	side = 'center',
	isMobileXL,
	renderBody,
}) => {
	const isFullscreen = size === 'fullscreen'
	const isLarge = size === 'large'
	const canBeLarge = (isLarge && isTablet && !withSideDash) || (isLarge && isLaptopML)

	const maxWidth = isFullscreen ? '100vw' : canBeLarge ? '768px' : 'min(100vw, 384px)'

	const isPlacementBottom = placement === 'bottom'
	const isBottom = fromBottom || isPlacementBottom
	const isSideRight = side === 'right'
	const isSideLeft = side === 'left'

	const top =
		typeof fromTop === 'number'
			? `calc(${fromTop}px + env(safe-area-inset-top))`
			: placement === 'top'
				? 'calc(192px + env(safe-area-inset-top))'
				: isBottom
					? 'unset'
					: undefined

	const bottom = fromBottom
		? `calc(${fromBottom}px + env(safe-area-inset-bottom))`
		: isPlacementBottom
			? 'calc(56px + env(safe-area-inset-bottom))'
			: undefined

	const marginRight =
		isMobileXL && ((staysRight && withSideDash) || (isSideRight && !withSideDash))
			? 'env(safe-area-inset-right)'
			: isSideRight && withSideDash
				? 'calc(384px + env(safe-area-inset-right))'
				: withSideDash
					? canBeLarge
						? 'calc(50vw - 192px)'
						: '50vw'
					: 'auto'

	const marginLeft = isSideLeft && isMobileXL ? 0 : 'auto'
	const left =
		isSideLeft && isMobileXL ? 'env(safe-area-inset-left)' : 'calc((100vw - min(100vw, 384px)) / 2)'

	return (
		<AntModal
			id={id}
			title={renderTitle()}
			open={isOpen}
			closable={false}
			mask={mask}
			maskClosable={canClose}
			keyboard={canClose}
			onCancel={handleCancel}
			footer={null}
			width={maxWidth}
			$stickRight={withSideDash && !canBeLarge}
			style={{
				maxWidth,
				position: isBottom && 'fixed',
				top: top ?? 'calc(96px + env(safe-area-inset-top))',
				bottom,
				left: isBottom && left,
				paddingBottom: 0,
				marginTop: 0,
				marginRight,
				marginBottom: 0,
				marginLeft,
			}}
		>
			{renderBody && renderBody()}
		</AntModal>
	)
}

export default Modal
