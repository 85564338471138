import styled from 'styled-components'

import { mobileMLNavMenuWidth } from '../../../../styles/sizes'

import TitleWrapper from './TitleWrapper'

const PageTitle = styled(TitleWrapper)`
	max-width: ${mobileMLNavMenuWidth};

	margin-right: auto;
	margin-left: auto;
`

export default PageTitle
