import styled from 'styled-components'

import { Tabs as AntTabs } from 'antd'

const Tabs = styled(AntTabs).attrs((props) => ({
	// Defining static props.
	tabBarGutter: 8,
	indicator: { size: (origin) => origin - 16 },

	// Defining dynamic props.
	$withLeftExtra: props.$withLeftExtra ?? false,
	// $withRightExtra: props.$withRightExtra ?? false,
}))`
	// &.ant-tabs {
	// 	margin-right: 8px;
	// 	margin-left: 8px;
	// }

	padding-right: env(safe-area-inset-right);
	padding-left: env(safe-area-inset-left);

	.ant-tabs-nav {
		min-height: 48px;
		margin-bottom: 8px;
	}

	.ant-tabs-nav-list {
		margin: auto;
	}

	.ant-tabs-tab {
		padding-right: 8px;
		padding-left: 8px;
	}

	.ant-tabs-nav-more {
		min-width: 48px;
		margin-right: 8px;
	}

	.ant-tabs-extra-content {
		padding-left: 16px;
		padding-right: 16px;
	}

	.ant-tabs-nav-wrap {
		padding-right: ${(props) => (props.$withLeftExtra ? '64px' : undefined)};
		// padding-left: ${(props) => (props.$withRightExtra ? '64px' : undefined)};
	}
`

export default Tabs
