// Function component (stateful & container, using React, Redux, React Router, Auth0, i18next & React Responsive hooks):

// React State & Effect hooks.
import React, { useState, useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hooks for routing.
import { useSearchParams } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
import { useMediaQuery } from 'react-responsive'

import build from 'redux-object'

import { accountableTypes } from '../../utils/yupSchema/accountsSchema'

import { accountsShow } from '../../ducks/accounts'
import { toggleDashboard } from '../../ducks/dashboard'
import { toggleAccounts } from '../../ducks/account'

import deviceMinWidth from '../../styles/deviceMinWidth'

import Deck from './Deck'

import { App as AntApp } from 'antd'

const DeckStatefulContainer = () => {
	const { currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const account = currentAccountId && build(apiData, 'account', currentAccountId)
	const isPerson = account?.accountableType === accountableTypes[0]

	const { getAccessTokenSilently } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['deck', 'dashboard'])

	const dispatch = useDispatch()

	const { message } = AntApp.useApp()

	const shouldViewAccount = !account?.accountable?.journal?.id
	useEffect(() => {
		if (!shouldViewAccount) return

		const viewAccount = async (accountId) => {
			try {
				const accessToken = await getAccessTokenSilently()

				const r = await dispatch(accountsShow(language, accessToken, accountId))
				return r
			} catch (e) {
				// throw e
			}
		}

		currentAccountId && viewAccount(currentAccountId)
	}, [shouldViewAccount, getAccessTokenSilently, language, dispatch, t, message, currentAccountId])

	let [searchParams, setSearchParams] = useSearchParams()

	const fallbackSection = 'compass'
	const paramsSection = searchParams.get('section')
	const initialSection = paramsSection ?? fallbackSection

	const [section, setSection] = useState(initialSection)
	const handleSectionChange = (key) => setSection(key)

	const shouldSetSearchParams = section !== initialSection
	useEffect(() => {
		shouldSetSearchParams && setSearchParams({ section })
	}, [shouldSetSearchParams, section, setSearchParams])

	const { isDashboardOpen } = useSelector((state) => state.dashboard)

	const isTablet = useMediaQuery({
		// query: '(min-width: 768px)'
		query: `${deviceMinWidth.tablet}`,
	})

	const withSideDash = isDashboardOpen && isTablet

	const { isDarkTheme } = useSelector((state) => state.appearance)

	return (
		<Deck
			currentAccountId={currentAccountId}
			account={account}
			isPerson={isPerson}
			section={section}
			showAccounts={() => dispatch(toggleAccounts())}
			handleSectionChange={handleSectionChange}
			withSideDash={withSideDash}
			showDashboard={() => dispatch(toggleDashboard())}
			t={t}
			// isLaptop={isLaptop}
			isDarkTheme={isDarkTheme}
		/>
	)
}

export default DeckStatefulContainer
