import { enLocales } from '../contexts/i18n/i18nConfig'
import { frLocales } from '../contexts/i18n/i18nConfig'

export const pathOptions = (path) => {
	const arr = path.split('.')
	const intent = arr.shift()
	const key = arr.pop() ?? ''

	return { intent, key }
}

const voiceLocales = [...enLocales, ...frLocales]
export const isLocaleWithVoice = (locale) => voiceLocales.includes(locale)

export const voices = [
	'onboarding:welcome.speech.2',
	'onboarding:welcome.speech.3',
	'onboarding:welcome.speech.4',
	'onboarding:new.speech.1',
	'onboarding:new.speech.2',
	'onboarding:new.speech.3',
	'onboarding:new.speech.4',
	'onboarding:completion.speech.1',
	'onboarding:completion.speech.2',
	'libra:greeting.speech.libra',
	'libra:greeting.speech.hello',
	'libra:greeting.speech.meeting',
	'libra:greeting.speech.state',
	'libra:greeting.speech.age',
	'libra:presentation.speech.who',
	'libra:presentation.speech.what',
	'libra:presentation.speech.why',
	'libra:presentation.speech.where',
	'libra:presentation.speech.when',
	'libra:presentation.speech.how',
	'libra:presentation.speech.help',
	'libra:presentation.speech.language',
	'libra:pitch.speech.elevator',
	'libra:pitch.speech.offering',
	'libra:pitch.speech.audience',
	'libra:pitch.speech.need',
	'libra:pitch.speech.secret',
	'libra:pitch.speech.features',
	'libra:pitch.speech.founder',
	'libra:problem.speech.1',
	'libra:problem.speech.2',
	'libra:problem.speech.3',
	'libra:solution.speech.1',
	'libra:solution.speech.2',
	'libra:solution.speech.3',
	'libra:mission.speech.1',
	'libra:mission.speech.2',
	'libra:mission.speech.3',
	'libra:explanation.speech.libra360',
	'libra:social.speech.facebook',
	'libra:social.speech.instagram',
	'libra:social.speech.youtube',
	'libra:social.speech.spotify',
	'libra:social.speech.twitter',
	'libra:social.speech.pinterest',
	'libra:social.speech.tiktok',
	'libra:social.speech.linkedin',
	'libra:tip.speech.1',
	'libra:tip.speech.2',
	'libra:tip.speech.3',
	'libra:tip.speech.4',
	'libra:tip.speech.5',
	'libra:tip.speech.6',
	'libra:tip.speech.7',
	'libra:tip.speech.8',
	'libra:access.speech.waitlist',
	'libra:login.speech.needed',
	'libra:logout.speech.bye',
	// 'libra:action.speech.onboarding',
	'libra:listening.speech.anotherTime',
	'libra:listening.speech.onboarding',
	'referral:notVerified.speech.verifyEmail',
	'referral:stats.speech.noReferral',
	'referral:stats.speech.first',
	'account:profileForm.speech.country',
	'lifestyle:palForm.speech.sedentaryLifestyle',
	'lifestyle:palForm.speech.activeLifestyle',
	'lifestyle:palForm.speech.vigorousLifestyle',
	'lifestyle:palForm.speech.generic',
	'lifestyle:exertionForm.speech.1',
	'lifestyle:exertionForm.speech.2',
]

const titleIntents = [
	'libra:problem',
	'libra:solution',
	'libra:mission',
	'libra:trivia',
	'libra:story',
	'libra:joke',
	// 'libra:tarot',
	'libra:help',
]
export const isIntentWithTitle = (intent) => titleIntents.includes(intent)

const imageIntents = ['libra:tarot']
export const isIntentWithImage = (intent) => imageIntents.includes(intent)
