import * as yup from 'yup'

import logsSchema from './logsSchema'
import bodyMassesSchema, { desiredBodyMassesSchema } from './bodyMassesSchema'
import bodyHeightsSchema from './bodyHeightsSchema'
import bodyMassIndicesSchema from './bodyMassIndicesSchema'

const bodyMeasurementsSchema = (t, birthDate = null) =>
	yup.object().shape({
		log: logsSchema(t, birthDate, false),
		bodyMass: bodyMassesSchema(t),
		bodyHeight: bodyHeightsSchema(t),
		bodyMassIndex: bodyMassIndicesSchema(t),
	})

export const desiredBodyMeasurementsSchema = (t) =>
	yup.object().shape({
		bodyMass: desiredBodyMassesSchema(t),
	})

export default bodyMeasurementsSchema
