import styled from 'styled-components'

import { colors } from '../../../styles/colors'

import { Badge as AntBadge } from 'antd'

const Badge = styled(AntBadge).attrs((props) => ({
	// Defining dynamic props.
	color: props.count ? colors.primary : undefined,
}))``

export default Badge
