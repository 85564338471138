import styled from 'styled-components'

const SubmitContent = styled.span`
	flex: 1 1 auto;

	margin: auto;

	text-align: center;
`

export default SubmitContent
