// Function component (presentational):

import React from 'react'

import NavButton from '../shared/styledComponents/button/NavButton'

import { Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const Date = ({ date, showCalendar, prev, next }) => {
	const renderDate = () => (
		<NavButton $showLabel onClick={showCalendar}>
			{date}
		</NavButton>
	)

	const renderPrev = () => (
		<Button type="text" shape="circle" icon={<LeftOutlined />} onClick={prev} />
	)

	const renderNext = () => (
		<Button type="text" shape="circle" icon={<RightOutlined />} onClick={next} />
	)

	return (
		<>
			{renderPrev()}
			{renderDate()}
			{renderNext()}
		</>
	)
}

export default Date
