// Function component (presentational):

import React from 'react'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import FormWrapper from '../shared/styledComponents/form/FormWrapper'

import renderLabel from '../shared/other/renderLabel'

import ObjectiveForm from './ObjectiveForm'

import { AimOutlined, CloseOutlined } from '@ant-design/icons'
import TextCircleButton from '../shared/styledComponents/button/TextCircleButton'

const NewObjective = ({
	isNewObjectiveOpen,
	hideNewObjective,
	birthDate,
	initialValues,
	endSubmit,
	createObjective,
	libraStart,
	isSI,
	language,
	t,
}) => {
	const renderTitle = () =>
		renderLabel({
			icon: <AimOutlined />,
			label: t('objective:form.title'),
			extra: <TextCircleButton icon={<CloseOutlined />} onClick={hideNewObjective} />,
		})

	const renderBody = () => (
		<FormWrapper>
			<ObjectiveForm
				initialValues={initialValues}
				birthDate={birthDate}
				endSubmit={endSubmit}
				createObjective={createObjective}
				libraStart={libraStart}
				isSI={isSI}
				language={language}
				t={t}
			/>
		</FormWrapper>
	)

	return (
		<DrawerOrModalContainer
			isOpen={isNewObjectiveOpen}
			hide={hideNewObjective}
			renderTitle={renderTitle}
			renderBody={renderBody}
		/>
	)
}

export default NewObjective
