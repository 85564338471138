// Function component (presentational):

import React from 'react'

import _ from 'lodash'

import { eventKinds } from '../../utils/yupSchema/logsSchema'

import { calendarL10n } from '../../utils/dates'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'

import Timeline from '../shared/styledComponents/timeline/Timeline'

import Icon, {
	ScheduleOutlined,
	LoadingOutlined,
	FireOutlined,
	PlusOutlined,
} from '@ant-design/icons'
import { ReactComponent as Nutrition } from '../../assets/icons/water-drop.svg'
import { ReactComponent as Mind } from '../../assets/icons/lotus.svg'
import TextRoundButton from '../shared/styledComponents/button/TextRoundButton'
import BlockRoundButton from '../shared/styledComponents/button/BlockRoundButton'

import { Entry, EntryCategory, EntryEnergy, EntryDescription } from './JournalTimelineStyles'

const JournalTimeline = ({
	showCalendar,
	date,
	dailyEntryLogs = {},
	isLoading,
	field,
	handleClick,
	t,
}) => {
	const renderTimelineIcon = () => {
		switch (field) {
			case 'nutrition':
				return <Icon component={Nutrition} />
			case 'mind':
				return <Icon component={Mind} />
			case 'fitness':
				return <FireOutlined />
			default:
				return null
		}
	}

	const newEntryLabel = (() => {
		switch (field) {
			case 'nutrition':
				return 'Meal'
			case 'mind':
				return 'Practice'
			case 'fitness':
				return 'Activity'
			default:
				return ''
		}
	})()

	const items = []

	const retreiveCalories = ({ loggable }) => {
		const nutritionValue = loggable.nutritionalComposition?.energy?.value
		const nutritionCalories = nutritionValue && Math.round(nutritionValue)
		const fitnessValue = loggable.metabolicComposition?.activityEnergyExpenditure?.value
		const fitnessCalories = fitnessValue && Math.round(fitnessValue)

		return nutritionCalories || fitnessCalories
	}

	const renderItem = (category, logs) => ({
		dot: renderTimelineIcon(),
		children: (
			<>
				<EntryCategory>{category}</EntryCategory>

				{logs.map(
					(l) =>
						l.loggable?.description && (
							<Entry key={l.id}>
								<EntryDescription delete={l.loggable.status === 'pending'}>
									{l.loggable?.description}
								</EntryDescription>

								{eventKinds.includes(l.loggable.type) && (
									<EntryEnergy count={retreiveCalories(l)} $kind={l.loggable.type} />
								)}
							</Entry>
						),
				)}
			</>
		),
	})

	const groupedLogs = Object.entries(_.groupBy(dailyEntryLogs, 'loggable.name'))

	const renderItems = () => groupedLogs.map(([k, v]) => renderItem(k, v))

	Object.keys(dailyEntryLogs).length && items.push(...renderItems())

	return (
		<Card
			title={renderLabel({
				icon: isLoading ? <ScheduleOutlined /> : null,
				label: (
					<TextRoundButton onClick={showCalendar}>
						{date.calendar(null, calendarL10n(t))}
					</TextRoundButton>
				),
				extra: isLoading ? <LoadingOutlined /> : null,
			})}
			style={{ marginRight: 8, marginLeft: 8 }}
		>
			{items.length !== 0 && <Timeline items={items} reverse />}

			<BlockRoundButton
				type="default"
				onClick={() => handleClick(date)}
				// style={{ marginTop: items.length ? 8 : undefined }}
			>
				{renderLabel({
					icon: renderTimelineIcon(),
					label: t(`event:add${newEntryLabel}.action`),
					extra: <PlusOutlined />,
				})}
			</BlockRoundButton>
		</Card>
	)
}

export default JournalTimeline
