import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

const path = '/objectives'

export const objectivesCreate = (locale, accessToken, objectiveAttrs) => (dispatch) => {
	const endpoint = localizePath(locale, path)
	const method = 'POST'
	const objective = { objective: objectiveAttrs }
	const options = initRequestOptions({ method, accessToken, data: objective })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}
