// Function component (presentational):

import React from 'react'

import { Typography } from 'antd'

const { Text } = Typography

const LanguageOption = ({ locale, disabled, handleSelect }) => (
	<Text onClick={() => handleSelect(locale)} disabled={disabled} style={{ display: 'flex' }}>
		{locale}
	</Text>
)

export default LanguageOption
