// Function component (presentational):

import React from 'react'

import { Button } from 'antd'
import {
	StepBackwardOutlined,
	// SoundOutlined,
	PauseOutlined,
	CaretRightOutlined,
	StepForwardOutlined,
} from '@ant-design/icons'

import StyledLibraControls from './LibraControlsStyles'

const LibraControls = ({
	withVoiceL10n,
	withVoice,
	withPrev,
	withNext,
	libraPrev,
	libraNext,
	isPlaying,
	togglePlaying,
	speech,
	toggleVoice,
}) => {
	const renderPrev = () => (
		<Button type="text" shape="circle" icon={<StepBackwardOutlined />} onClick={libraPrev} />
	)

	const handleClick = () => (speech ? togglePlaying() : toggleVoice())
	const icon = isPlaying ? <PauseOutlined /> : <CaretRightOutlined />
	const renderPlayPause = () => (
		<Button type="text" shape="circle" icon={icon} onClick={handleClick} />
	)

	const renderNext = () => (
		<Button type="text" shape="circle" icon={<StepForwardOutlined />} onClick={libraNext} />
	)

	return (
		withVoiceL10n &&
		withVoice && (
			<StyledLibraControls>
				{withPrev && renderPrev()}
				{renderPlayPause()}
				{withNext && renderNext()}
			</StyledLibraControls>
		)
	)
}

export default LibraControls
