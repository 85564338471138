// Function component (presentational):

import React from 'react'

import { Segmented } from 'antd'

const SystemSegmented = ({ isSI, handleChange, t }) => (
	<Segmented
		options={[
			{ value: false, label: t('preference:system.avoirdupois.label') },
			{ value: true, label: t('preference:system.international.label') },
		]}
		value={isSI}
		size="small"
		onChange={handleChange}
	/>
)

export default SystemSegmented
