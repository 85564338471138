export const moodQuestionnaireId = '4'

export const categorizeMood = (moodRating) => {
	switch (true) {
		case moodRating < 2:
			return 'worst'
		case moodRating < 4:
			return 'bad'
		case moodRating < 7:
			return 'normal'
		case moodRating < 9:
			return 'good'
		case moodRating >= 9:
			return 'best'
		default:
			return 'uncategorized'
	}
}
