// Function component (presentational):

import React from 'react'

import SectionWrapper from '../shared/styledComponents/page/SectionWrapper'
import SectionTitle from '../shared/styledComponents/page/SectionTitle'
import ContentWrapper from '../shared/styledComponents/page/ContentWrapper'
// import Illustration from '../shared/styledComponents/Illustration'

import Title from '../shared/styledComponents/text/Title'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'

import completion from '../../assets/illustrations/onboarding/checked-map.png'

import Icon, { RightOutlined } from '@ant-design/icons'
import { ReactComponent as Wellness } from '../../assets/icons/circle.svg'
import BlockRoundButton from '../shared/styledComponents/button/BlockRoundButton'

const OnboardingCompletion = ({ nextStep, t }) => (
	<SectionWrapper
	// id="onboarding-completion-wrapper"
	// $type="illustration"
	>
		<SectionTitle>
			<Title level={3}>{t('onboarding:completion.title')}</Title>
		</SectionTitle>

		<ContentWrapper style={{ alignItems: 'center' }}>
			<Card
				cover={
					<img
						src={completion}
						alt={t('onboarding:completion.illustration')}
						width="100%"
						height="100%"
					/>
				}
				$withMeta
			>
				<Card.Meta
					title={
						<BlockRoundButton onClick={nextStep}>
							{renderLabel({
								icon: <Icon component={Wellness} />,
								label: t('onboarding:completion.action'),
								extra: <RightOutlined />,
							})}
						</BlockRoundButton>
					}
				/>
			</Card>
		</ContentWrapper>
	</SectionWrapper>
)

export default OnboardingCompletion
