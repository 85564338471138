import styled from 'styled-components'

const PageIllustration = styled.div.attrs((props) => ({
	// Defining static props.
	className: 'illustration',

	// Defining dynamic props.
	$isFullscreen: props.$isFullscreen ?? false,
}))`
	height: ${(props) => (props.$isFullscreen ? '100vh' : 'calc(256px + env(safe-area-inset-top))')};
	@supports (height: 100dvh) {
		height: ${(props) =>
			props.$isFullscreen ? '100dvh' : 'calc(256px + env(safe-area-inset-top))'};
	}
	width: 100vw;

	margin-top: calc(0px - env(safe-area-inset-top));

	background-image: ${(props) => `url(${props.$src})`};
	background-repeat: repeat;
	background-size: contain;
	filter: ${(props) => (props.$isDarkTheme ? 'invert(1)' : undefined)};

	animation: slide-down 180s linear infinite;

	@keyframes slide-down {
		from {
			background-position: 0 -256px;
		}

		to {
			background-position: 0 0;
		}
	}
`

export default PageIllustration
