export const supportedLocaleStandards = [
	'en',
	'fr',
	// 'es', 'de', 'pt', 'it', 'da', 'cs'
]

export const defaultLocale = supportedLocaleStandards[0]

const enLocaleVarieties = ['en-US', 'en-GB', 'en-CA', 'en-AU', 'en-NZ', 'en-IN']
const frLocaleVarieties = ['fr-FR', 'fr-CA', 'fr-CH', 'fr-BE']
const esLocaleVarieties = ['es-ES', 'es-US', 'es-MX', 'es-AR', 'es-CO', 'es-CL']
const deLocaleVarieties = ['de-DE', 'de-AT', 'de-CH']
const ptLocaleVarieties = ['pt-PT', 'pt-BR']
const itLocaleVarieties = ['it-IT', 'it-CH']

const supportedLocaleVarieties = [
	...enLocaleVarieties,
	...frLocaleVarieties,
	// ...esLocaleVarieties,
	// ...deLocaleVarieties,
	// ...ptLocaleVarieties,
	// ...itLocaleVarieties,
]

export const supportedLocales = [...supportedLocaleStandards, ...supportedLocaleVarieties]

export const enLocales = ['en', ...enLocaleVarieties]
export const frLocales = ['fr', ...frLocaleVarieties]
export const esLocales = ['es', ...esLocaleVarieties]
export const deLocales = ['de', ...deLocaleVarieties]
export const ptLocales = ['pt', ...ptLocaleVarieties]
export const itLocales = ['it', ...itLocaleVarieties]

export const avoirdupoisLocales = ['en-US', 'en-GB']

const decimalCommaEsLocales = esLocales.filter((l) => !['es-US', 'es-MX'].includes(l))

export const decimalCommaLocales = [
	...frLocales,
	...decimalCommaEsLocales,
	...deLocales,
	...ptLocales,
	...itLocales,
]
