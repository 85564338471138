import styled from 'styled-components'

const StyledNavDate = styled.nav`
	display: flex;
	flex-grow: 1;

	align-items: center;
	justify-content: center;
`

export default StyledNavDate
