import { deviceViewportWidth } from './deviceMinWidth'

export const appPadding = '16px'
export const buttonWidth = '32px'
export const buttonHeight = buttonWidth // 32px
export const buttonBorderRadius = `calc(${buttonWidth} / 2)` // 16px
export const navMenuItemPadding = appPadding // 16px
export const navMenuItemWidth = `
  calc(${buttonWidth} + 2 * ${navMenuItemPadding})
` // 64px
export const navMenuItemHeight = navMenuItemWidth // 64px

// export const mobileSRemainingHeight = `
//   calc(100vh - 2 * ${navMenuItemHeight})
// `
export const mobileSNavMenuWidth = `
  calc(100vw)
`
export const mobileSSideNavWidth = navMenuItemWidth // 64px
export const cardBorderRadius = buttonWidth // 32px
export const mobileSCardWidth = `calc(100vw - 2 * ${appPadding})`
// const mobileSComponentWrapperWidth = mobileSCardWidth

export const mobileMLNavMenuWidth = deviceViewportWidth.mobileML // 384px
export const mobileMLNavMenuPadding = `
  calc((100vw - ${mobileMLNavMenuWidth}) / 2)
`
export const mobileMLSideNavPadding = mobileMLNavMenuPadding
export const mobileMLSideNavWidth = `
  calc(${navMenuItemWidth} + ${mobileMLSideNavPadding})
`
export const mobileMLCenterNavWidth = `
  calc(${mobileMLNavMenuWidth} - 2 * ${navMenuItemWidth})
` // 256px
export const mobileMLInnerCardWidth = `
  calc(${mobileMLNavMenuWidth} - 2 * ${appPadding})
` // 352px
// const mobileMLComponentWrapperWidth = mobileMLInnerCardWidth

// export const laptopRemainingHeight = `
//   calc(100vh - 1 * ${navMenuItemHeight})
// `
