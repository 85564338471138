const deviceViewportHeight = {
	mobileS: '568px',
	mobileM: '667px',
	mobileL: '425px',
	tablet: '1024px',
	laptop: '768px',
	laptopL: '900px',
	desktop: '1600px',
}
deviceViewportHeight['mobileML'] = `calc(${deviceViewportHeight.tablet} / 2)` // 512px
export { deviceViewportHeight }

const deviceMinHeight = {
	mobileS: `(min-height: ${deviceViewportHeight.mobileS})`,
	mobileM: `(min-height: ${deviceViewportHeight.mobileM})`,
	mobileML: `(min-height: ${deviceViewportHeight.mobileML})`,
	mobileL: `(min-height: ${deviceViewportHeight.mobileL})`,
	tablet: `(min-height: ${deviceViewportHeight.tablet})`,
	laptop: `(min-height: ${deviceViewportHeight.laptop})`,
	laptopL: `(min-height: ${deviceViewportHeight.laptopL})`,
	desktop: `(min-height: ${deviceViewportHeight.desktop})`,
	desktopL: `(min-height: ${deviceViewportHeight.desktop})`,
}

export default deviceMinHeight
