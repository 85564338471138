import { createSlice } from '@reduxjs/toolkit'
// import { createAppSlice } from '../store/createAppSlice'
import _ from 'lodash'
import { synthesizeSpeech } from './amazonPolly'

import { pathOptions, isLocaleWithVoice, voices } from '../utils/libra'
import { gaEvents } from '../utils/gaEvents'

const initialState = {
	isSpeaking: false,
	intent: '',
	key: '',
	isFirst: true,
	speech: '',
	sequenceType: 'single',
	sequenceNumber: 1,
	sequenceList: [],
	sequenceIndex: 0,
	sequenceEnd: 1,
	sequenceLimit: '',
	isEnded: true,
	speechCount: 0,
	withVoice: true,
	voiceSrc: '',
	isVoiced: false,
	isListening: false,
	iframeSrc: '',
	iframeOpts: '',
	imageSrc: '',
	isSpeechHidden: false,
}

const libra = createSlice({
	name: 'libra',
	initialState: initialState,
	reducers: {
		// ‘Mutating’ the state is possible thanks to immer (shipped with Redux Toolkit).
		speak(state, action) {
			state.isSpeaking = true
			state.intent = action.payload.intent
			state.key = action.payload.key
			state.isFirst = action.payload.isFirst ?? true
			state.speech = action.payload.speech

			if (state.isFirst) {
				state.sequenceType = action.payload.sequenceType ?? 'single'

				if (state.sequenceType === 'single') {
					state.sequenceNumber = 1
					state.sequenceEnd = 1
				} else {
					state.sequenceNumber = parseInt(state.key)
					state.sequenceEnd = action.payload.sequenceEnd
				}

				state.sequenceList = [state.sequenceNumber]
				state.sequenceIndex = 0
				state.sequenceLimit = action.payload.sequenceLimit ?? ''
				state.withVoice = action.payload.withVoice ?? true
				state.isSpeechHidden = action.payload.isSpeechHidden ?? false
			} else {
				state.sequenceNumber = parseInt(state.key)
				if (state.sequenceList.indexOf(state.sequenceNumber) === -1) {
					state.sequenceList.push(state.sequenceNumber)
				}
				state.sequenceIndex = state.sequenceList.indexOf(state.sequenceNumber)
			}

			state.isEnded =
				state.sequenceType !== 'random'
					? state.sequenceList.slice(-1)[0] === state.sequenceEnd ||
						state.sequenceList.length === state.sequenceEnd
					: state.sequenceLimit
						? state.sequenceList.length === state.sequenceLimit
						: false
			state.speechCount = 1
			state.voiceSrc = ''
			state.isVoiced = false
			state.iframeSrc = action.payload.iframeSrc ?? ''
			state.iframeOpts = action.payload.iframeOpts ?? ''
			state.imageSrc = action.payload.imageSrc ?? ''

			gaEvents.libra(action.payload.intent, action.payload.key)
		},
		voice(state) {
			state.isVoiced = true
		},
		repeat(state) {
			state.isSpeaking = true
			state.isFirst = false
			state.speechCount++
		},
		proceed(state) {
			if (state.sequenceType === 'ordered' && !state.isEnded) {
				state.isFirst = false

				state.sequenceNumber++
				state.sequenceIndex++
			}
		},
		randomize(state) {
			if (state.sequenceType === 'random' && !state.isEnded) {
				state.isFirst = false

				const possibilities = _.difference(_.range(1, state.sequenceEnd + 1), state.sequenceList)
				state.sequenceNumber = _.sample(possibilities)
				state.sequenceIndex++
			}
		},
		goBack(state) {
			if (state.sequenceList.length > 1) {
				state.sequenceIndex--
				state.sequenceNumber = state.sequenceList[state.sequenceIndex]
			}
		},
		goForward(state) {
			if (state.sequenceIndex <= state.sequenceList.length - 1) {
				state.sequenceIndex++
				state.sequenceNumber = state.sequenceList[state.sequenceIndex]
			}
		},
		silence(state) {
			state.isSpeaking = false
		},
		mute(state) {
			state.withVoice = false
		},
		unmute(state) {
			state.withVoice = true
		},
		sound(state, action) {
			state.voiceSrc = action.payload.voiceSrc
		},
		listen(state) {
			state.isListening = true
		},
		deafen(state) {
			state.isListening = false
		},
	},
})

export default libra
export const { reducer: libraReducer, actions: libraActions } = libra
export const {
	speak,
	voice,
	repeat,
	proceed,
	randomize,
	goBack,
	goForward,
	silence,
	mute,
	unmute,
	sound,
	listen,
	deafen,
} = libraActions

const withSound =
	({ intent, key, speech, language, withMarkdown }) =>
	(dispatch) => {
		if (!isLocaleWithVoice(language)) return

		const lngStd = language.substring(0, 2)
		const path = `${intent}.speech.${key}`
		if (voices.includes(path)) {
			const voiceSrc = require(`../assets/voices/${lngStd}/${path.replace(':', '.')}.mp3`)

			dispatch(sound({ voiceSrc }))
		} else {
			dispatch(synthesizeSpeech(speech, lngStd, withMarkdown))
		}
	}

export const speakWithSound =
	({ path, speech, language, withMarkdown, ...rest }) =>
	(dispatch) => {
		const { intent, key } = pathOptions(path)

		dispatch(speak({ intent, key, speech, ...rest }))
		dispatch(withSound({ intent, key, speech, language, withMarkdown }))
	}
