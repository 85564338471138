import { Tooltip, Table } from 'antd'

const buildColumns = ({ key, t, measure, isSI }) => [
	{
		dataIndex: 'component',
		title: t(`${key}:columns.component.label`),
		render: (description) => {
			const splits = description.split(' | ')
			const methodology = splits[1]
			const name = splits[0]
				.replace('%', 'g/100g')
				.replace('pcm', 'mg/100g')
				.replace('pphm', 'μg/100g')
				.replace('+', ' + ')
			const amountName = name.replace('/100g', '')
			const amountNameSA = amountName.replace('g)', 'oz)')
			const isProportion = measure === 'proportion'
			const label = isProportion ? name : isSI ? amountName : amountNameSA

			return <Tooltip title={methodology}>{label}</Tooltip>
		},
	},
	{
		dataIndex: 'value',
		title: t(`${key}:columns.value.label`),
		align: 'center',
	},
	{
		dataIndex: 'pctRef',
		title: t(`${key}:columns.pctRef.label`),
		render: (value) => value && `${value} %`,
		align: 'right',
	},
]

export const renderCategoryTable = ({ key, t, measure = 'amount', isSI, dataSource, category }) => (
	<Table
		columns={buildColumns({ key, t, measure, isSI })}
		dataSource={dataSource(category)}
		// showHeader={false}
		size="small"
		pagination={
			key === 'ncmComposition' && {
				pageSize: 12,
				hideOnSinglePage: true,
				size: 'small',
				position: ['none', 'bottomCenter'],
			}
		}
	/>
)
