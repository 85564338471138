// Function component (presentational):

import React from 'react'

import NavAppContainer from './NavAppContainer'
import NavDateContainer from './NavDateContainer'
import NavUserContainer from './NavUserStatefulContainer'

import StyledNav from './NavStyles'

const Nav = ({
	withNavApp,
	withNavUser,
	sleekNav,
	spreadNavUser,
	isHub,
	isDashboardOpen,
	withSideDash,
	navKey,
	navigate,
	t,
	isMobileM,
	isTabletL,
}) => (
	<StyledNav
	// id="nav"
	>
		<NavAppContainer
			withNavApp={withNavApp}
			sleekNav={sleekNav}
			isHub={isHub}
			withSideDash={withSideDash}
			navKey={navKey}
			navigate={navigate}
			t={t}
			isTabletL={isTabletL}
		/>

		<NavDateContainer sleekNav={sleekNav} isDashboardOpen={isDashboardOpen} isMobileM={isMobileM} />

		<NavUserContainer
			withNavUser={withNavUser}
			sleekNav={sleekNav}
			spreadNavUser={spreadNavUser}
			isDashboardOpen={isDashboardOpen}
			withSideDash={withSideDash}
			inOnboarding={navKey === 'onboarding'}
			navigate={navigate}
			t={t}
			isTabletL={isTabletL}
		/>
	</StyledNav>
)

export default Nav
