// Function component (presentational):

// React State hook.
import React, { useState } from 'react'
// React Router hook for routing.
import { useLocation } from 'react-router-dom'

import LayoutWrapper from '../shared/styledComponents/page/LayoutWrapper'
import PageWrapper from '../shared/styledComponents/page/PageWrapper'

import NavContainer from '../nav/NavContainer'
import LibraContainer from '../libra/LibraStatefulContainer'

import OnboardingContainer from './OnboardingStatefulContainer'

const OnboardingView = () => {
	const [step, setStep] = useState(1)
	const nextStep = () => setStep((prev) => prev + 1)

	let { state } = useLocation()
	const { isFirst = false } = state || {}

	const withNavApp = step === 1 && !isFirst

	return (
		<LayoutWrapper>
			<NavContainer withNavApp={withNavApp} sleekNav />

			<PageWrapper>
				<OnboardingContainer isFirst={isFirst} step={step} nextStep={nextStep} />
			</PageWrapper>

			<LibraContainer />
		</LayoutWrapper>
	)
}

export default OnboardingView
