import dayjs from 'dayjs'

import { midnight, timeFormat } from './dates'

export const palQuestionnaireId = '1'
export const timeUseQuestionnaireId = '2'
export const exertionQuestionnaireId = '3'

export const transformRoutineReplies = (replies) =>
	replies.map((reply) => {
		const trReply = { ...reply }
		const { kind, value } = trReply
		switch (kind) {
			case 'daysOfTheWeek':
				if (typeof value !== 'undefined') {
					trReply.value = value.toString()
				}
				return trReply
			case 'dailyDuration':
				if (typeof value !== 'undefined' && value !== null && value !== '') {
					const diff = dayjs(value).diff(midnight)
					const durationTime = dayjs.utc(diff).format(timeFormat)
					trReply.value = durationTime
				}
				return trReply
			default:
				return trReply
		}
	})

export const categorizeLifestyle = (palValue) => {
	switch (true) {
		case palValue < 1.4:
			return 'inactive'
		case palValue < 1.7:
			return 'sedentary'
		case palValue < 2.0:
			return 'active'
		case palValue < 2.4:
			return 'vigorous'
		case palValue >= 2.0:
			return 'extremelyActive'
		default:
			return 'uncategorized'
	}
}
