import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

const path = '/events'

export const eventsCreate = (locale, accessToken, eventAttrs) => (dispatch) => {
	const endpoint = localizePath(locale, path)
	const method = 'POST'
	const event = { event: eventAttrs }
	const options = initRequestOptions({ method, accessToken, data: event })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}

export const eventsUpdate = (locale, accessToken, eventAttrs, id) => (dispatch) => {
	const endpoint = localizePath(locale, `${path}/${id}`)
	const method = 'PUT'
	const event = { event: eventAttrs }
	const options = initRequestOptions({ method, accessToken, data: event })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}
