import styled from 'styled-components'

import { miscStyles } from '../../styles/misc'

export const Hero = styled.span`
	width: auto;
	height: auto;

	line-height: 48px;

	padding: 0.1em 0.4em;

	margin-block-start: 0.6em;

	border-radius: 24px;

	// background: ${(props) => props.theme.glassBackgroundColor};
	// @supports not (
	// 	(-webkit-backdrop-filter: ${miscStyles.glassBackdropFilter}) or
	// 		(backdrop-filter: ${miscStyles.glassBackdropFilter})
	// ) {
	// 	background: ${(props) => props.theme.backgroundColor};
	// }

	// -webkit-backdrop-filter: ${(props) => props.theme.glassBackdropFilter};
	// backdrop-filter: ${(props) => props.theme.glassBackdropFilter};

	// box-shadow: ${(props) => props.theme.boxShadow};

	// -webkit-box-decoration-break: clone;
	// box-decoration-break: clone;

	font-family: 'Fredoka';

	@media (max-width: 375px) {
		font-size: 30px;
	}
	font-size: 34px;
	font-weight: 600;
`
