// Function component (presentational):

import React from 'react'

import { Formik } from 'formik'
import {
	Form,
	// Radio,
	SubmitButton,
} from 'formik-antd'

import palFormSchema from '../../utils/yupSchema/palFormSchema'
// import { lifestyles } from '../../utils/yupSchema/physicalActivityLevelsSchema'

import { transformInvalidAttrErrors } from '../../utils/api'

import Paragraph from '../shared/styledComponents/text/Paragraph'

import FormItem from '../shared/styledComponents/form/FormItem'
import { radioGroupStyle } from '../shared/styledComponents/form/RadioGroup'
import RadioGroup from '../shared/styledComponents/form/RadioGroup'
import RadioButton from '../shared/styledComponents/form/RadioButton'

import InlineInfo from '../shared/styledComponents/text/InlineInfo'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'
import SubmitContent from '../shared/styledComponents/form/SubmitContent'

import renderQuestionLabel from './renderQuestionLabel'

import { DownOutlined } from '@ant-design/icons'
import { App as AntApp, Tooltip, Segmented } from 'antd'

// const { Group: RadioGroup, Button: RadioButton } = Radio

const PalForm = ({
	initialValues,
	isOnboarding,
	palLifestyle,
	palAvgLifestyle,
	createQuestionnaireResponse,
	isPersonalized,
	goPersonalized,
	endSubmit,
	libraStart,
	t,
}) => {
	const { message } = AntApp.useApp()

	const handleSubmit = async (values, { setErrors }) => {
		const [attrs] = values

		if (attrs.replies[0].value === palLifestyle) {
			message.warning(t('lifestyle:palForm.message.unchangedWarning'))

			endSubmit(values)
			return
		}

		try {
			const { response } = await createQuestionnaireResponse(attrs)

			if (response) {
				message.success(t('lifestyle:palForm.message.success'))

				libraStart('lifestyle:palForm.speech.generic')

				endSubmit(values)
			}
		} catch (e) {
			const errors = transformInvalidAttrErrors(e.errors)

			const q1Errors = [errors]

			// Async (server-side) validation.
			setErrors(q1Errors)
		}
	}

	const handleClick = (lifestyle) => libraStart(`lifestyle:palForm.speech.${lifestyle}Lifestyle`)

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={palFormSchema(t)}
			onSubmit={handleSubmit}
		>
			{({
				values,
				values: {
					0: {
						replies: {
							0: { value: lifestyle },
						},
					},
				},
			}) => (
				<Form
					// id="pal-form"
					layout="vertical"
					colon={false}
				>
					<FormItem
						name="[0].replies[0].value"
						label={renderQuestionLabel(t('physicalActivityLevels:attributes.lifestyle.label'))}
					>
						<RadioGroup name="[0].replies[0].value" style={radioGroupStyle}>
							<RadioButton value={'sedentary'}>
								{t('physicalActivityLevels:attributes.lifestyles.sedentary.label')}
							</RadioButton>
							<RadioButton value={'active'}>
								{t('physicalActivityLevels:attributes.lifestyles.active.label')}
							</RadioButton>
							<RadioButton value={'vigorous'}>
								{t('physicalActivityLevels:attributes.lifestyles.vigorous.label')}
							</RadioButton>
						</RadioGroup>
					</FormItem>

					{lifestyle && (
						<div style={{ padding: '0 8px' }}>
							<Paragraph>
								<blockquote>
									{t(`lifestyle:palForm.explanation.${lifestyle}Lifestyle`)}
									<Tooltip title={t('lifestyle:palForm.explanation.example')}>
										<InlineInfo onClick={() => handleClick(lifestyle)} />
									</Tooltip>
								</blockquote>
							</Paragraph>
						</div>
					)}

					<SubmitWrapper $step="first">
						<SubmitContent>
							<Segmented
								options={[
									{ value: true, label: t('lifestyle:formSwitch.personalized') },
									{ value: false, label: t('lifestyle:formSwitch.generic') },
								]}
								value={isPersonalized}
								size="small"
								onChange={() => goPersonalized(values)}
							/>
						</SubmitContent>

						<SubmitButton
							// htmlType="submit"
							shape="circle"
							icon={<DownOutlined />}
							disabled={!isOnboarding && !palAvgLifestyle}
						/>
					</SubmitWrapper>
				</Form>
			)}
		</Formik>
	)
}

export default PalForm
