import * as yup from 'yup'

import profilesSchema from './profilesSchema'

const accountProfileFormSchema = (t) =>
	yup.object().shape({
		account: profilesSchema(t),
	})

export default accountProfileFormSchema
