// Function HOC/Higher Order Component
// wrapping any component to check if user is authenticated
// and redirect, where appropriate, to the Auth0 login page
// (container, using Auth0 hook):

import React from 'react'

import { withAuthenticationRequired } from '@auth0/auth0-react'

import LoadingView from '../../loading/LoadingView'

const AuthenticationGuard = ({ component }) => {
	const Component = withAuthenticationRequired(component, {
		// Loading route...
		onRedirecting: () => <LoadingView />,
	})

	return <Component />
}

export default AuthenticationGuard
