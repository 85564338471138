// Function component (presentational):

import React from 'react'

import Sider from '../shared/styledComponents/Sider'

import { Layout } from 'antd'

const { Sider: AntSider } = Layout

const DashboardSider = ({ isOpen, renderHeader, renderBody }) => (
	<>
		<AntSider
			// id="dashboard-fake-sider"
			collapsed={!isOpen}
			collapsible
			collapsedWidth={0}
			trigger={null}
			reverseArrow
			width={384}
			style={{ background: 'transparent' }}
		></AntSider>
		<Sider
			// id="dashboard-sider"
			collapsed={!isOpen}
			collapsible
			collapsedWidth={0}
			trigger={null}
			reverseArrow
			// theme="light"
			width={368}
		>
			{renderHeader()}
			{renderBody()}
		</Sider>
	</>
)

export default DashboardSider
