import renderLabel from '../shared/other/renderLabel'

import Icon from '@ant-design/icons'
import { ReactComponent as Scale } from '../../assets/icons/scale.svg'

export const buildWeightItem = (handleClick, t, disabled = false) => ({
	key: 'libra-menu-actions-add-weight',
	icon: <Icon component={Scale} />,
	label: renderLabel({
		label: (
			<span onClick={disabled ? () => null : handleClick}>
				{t('measurement:actions.addBodyMass')}
			</span>
		),
	}),
	disabled,
})
