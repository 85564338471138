import styled from 'styled-components'

import RoundButton from './RoundButton'

const TextRoundButton = styled(RoundButton).attrs((props) => ({
	// Defining static props.
	type: 'text',
}))``

export default TextRoundButton
