// Function component (container, using Redux hooks):

import React from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// i18next hook for localization (via React Context).
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import { dateFormat, liteCalendarL10n, calendarL10n } from '../../utils/dates'

import { toggleCalendar, prevDay, nextDay } from '../../ducks/journal'

import Date from './Date'

const DateContainer = ({ lite = false }) => {
	const { currentDay } = useSelector((state) => state.journal)
	const currentDate = dayjs(currentDay, dateFormat)

	const { t } = useTranslation('l10n')

	const calL10n = () => (lite ? liteCalendarL10n(t) : calendarL10n(t))
	const date = currentDate.calendar(null, calL10n())

	const dispatch = useDispatch()

	return (
		<Date
			date={date}
			showCalendar={() => dispatch(toggleCalendar())}
			prev={() => dispatch(prevDay())}
			next={() => dispatch(nextDay())}
		/>
	)
}

export default DateContainer
