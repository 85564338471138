// Function component (presentational):

import React from 'react'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import FormWrapper from '../shared/styledComponents/form/FormWrapper'

import renderLabel from '../shared/other/renderLabel'

import PalForm from './PalForm'
import TimeUseForm from './TimeUseForm'
import ExertionForm from './ExertionForm'

import { ScheduleOutlined, FireOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import TextCircleButton from '../shared/styledComponents/button/TextCircleButton'

const NewRoutine = ({
	isOnboarding,
	isNewRoutineOpen,
	hideNewRoutine,
	step,
	isPalResponse,
	palLifestyle,
	palAvgLifestyle,
	timeUseReplies,
	withInitialTimeUseReplies,
	exertionReplies,
	withInitialExertionReplies,
	formData,
	goBack,
	goGeneric,
	goPersonalized,
	endSubmit,
	timeUseTitles,
	exertionTitles,
	createQuestionnaireResponse,
	libraStart,
	t,
}) => {
	const renderTitle = () => {
		const key = (() => {
			switch (step) {
				case 0:
					return 'pal'
				case 1:
					return 'timeUse'
				case 2:
					return 'exertion'
				default:
					return ''
			}
		})()

		const isTimeUse = key === 'timeUse'
		const isExertion = key === 'exertion'
		const icon = isTimeUse ? <ScheduleOutlined /> : <FireOutlined />
		const label = isExertion ? (
			<span>
				{t('lifestyle:exertionForm.title')}
				<Tooltip title={t('lifestyle:exertionForm.subtitleTooltip')}>
					{t('lifestyle:exertionForm.subtitle')}
				</Tooltip>
			</span>
		) : (
			t(`lifestyle:${key}Form.title`)
		)
		const extra =
			(key === 'pal' && !isOnboarding && !palAvgLifestyle) ||
			(isTimeUse && !withInitialTimeUseReplies) ||
			(isTimeUse && !isOnboarding && !isPalResponse && !timeUseReplies) ||
			(isExertion && !withInitialExertionReplies) ||
			(isExertion && !isOnboarding && !isPalResponse && !exertionReplies) ? (
				<LoadingOutlined />
			) : isOnboarding ? null : (
				<TextCircleButton icon={<CloseOutlined />} onClick={hideNewRoutine} />
			)

		return renderLabel({ icon, label, extra })
	}

	const isPersonalized = step !== 0

	const renderForm = () => {
		switch (step) {
			case 0:
				return (
					<PalForm
						initialValues={formData}
						isOnboarding={isOnboarding}
						palLifestyle={palLifestyle}
						palAvgLifestyle={palAvgLifestyle}
						isPersonalized={isPersonalized}
						goPersonalized={goPersonalized}
						endSubmit={endSubmit}
						createQuestionnaireResponse={createQuestionnaireResponse}
						libraStart={libraStart}
						t={t}
					/>
				)
			case 1:
				return (
					<TimeUseForm
						initialValues={formData}
						isOnboarding={isOnboarding}
						isPalResponse={isPalResponse}
						timeUseReplies={timeUseReplies}
						isPersonalized={isPersonalized}
						goGeneric={goGeneric}
						endSubmit={endSubmit}
						timeUseTitles={timeUseTitles}
						createQuestionnaireResponse={createQuestionnaireResponse}
						libraStart={libraStart}
						t={t}
					/>
				)
			case 2:
				return (
					<ExertionForm
						initialValues={formData}
						isOnboarding={isOnboarding}
						isPalResponse={isPalResponse}
						timeUseReplies={timeUseReplies}
						exertionReplies={exertionReplies}
						goBack={goBack}
						isPersonalized={isPersonalized}
						goGeneric={goGeneric}
						endSubmit={endSubmit}
						exertionTitles={exertionTitles}
						createQuestionnaireResponse={createQuestionnaireResponse}
						libraStart={libraStart}
						t={t}
					/>
				)
			default:
				return null
		}
	}

	const renderBody = () =>
		(step === 0 ||
			(step === 1 && withInitialTimeUseReplies) ||
			(step === 2 && withInitialExertionReplies)) && <FormWrapper>{renderForm()}</FormWrapper>

	return (
		<DrawerOrModalContainer
			isOpen={isOnboarding || isNewRoutineOpen}
			hide={hideNewRoutine}
			canClose={!isOnboarding}
			renderTitle={renderTitle}
			renderBody={renderBody}
			// onlyModal
			size={'large'}
		/>
	)
}

export default NewRoutine
