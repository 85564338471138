// Function component (stateful & container, using React, Redux, Auth0 & i18next hooks):

// React State & Effect hooks.
import React, { useState, useEffect, useCallback } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'

import { profilableTypes } from '../../utils/yupSchema/profilesSchema'
import { accountableTypes } from '../../utils/yupSchema/accountsSchema'

import { getFullName } from '../../utils/misc'
import { nestify } from '../../utils/api'

import { fetchIpGeolocationApiData } from '../../ducks/apiData'
import { territoriesIndex } from '../../ducks/territories'
import { profilesCheck } from '../../ducks/profiles'
import { userAccountsCreate } from '../../ducks/userAccounts'
import { speakWithSound } from '../../ducks/libra'

import NewAccount from './NewAccount'

const NewAccountStatefulContainer = ({ isFirst, close }) => {
	const [geolocatedCountryCode, setGeolocatedCountryCode] = useState('')

	const apiData = useSelector((state) => state.apiData)

	const { geolocation: { countryCode } = {} } = apiData
	const territories = build(apiData, 'territory')
	const countries = territories?.filter((t) => t.territoryKind === 'country')

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchIpGeolocationApiData())
	}, [dispatch])

	const {
		user: { name },
		getAccessTokenSilently,
	} = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation([
		'account',
		'accounts',
		'profiles',
		'people',
		'motherhoods',
		// 'businessAccounts',
		'validation',
	])

	useEffect(() => {
		const getTerritories = async () => {
			const accessToken = await getAccessTokenSilently()
			dispatch(territoriesIndex(language, accessToken))
		}

		getTerritories()
	}, [getAccessTokenSilently, language, dispatch])

	const shouldSetGeolocatedCountryCode = territories && countryCode && !geolocatedCountryCode
	useEffect(() => {
		if (!shouldSetGeolocatedCountryCode) return

		let geolocatedTerritory = territories.find((t) => t.alpha2Code === countryCode)
		if (geolocatedTerritory.territoryKind === 'subdivision') {
			geolocatedTerritory = geolocatedTerritory.parent
		}

		setGeolocatedCountryCode(geolocatedTerritory.alpha2Code)
	}, [shouldSetGeolocatedCountryCode, territories, countryCode])

	const fullName = getFullName(name)
	const username = fullName.replace(/\s+/g, '.')
	const firstUsername = isFirst ? username : ''
	const firstFullName = isFirst ? fullName : ''

	const initialValues = {
		account: {
			profile: {
				profilable_type: profilableTypes[2],
				username: firstUsername,
				name: firstFullName,
				phone: '',
				countryCode: '',
			},
			accountableType: accountableTypes[0],
			accountable: {
				gender: '',
				birthDate: null,
				motherhoods: [{ dueDate: null }],
			},
		},
	}

	const [formData, setFormData] = useState(initialValues)

	const [step, setStep] = useState(1)
	const nextStep = () => setStep((prev) => prev + 1)
	const prevStep = () => setStep((prev) => prev - 1)

	const goBack = (values) => {
		setFormData(values)
		prevStep()
	}

	const endSubmit = (values) => {
		setFormData(values)
		step === 3 ? close() : nextStep()
	}

	const checkProfile = async (profileAttrs) => {
		const accessToken = await getAccessTokenSilently()
		return dispatch(profilesCheck(language, accessToken, profileAttrs))
	}

	const createUserAccount = async (userAccountAttrs) => {
		const accessToken = await getAccessTokenSilently()

		// Comply with Rails nested attributes.
		const nestedUserAccountAttrs = ['account', 'profile', 'accountable', 'motherhoods']
		const nestifiedUserAccountAttrs = nestify(userAccountAttrs, nestedUserAccountAttrs)

		return dispatch(userAccountsCreate(language, accessToken, nestifiedUserAccountAttrs))
	}

	const libraStart = useCallback(
		(path) => dispatch(speakWithSound({ path, speech: t(path), language })),
		[t, language, dispatch],
	)

	const shouldLibraStart = geolocatedCountryCode && step === 2 && isFirst
	useEffect(() => {
		shouldLibraStart && libraStart('account:profileForm.speech.country')
	}, [shouldLibraStart, libraStart])

	return (
		<NewAccount
			isFirst={isFirst}
			geolocatedCountryCode={geolocatedCountryCode}
			countries={countries}
			formData={formData}
			step={step}
			goBack={goBack}
			endSubmit={endSubmit}
			checkProfile={checkProfile}
			createUserAccount={createUserAccount}
			t={t}
		/>
	)
}

export default NewAccountStatefulContainer
