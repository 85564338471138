import styled from 'styled-components'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'

const StyledLibraMenu = styled(FloatMenu).attrs(() => ({
	// Defining static props.
	$bottom: 0,
	$left: 0,
}))``

export default StyledLibraMenu
