import * as yup from 'yup'

import dayjs from 'dayjs'
import _ from 'lodash'

import { setDateFromTime, midnight, timeFormat } from '../dates'

const minDate = setDateFromTime('00:01')
const maxDate = setDateFromTime('16:00')

const minMinute = 1

export const disabledHours = (maxHour = 15) => _.range(maxHour + 1, 24)
export const disabledMinutes = (selectedHour, maxHour = 15, maxMinute = 59) => {
	if (selectedHour === 0 && maxHour === 0) {
		return [..._.range(0, minMinute), ..._.range(maxMinute + 1, 60)]
	} else if (selectedHour < maxHour) {
		return []
	} else if ((selectedHour = maxHour)) {
		return _.range(maxMinute + 1, 60)
	} else {
		return _.range(0, 60)
	}
}
export const disabledSeconds = (
	selectedHour,
	selectedMinute,
	maxHour = 15,
	maxMinute = 60,
	maxSecond = 60,
) => {
	if (selectedHour < maxHour || (selectedHour === maxHour && selectedMinute < maxMinute)) {
		return []
	} else if (selectedMinute === maxMinute) {
		return _.range(maxSecond + 1, 60)
	} else {
		return _.range(0, 60)
	}
}

export const durationValidation = (t, remainderDuration) => {
	const label = t('activities:attributes.duration.label')

	const max = remainderDuration ? midnight.add(remainderDuration, 's') : maxDate

	const diff = maxDate.diff(midnight)
	const maxDuration = dayjs.duration(diff)
	const duration = remainderDuration ?? maxDuration
	const dailyDuration = duration.format(timeFormat)

	const maxMsg =
		remainderDuration?.asSeconds() === 0
			? t('activities:attributes.duration.validation.maxNoRemainder')
			: remainderDuration
				? t('activities:attributes.duration.validation.maxRemainder', { dailyDuration })
				: t('activities:attributes.duration.validation.max', { dailyDuration })

	return yup
		.date()
		.min(minDate, t('activities:attributes.duration.validation.min'))
		.max(max, maxMsg)
		.nullable()
		.required(t('validation:mixed.required', { label }))
}

// const activitiesSchema = (t) =>
// 	yup.object().shape({
// 		duration: durationValidation(t),
// 	})

// export default activitiesSchema
