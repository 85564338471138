// Function component (presentational):

import React from 'react'

import Layout from './LoadingStyles'

import libradiet from '../../assets/logos/libra.diet-logo.type.png'

const LoadingView = () => (
	<Layout>
		<img src={libradiet} alt="libra diet logotype" height="48px" />
	</Layout>
)

export default LoadingView
