import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

const path = '/body_heights'

export const bodyHeightsIndex =
	(locale, accessToken, filter = {}, page = {}) =>
	(dispatch) => {
		const endpoint = localizePath(locale, path)
		const options = initRequestOptions({ accessToken })
		const params = { filter, page }

		return dispatch(fetchLibraDietApiData({ endpoint, options, params }))
	}
