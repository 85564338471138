// Function component (presentational):

import React from 'react'

import { normalize } from '../../utils/misc'
import { transformInvalidAttrErrors } from '../../utils/api'

import { Formik } from 'formik'
import { Form, Input, Select, SubmitButton } from 'formik-antd'

import accountProfileFormSchema from '../../utils/yupSchema/accountProfileFormSchema'

import FormItem from '../shared/styledComponents/form/FormItem'

import renderLabel, { renderNameLabel } from '../shared/other/renderLabel'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'

import {
	PhoneOutlined,
	GlobalOutlined,
	InfoCircleOutlined,
	UpOutlined,
	DownOutlined,
} from '@ant-design/icons'
import { Button } from 'antd'

const ProfileForm = ({
	initialValues,
	geolocatedCountryCode,
	countries,
	goBack,
	checkProfile,
	endSubmit,
	t,
}) => {
	const handleSubmit = async (values, { setErrors }) => {
		try {
			const {
				account: { profile: profileAttrs },
			} = values

			const { response } = await checkProfile(profileAttrs)

			if (response) {
				endSubmit(values)
			}
		} catch (e) {
			const errors = transformInvalidAttrErrors(e.errors)
			const profileErrors = { account: { profile: errors } }

			// Async (server-side) validation.
			setErrors(profileErrors)
		}
	}

	const countriesOptions = []

	const anyCountries = countries?.length

	const buildCountryOption = ({ alpha2Code: value, name: label }) => ({ value, label })
	const buildCountryOptions = () =>
		countries.sort((a, b) => a.name.localeCompare(b.name)).map((c) => buildCountryOption(c))
	anyCountries && countriesOptions.push(...buildCountryOptions())

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={accountProfileFormSchema(t)}
			onSubmit={handleSubmit}
		>
			{({
				values,
				values: {
					account: {
						profile: { countryCode },
					},
				},
				setFieldValue,
			}) => {
				if (countryCode === '' && geolocatedCountryCode) {
					setFieldValue('account.profile.countryCode', geolocatedCountryCode)
				}

				return (
					<Form
						// id="account-profile-form"
						layout="vertical"
						colon={false}
						requiredMark="optional"
					>
						<FormItem
							name="account.profile.username"
							required
							hasFeedback={false}
							label={renderNameLabel(t('profiles:attributes.username.label'))}
						>
							<Input
								name="account.profile.username"
								placeholder={t('profiles:attributes.username.placeholder')}
							/>
						</FormItem>

						<FormItem
							name="account.profile.name"
							// required
							hasFeedback
							label={renderNameLabel(t('profiles:attributes.name.label'))}
						>
							<Input
								name="account.profile.name"
								placeholder={t('profiles:attributes.name.placeholder')}
								// allowClear
							/>
						</FormItem>

						<FormItem
							name="account.profile.phone"
							hasFeedback={false}
							label={renderLabel({
								icon: <PhoneOutlined />,
								label: t('profiles:attributes.phone.label'),
							})}
							tooltip={{
								icon: <InfoCircleOutlined />,
								title: t('profiles:attributes.phone.tooltip'),
							}}
						>
							<Input
								name="account.profile.phone"
								placeholder={t('profiles:attributes.phone.placeholder')}
								allowClear
							/>
						</FormItem>

						<FormItem
							name="account.profile.countryCode"
							label={renderLabel({
								icon: <GlobalOutlined />,
								label: t('profiles:attributes.country.label'),
							})}
							tooltip={{
								icon: <InfoCircleOutlined />,
								title: t('profiles:attributes.country.tooltip'),
							}}
						>
							<Select
								name="account.profile.countryCode"
								placeholder={t(
									`profiles:attributes.country.${!countries ? 'loading' : 'placeholder'}`,
								)}
								loading={!countries}
								options={countriesOptions}
								showSearch
								filterOption={(input, option) =>
									normalize(option?.label ?? '').includes(normalize(input))
								}
								allowClear
								variant="filled"
							/>
						</FormItem>

						<SubmitWrapper>
							<Button shape="circle" icon={<UpOutlined />} onClick={() => goBack(values)} />

							<SubmitButton
								// htmlType="submit"
								shape="circle"
								icon={<DownOutlined />}
								disabled={!countries}
							/>
						</SubmitWrapper>
					</Form>
				)
			}}
		</Formik>
	)
}

export default ProfileForm
