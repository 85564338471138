import * as yup from 'yup'

import logsSchema from './logsSchema'
import bodyMassesSchema from './bodyMassesSchema'
import bodyMassIndicesSchema from './bodyMassIndicesSchema'

const bodyMassFormSchema = (t, birthDate) =>
	yup.object().shape({
		log: logsSchema(t, birthDate, false),
		bodyMass: bodyMassesSchema(t),
		bodyMassIndex: bodyMassIndicesSchema(t),
	})

export default bodyMassFormSchema
