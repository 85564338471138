import { remark } from 'remark'
import strip from 'strip-markdown'

import { fetchAmazonPollySynthesizeSpeechApiData } from './apiData'
import { sound } from './libra'

export const synthesizeSpeech =
	(text, language, withMarkdown = false) =>
	async (dispatch) => {
		let languageCode = 'en-US'
		let voiceId = 'Salli'
		if (language.slice(0, 2) === 'fr') {
			languageCode = 'fr-FR'
			voiceId = 'Lea'
		}

		let cleanText = text

		if (withMarkdown) {
			const { value } = await remark().use(strip).process(text)

			if (value) {
				cleanText = value.replaceAll('\\. ', '. ')
			}
		}

		cleanText = cleanText
			// Escape ALL ampersands (reserved character in SSML).
			.replaceAll('&', '&amp;')
			// Replace ALL single quotes.
			.replace(/[‘’]/g, "'")
			// Replace ALL dashes.
			.replace(/[—–]/g, '-')
			// Remove ALL emojis.
			.replace(
				/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
				'',
			)
			// Remove ALL tags.
			.replace(/(<([^>]+)>)/g, '')
			// Remove gender neutrality signs.
			.replace(/·([^\s,.]+)\s/g, ' ')
			.replace(/·([^\s,.]+),/g, ',')
			.replace(/·([^\s,.]+)./g, '.')

		const params = {
			Engine: 'neural',
			LanguageCode: languageCode,
			// LexiconNames: [''],
			OutputFormat: 'mp3',
			// SampleRate: '24000',
			// SpeechMarkTypes: [''],
			Text: `<speak>${cleanText}</speak>`,
			TextType: 'ssml',
			VoiceId: voiceId,
		}

		try {
			const { response } = await dispatch(fetchAmazonPollySynthesizeSpeechApiData(params))

			if (response) {
				const url = response.speechSynthesis

				dispatch(sound({ voiceSrc: url }))
			}
		} catch (e) {
			console.log('Amazon Polly Synthesize Speech Errors', e.errors)
		}
	}
