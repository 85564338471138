// Function component (presentational):

import React from 'react'

import PageTitle from '../shared/styledComponents/page/PageTitle'
import SectionWrapper from '../shared/styledComponents/page/SectionWrapper'
import SectionTitle from '../shared/styledComponents/page/SectionTitle'
import ContentWrapper from '../shared/styledComponents/page/ContentWrapper'

import Title from '../shared/styledComponents/text/Title'

import Card from '../shared/styledComponents/card/Card'

import renderLabel, { renderCheckLabel } from '../shared/other/renderLabel'

import ReferralForm from './ReferralForm'

import access from '../../assets/illustrations/shared/access-key.png'
import perks from '../../assets/illustrations/referral/perks.png'
import sharing from '../../assets/illustrations/referral/share-love.png'

import {
	LinkOutlined,
	FieldNumberOutlined,
	NumberOutlined,
	ClearOutlined,
	RightOutlined,
} from '@ant-design/icons'
import { Col, Row, Statistic, Typography } from 'antd'
import BlockRoundButton from '../shared/styledComponents/button/BlockRoundButton'

const { Text } = Typography

const Referral = ({
	initialValues,
	setSearchParams,
	shareKey,
	rank,
	referralsCount,
	foundSubscriber,
	verifiedSubscriber,
	t,
	pt,
	// isLaptop,
}) => (
	<>
		<PageTitle>
			<Title>{t('referral:page.title')}</Title>
		</PageTitle>

		{!foundSubscriber && (
			<SectionWrapper
			// id="referral-form-wrapper"
			>
				<SectionTitle>
					<Title level={3}>{t('referral:form.title')}</Title>
				</SectionTitle>

				<ContentWrapper style={{ padding: 16 }}>
					<ReferralForm initialValues={initialValues} setSearchParams={setSearchParams} t={t} />
				</ContentWrapper>
			</SectionWrapper>
		)}

		{foundSubscriber && !verifiedSubscriber && (
			<SectionWrapper
			// id="not-verified-wrapper"
			>
				<SectionTitle>
					<Title level={3}>{t('referral:notVerified.title')}</Title>
				</SectionTitle>
			</SectionWrapper>
		)}

		{verifiedSubscriber && (
			<SectionWrapper
				// id="referral-stats-wrapper"
				$type="cards"
			>
				<SectionTitle>
					<Title level={3}>{t('referral:stats.title')}</Title>
				</SectionTitle>

				<ContentWrapper>
					<Card>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '16px',
								marginBottom: '16px',
							}}
						>
							{renderLabel({ icon: <LinkOutlined />, label: t('referral:stats.details.link') })}
							<div
								style={{
									border: '1px solid #d9d9d9',
									borderRadius: '32px',
									lineHeight: '32px',
									padding: '0 8px',
								}}
							>
								<Text
									copyable
									ellipsis={{ rows: 1 }}
									style={{ marginBottom: 0 }}
								>{`https://www.libra.diet/subscription?referrerKey=${shareKey}`}</Text>
							</div>
						</div>

						<Row gutter={16}>
							<Col span={12}>
								<Statistic
									title={t('referral:stats.details.rank')}
									value={rank}
									prefix={<FieldNumberOutlined />}
								/>
							</Col>
							<Col span={12}>
								<Statistic
									title={t('referral:stats.details.count')}
									value={referralsCount}
									prefix={<NumberOutlined />}
								/>
							</Col>
						</Row>
					</Card>
				</ContentWrapper>
			</SectionWrapper>
		)}

		{foundSubscriber && (
			<SectionWrapper
			// id="referral-reset-wrapper"
			>
				<ContentWrapper>
					<BlockRoundButton onClick={() => setSearchParams()}>
						{renderLabel({
							icon: <ClearOutlined />,
							label: t('referral:reset.action'),
							extra: <RightOutlined />,
						})}
					</BlockRoundButton>
				</ContentWrapper>
			</SectionWrapper>
		)}

		<SectionWrapper
			// id="referral-benefits-wrapper"
			$type="cards"
		>
			<SectionTitle>
				<Title level={3}>{t('referral:benefits.title')}</Title>
			</SectionTitle>

			<ContentWrapper>
				<Card
					cover={
						<img
							src={access}
							alt={t('referral:benefits.access.illustration')}
							width="100%"
							height="100%"
						/>
					}
				>
					<Card.Meta title={renderCheckLabel(t('referral:benefits.access.title'))} />

					<div style={{ padding: '5px 8px' }}>
						<Text>{pt('referral:benefits.access.description')}</Text>
					</div>
				</Card>

				<Card
					cover={
						<img
							src={perks}
							alt={t('referral:benefits.perks.illustration')}
							width="100%"
							height="100%"
						/>
					}
				>
					<Card.Meta title={renderCheckLabel(t('referral:benefits.perks.title'))} />

					<div style={{ padding: '5px 8px' }}>
						<Text>{pt('referral:benefits.perks.description')}</Text>
					</div>
				</Card>

				<Card
					cover={
						<img
							src={sharing}
							alt={t('referral:benefits.sharing.illustration')}
							width="100%"
							height="100%"
						/>
					}
				>
					<Card.Meta title={renderCheckLabel(t('referral:benefits.sharing.title'))} />

					<div style={{ padding: '5px 8px' }}>
						<Text>{pt('referral:benefits.sharing.description')}</Text>
					</div>
				</Card>
			</ContentWrapper>
		</SectionWrapper>
	</>
)

export default Referral
