import * as yup from 'yup'

import { convertKgToLb } from '../numbers'
import { mapToCamelCase, joinWithQuotesAndAmpersand } from '../misc'

// Enduser maximum body mass value in kg.
export const maxValue = 635
// Enduser maximum body mass value in lb.
export const maxValueInLb = convertKgToLb(maxValue)

const valueValidation = (t) => {
	const label = t('bodyMasses:attributes.value.label')

	return (
		yup
			.number()
			.positive(t('validation:number.positive', { label }))
			.max(maxValue, t('bodyMasses:attributes.value.validation.max', { value: maxValue }))
			.nullable()
			// .typeError(t('validation:number.typeError', { label }))
			.required(t('validation:mixed.required', { label }))
	)
}

const valueInLbValidation = (t) => {
	const label = t('bodyMasses:attributes.valueInLb.label')

	return (
		yup
			.number()
			.positive(t('validation:number.positive', { label }))
			.max(
				maxValueInLb,
				t('bodyMasses:attributes.valueInLb.validation.max', { value: maxValueInLb }),
			)
			.nullable()
			// .typeError(t('validation:number.typeError', { label }))
			.required(t('validation:mixed.required', { label }))
	)
}

const desiredValueValidation = (t) => {
	const label = t('bodyMasses:attributes.desiredValue.label')

	return yup
		.number()
		.positive(t('validation:number.positive', { label }))
		.max(maxValue, t('bodyMasses:attributes.desiredValue.validation.max', { value: maxValue }))
		.nullable()
		.typeError(t('validation:number.typeError', { label }))
	// .required(t('validation:mixed.required', { label }))
}

const desiredValueInLbValidation = (t) => {
	const label = t('bodyMasses:attributes.desiredValueInLb.label')

	return yup
		.number()
		.positive(t('validation:number.positive', { label }))
		.max(
			maxValueInLb,
			t('bodyMasses:attributes.desiredValueInLb.validation.max', { value: maxValueInLb }),
		)
		.nullable()
		.typeError(t('validation:number.typeError', { label }))
	// .required(t('validation:mixed.required', { label }))
}

export const digestions = ['fasting', 'between_meals', 'after_meal']
export const digestionKinds = mapToCamelCase(digestions)

export const digestionValidation = (t) => {
	const label = t('bodyMasses:attributes.digestion.label')
	const valuesLabels = digestionKinds.map((v) => t(`bodyMasses:attributes.digestions.${v}.label`))
	const valuesLabelsList = joinWithQuotesAndAmpersand(valuesLabels)

	return yup
		.mixed()
		.oneOf(digestions, t('validation:mixed.oneOf', { label, values: valuesLabelsList }))
	// .required(t('validation:mixed.required', { label }))
}

export const hydrations = ['normal_hydration', 'dehydration']
export const hydrationKinds = mapToCamelCase(hydrations)

export const hydrationValidation = (t) => {
	const label = t('bodyMasses:attributes.hydration.label')
	const valuesLabels = hydrationKinds.map((v) => t(`bodyMasses:attributes.hydrations.${v}.label`))
	const valuesLabelsList = joinWithQuotesAndAmpersand(valuesLabels)

	return yup
		.mixed()
		.oneOf(hydrations, t('validation:mixed.oneOf', { label, values: valuesLabelsList }))
	// .required(t('validation:mixed.required', { label }))
}

export const excretions = [
	'after_urination_and_defecation',
	'after_urination',
	'no_recent_excretion',
]
export const excretionKinds = mapToCamelCase(excretions)

export const excretionValidation = (t) => {
	const label = t('bodyMasses:attributes.excretion.label')
	const valuesLabels = excretionKinds.map((v) => t(`bodyMasses:attributes.excretions.${v}.label`))
	const valuesLabelsList = joinWithQuotesAndAmpersand(valuesLabels)

	return yup
		.mixed()
		.oneOf(excretions, t('validation:mixed.oneOf', { label, values: valuesLabelsList }))
	// .required(t('validation:mixed.required', { label }))
}

export const exertions = ['before', 'after']

export const exertionValidation = (t) => {
	const label = t('bodyMasses:attributes.exertion.label')
	const valuesLabels = exertions.map((v) => t(`bodyMasses:attributes.exertions.${v}.label`))
	const valuesLabelsList = joinWithQuotesAndAmpersand(valuesLabels)

	return yup
		.mixed()
		.oneOf(exertions, t('validation:mixed.oneOf', { label, values: valuesLabelsList }))
	// .required(t('validation:mixed.required', { label }))
}

const bodyMassesSchema = (t) =>
	yup.object().shape({
		value: valueValidation(t),
		valueInLb: valueInLbValidation(t),
		digestion: digestionValidation(t),
		hydration: hydrationValidation(t),
		excretion: excretionValidation(t),
		exertion: exertionValidation(t),
	})

export const desiredBodyMassesSchema = (t) =>
	yup.object().shape({
		value: desiredValueValidation(t),
		valueInLb: desiredValueInLbValidation(t),
	})

export default bodyMassesSchema
