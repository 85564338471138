// Function component (stateful & container, using React, Redux, React Router, Auth0, i18next & React Responsive hooks):

// React Effect hooks.
import React, { useEffect, useCallback } from 'react'
// Redux hook to dispatch actions.
import { useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization (via React Context).
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
// import { useMediaQuery } from 'react-responsive'

import _ from 'lodash'

import { getFirstName } from '../../utils/misc'

import { speakWithSound } from '../../ducks/libra'

// import deviceMinWidth from '../../styles/deviceMinWidth'

import Onboarding from './Onboarding'

const OnboardingStatefulContainer = ({ isFirst, step, nextStep }) => {
	const dispatch = useDispatch()

	const {
		user: { given_name: givenName, name },
	} = useAuth0()
	const firstName = _.capitalize(givenName || getFirstName(name))

	const {
		i18n: { language },
		t,
	} = useTranslation(['onboarding'])

	const libraStart = useCallback(
		(path, l10nKeys = {}, speech = '', opts = {}) =>
			dispatch(speakWithSound({ path, speech: speech || t(path, l10nKeys), ...opts, language })),
		[t, language, dispatch],
	)

	// const isLaptop = useMediaQuery({
	// 	// query: '(min-width: 1024px)'
	// 	query: `${deviceMinWidth.laptop}`,
	// })

	const shouldLibraIntro = step === 1
	useEffect(() => {
		shouldLibraIntro &&
			libraStart(`onboarding:${isFirst ? 'welcome' : 'new'}.speech.1`, { firstName }, '', {
				sequenceType: 'ordered',
				sequenceEnd: 4,
			})
	}, [shouldLibraIntro, isFirst, firstName, libraStart])

	const shouldLibraOutro = step === 10
	useEffect(() => {
		shouldLibraOutro &&
			libraStart('onboarding:completion.speech.1', null, '', {
				sequenceType: 'ordered',
				sequenceEnd: 2,
			})
	}, [shouldLibraOutro, isFirst, firstName, libraStart])

	return (
		<Onboarding
			isFirst={isFirst}
			step={step}
			nextStep={nextStep}
			firstName={firstName}
			libraStart={libraStart}
			t={t}
			// isLaptop={isLaptop}
		/>
	)
}

export default OnboardingStatefulContainer
