import dayjs from 'dayjs'

export const timeFormat = 'HH:mm'

export const getDatesBetween = (startDate, endDate) => {
	let now = startDate,
		dates = []

	while (now.isSameOrBefore(endDate)) {
		dates.push(now)
		// Contrary to momentjs, dayjs is immutable.
		now = now.add(1, 'd')
	}

	return dates
}

export const dateFormat = 'YYYY-MM-DD'

export const today = dayjs().format(dateFormat)

export const midnight = dayjs().startOf('d')

export const setDateFromTime = (hms) => {
	const tokens = hms.split(':').map((t) => +t)
	const h = tokens[0] ? tokens[0] : 0
	const m = tokens[1] ? tokens[1] : 0
	const s = tokens[2] ? tokens[2] : 0

	return midnight.set('h', h).set('m', m).set('s', s)
}

export const calendarL10n = (t) => ({
	sameDay: t('l10n:calendar.sameDay'),
	nextDay: t('l10n:calendar.nextDay'),
	nextWeek: 'dddd',
	lastDay: t('l10n:calendar.lastDay'),
	lastWeek: t('l10n:calendar.lastWeek'),
	sameElse: t('l10n:calendar.sameElse'),
})

export const liteCalendarL10n = (t) => ({
	sameDay: t('l10n:liteCalendar.sameDay'),
	nextDay: t('l10n:liteCalendar.nextDay'),
	nextWeek: 'ddd',
	lastDay: t('l10n:liteCalendar.lastDay'),
	lastWeek: t('l10n:liteCalendar.lastWeek'),
	sameElse: t('l10n:liteCalendar.sameElse'),
})
