import styled from 'styled-components'

import { Layout } from 'antd'

const LayoutWrapper = styled(Layout)`
	padding-top: env(safe-area-inset-top);
	padding-bottom: env(safe-area-inset-bottom);
`

export default LayoutWrapper
