// Function component (presentational):

import React from 'react'

import Drawer from './Drawer'
import Modal from './Modal'

const DrawerOrModal = ({
	id,
	isOpen,
	mask,
	canClose,
	hide,
	renderTitle,
	renderBody,
	onlyDrawer,
	onlyModal,
	placement,
	side,
	fromTop,
	fromBottom,
	staysRight,
	size,
	isMobileXL,
	isTablet,
	withSideDash,
	isLaptop,
	isLaptopML,
}) =>
	onlyDrawer || (!onlyModal && !isLaptop) ? (
		<Drawer
			isOpen={isOpen}
			canClose={canClose}
			handleClose={hide}
			renderTitle={renderTitle}
			renderBody={renderBody}
			placement={placement}
			side={side}
			size={size}
			isTablet={isTablet}
			isLaptopML={isLaptopML}
			withSideDash={withSideDash}
		/>
	) : (
		<Modal
			id={id}
			isOpen={isOpen}
			mask={mask}
			canClose={canClose}
			handleCancel={hide}
			renderTitle={renderTitle}
			renderBody={renderBody}
			fromTop={fromTop}
			fromBottom={fromBottom}
			placement={placement}
			staysRight={staysRight}
			side={side}
			size={size}
			isTablet={isTablet}
			isMobileXL={isMobileXL}
			withSideDash={withSideDash}
			isLaptopML={isLaptopML}
		/>
	)

export default DrawerOrModal
