import React, { Suspense } from 'react'
// Replaces ReactDOM.createRoot.
import { createRoot } from 'react-dom/client'

// Import i18n (needs to be bundled).
import './contexts/i18n/i18n'
import { Provider } from 'react-redux'
import store from './store/store'
import { BrowserRouter as Router } from 'react-router-dom'
import Auth0Provider from './contexts/auth/auth'
import CableProvider from './contexts/cable/cable'
import L10n from './contexts/l10n/l10n'
import Theme from './contexts/theme/theme'
import { App as AntApp } from 'antd'

import LoadingView from './components/loading/LoadingView'
import App from './components/app/App'

import reportWebVitals from './reportWebVitals'

// As of React 18.
const root = createRoot(document.getElementById('root'))

root.render(
	// Trigger React’s Suspense if translations are still being loaded by the `i18next-http-backend`.
	<Suspense fallback={<LoadingView />}>
		<Provider store={store}>
			<Router>
				<Auth0Provider>
					<CableProvider>
						<Theme>
							<L10n>
								<AntApp>
									<App />
								</AntApp>
							</L10n>
						</Theme>
					</CableProvider>
				</Auth0Provider>
			</Router>
		</Provider>
	</Suspense>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
