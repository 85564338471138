import styled from 'styled-components'

// import { InputNumber } from 'formik-antd'
import { InputNumber } from 'antd'

const StyledInputNumber = styled(InputNumber)`
	width: 100%;

	.ant-input-number-handler-wrap {
		width: 32px;
	}
`

export default StyledInputNumber

export const inputNumberStyle = { width: '100%' }
