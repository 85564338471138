// Function component (presentational):

import React from 'react'

import NavAppMenu from './NavAppMenu'

import StyledNavApp from './NavAppStyles'

const NavApp = ({ withNavApp, sleekNav, navKey, isHub, withSideDash, navigate, t, isTabletL }) => (
	<StyledNavApp
	// id="nav-app"
	>
		{withNavApp && (
			<NavAppMenu
				sleekNav={sleekNav}
				isHub={isHub}
				withSideDash={withSideDash}
				navKey={navKey}
				navigate={navigate}
				t={t}
				isTabletL={isTabletL}
			/>
		)}
	</StyledNavApp>
)

export default NavApp
