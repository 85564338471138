import styled from 'styled-components'

import { mobileMLNavMenuWidth } from '../../../../styles/sizes'

import Wrapper from './Wrapper'

const ContentWrapper = styled(Wrapper)`
	max-width: ${mobileMLNavMenuWidth};

	flex-direction: column;
	justify-content: flex-end;
	flex-grow: 1;
	gap: 16px;

	margin-right: auto;
	margin-left: auto;

	// padding: 16px;
`

export default ContentWrapper
