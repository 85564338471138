import * as yup from 'yup'

import { startDateValidation } from './eventsSchema'
import dishesSchema from './dishesSchema'

const mealEventFormSchema = (t, birthDate) =>
	yup.object().shape({
		startDate: startDateValidation(t, birthDate),
		schedulable: yup.object().shape({
			dishes: yup.array().of(dishesSchema(t)),
		}),
	})

export default mealEventFormSchema
