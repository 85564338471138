export const targetNCCategories = [
	'proximate',
	'availableCarb',
	'fattyAcid',
	'vitamin',
	'sterol',
	'aminoAcid',
	'mineral',
]

export const nutritionalComponentCategories = [
	'proximate',
	'availableCarb',
	'fibre',
	'polyol',
	'organicAcid',
	'fattyAcid',
	'vitamin',
	'sterol',
	'aminoAcid',
	'biogenicAmine',
	'mineral',
	'other',
]
