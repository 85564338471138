// Function component (container, using React, Redux, React Router, Auth0 & i18next hooks):

// React Effect hooks.
import React, { useEffect, useCallback } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hook for routing.
import { useMatch, useLocation, useNavigate } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import build from 'redux-object'

import { today } from '../../utils/dates'

import { moodQuestionnaireId } from '../../utils/mood'
import { dateFormat } from '../../utils/dates'
import { nestify } from '../../utils/api'
import { prefixPath } from '../../utils/l10n'

import { setChecked, toggleQuickMood, toggleNewMood } from '../../ducks/mood'
import { selectDay } from '../../ducks/journal'
import { speakWithSound } from '../../ducks/libra'
import { questionnaireResponsesCreate } from '../../ducks/questionnaireResponses'

import NewMood from './NewMood'

const NewMoodContainer = ({ fromLibra, close }) => {
	const { isNewMoodOpen, isChecked, isQuickAction } = useSelector((state) => state.mood)

	const dispatch = useDispatch()

	const shouldSetChecked = (fromLibra || isNewMoodOpen) && !isChecked
	useEffect(() => {
		shouldSetChecked && dispatch(setChecked())
	}, [shouldSetChecked, dispatch])

	const { user: { sub: auth0Uid } = {}, getAccessTokenSilently } = useAuth0()

	const apiData = useSelector((state) => state.apiData)

	// const user = auth0Uid && build(apiData, 'user', auth0Uid)
	const users = build(apiData, 'user')
	const user = users?.find((u) => u.auth0Uid === auth0Uid)
	const visitDays = user?.visitDates
	const lastVisitDay = visitDays?.[0]
	const lastVisitDate = lastVisitDay && dayjs(lastVisitDay, dateFormat)
	const secToLastVisitDay = visitDays?.[1]
	const secToLastVisitDate = secToLastVisitDay && dayjs(secToLastVisitDay, dateFormat)
	const isFirstDailyVisit =
		visitDays?.length && (!secToLastVisitDate || !lastVisitDate.isSame(secToLastVisitDate, 'd'))

	const { currentAccountId } = useSelector((state) => state.account)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const isPrimaryAccount = currentAccountId && currentAccountId === user?.account?.id
	const name = currentAccount?.profile?.name
	const birthDay = currentAccount?.accountable?.birthDate
	const birthDate = birthDay && dayjs(birthDay)

	let { params: { navKey = '' } = {} } = useMatch('/:locale/:navKey') || {}
	const inOnboarding = navKey === 'onboarding'

	const { currentJournalId: journalId, currentDay } = useSelector((state) => state.journal)
	const currentDate = dayjs(currentDay, dateFormat)
	const isToday = dayjs(currentDate).isToday()

	const qRs = build(apiData, 'questionnaireResponse')
	const moods = qRs?.filter(
		(qr) => qr.log.journal?.id === journalId && qr.questionnaire.id === moodQuestionnaireId,
	)
	const currentMoodResponse = moods?.find((qr) => qr.log.date === currentDay)

	const isOnboarding = close ? true : false

	const initialValues = {
		questionnaireId: moodQuestionnaireId,
		log: { journalId, date: isOnboarding ? today : currentDay },
		replies: [{ questionId: 23, kind: 'rating', value: 6 }],
	}

	const {
		i18n: { language },
		t,
	} = useTranslation(['mood', 'replies', 'logs', 'physicalActivityLevels'])

	const libraStart = useCallback(
		(path, l10nKeys = {}, opts = {}) =>
			dispatch(speakWithSound({ path, speech: t(path, l10nKeys), ...opts, language })),
		[t, language, dispatch],
	)

	const shouldLibraStart =
		isFirstDailyVisit && isPrimaryAccount && !inOnboarding && !currentMoodResponse && !isChecked
	useEffect(() => {
		shouldLibraStart && libraStart('account:accounts.speech.moodCheck', { name })
	}, [shouldLibraStart, name, libraStart])

	const createQuestionnaireResponse = async (questionnaireResponseAttrs) => {
		const accessToken = await getAccessTokenSilently()

		// Comply with Rails nested attributes.
		const nestedQuestionnaireResponseAttrs = ['log', 'replies']
		const nestifiedQuestionnaireResponseAttrs = nestify(
			questionnaireResponseAttrs,
			nestedQuestionnaireResponseAttrs,
		)

		return dispatch(
			questionnaireResponsesCreate(language, accessToken, nestifiedQuestionnaireResponseAttrs),
		)
	}

	const hideNewMood = () => dispatch(isQuickAction ? toggleQuickMood() : toggleNewMood())

	let { pathname, search } = useLocation()
	const currentPath = `${pathname}${search}`

	const navigate = useNavigate()

	const navToProfileMoods = () => {
		const newPath = prefixPath('/profile?section=moods', language)

		currentPath !== newPath && navigate(newPath)
	}

	const endSubmit = () => {
		if (isOnboarding) return close()

		!fromLibra && hideNewMood()
		navToProfileMoods()
	}

	return (
		<NewMood
			isOnboarding={isOnboarding}
			fromLibra={fromLibra}
			isNewMoodOpen={isNewMoodOpen}
			isQuickAction={isQuickAction}
			birthDate={birthDate}
			isToday={isToday}
			selectDay={(d) => dispatch(selectDay(d))}
			hideNewMood={hideNewMood}
			initialValues={initialValues}
			endSubmit={endSubmit}
			createQuestionnaireResponse={createQuestionnaireResponse}
			libraStart={libraStart}
			t={t}
		/>
	)
}

export default NewMoodContainer
