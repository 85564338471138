// Function component (stateful & container, using React, Redux, Auth0 & i18next hooks):

// React State hook.
import React, { useState } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'
import dayjs from 'dayjs'

import { today } from '../../utils/dates'

import { accountableTypes } from '../../utils/yupSchema/accountsSchema'
import {
	digestions,
	hydrations,
	excretions,
	exertions,
} from '../../utils/yupSchema/bodyMassesSchema'
import { sleepings } from '../../utils/yupSchema/bodyHeightsSchema'
import { objectiveKinds } from '../../utils/yupSchema/objectivesSchema'

import { nestify } from '../../utils/api'

import { bodyMeasurementsCreate } from '../../ducks/bodyMeasurements'
import { objectivesCreate } from '../../ducks/objectives'
import { speakWithSound } from '../../ducks/libra'

import FirstMeasurement from './FirstMeasurement'

const FirstMeasurementStatefulContainer = ({ close }) => {
	const { currentJournalId: journalId } = useSelector((state) => state.journal)

	const { currentAccountId, currentAccountableType } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const person = currentAccountableType === accountableTypes[0] && currentAccount.accountable
	const personId = person?.id
	const birthDay = person?.birthDate
	const birthDate = birthDay && dayjs(birthDay)

	const {
		i18n: { language },
		t,
	} = useTranslation([
		'measurement',
		// 'bodyMeasurements',
		// 'logs',
		'bodyMasses',
		'bodyHeights',
		'bodyMassIndices',
		'objective',
		'objectives',
		'validation',
	])

	const initialValues = {
		bodyMeasurement: {
			log: { journalId, date: today },
			bodyMass: {
				value: '',
				valueInLb: '',
				digestion: digestions[0],
				hydration: hydrations[0],
				excretion: excretions[0],
				exertion: exertions[0],
			},
			bodyHeight: {
				value: '',
				valueInFt: '',
				sleeping: sleepings[0],
			},
			bodyMassIndex: { value: '' },
		},
		objective: {
			personId,
			objectiveKind: objectiveKinds[0],
			startDate: today,
			bodyMeasurement: { bodyMass: { value: '', valueInLb: '' } },
			endDate: null,
		},
	}

	const [formData, setFormData] = useState(initialValues)

	const [step, setStep] = useState(1)
	const nextStep = () => setStep((prev) => prev + 1)

	const endSubmit = (values) => {
		setFormData(values)
		step === 2 ? close() : nextStep()
	}

	const { getAccessTokenSilently } = useAuth0()

	const dispatch = useDispatch()

	const createBodyMeasurement = async (bodyMeasurementAttrs) => {
		const accessToken = await getAccessTokenSilently()

		// Comply with Rails nested attributes.
		const nestedBodyMeasurementAttrs = ['log', 'bodyMass', 'bodyHeight']
		const nestifiedBodyMeasurementAttrs = nestify(bodyMeasurementAttrs, nestedBodyMeasurementAttrs)

		return dispatch(bodyMeasurementsCreate(language, accessToken, nestifiedBodyMeasurementAttrs))
	}

	const createObjective = async (objectiveAttrs) => {
		const accessToken = await getAccessTokenSilently()

		// Comply with Rails nested attributes.
		const nestedObjectiveAttrs = ['bodyMeasurement', 'bodyMass']
		const nestifiedObjectiveAttrs = nestify(objectiveAttrs, nestedObjectiveAttrs)

		return dispatch(objectivesCreate(language, accessToken, nestifiedObjectiveAttrs))
	}

	const libraStart = (path, l10nKeys = {}, speech = '') =>
		dispatch(speakWithSound({ path, speech: speech || t(path, l10nKeys), language }))

	const { isSI } = useSelector((state) => state.measurement)

	return (
		<FirstMeasurement
			birthDate={birthDate}
			formData={formData}
			step={step}
			endSubmit={endSubmit}
			createBodyMeasurement={createBodyMeasurement}
			createObjective={createObjective}
			libraStart={libraStart}
			isSI={isSI}
			language={language}
			t={t}
		/>
	)
}

export default FirstMeasurementStatefulContainer
