// Function component to prefix locale to Link pathname
// (container, using i18next hook):

import React from 'react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'

import { l10nTo } from '../../../utils/l10n'

const L10nLink = ({ to, children, ...rest }) => {
	const {
		i18n: { language },
	} = useTranslation()

	return (
		<Link to={l10nTo(to, language)} {...rest} style={{ display: 'flex', color: 'unset' }}>
			{children}
		</Link>
	)
}

export default L10nLink
