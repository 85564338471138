// Function component (presentational):

import React, { Suspense } from 'react'

import PageIllustration from '../shared/styledComponents/page/PageIllustration'
import PageAction from '../shared/styledComponents/page/PageAction'

import Card from '../shared/styledComponents/card/Card'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'
import NavButton from '../shared/styledComponents/button/NavButton'

import renderLabel from '../shared/other/renderLabel'
import renderSkeletonCard from '../shared/other/renderSkeletonCard'

import Tabs from '../shared/styledComponents/tabs/Tabs'
import TabPaneWrapper from '../shared/styledComponents/tabs/TabPaneWrapper'

import List from '../shared/styledComponents/list/List'

import L10nLink from '../shared/navigation/L10nLink'

import NutritionFactsContainer from '../nutrition/NutritionFactsStatefulContainer'

import pattern from '../../assets/patterns/abstract-1.jpg'

import Icon, {
	LoadingOutlined,
	PlusOutlined,
	HeartOutlined,
	ProfileOutlined,
	NodeCollapseOutlined,
	EyeOutlined,
	UnorderedListOutlined,
	WarningOutlined,
} from '@ant-design/icons'
import { ReactComponent as Nutrition } from '../../assets/icons/water-drop.svg'
import { ReactComponent as Liquid } from '../../assets/icons/water-glass.svg'
import { ReactComponent as Solid } from '../../assets/icons/fork-and-knife.svg'
import { Avatar, Image, Button, Descriptions, Statistic, Tooltip, Typography } from 'antd'

const { Link, Text } = Typography

const FoodItem = ({
	section,
	handleSectionChange,
	isAuthenticated,
	foodItem,
	handleClick,
	t,
	// isLaptop,
	isDarkTheme,
}) => {
	const items = []

	const overviewChildren = []

	const food = foodItem?.food
	const branding = [foodItem?.brandOwnerName, foodItem?.brandName, foodItem?.subbrandName]
		.filter(Boolean)
		.join(', ')

	const infoItems = [
		{
			key: 'branding',
			label: t('foodItem:sections.overview.info.branding'),
			children: branding,
			span: 2,
		},
		{
			key: 'physicalState',
			label: t('foodItem:sections.overview.info.physicalState'),
			children: food?.physicalStateLabel,
			span: 2,
		},
	]

	const categoryName = food?.categoryName
	const categoryItem = {
		key: 'category',
		label: t('foodItem:sections.overview.info.category'),
		children: categoryName,
		span: 2,
	}
	categoryName && infoItems.push(categoryItem)

	const foodItemLink = foodItem?.url
	const linkItem = {
		key: 'link',
		label: t('foodItem:sections.overview.info.link'),
		children: (
			<Link href={foodItemLink} target="_blank">
				{t('foodItem:sections.overview.info.linkDescription')}
			</Link>
		),
		span: 2,
	}
	foodItemLink && infoItems.push(linkItem)

	const renderInfo = () => (
		<Card
			title={renderLabel({
				icon: <ProfileOutlined />,
				label: t('foodItem:sections.overview.info.title'),
			})}
			style={{ height: '100%' }}
		>
			<Descriptions column={2} items={infoItems} style={{ padding: '0 6px' }} />
		</Card>
	)

	const renderFoodLabel = (item) =>
		renderLabel({
			icon: (
				<Avatar
					icon={<Icon component={!item?.name ? Nutrition : item.isLiquid ? Liquid : Solid} />}
					src={item?.url && <Image src={item?.url} />}
					shape="square"
				/>
			),
			label: (
				<L10nLink to={`/library/standard-food/${item.id}`}>
					{item?.name ?? t('foodItem:sections.overview.standardFood.title')}
				</L10nLink>
			),
			extra: !item?.name ? <LoadingOutlined /> : null,
		})

	const standardFood = foodItem?.standardFood
	const stdFood = standardFood?.food
	const renderStandardFood = () => (
		<Card
			title={renderLabel({
				icon: <NodeCollapseOutlined />,
				label: t('foodItem:sections.overview.standardFood.title'),
			})}
			style={{ height: '100%' }}
		>
			{renderFoodLabel({
				id: standardFood.id,
				name: stdFood.name,
				isLiquid: stdFood.physicalState === 'liquid',
				url: standardFood.cartoonImage?.url ?? stdFood.image?.url,
			})}
		</Card>
	)

	const renderStats = ({ title, value, prefix }) => (
		<Card $size={'small'} style={{ height: '100%' }}>
			<Statistic
				title={title}
				value={value}
				prefix={prefix}
				style={{ padding: '1px 6px 6px 2px' }}
			/>
		</Card>
	)

	const viewsCount = food?.viewsCount
	const renderViews = () =>
		renderStats({
			title: t('foodItem:sections.overview.stats.views'),
			value: viewsCount,
			prefix: <EyeOutlined />,
		})

	const nutriscoreGrade = food?.nutriscoreGrade
	const nutriscoreSrc =
		nutriscoreGrade &&
		`https://static.openfoodfacts.org/images/attributes/dist/nutriscore-${nutriscoreGrade.toLowerCase()}.svg`
	const renderNutriscore = () => (
		<Card $size={'small'} style={{ height: '100%' }}>
			<Tooltip title={t('foodItem:sections.overview.nutriscore.methodology')}>
				<Image
					alt={t('foodItem:sections.overview.nutriscore.grade', { grade: nutriscoreGrade })}
					preview={false}
					src={nutriscoreSrc}
				/>
			</Tooltip>
		</Card>
	)

	const enoughViewsCount = viewsCount >= 12
	const renderOverviewChildren = () => (
		<TabPaneWrapper key="overview-pane">
			{renderInfo()}
			{standardFood && renderStandardFood()}
			{nutriscoreGrade && renderNutriscore()}
			{enoughViewsCount && renderViews()}
		</TabPaneWrapper>
	)
	foodItem && overviewChildren.push(renderOverviewChildren())

	const overviewItem = {
		key: 'overview',
		label: t('foodItem:sections.overview.title'),
		children: overviewChildren,
	}

	const ingredientsChildren = []

	const ingredientsList = foodItem?.ingredientsList

	const renderIngredientsList = () => (
		<Card
			title={renderLabel({
				icon: <UnorderedListOutlined />,
				label: t('foodItem:sections.ingredients.ingredientsList.title'),
			})}
			style={{ height: '100%' }}
		>
			<div style={{ padding: '5px 8px' }}>
				<Text>{ingredientsList}</Text>
			</div>
		</Card>
	)

	const allergens = food?.allergens
	const buildAllegenData = () =>
		allergens.map((a) => ({
			id: a.id,
			name: a.name,
			description: a.description,
			url: a.image.url,
		}))

	const anyAllergens = allergens && Object.keys(allergens).length !== 0
	const allergensData = anyAllergens && buildAllegenData()

	const renderAllergensLabel = (item) =>
		renderLabel({
			icon: <Avatar icon={<WarningOutlined />} src={item.url && <Image src={item.url} />} />,
			label: <Tooltip title={item.description}>{item.name}</Tooltip>,
		})

	const renderAllergensList = () => (
		<Card
			title={renderLabel({
				icon: <UnorderedListOutlined />,
				label: t('foodItem:sections.ingredients.allergensList.title'),
			})}
			style={{ height: '100%' }}
		>
			<List dataSource={allergensData} renderItem={renderAllergensLabel} />
		</Card>
	)

	const renderIngredientsChildren = () => (
		<TabPaneWrapper key="ingredients-pane">
			{ingredientsList && renderIngredientsList()}
			{anyAllergens && renderAllergensList()}
		</TabPaneWrapper>
	)

	ingredientsChildren.push(renderIngredientsChildren())

	const anyIngredients = ingredientsList || anyAllergens
	const ingredientsItem = {
		key: 'ingredients',
		label: t('foodItem:sections.ingredients.title'),
		children: ingredientsChildren,
		disabled: !anyIngredients,
	}

	const renderNutritionFactsChildren = () => (
		<TabPaneWrapper key="nutrition-facts-pane">
			<Suspense fallback={renderSkeletonCard()}>
				<NutritionFactsContainer food={food} />
			</Suspense>
		</TabPaneWrapper>
	)

	const nutritionFactsItem = {
		key: 'nutritionFacts',
		label: t('foodItem:sections.nutritionFacts.title'),
		children: renderNutritionFactsChildren(),
		disabled: !food?.nutritionalComposition,
	}

	const galleryChildren = []

	const image = food?.image
	const photoUrl = image?.url

	const renderPhotoGallery = () => (
		<Card $withSingleImage>
			<Image src={photoUrl} />
		</Card>
	)

	const renderGalleryChildren = () => (
		<TabPaneWrapper key="gallery-pane">{photoUrl && renderPhotoGallery()}</TabPaneWrapper>
	)

	galleryChildren.push(renderGalleryChildren())

	const anyImage = photoUrl

	const galleryItem = {
		key: 'gallery',
		label: t('foodItem:sections.gallery.title'),
		children: galleryChildren,
		disabled: !anyImage,
	}

	items.push(overviewItem, ingredientsItem, nutritionFactsItem, galleryItem)

	const isLiquid = food?.physicalState === 'liquid'
	const iconComponent = !foodItem ? Nutrition : isLiquid ? Liquid : Solid
	const thumbUrl = image?.thumbUrl
	const src = photoUrl && (
		<Image src={photoUrl} placeholder={thumbUrl && <Image preview={false} src={thumbUrl} />} />
	)
	const avatar = (
		<Avatar icon={<Icon component={iconComponent} />} src={src} size={60} shape="square" />
	)

	const title = food?.name ?? t('foodItem:page.title')
	const desc = food?.description
	const description = desc !== title ? desc : null
	const extra = !foodItem ? (
		<LoadingOutlined />
	) : isAuthenticated ? (
		<Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={handleClick} />
	) : null

	return (
		<>
			<PageIllustration $src={pattern} $isDarkTheme={isDarkTheme} />

			<PageAction style={{ position: 'relative' }}>
				<Card
					$size={'wide'}
					$isAction
					$withBigAvatar
					$withSquareAvatar
					$withDescription={description}
				>
					<Card.Meta
						avatar={avatar}
						title={renderLabel({ label: title, extra })}
						description={description}
					/>
				</Card>

				<FloatMenu style={{ position: 'absolute', bottom: 0, margin: '0 8px 8px 8px' }}>
					<NavButton
						icon={<HeartOutlined />}
						// onClick={handleFavorite}
						disabled
					>
						{t('foodItem:actions.markFavorite')}
					</NavButton>
				</FloatMenu>
			</PageAction>

			<Tabs activeKey={section} items={items} onChange={handleSectionChange}></Tabs>
		</>
	)
}

export default FoodItem
