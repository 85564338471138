import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

import { selectAccountAndJournal } from './account'

const path = '/user_accounts'

export const userAccountsCreate = (locale, accessToken, userAccountAttrs) => async (dispatch) => {
	const endpoint = localizePath(locale, path)
	const method = 'POST'
	const userAccount = { userAccount: userAccountAttrs }
	const options = initRequestOptions({ method, accessToken, data: userAccount })

	const apiResponse = await dispatch(fetchLibraDietApiData({ endpoint, options }))

	const {
		response: { account, journal },
	} = apiResponse

	const {
		id: accountId,
		attributes: { accountableType },
	} = Object.values(account)[0]
	const journalId = Object.keys(journal)[0]

	dispatch(selectAccountAndJournal({ accountId, accountableType, journalId }))

	return apiResponse
}
