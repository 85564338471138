import * as yup from 'yup'

// Enduser maximum body mass index value in kg/m².
const maxValue = 204

const valueValidation = (t) => {
	const label = t('bodyMassIndices:attributes.value.label')

	return yup
		.number()
		.positive(t('validation:number.positive', { label }))
		.max(maxValue, t('bodyMassIndices:attributes.value.validation.max', { value: maxValue }))
	// .typeError(t('validation:number.typeError', { label }))
	// .required(t('validation:mixed.required', { label }))
}

const bodyMassIndicesSchema = (t) =>
	yup.object().shape({
		value: valueValidation(t),
	})

export default bodyMassIndicesSchema
