// Function component (presentational):

import React from 'react'

import DateContainer from '../date/DateContainer'

import StyledNavDateMenu from './NavDateMenuStyles'

const NavDateMenu = ({ isDashboardOpen, isMobileM }) => (
	<StyledNavDateMenu $isDashboardOpen={isDashboardOpen}>
		<DateContainer lite={!isMobileM} />
	</StyledNavDateMenu>
)

export default NavDateMenu
