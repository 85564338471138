import styled from 'styled-components'

import { miscStyles } from '../../../../styles/misc'

import { Menu } from 'antd'

const InlineMenu = styled(Menu).attrs((props) => ({
	// Defining static props.
	mode: 'inline',
	inlineIndent: 13,
}))`
	&.ant-menu-inline {
		border-inline-end: none !important;
	}

	.ant-menu-item-icon + span {
		margin-inline-start: 18px !important;
	}

	border-radius: 24px;

	background: ${(props) => props.theme.glassBackgroundColor};
	@supports not (
		(-webkit-backdrop-filter: ${miscStyles.glassBackdropFilter}) or
			(backdrop-filter: ${miscStyles.glassBackdropFilter})
	) {
		background: ${(props) => props.theme.backgroundColor};
	}

	-webkit-backdrop-filter: ${(props) => props.theme.glassBackdropFilter};
	backdrop-filter: ${(props) => props.theme.glassBackdropFilter};
`

export default InlineMenu
