// Function component (presentational):

import React from 'react'

import L10nLink from '../shared/navigation/L10nLink'

import InlineMenu from '../shared/styledComponents/menu/InlineMenu'

import renderLabel from '../shared/other/renderLabel'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import {
	UserSwitchOutlined,
	CloseOutlined,
	UserOutlined,
	UsergroupAddOutlined,
	SaveOutlined,
} from '@ant-design/icons'
import { Typography, Switch } from 'antd'
import TextCircleButton from '../shared/styledComponents/button/TextCircleButton'

const { Text } = Typography

const Accounts = ({
	isAccountsOpen,
	withNew,
	hideAccounts,
	currentAccountId,
	accounts,
	handleAccountSelect,
	t,
}) => {
	const renderTitle = () =>
		renderLabel({
			icon: <UserSwitchOutlined />,
			label: currentAccountId ? t('account:accounts.altTitle') : t('account:accounts.title'),
			extra: withNew && <TextCircleButton icon={<CloseOutlined />} onClick={hideAccounts} />,
		})

	const items = []

	const dividerItem = { type: 'divider' }

	const personalAccounts = accounts && accounts.filter((a) => a.accountableType === 'Person')
	const anyPersons = personalAccounts && Object.keys(personalAccounts)

	const buildAccountItem = ({ id, username }) => ({
		key: id,
		icon: <UserOutlined />,
		label: (
			<Text
				onClick={() => handleAccountSelect(id)}
				disabled={id === currentAccountId}
				style={{ display: 'flex' }}
			>
				{`@${username}`}
			</Text>
		),
	})

	const personalAccountsGroup = {
		type: 'group',
		label: t('account:accounts.person'),
		children: anyPersons && personalAccounts.map((a) => buildAccountItem(a)),
	}

	anyPersons && items.push(personalAccountsGroup)

	const primaryAccountItem = {
		key: 'primary-account',
		icon: <SaveOutlined />,
		label: renderLabel({
			label: t('account:accounts.primaryAccount'),
			extra: <Switch disabled />,
		}),
		disabled: true, // Temporary...
	}
	items.push(primaryAccountItem)

	const addAccountItem = {
		key: 'add-account',
		icon: <UsergroupAddOutlined />,
		label: (
			<div onClick={hideAccounts}>
				<L10nLink to="/onboarding">{t('account:accounts.addAccount')}</L10nLink>
			</div>
		),
	}
	withNew && items.push(dividerItem, addAccountItem)

	const renderBody = () => <InlineMenu items={items} />

	return (
		<DrawerOrModalContainer
			isOpen={isAccountsOpen}
			canClose={withNew}
			hide={hideAccounts}
			renderTitle={renderTitle}
			renderBody={renderBody}
			// placement="top"
		/>
	)
}

export default Accounts
