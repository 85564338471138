import styled from 'styled-components'

const TabPaneWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 16px;

	padding: 8px 0;
`

export default TabPaneWrapper
