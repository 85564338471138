import * as yup from 'yup'

import dayjs from 'dayjs'

import { joinWithQuotesAndAmpersand } from '../misc'

import { desiredBodyMeasurementsSchema } from './bodyMeasurementsSchema'

export const objectiveKinds = ['maintain', 'lose', 'gain']

const objectiveKindValidation = (t) => {
	const label = t('objectives:attributes.objectiveKind.label')
	const valuesLabels = objectiveKinds.map((v) =>
		t(`objectives:attributes.objectiveKinds.${v}.label`),
	)
	const valuesLabelsList = joinWithQuotesAndAmpersand(valuesLabels)

	return yup
		.mixed()
		.oneOf(objectiveKinds, t('validation:mixed.oneOf', { label, values: valuesLabelsList }))
		.required(t('validation:mixed.required', { label }))
}

export const disabledDate = (current) => {
	// Cannot select days before today or after 1 year.
	// return current < dayjs() || current >= dayjs().add(1, 'y')
	return !current.isBetween(dayjs(), dayjs().add(1, 'y'))
}

const minDate = dayjs().add(0, 'M')

// Maximum change duration in months.
const maxDuration = 12
export const maxDate = dayjs().add(maxDuration, 'M')

const endDateValidation = (t) => {
	// const label = t('objectives:attributes.endDate.label')

	return yup
		.date()
		.min(minDate, t('objectives:attributes.endDate.validation.min'))
		.max(maxDate, t('objectives:attributes.endDate.validation.max', { maxDuration: maxDuration }))
		.nullable()
	// .typeError(t('validation:date.typeError', { label }))
}

const objectivesSchema = (t) =>
	yup.object().shape({
		objectiveKind: objectiveKindValidation(t),
		bodyMeasurement: desiredBodyMeasurementsSchema(t),
		endDate: endDateValidation(t),
	})

export default objectivesSchema
