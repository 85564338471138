// Function HOC/Higher Order Component
// wrapping App components to authenticate user
// (container, using React Router hook):

import React from 'react'
// React Router hook for routing.
import { useNavigate } from 'react-router-dom'

import { Auth0Provider } from '@auth0/auth0-react'

import { Capacitor } from '@capacitor/core'

import { domain, clientId, audience, redirectUri } from './authConfig'

const Auth0ProviderWithNavigate = ({ children }) => {
	const isNative = Capacitor.isNativePlatform()

	const navigate = useNavigate()

	const onRedirectCallback = (appState) => {
		navigate(appState?.returnTo || window.location.pathname)
	}

	// if (!(domain && clientId && redirectUri)) {
	// 	return null
	// }

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			useRefreshTokens={isNative ? true : undefined}
			useRefreshTokensFallback={isNative ? false : undefined}
			authorizationParams={{ audience, redirect_uri: redirectUri }}
			onRedirectCallback={!isNative && onRedirectCallback}
		>
			{children}
		</Auth0Provider>
	)
}

export default Auth0ProviderWithNavigate
