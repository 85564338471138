import styled from 'styled-components'

import Card from '../card/Card'

import { List as AntList } from 'antd'

const List = styled(AntList)`
	// ${Card} {
	// 	height: 100%;
	// }

	.ant-list-item {
		margin-block-end: 8px !important;
	}

	.ant-list-pagination {
		margin-block-start: 8px;
		margin-block-end: 8px;
	}
`

export default List
