import styled from 'styled-components'

import { Typography } from 'antd'

const { Paragraph: AntParagraph } = Typography

const Paragraph = styled(AntParagraph)`
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: 'Fredoka';
		// font-weight: 300;
	}

	h1 {
		font-size: 48px; // ex 36px
	}
	h2 {
		font-size: 40px; // ex 30px
	}
	h3 {
		font-size: 32px; // ex 24px
	}
	h4 {
		font-size: 27px; // ex 20px
	}
	h5 {
		font-size: 22px; // ex 16px
	}
`

export default Paragraph
