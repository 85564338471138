// Function component (stateful & container, using React, Redux, React Router, Auth0, i18next & React Responsive hooks):

// React State & Effect hooks.
import React, { useState, useMemo, useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hooks for routing.
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
// import { useMediaQuery } from 'react-responsive'

import build from 'redux-object'

import { prefixPath } from '../../utils/l10n'

import { recipesShow } from '../../ducks/recipes'
import { toggleNewEvent, setMeal } from '../../ducks/event'
import { speakWithSound } from '../../ducks/libra'

// import deviceMinWidth from '../../styles/deviceMinWidth'

import Recipe from './Recipe'

import { App as AntApp } from 'antd'

const RecipeStatefulContainer = () => {
	let { id } = useParams()

	const apiData = useSelector((state) => state.apiData)

	const recipe = build(apiData, 'recipe', id, { includeType: true })

	const { getAccessTokenSilently, isAuthenticated } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['recipe', 'food'])

	const dispatch = useDispatch()

	const { message } = AntApp.useApp()

	const navigate = useNavigate()

	const shouldViewRecipe = !recipe?.food?.nutritionalComposition?.ncmCompositions
	useEffect(() => {
		if (!shouldViewRecipe) return

		const navToLibraryRecipe = ({ content, key = 'idError' }) => {
			message.error({ content, key })

			navigate(prefixPath('/library?scope=recipe', language), { replace: true })
		}

		const viewRecipe = async (recipeId) => {
			if (!+recipeId) {
				return navToLibraryRecipe({ content: 'Invalid Recipe Id', key: 'invalidId' })
			}

			try {
				const accessToken = await getAccessTokenSilently()

				const r = await dispatch(recipesShow(language, accessToken, recipeId))
				return r
			} catch (e) {
				const status = Object.values(e.errors)[0].status
				const content = Object.values(e.errors)[0].detail
				if (['403', '404'].includes(status)) {
					navToLibraryRecipe({ content })
				}
				// throw e
			}
		}

		viewRecipe(id)
	}, [shouldViewRecipe, getAccessTokenSilently, language, dispatch, message, navigate, id])

	let [searchParams, setSearchParams] = useSearchParams()

	const fallbackSection = 'overview'
	const paramsSection = searchParams.get('section')
	const initialSection = paramsSection ?? fallbackSection

	const [section, setSection] = useState(initialSection)
	const handleSectionChange = (key) => setSection(key)

	const shouldSetSearchParams = section !== initialSection
	useEffect(() => {
		shouldSetSearchParams && setSearchParams({ section })
	}, [shouldSetSearchParams, section, setSearchParams])

	const [step, setStep] = useState(0)
	const [outOfFlow, setOutOfFlow] = useState(false)

	// const handleStepChange = (v) => setStep(v)
	const handleStepChange = (v) => {
		setOutOfFlow(true)
		setStep(v)
	}

	const [withDictation, setDictation] = useState(false)
	const toggleDictation = () => setDictation((v) => !v)

	const recipeInstructions = recipe?.instructions
	const instructions = useMemo(
		() =>
			recipeInstructions
				?.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
				?.map(
					({ sequenceNumber, previousInstructions, recipeIngredients, description, notes }) => ({
						sequenceNumber,
						previousInstructions: previousInstructions?.map((i) => i.sequenceNumber),
						ingredients: recipeIngredients?.map(
							({ ingredient: { name, foodable, physicalState, image } }) => ({
								title: name,
								foodableType: foodable.type,
								isLiquid: physicalState === 'liquid',
								url: image?.url,
							}),
						),
						description,
						// imageSrc,
						notes: notes?.map((n) => n.description),
					}),
				) ?? [],
		[recipeInstructions],
	)

	const { speech, intent, sequenceNumber } = useSelector((state) => state.libra)
	const sequenceEnd = instructions?.length

	const instructionSpeech = instructions[step]?.description
	// const instructionImageSrc = instructions[step]?.imageSrc

	const isRecipeInstructionsIntent = intent === 'recipe:instructions'

	const shouldChangeStep = isRecipeInstructionsIntent && sequenceNumber - 1 !== step && !outOfFlow
	useEffect(() => {
		shouldChangeStep && setStep(sequenceNumber - 1)
	}, [shouldChangeStep, sequenceNumber, step])

	const shouldLibraContinue =
		((section === 'instructions' && step === 0) || isRecipeInstructionsIntent) &&
		sequenceEnd &&
		withDictation &&
		instructionSpeech !== speech
	useEffect(() => {
		if (!shouldLibraContinue) return

		dispatch(
			speakWithSound({
				path: `recipe:instructions.speech.${step + 1}`,
				speech: instructionSpeech,
				isFirst: step === 0,
				sequenceType: 'ordered',
				sequenceEnd,
				// imageSrc: instructionImageSrc,
				language,
			}),
		)
		outOfFlow && setOutOfFlow(false)
	}, [shouldLibraContinue, step, instructionSpeech, sequenceEnd, language, dispatch, outOfFlow])

	const handleClick = () => {
		dispatch(setMeal({ foodId: recipe.food.id }))
		dispatch(toggleNewEvent())
	}

	// const isLaptop = useMediaQuery({
	// 	// query: '(min-width: 1024px)'
	// 	query: `${deviceMinWidth.laptop}`,
	// })

	const { isDarkTheme } = useSelector((state) => state.appearance)

	return (
		<Recipe
			recipe={recipe}
			isAuthenticated={isAuthenticated}
			section={section}
			handleSectionChange={handleSectionChange}
			step={step}
			handleStepChange={handleStepChange}
			withDictation={withDictation}
			toggleDictation={toggleDictation}
			instructions={instructions}
			handleClick={handleClick}
			t={t}
			// isLaptop={isLaptop}
			isDarkTheme={isDarkTheme}
		/>
	)
}

export default RecipeStatefulContainer
