// Function component (container, using React, Redux, Auth0 & i18next hooks):

// React Effect hooks.
import React, { useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'
import _ from 'lodash'

import { convertGToOz } from '../../utils/numbers'
import { decimalCommaFormatter } from '../../utils/misc'

import { reportsIndex } from '../../ducks/reports'
import { intakesShow } from '../../ducks/intakes'

import NutritionTargetsCollapse from './NutritionTargetsCollapse'

const NutritionTargetsContainer = ({ fromDeck = false }) => {
	const { currentJournalId: journalId, currentDay } = useSelector((state) => state.journal)

	const apiData = useSelector((state) => state.apiData)

	const reports = journalId && build(apiData, 'report', null)
	const currentReport = reports?.find(
		(r) => r.journal.id === journalId && r.startDate === currentDay && r.frequency === 'daily',
	)
	const intake = currentReport?.intake

	const { getAccessTokenSilently } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['nutrition', 'ncmTarget', 'ncmComposition', 'nutritionalComponents'])

	const dispatch = useDispatch()

	const shouldSearchReportIntake = !intake
	useEffect(() => {
		if (!shouldSearchReportIntake) return

		const searchReportThenReadIntake = async () => {
			try {
				const accessToken = await getAccessTokenSilently()

				const { response } = await dispatch(
					reportsIndex(language, accessToken, {
						of: journalId,
						on: currentDay,
						frequency: 'daily',
					}),
				)

				if (response) {
					const id = Object.keys(response.intake)[0]

					id && dispatch(intakesShow(language, accessToken, id))
				}
			} catch (e) {
				// throw e
			}
		}

		searchReportThenReadIntake()
	}, [shouldSearchReportIntake, journalId, currentDay, getAccessTokenSilently, language, dispatch])

	const ncmTargets = intake?.target?.ncmTargets
	const ncmts = ncmTargets?.sort(
		(a, b) =>
			a.nutritionalComponentMeasure.nutritionalComponent.id -
			b.nutritionalComponentMeasure.nutritionalComponent.id,
	)

	const filterNCMTs = (category) =>
		ncmts?.filter(
			(ncmt) =>
				_.camelCase(ncmt.nutritionalComponentMeasure.nutritionalComponent.category) === category,
		)

	const refNCMCs = intake?.nutritionalComposition?.ncmCompositions
		?.filter((ncmc) => ncmc.variant === 'from_target')
		?.map((ncmc) => ({ key: ncmc.ncmTarget.id, value: ncmc.value }))

	const findRefValue = (key) => refNCMCs && refNCMCs.find((r) => r.key === key)?.value

	const { isSI } = useSelector((state) => state.measurement)

	const dataSource = (category) =>
		filterNCMTs(category) &&
		filterNCMTs(category).map((ncmt) => {
			const ncm = ncmt.nutritionalComponentMeasure
			const isEnergyNcm = ['13', '15', '16'].includes(ncm.id)
			const cf = !isSI ? convertGToOz(1000) / 1000 : 1
			const ncmtValue = ncmt.value ? +ncmt.value * (!isEnergyNcm ? cf : 1) : 0
			const value = ncmtValue.toFixed(ncmtValue >= 100 ? 0 : ncmtValue >= 10 ? 1 : 2)
			const refValue = findRefValue(ncmt.id)
			const pctRef = refValue && (+refValue).toFixed(1)

			return {
				key: ncmt.id,
				component: ncm.description,
				value: decimalCommaFormatter(value, language),
				pctRef: decimalCommaFormatter(pctRef, language),
			}
		})

	return <NutritionTargetsCollapse fromDeck={fromDeck} dataSource={dataSource} isSI={isSI} t={t} />
}

export default NutritionTargetsContainer
