import styled from 'styled-components'

import { buttonWidth } from '../../../../styles/sizes'

import { Radio } from 'antd'

const { Button } = Radio

const RadioButton = styled(Button)`
	flex: 1 1 0;

	padding: 0;
	padding-right: ${(props) => (props.boxicons === 'left' ? buttonWidth : undefined)};
	padding-left: ${(props) => (props.boxicons === 'right' ? buttonWidth : undefined)};

	text-align: center;
`

export default RadioButton
