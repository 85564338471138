import styled from 'styled-components'

import TextRoundButton from './TextRoundButton'

const NavButton = styled(TextRoundButton).attrs((props) => ({
	// Defining dynamic props.
	$showLabel: props.$showLabel ?? false,
}))`
	> .ant-btn-icon:not(:last-child) {
		margin-inline-end: 0 !important;
	}

	> span:not(.ant-btn-icon) {
		display: ${(props) => (!props.$showLabel ? 'none' : undefined)};
	}
	@media (hover: hover) {
		&:hover {
			width: ${(props) => (props.$hasAvatarIcon ? 'unset' : undefined)};

			&.ant-btn {
				padding-inline-end: 8px !important;
			}

			.ant-avatar {
				left: -1px;
			}

			> span:not(.ant-btn-icon) {
				display: ${(props) => (!props.$showLabel ? 'inline' : undefined)};
			}
		}
	}
`

export default NavButton
