import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

const path = '/users'

export const usersShow = (locale, accessToken, auth0Uid) => (dispatch) => {
	const endpoint = localizePath(locale, `${path}/${auth0Uid}`)
	const options = initRequestOptions({ accessToken })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}
