import styled from 'styled-components'

import { InfoCircleOutlined } from '@ant-design/icons'

const InlineInfo = styled(InfoCircleOutlined)`
	margin-left: 4px;

	cursor: help;
`

export default InlineInfo
