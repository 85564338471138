// Function component (container, using React Router & i18next hooks):

import React from 'react'
// React Router hook for routing.
import { useLocation, useNavigate } from 'react-router-dom'
// i18next hook for localization (via React Context).
import { useTranslation } from 'react-i18next'

import { replacePathLocale } from '../../utils/l10n'

import LanguageOption from './LanguageOption'

const LanguageContainer = ({ locale }) => {
	let { pathname, search, hash } = useLocation()
	const currentFullPath = `${pathname}${search}${hash}`

	const navigate = useNavigate()

	const {
		i18n: { language },
	} = useTranslation('l10n')

	const stdLng = language.slice(0, 2)

	const disabled = locale === stdLng

	const handleSelect = (newLocale) => {
		if (stdLng === newLocale) return

		const newPath = replacePathLocale(currentFullPath, newLocale)
		return navigate(newPath)
	}

	return <LanguageOption locale={locale} disabled={disabled} handleSelect={handleSelect} />
}

export default LanguageContainer
