import styled from 'styled-components'

import { eventKinds } from '../../utils/yupSchema/logsSchema'

import { colors } from '../../styles/colors'

import { CaretDownOutlined } from '@ant-design/icons'
import { Typography, Badge } from 'antd'

const { Paragraph } = Typography

const StyledJournalTimeline = styled.div`
	// padding-bottom: 16px;

	// .ant-timeline-item-head {
	// 	background-color: transparent;
	// }
`

export default StyledJournalTimeline

export const Entry = styled.section`
	display: flex;
`

export const EntryCategory = styled(Paragraph).attrs((props) => ({
	// Defining dynamic props.
	type: props.type ?? 'secondary',
}))`
	&.ant-typography {
		margin-bottom: 8px;
	}
`

export const EntryEnergy = styled(Badge).attrs((props) => ({
	// Defining dynamic props.
	overflowCount: props.overflowCount ?? 9999,
}))`
	margin-top: 1px;
	margin-right: 6px;

	.ant-badge-count {
		background-color: ${(props) => (props.$kind === eventKinds[0] ? colors.meal : colors.activity)};
	}
`

const Expand = styled(CaretDownOutlined)`
	// margin-right: 9px;
`

export const EntryDescription = styled(Paragraph).attrs((props) => ({
	// Defining dynamic props.
	ellipsis: props.ellipsis ?? {
		rows: 2,
		expandable: true,
		symbol: <Expand />,
	},
}))`
	width: 100%;

	margin-right: 9px;

	&.ant-typography {
		margin-bottom: 8px;
	}
`
