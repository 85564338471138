import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

const path = '/food_items'

export const foodItemsIndex =
	(locale, accessToken, filter = {}, page = {}) =>
	(dispatch) => {
		const endpoint = localizePath(locale, path)
		const options = initRequestOptions({ accessToken })
		const params = { filter, page }

		return dispatch(fetchLibraDietApiData({ endpoint, options, params }))
	}

export const foodItemsShow = (locale, accessToken, gtin) => (dispatch) => {
	const endpoint = localizePath(locale, `${path}/${gtin}`)
	const options = initRequestOptions({ accessToken })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}
