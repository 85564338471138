// Function component (presentational):

import React from 'react'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import FormWrapper from '../shared/styledComponents/form/FormWrapper'

import ChatForm from './ChatForm'

const NewChat = ({
	fromThread,
	isNewChatOpen,
	isAnswering,
	initialValues,
	inputRef,
	syncContent,
	createChat,
	hideNewChat,
	endSubmit,
	t,
}) => {
	const renderChatForm = () => (
		<ChatForm
			fromThread={fromThread}
			isAnswering={isAnswering}
			initialValues={initialValues}
			inputRef={inputRef}
			syncContent={syncContent}
			createChat={createChat}
			endSubmit={endSubmit}
			t={t}
		/>
	)

	const renderBody = () => <FormWrapper>{renderChatForm()}</FormWrapper>

	return fromThread ? (
		renderChatForm()
	) : (
		<DrawerOrModalContainer
			id={'user-writing'}
			isOpen={isNewChatOpen}
			hide={hideNewChat}
			renderBody={renderBody}
			onlyModal
			fromTop={56}
			side={'right'}
		/>
	)
}

export default NewChat
