// Function component (potential container):

import React from 'react'

import NavApp from './NavApp'

const NavAppContainer = ({
	withNavApp,
	noNav,
	sleekNav,
	navKey,
	isHub,
	withSideDash,
	navigate,
	t,
	isTabletL,
}) => (
	<NavApp
		withNavApp={withNavApp}
		noNav={noNav}
		sleekNav={sleekNav}
		isHub={isHub}
		withSideDash={withSideDash}
		navKey={navKey}
		navigate={navigate}
		t={t}
		isTabletL={isTabletL}
	/>
)

export default NavAppContainer
