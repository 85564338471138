import fetch from 'cross-fetch'
import normalize from 'json-api-normalizer'

import { defaultApiSpecs, camelify, readStream } from '../utils/api'

import { pending, fulfilled, rejected } from '../ducks/apiData'

// In development, no need to specify the `http://localhost:4000` domain
// as the Webpack development server proxies requests intended for the Libra Diet API server.
// The Libra Diet API is namespaced.
// const LIBRA_DIET_API_ROOT = '/api/v1'
const LIBRA_DIET_API_ROOT = `${
	process.env.NODE_ENV === 'production'
		? 'https://' + process.env.REACT_APP_LIBRA_DIET_API_HOST
		: ''
}/api/v1`

// Fetches an API response.
export const callApi = async (
	endpoint,
	options = {},
	{ client, command } = {},
	{ proxied, naming, dataFormat } = defaultApiSpecs,
) => {
	// str.indexOf(searchValue) returns -1 if not found.
	const url =
		endpoint.indexOf(LIBRA_DIET_API_ROOT) === -1 && proxied
			? `${LIBRA_DIET_API_ROOT}${endpoint}`
			: endpoint

	const response = await (!(client && command) ? fetch(url, options) : client.send(command))
	const { ok, $metadata: { httpStatusCode: status } = {}, AudioStream } = response
	const isOk = ok || status === 200
	const isStream = AudioStream instanceof ReadableStream

	let res = ''
	if (isOk && isStream) {
		res = await readStream(response.AudioStream)
	} else {
		res = await response.json()

		if (naming !== 'camelCase') {
			res = camelify(res)
		}
	}

	if (isOk) {
		// Using the `json-api-normalizer` package
		// to 'normalize' API data (following the JSON:API specification) to a Redux-friendly format.
		return dataFormat === 'JSON:API'
			? Object.assign({}, normalize(res, { endpoint }))
			: { [dataFormat]: res }
	} else {
		const { error } = res
		const errors = error ? { errors: [error] } : {}

		return dataFormat === 'JSON:API' ? Promise.reject(res) : Promise.reject(errors)
	}
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API')

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
const api = (store) => (next) => async (action) => {
	const callApiAction = action[CALL_API]

	if (typeof callApiAction === 'undefined') {
		return next(action)
	}

	let { endpoint } = callApiAction
	const { options, aws, apiSpecs } = callApiAction

	if (typeof endpoint === 'function') {
		endpoint = endpoint(store.getState())
	}

	if (typeof endpoint !== 'string') {
		throw new Error('Specify a string endpoint URL.')
	}

	const actionWith = (data) => {
		const finalAction = Object.assign({}, action, data)
		delete finalAction[CALL_API]
		return finalAction
	}

	next(actionWith(pending(endpoint)))

	try {
		const response = await callApi(endpoint, options || {}, aws || {}, apiSpecs)

		return next(
			actionWith({
				response,
				...fulfilled(endpoint),
			}),
		)
	} catch (error) {
		const { errors, name, message, $metadata: { httpStatusCode: status } = {} } = error
		throw next(
			actionWith({
				errors: errors || [
					{
						status: status || 500,
						title: name || 'Unexpected Error',
						detail: message || 'No further detail available from server...',
					},
				],
				...rejected(endpoint),
			}),
		)
	}
}

export default api
