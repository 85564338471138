import styled from 'styled-components'

import { mobileSSideNavWidth } from '../../styles/sizes'

const StyledNavUser = styled.nav`
	width: ${mobileSSideNavWidth};

	justify-content: flex-end;
`

export default StyledNavUser
