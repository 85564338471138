import styled from 'styled-components'

import { DatePicker } from 'formik-antd'

const StyledDatePicker = styled(DatePicker)`
	// width: 100%;
`

export default StyledDatePicker

export const datePickerStyle = { width: '100%' }
