// Function component (presentational):

import React from 'react'

import LayoutWrapper from '../shared/styledComponents/page/LayoutWrapper'
import PageWrapper from '../shared/styledComponents/page/PageWrapper'

import NavContainer from '../nav/NavContainer'

import ScannerContainer from './ScannerStatefulContainer'

const ScannerView = () => (
	<LayoutWrapper>
		<NavContainer sleekNav />

		<PageWrapper>
			<ScannerContainer />
		</PageWrapper>
	</LayoutWrapper>
)

export default ScannerView
