import * as yup from 'yup'

import dayjs from 'dayjs'

import { maxAge, userMinAge } from './peopleSchema'

export const loggableTypes = [
	'BodyMeasurement',
	'BodyConstitution',
	'MetabolicDynamic',
	'ActivityInstance',
	'MealInstance',
]

export const eventKinds = ['mealInstance', 'activityInstance']

export const entryKinds = [...eventKinds, 'bodyMeasurement', 'questionnaireResponse']

export const disabledDate = (current, birthDate, maxDate) => {
	const baseDate = birthDate ?? dayjs().subtract(userMinAge, 'y')
	const max = maxDate ?? baseDate.add(maxAge, 'y')

	// Cannot select days before date of birth or after max date.
	// return current < birthDate || current > max
	return !current.isBetween(birthDate, max)
}

export const dateValidation = (t, birthDate, withFuture = true) => {
	const label = t('logs:attributes.date.label')

	const baseDate = birthDate ?? dayjs().subtract(maxAge, 'y')
	const min = baseDate.add(1, 'd')
	const max = withFuture ? baseDate.add(maxAge, 'y') : dayjs()

	return (
		yup
			.date()
			.min(min, t('logs:attributes.date.validation.min'))
			.max(max, t('logs:attributes.date.validation.max'))
			.nullable()
			// .typeError(t('validation:date.typeError', { label }))
			.required(t('validation:mixed.required', { label }))
	)
}

const logsSchema = (t, birthDate, withFuture) =>
	yup.object().shape({
		date: dateValidation(t, birthDate, withFuture),
	})

export default logsSchema
