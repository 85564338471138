import _ from 'lodash'

import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

const path = '/body_measurements'

export const bodyMeasurementsIndex =
	(locale, accessToken, filter = {}) =>
	async (dispatch) => {
		const endpoint = localizePath(locale, path)
		const options = initRequestOptions({ accessToken })
		const params = (n) => ({ filter, page: { number: n } })

		const apiResponse = {}

		let n = 1
		while (true) {
			const pageResponse = await dispatch(
				fetchLibraDietApiData({ endpoint, options, params: params(n) }),
			)
			_.merge(apiResponse, pageResponse)

			const {
				response: {
					meta: {
						[endpoint]: {
							meta: { pages },
						},
					},
				},
			} = pageResponse

			if (n === pages) break

			n++
		}

		return apiResponse
	}

export const bodyMeasurementsCreate = (locale, accessToken, bodyMeasurementAttrs) => (dispatch) => {
	const endpoint = localizePath(locale, path)
	const method = 'POST'
	const bodyMeasurement = { bodyMeasurement: bodyMeasurementAttrs }
	const options = initRequestOptions({ method, accessToken, data: bodyMeasurement })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}
