// Function component (presentational):

import React from 'react'

import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'

import subscriptionFormSchema from '../../utils/yupSchema/subscriptionFormSchema'

import { transformInvalidAttrErrors } from '../../utils/api'

import FormItem from '../shared/styledComponents/form/FormItem'

import renderLabel, { renderNameLabel } from '../shared/other/renderLabel'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'

import { KeyOutlined, MailOutlined, InfoCircleOutlined, RightOutlined } from '@ant-design/icons'
import { App as AntApp } from 'antd'

const SubscriptionForm = ({ initialValues, createSubscriber, navToReferral, t }) => {
	const { message } = AntApp.useApp()

	const handleSubmit = async (values, { setErrors }) => {
		try {
			const { response } = await createSubscriber(values)

			if (response) {
				message.success(t('subscription:form.message.success'))

				const { subscriber } = response
				const subscriberKey = Object.keys(subscriber)[0]

				navToReferral(subscriberKey)
			}
		} catch (e) {
			const errors = transformInvalidAttrErrors(e.errors)

			// Async (server-side) validation.
			setErrors(errors)
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={subscriptionFormSchema(t)}
			onSubmit={handleSubmit}
		>
			<Form
				// id="subscription-form"
				layout="vertical"
				colon={false}
				requiredMark="optional"
			>
				<FormItem
					name="referrerKey"
					label={renderLabel({
						icon: <KeyOutlined />,
						label: t('subscribers:attributes.referrerKey.label'),
					})}
				>
					<Input
						name="referrerKey"
						allowClear
						placeholder={t('subscribers:attributes.referrerKey.placeholder')}
					/>
				</FormItem>

				<FormItem
					name="profile.givenName"
					label={renderNameLabel(t('profiles:attributes.givenName.label'))}
				>
					<Input
						name="profile.givenName"
						placeholder={t('profiles:attributes.givenName.placeholder')}
					/>
				</FormItem>

				<FormItem
					name="profile.email"
					// type="email"
					required
					hasFeedback
					label={renderLabel({
						icon: <MailOutlined />,
						label: t('profiles:attributes.email.label'),
					})}
				>
					<Input
						name="profile.email"
						placeholder={t('profiles:attributes.email.placeholder')}
						tooltip={{
							icon: <InfoCircleOutlined />,
							title: t('profiles:attributes.email.tooltip'),
						}}
					/>
				</FormItem>

				<SubmitWrapper $step="first">
					<SubmitButton
						// htmlType="submit"
						shape="circle"
						icon={<RightOutlined />}
					/>
				</SubmitWrapper>
			</Form>
		</Formik>
	)
}

export default SubscriptionForm
