const keywordsEn = [
	'diet',
	'life',
	'health',
	'well',
	'nutrition',
	'meal',
	'plan',
	'menu',
	'recipe',
	'cooking',
	'gastronomy',
	'cuisine',
	'restaurant',
	'fitness',
	'activity',
	'sport',
	'exercise',
	'training',
	'workout',
	'gym',
	'coach',
	'trainer',
	'guide',
	'mentor',
	'admin',
	'support',
	'contact',
	'team',
]
const keywordsFR = [
	'diète',
	'santé',
	'bien',
	'nutrition',
	'repas',
	'régime',
	'menu',
	'recette',
	'gastronomie',
	'cuisine',
	'restaurant',
	'forme',
	'activité',
	'sport',
	'exercice',
	'entraînement',
	'gym',
	'entraîneur',
	'guide',
	'mentor',
	'admin',
	'support',
	'contact',
	'équipe',
]

export const keywords = [...new Set([...keywordsEn, ...keywordsFR])]
