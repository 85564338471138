import renderLabel from '../shared/other/renderLabel'

import { waterFoodId, teaFoodId, coffeeFoodId } from '../../utils/meal'

import Icon, { CoffeeOutlined } from '@ant-design/icons'
import { ReactComponent as Liquid } from '../../assets/icons/water-glass.svg'
// import { ReactComponent as Solid } from '../../assets/icons/fork-and-knife.svg'

const buildMealItem = ({
	handleClick,
	t,
	key,
	id,
	physicalState = 'liquid',
	isHot = false,
	icon = <Icon component={Liquid} />,
}) => {
	const name = t(`event:addMeal.${key}`)

	return {
		key: `food-id-${id}`,
		icon,
		label: renderLabel({
			label: <span onClick={() => handleClick(name, id, physicalState, isHot)}>{name}</span>,
		}),
	}
}

export const buildWaterDrinkingItem = (handleClick, t) =>
	buildMealItem({ handleClick, t, key: 'waterDrinking', id: waterFoodId })

export const buildHotDrinkingItem = ({ handleClick, t, key, id }) =>
	buildMealItem({ handleClick, t, key, id, isHot: true, icon: <CoffeeOutlined /> })

export const buildTeaDrinkingItem = (handleClick, t) =>
	buildHotDrinkingItem({ handleClick, t, key: 'teaDrinking', id: teaFoodId })

export const buildCoffeeDrinkingItem = (handleClick, t) =>
	buildHotDrinkingItem({ handleClick, t, key: 'coffeeDrinking', id: coffeeFoodId })
