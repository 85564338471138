import * as yup from 'yup'

import { accountableTypeValidation } from './accountsSchema'

const accountUserFormSchema = (t) =>
	yup.object().shape({
		account: yup.object().shape({
			accountableType: accountableTypeValidation(t),
		}),
	})

export default accountUserFormSchema
