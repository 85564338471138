// Function component (container, using Redux & Auth0 hooks):

import React from 'react'
// Redux hook to extract data from the Redux store state.
import { useSelector } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'

import build from 'redux-object'

import Avatar from './Avatar'

const AvatarContainer = ({ size = 'default' }) => {
	const { user: { picture, name: userName } = {} } = useAuth0()

	const { currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const src = currentAccount?.avatar ?? picture
	const username = currentAccount?.username
	const label = username ?? userName

	return <Avatar src={src} label={label} size={size} />
}

export default AvatarContainer
