// Function component (stateful & container, using React, Redux, React Router, Auth0, i18next & React Responsive hooks):

// React State & Effect hooks.
import React, { useState, useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hooks for routing.
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
// import { useMediaQuery } from 'react-responsive'

import build from 'redux-object'

import { prefixPath } from '../../utils/l10n'

import { foodItemsShow } from '../../ducks/foodItems'
import { toggleNewEvent, setMeal } from '../../ducks/event'

// import deviceMinWidth from '../../styles/deviceMinWidth'

import FoodItem from './FoodItem'

import { App as AntApp } from 'antd'

const FoodItemStatefulContainer = () => {
	let { gtin } = useParams()

	const apiData = useSelector((state) => state.apiData)

	const foodItem = build(apiData, 'foodItem', gtin)

	const { getAccessTokenSilently, isAuthenticated } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['foodItem', 'food'])

	const dispatch = useDispatch()

	const { message } = AntApp.useApp()

	const navigate = useNavigate()

	const shouldViewStandardFood = !foodItem?.food?.nutritionalComposition?.ncmCompositions
	useEffect(() => {
		if (!shouldViewStandardFood) return

		const navToLibraryFoodItem = ({ content, key = 'idError' }) => {
			message.error({ content, key })

			navigate(prefixPath('/library?scope=foodItem', language), { replace: true })
		}

		const viewFoodItem = async (foodItemId) => {
			try {
				const accessToken = await getAccessTokenSilently()

				const r = await dispatch(foodItemsShow(language, accessToken, foodItemId))
				return r
			} catch (e) {
				const status = Object.values(e.errors)[0].status
				const content = Object.values(e.errors)[0].detail
				if (['403', '404'].includes(status)) {
					navToLibraryFoodItem({ content })
				}
				// throw e
			}
		}

		viewFoodItem(gtin)
	}, [shouldViewStandardFood, getAccessTokenSilently, language, dispatch, message, navigate, gtin])

	let [searchParams, setSearchParams] = useSearchParams()

	const fallbackSection = 'overview'
	const paramsSection = searchParams.get('section')
	const initialSection = paramsSection ?? fallbackSection

	const [section, setSection] = useState(initialSection)
	const handleSectionChange = (key) => setSection(key)

	const shouldSetSearchParams = section !== initialSection
	useEffect(() => {
		shouldSetSearchParams && setSearchParams({ section })
	}, [shouldSetSearchParams, section, setSearchParams])

	const handleClick = () => {
		dispatch(setMeal({ foodId: foodItem.food.id }))
		dispatch(toggleNewEvent())
	}

	// const isLaptop = useMediaQuery({
	// 	// query: '(min-width: 1024px)'
	// 	query: `${deviceMinWidth.laptop}`,
	// })

	const { isDarkTheme } = useSelector((state) => state.appearance)

	return (
		<FoodItem
			foodItem={foodItem}
			isAuthenticated={isAuthenticated}
			section={section}
			handleSectionChange={handleSectionChange}
			handleClick={handleClick}
			t={t}
			// isLaptop={isLaptop}
			isDarkTheme={isDarkTheme}
		/>
	)
}

export default FoodItemStatefulContainer
