// Function component (presentational):

import React from 'react'

import { colors } from '../../styles/colors'

import { Column } from '@ant-design/charts'

const MoodColumn = ({
	data,
	// t,
	isDarkTheme,
}) => {
	const config = {
		data,
		xField: 'date',
		yField: 'moodRating',
		label: { textBaseline: 'bottom' },
		shapeField: 'hollow',
		style: {
			maxWidth: 32,
			lineWidth: 3,
			// fill: colors.primary,
			stroke: colors.primary,
			radiusTopLeft: 16,
			radiusTopRight: 16,
		},
		scale: { y: { domain: [0, 10] } },
		theme: isDarkTheme ? 'classicDark' : 'classic',
		height: 352,
		tooltip: false,
	}

	return <Column className={'chart'} {...config} />
}

export default MoodColumn
