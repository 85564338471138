import * as yup from 'yup'

import { mapToCamelCase, joinWithQuotesAndAmpersand } from '../misc'

// import profilesSchema from './profilesSchema'
// import accountablesSchema from './accountablesSchema'

export const accountableTypes = ['Person', 'Business']
export const accountableKinds = mapToCamelCase(accountableTypes)

export const accountableTypeValidation = (t) => {
	const label = t('accounts:attributes.accountableType.label')
	const valuesLabels = accountableKinds.map((v) =>
		t(`accounts:attributes.accountableTypes.${v}.label`),
	)
	const valuesLabelsList = joinWithQuotesAndAmpersand(valuesLabels)

	return yup
		.mixed()
		.oneOf(accountableTypes, t('validation:mixed.oneOf', { label, values: valuesLabelsList }))
		.required(t('validation:mixed.required', { label }))
}

// const accountsSchema = (t) =>
// 	yup.object().shape({
// 		profile: profilesSchema(t),
// 		accountableType: accountableTypeValidation(t),
// 		accountable: accountablesSchema(t),
// 	})

// export default accountsSchema
