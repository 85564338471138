// Function component (presentational):

import React from 'react'

import renderLabel from '../shared/other/renderLabel'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import FormWrapper from '../shared/styledComponents/form/FormWrapper'

import MeasurementForm from './MeasurementForm'

import Icon, { ColumnHeightOutlined, ExpandAltOutlined, CloseOutlined } from '@ant-design/icons'
import { ReactComponent as Scale } from '../../assets/icons/scale.svg'
import TextCircleButton from '../shared/styledComponents/button/TextCircleButton'

const NewMeasurement = ({
	isBodyMass,
	isBodyHeight,
	beforeAccountCreation,
	isNewMeasurementOpen,
	isQuickAction,
	isToday,
	hideNewMeasurement,
	birthDate,
	selectDay,
	latestBmValue,
	latestBhValue,
	initialValues,
	createBodyMeasurement,
	endSubmit,
	libraStart,
	isSI,
	language,
	t,
}) => {
	const key = isBodyMass ? 'bodyMassTitle' : isBodyHeight ? 'bodyHeightTitle' : 'title'
	const icon = isBodyMass ? (
		<Icon component={Scale} />
	) : isBodyHeight ? (
		<ColumnHeightOutlined />
	) : (
		<ExpandAltOutlined />
	)

	const renderTitle = () =>
		renderLabel({
			icon,
			label: t(`measurement:form.${key}`),
			extra: <TextCircleButton icon={<CloseOutlined />} onClick={hideNewMeasurement} />,
		})

	const renderBody = () => (
		<FormWrapper>
			<MeasurementForm
				initialValues={initialValues}
				isBodyMass={isBodyMass}
				isBodyHeight={isBodyHeight}
				beforeAccountCreation={beforeAccountCreation}
				isQuickAction={isQuickAction}
				isToday={isToday}
				birthDate={birthDate}
				selectDay={selectDay}
				latestBmValue={latestBmValue}
				latestBhValue={latestBhValue}
				createBodyMeasurement={createBodyMeasurement}
				endSubmit={endSubmit}
				libraStart={libraStart}
				isSI={isSI}
				language={language}
				t={t}
			/>
		</FormWrapper>
	)

	return (
		<DrawerOrModalContainer
			isOpen={isNewMeasurementOpen}
			hide={hideNewMeasurement}
			renderTitle={renderTitle}
			renderBody={renderBody}
		/>
	)
}

export default NewMeasurement
