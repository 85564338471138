import { createSlice } from '@reduxjs/toolkit'
// import { createAppSlice } from '../store/createAppSlice'

import dayjs from 'dayjs'

import { today, dateFormat } from '../utils/dates'

const initialState = {
	currentJournalId: '',
	currentDay: today,
	isCalendarOpen: false,
}

const journal = createSlice({
	name: 'journal',
	initialState: initialState,
	reducers: {
		// ‘Mutating’ the state is possible thanks to immer (shipped with Redux Toolkit).
		selectJournal(state, action) {
			state.currentJournalId = action.payload
		},
		selectDay(state, action) {
			state.currentDay = action.payload
		},
		toggleCalendar(state) {
			state.isCalendarOpen = !state.isCalendarOpen
		},
		prevDay(state) {
			state.currentDay = dayjs(state.currentDay).subtract(1, 'd').format(dateFormat)
		},
		nextDay(state) {
			state.currentDay = dayjs(state.currentDay).add(1, 'd').format(dateFormat)
		},
	},
})

export default journal
export const { reducer: journalReducer, actions: journalActions } = journal
export const { selectJournal, selectDay, toggleCalendar, nextDay, prevDay } = journalActions
