import styled from 'styled-components'

import { Modal as AntModal } from 'antd'

const Modal = styled(AntModal)`
	.ant-modal-header {
		margin-right: ${(props) => (props.$stickRight ? 0 : 'auto')};
	}

	// .ant-modal-body {
	// 	border: ${(props) => (props.$fromLibra ? '1px solid gold' : undefined)};
	// 	border-bottom-left-radius: ${(props) => (props.$fromLibra ? 0 : undefined)};
	// }
`

export default Modal
