import { createSlice } from '@reduxjs/toolkit'
// import { createAppSlice } from '../store/createAppSlice'

import { selectJournal } from './journal'

const initialState = {
	currentAccountId: '',
	currentAccountableType: '',
	accountIds: [],
	isAccountsOpen: false,
	isAccountsWithNew: true,
	field: '',
}

const account = createSlice({
	name: 'account',
	initialState: initialState,
	reducers: {
		// ‘Mutating’ the state is possible thanks to immer (shipped with Redux Toolkit).
		selectAccount(state, action) {
			state.currentAccountId = action.payload.id
			state.currentAccountableType = action.payload.type
		},
		setAccountIds(state, action) {
			state.accountIds = action.payload
		},
		toggleAccountsWithNew(state) {
			state.isAccountsWithNew = !state.isAccountsWithNew
		},
		toggleAccounts(state) {
			state.isAccountsOpen = !state.isAccountsOpen
		},
		changeField(state, action) {
			state.field = action.payload
		},
	},
})

export default account
export const { reducer: accountReducer, actions: accountActions } = account
export const { selectAccount, setAccountIds, toggleAccountsWithNew, toggleAccounts, changeField } =
	accountActions

export const selectAccountAndJournal =
	({ accountId, accountableType, journalId }) =>
	(dispatch) => {
		dispatch(selectAccount({ id: accountId, type: accountableType }))
		dispatch(selectJournal(journalId))
	}

export const showInitAccounts = () => (dispatch) => {
	dispatch(toggleAccountsWithNew())
	dispatch(toggleAccounts())
}
