import * as yup from 'yup'

import { givenNameValidation, emailValidation } from './profilesSchema'

const subscriptionFormSchema = (t) =>
	yup.object().shape({
		referrerKey: yup.string(),
		profile: yup.object().shape({
			givenName: givenNameValidation(t),
			email: emailValidation(t),
		}),
	})

export default subscriptionFormSchema
