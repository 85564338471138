// Function component (presentational):

import React from 'react'

import AvatarContainer from '../avatar/AvatarContainer'
import SystemContainer from '../system/SystemContainer'
import LanguageContainer from '../language/LanguageContainer'
import ThemeContainer from '../theme/ThemeContainer'

import { supportedLocaleStandards } from '../../contexts/i18n/i18nConfig'

import L10nLink from '../shared/navigation/L10nLink'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'
import NavButton from '../shared/styledComponents/button/NavButton'
import InlineMenu from '../shared/styledComponents/menu/InlineMenu'

import ItemLabel from '../shared/styledComponents/label/ItemLabel'

import renderLabel from '../shared/other/renderLabel'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import {
	KeyOutlined,
	LoginOutlined,
	LogoutOutlined,
	UserSwitchOutlined,
	ProfileOutlined,
	DashboardOutlined,
	GlobalOutlined,
	TranslationOutlined,
	BgColorsOutlined,
	SunOutlined,
	MoonOutlined,
	CloseOutlined,
} from '@ant-design/icons'
import TextCircleButton from '../shared/styledComponents/button/TextCircleButton'

import StyledNavUserMenu from './NavUserMenuStyles'

const NavUserMenu = ({
	sleekNav,
	spreadNavUser,
	inOnboarding,
	isAuthenticated,
	handleSignup,
	handleLogin,
	handleLogout,
	areSettingsOpen,
	toggleSettings,
	withAccounts,
	showAccounts,
	isDashboardOpen,
	withSideDash,
	toggleDashboard,
	t,
	isTabletL,
}) => {
	const showOutsideMenu = spreadNavUser && isTabletL

	const renderNavUserMenuSignup = () => (
		<NavButton
			// key="nav-user-menu-signup"
			icon={<KeyOutlined />}
			onClick={handleSignup}
		>
			{t('nav:userMenu.signup')}
		</NavButton>
	)

	const renderNavUserMenuLogin = () => (
		<NavButton
			// key="nav-user-menu-login"
			icon={<LoginOutlined />}
			onClick={handleLogin}
		>
			{t('nav:userMenu.login')}
		</NavButton>
	)

	const renderNavUserMenuSettings = () => (
		<NavButton
			key="nav-user-menu-settings"
			icon={<AvatarContainer />}
			$hasAvatarIcon
			onClick={toggleSettings}
		>
			{t('nav:userMenu.settings.shortTitle')}
		</NavButton>
	)

	const settingsItems = []

	const dividerItem = { type: 'divider' }

	const profileItem = {
		key: 'nav-user-menu-settings-profile',
		icon: <ProfileOutlined />,
		label: <L10nLink to={'/profile'}>{t('nav:userMenu.settings.profile')}</L10nLink>,
	}
	isAuthenticated && !sleekNav && !isTabletL && settingsItems.push(profileItem)

	const accountSwitchItem = {
		key: 'nav-user-menu-settings-account-switch',
		icon: <UserSwitchOutlined />,
		label: <ItemLabel onClick={showAccounts}>{t('nav:userMenu.settings.accountSwitch')}</ItemLabel>,
	}
	isAuthenticated &&
		(!sleekNav || (sleekNav && withAccounts)) &&
		settingsItems.push(accountSwitchItem, dividerItem)

	const handleDashboardClick = () => {
		toggleSettings()
		toggleDashboard()
	}
	const dashboardItem = {
		key: 'nav-user-menu-dashboard',
		icon: <DashboardOutlined />,
		label: <ItemLabel onClick={handleDashboardClick}>{t('nav:userMenu.dashboard')}</ItemLabel>,
	}

	isAuthenticated && !sleekNav && !isTabletL && settingsItems.push(dashboardItem, dividerItem)

	const systemItem = {
		key: 'nav-user-menu-settings-system',
		icon: <GlobalOutlined />,
		label: renderLabel({
			label: t('nav:userMenu.settings.system'),
			extra: <SystemContainer />,
		}),
	}
	!sleekNav && settingsItems.push(systemItem)

	const buildLngItem = (locale) => ({
		key: locale,
		icon: <TranslationOutlined />,
		label: <LanguageContainer locale={locale} />,
	})

	const lngItems = supportedLocaleStandards.map((locale) => buildLngItem(locale))

	const languageItem = {
		key: 'nav-user-menu-settings-language',
		icon: <TranslationOutlined />,
		label: t('nav:userMenu.settings.language'),
		children: lngItems,
	}

	const themeItem = {
		key: 'nav-user-menu-settings-theme',
		icon: <BgColorsOutlined />,
		label: t('nav:userMenu.settings.theme'),
		children: [
			{ key: 'light', icon: <SunOutlined />, label: <ThemeContainer theme={'light'} /> },
			{
				key: 'dark',
				icon: <MoonOutlined />,
				label: <ThemeContainer theme={'dark'} />,
			},
			{
				key: 'oS',
				icon: <BgColorsOutlined />,
				label: <ThemeContainer theme={'oS'} />,
			},
		],
	}

	settingsItems.push(languageItem, dividerItem, themeItem)

	const signupItem = {
		key: 'nav-user-menu-signup',
		icon: <KeyOutlined />,
		label: <ItemLabel onClick={handleSignup}>{t('nav:userMenu.settings.signup')}</ItemLabel>,
	}

	const loginItem = {
		key: 'nav-user-menu-login',
		icon: <LoginOutlined />,
		label: <ItemLabel onClick={handleLogin}>{t('nav:userMenu.settings.login')}</ItemLabel>,
	}

	!isAuthenticated && !showOutsideMenu && settingsItems.push(dividerItem, signupItem, loginItem)

	const logoutItem = {
		key: 'nav-user-menu-login',
		icon: <LogoutOutlined />,
		label: <ItemLabel onClick={handleLogout}>{t('nav:userMenu.settings.logout')}</ItemLabel>,
	}
	isAuthenticated && !inOnboarding && settingsItems.push(dividerItem, logoutItem)

	const renderSettingsTitle = () =>
		renderLabel({
			icon: <AvatarContainer />,
			label: t('nav:userMenu.settings.title'),
			extra: <TextCircleButton icon={<CloseOutlined />} onClick={toggleSettings} />,
		})

	const renderSettingsBody = () => <InlineMenu items={settingsItems} />

	const renderNavUserMenuDashboard = () => (
		<NavButton key="nav-user-menu-dashboard" icon={<DashboardOutlined />} onClick={toggleDashboard}>
			{t('nav:userMenu.dashboard')}
		</NavButton>
	)

	return (
		<>
			<StyledNavUserMenu
				// id="nav-user-menu"
				$withSideDash={withSideDash}
			>
				{!isAuthenticated && showOutsideMenu && renderNavUserMenuSignup()}
				{!isAuthenticated && showOutsideMenu && renderNavUserMenuLogin()}
				{renderNavUserMenuSettings()}
			</StyledNavUserMenu>

			{isAuthenticated && !sleekNav && isTabletL && !isDashboardOpen && (
				<FloatMenu $top={192} $right={0}>
					{renderNavUserMenuDashboard(t)}
				</FloatMenu>
			)}

			<DrawerOrModalContainer
				isOpen={areSettingsOpen}
				hide={toggleSettings}
				renderTitle={renderSettingsTitle}
				renderBody={renderSettingsBody}
				placement="top"
				side="right"
				onlyDrawer
			/>
		</>
	)
}

export default NavUserMenu
