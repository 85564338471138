// Function component (presentational):

import React from 'react'

import L10nLink from '../shared/navigation/L10nLink'

import Badge from '../shared/styledComponents/Badge'
import NavButton from '../shared/styledComponents/button/NavButton'

import Icon, {
	// HomeOutlined,
	MessageOutlined,
	SearchOutlined,
	// ScanOutlined,
	ShoppingCartOutlined,
	ProfileOutlined,
	ScheduleOutlined,
} from '@ant-design/icons'
import { ReactComponent as LibraLogoMark } from '../../assets/icons/libra-logo.mark.svg'
import { Avatar } from 'antd'

const renderNavAppMenuItem = ({
	key,
	count,
	icon,
	disabled,
	selectedKey,
	hasAvatarIcon = false,
	t,
}) => (
	<L10nLink to={`/${key}`}>
		<Badge count={count}>
			<NavButton
				// type={key === selectedKey ? 'default' : undefined}
				icon={icon}
				// iconPosition="left"
				disabled={disabled}
				danger={key === selectedKey}
				$hasAvatarIcon={hasAvatarIcon}
			>
				{key ? t(`nav:appMenu.${key}`) : t('nav:appMenu.home')}
			</NavButton>
		</Badge>
	</L10nLink>
)

export const renderNavAppMenuHome = (t, selectedKey = '') =>
	renderNavAppMenuItem({
		key: '',
		icon: (
			<Avatar
				icon={<Icon component={LibraLogoMark} />}
				style={{ backgroundColor: 'white', color: '#06061f' }}
				// style={{ color: 'white', backgroundColor: '#06061f' }}
			/>
		),
		hasAvatarIcon: true,
		t,
		selectedKey,
	})

export const renderNavAppMenuCoaching = (t, selectedKey = '') =>
	renderNavAppMenuItem({
		key: 'coaching',
		icon: <MessageOutlined />,
		// count: 1,
		disabled: true,
		t,
		selectedKey,
	})

export const renderNavAppMenuLibrary = (t, selectedKey = '') =>
	renderNavAppMenuItem({ key: 'library', icon: <SearchOutlined />, t, selectedKey })

// export const renderNavAppMenuScanner = (t) =>
// 	renderNavAppMenuItem({ key: 'scanner', icon: <ScanOutlined />, t })

export const renderNavAppMenuShopping = (t, selectedKey = '') =>
	renderNavAppMenuItem({
		key: 'shopping',
		icon: <ShoppingCartOutlined />,
		disabled: true,
		t,
		selectedKey,
	})

export const renderNavAppMenuJournal = (t, selectedKey = '') =>
	renderNavAppMenuItem({ key: 'journal', icon: <ScheduleOutlined />, t, selectedKey })

export const renderNavAppMenuProfile = (t, selectedKey = '') =>
	renderNavAppMenuItem({ key: 'profile', icon: <ProfileOutlined />, t, selectedKey })
