// Function component (presentational):

import React from 'react'

import AvatarContainer from '../avatar/AvatarContainer'

import StyledUserAvatar from './UserAvatarStyles'

const UserAvatar = ({ withSideDash, hide }) => (
	<StyledUserAvatar onClick={hide} $withSideDash={withSideDash}>
		<AvatarContainer />
	</StyledUserAvatar>
)

export default UserAvatar
