import styled from 'styled-components'

import SubmitContent from './SubmitContent'

const SubmitWrapper = styled.div`
	display: flex;
	justify-content: ${(props) => (props.$step === 'first' ? 'flex-end' : 'space-between')};

	> ${SubmitContent} {
		// flex-grow: 1;

		padding-left: ${(props) => (props.$step === 'first' ? '32px' : undefined)};
	}
`

export default SubmitWrapper
