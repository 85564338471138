export const scrollToTop = () =>
	window.scrollY && window.scrollTo({ top: 0, left: 0, behavior: 'auto' })

export const scrollToId = (hash, opts = { behavior: 'instant' }) => {
	setTimeout(() => {
		const id = hash.replace('#', '')
		const element = document.getElementById(id)
		element && element.scrollIntoView(opts)
	}, 0)
}
