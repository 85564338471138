import styled from 'styled-components'

import { Button } from 'antd'

const TextCircleButton = styled(Button).attrs(() => ({
	// Defining static props.
	type: 'text',
	shape: 'circle',
}))``

export default TextCircleButton
