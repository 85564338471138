import * as yup from 'yup'

import logsSchema from './logsSchema'
import bodyHeightsSchema from './bodyHeightsSchema'
import bodyMassIndicesSchema from './bodyMassIndicesSchema'

const bodyHeightFormSchema = (t, birthDate) =>
	yup.object().shape({
		log: logsSchema(t, birthDate, false),
		bodyHeight: bodyHeightsSchema(t),
		bodyMassIndex: bodyMassIndicesSchema(t),
	})

export default bodyHeightFormSchema
