// Function component (presentational):

import React from 'react'

import Markdown from 'react-markdown'
import rehypeExternalLinks from 'rehype-external-links'

import { colors } from '../../styles/colors'

import PageIllustration from '../shared/styledComponents/page/PageIllustration'

import Card from '../shared/styledComponents/card/Card'

import pattern from '../../assets/patterns/abstract-4.jpg'

import { ExpandOutlined } from '@ant-design/icons'
import { Button, Typography } from 'antd'
import BlockRoundButton from '../shared/styledComponents/button/BlockRoundButton'

import NewChatContainer from './NewChatContainer'

import { Thread } from './AssistanceStyles'

const { Paragraph } = Typography

const Assistance = ({
	endedList,
	loading,
	recallChats,
	chats,
	writeChatRef,
	showNewChat,
	t,
	isMobileXL,
	isDarkTheme,
}) => {
	const renderRecallChats = () => (
		<Card $size={'small'} style={{ alignSelf: 'center', marginTop: '-56px', zIndex: 4 }}>
			<BlockRoundButton type="default" onClick={recallChats} loading={loading}>
				{t('assistance:actions.recallChats')}
			</BlockRoundButton>
		</Card>
	)

	const renderChat = (c) => (
		<Card
			key={c.id}
			id={c.id}
			bordered={c.origin === 'assistant'}
			style={{
				width: 'unset',
				alignSelf: c.origin === 'user' ? 'flex-end' : undefined,
				borderTopRightRadius: c.origin === 'user' && 0,
				borderBottomLeftRadius: c.origin === 'assistant' && 0,
				borderColor: colors.libraStar,
				scrollMarginTop: 'calc(64px + env(env(safe-area-inset-top))',
			}}
		>
			<div style={{ padding: '5px 8px' }}>
				<Paragraph>
					<Markdown rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}>
						{c.content}
					</Markdown>
				</Paragraph>
			</div>
		</Card>
	)

	const renderChats = () => chats?.map((c) => renderChat(c))

	const renderWriteChat = () => (
		<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
			{isMobileXL && (
				<Card style={{ width: 48, height: 48, marginTop: 'auto', marginRight: '8px' }}>
					<Button shape="circle" icon={<ExpandOutlined />} onClick={showNewChat} />
				</Card>
			)}

			<Card
				id="write-chat"
				ref={writeChatRef}
				style={{
					width: 'calc(100% - 56px)',
					alignSelf: 'flex-end',
					borderTopRightRadius: 0,
				}}
			>
				<NewChatContainer fromThread />
			</Card>
		</div>
	)

	return (
		<>
			<PageIllustration $isFullscreen $src={pattern} $isDarkTheme={isDarkTheme} />

			<Thread>
				{!endedList && renderRecallChats()}

				{renderChats()}

				{renderWriteChat()}
			</Thread>
		</>
	)
}

export default Assistance
