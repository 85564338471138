// Function component to prefix locale to Navigate pathname
// (container, using i18next hook):

import React from 'react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import { Navigate } from 'react-router-dom'

import { l10nTo } from '../../../utils/l10n'

const L10nNavigate = ({ to, ...rest }) => {
	const {
		i18n: { language },
	} = useTranslation()

	return <Navigate to={l10nTo(to, language)} {...rest} />
}

export default L10nNavigate
