import renderLabel from '../shared/other/renderLabel'

import L10nLink from '../shared/navigation/L10nLink'

import { ScanOutlined } from '@ant-design/icons'
import { Tag } from 'antd'

export const buildScanItem = (t) => ({
	key: 'libra-menu-actions-scan',
	icon: <ScanOutlined />,
	label: renderLabel({
		label: (
			<L10nLink to={'/scanner'}>
				{t('nav:libraMenu.actions.scan')}
				<Tag bordered={false} style={{ margin: 'auto 0 auto 8px' }}>
					beta
				</Tag>
			</L10nLink>
		),
	}),
})
