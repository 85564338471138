// Function HOC/Higher Order Component
// wrapping App components to set the current theme
// (container):

import React from 'react'

import { theme } from 'antd'

import { ThemeProvider } from 'styled-components'

const { useToken } = theme

const StyledTheme = ({ children }) => {
	const { token } = useToken()

	return <ThemeProvider theme={token}>{children}</ThemeProvider>
}

export default StyledTheme
