// import * as yup from 'yup'

import { dateValidation } from './logsSchema'

export const schedulableTypes = ['Activity', 'Meal']

export const startDateValidation = dateValidation

// const eventsSchema = (t) =>
// 	yup.object().shape({
// 		startDate: startDateValidation(t),
// 	})

// export default eventsSchema
