import styled from 'styled-components'

import { colors } from '../../../../styles/colors'

import { Button } from 'antd'

const RoundButton = styled(Button).attrs((props) => ({
	// Defining static props.
	shape: 'round',

	// Defining dynamic props.
	$hasAvatarIcon: props.$hasAvatarIcon ?? false,
}))`
	width: ${(props) => (props.$hasAvatarIcon ? '32px' : undefined)};

	padding: ${(props) => (props.$hasAvatarIcon ? '0 !important' : undefined)};

	&.ant-btn {
		padding-inline: ${(props) =>
			props.$hasAvatarIcon || props.block ? '0 !important' : '8px !important'};
	}

	&.ant-btn-dangerous {
		color: ${colors.primary} !important;
		// border-color: ${colors.primary} !important;
	}
`

export default RoundButton
