import ReactGA from 'react-ga4'

// Recommended events:
const signup = (connection) => ReactGA.event('sign_up', { method: connection })
const login = (connection) => ReactGA.event('login', { method: connection })
const search = (searchTerm, scope, locale) =>
	ReactGA.event('search', { search_term: searchTerm, scope, locale })

// Custom events:
const heroCta = (path) => ReactGA.event('hero_cta', { path })
const libra = (intent, key) => ReactGA.event('libra', { intent, key })
const scan = (code, format) => ReactGA.event('scan', { code, format })
const custom = (name, options = {}) => ReactGA.event(name, options)

export const gaEvents = { signup, login, search, heroCta, libra, scan, custom }
