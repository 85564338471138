import * as yup from 'yup'

import { keywords } from '../keywords'

export const profilableTypes = ['Subscriber', 'User', 'Account']

const unallowedTlds = ['.com', '.net', '.org']
const unallowedTldsPattern = `^(?!.*(${unallowedTlds.join('|')})).+$`

const reservedWords = ['libra', 'admin', 'lbrai']
const reservedWordsPattern = `^(?!.*(${reservedWords.join('|')})).+$`

const usernameValidation = (t) => {
	const label = t('profiles:attributes.username.label')

	return (
		yup
			.string()
			.min(3, t('validation:string.min', { label, min: 3 }))
			.max(30, t('validation:string.max', { label, max: 30 }))
			.matches(/^[a-zA-Z0-9._-]+$/, t('profiles:attributes.username.validation.allowedChars'))
			// .matches(
			// 	/^(?![._-])(?!.*([._-]{2})).+(?<![._-])$/,
			// 	t('profiles:attributes.username.validation.unallowedChars')
			// )
			.matches(
				new RegExp(unallowedTldsPattern, 'i'),
				t('profiles:attributes.username.validation.unallowedTlds'),
			)
			.matches(
				new RegExp(reservedWordsPattern, 'i'),
				t('profiles:attributes.username.validation.reservedWords'),
			)
			// .matches(
			// 	/^(?=.{3,30}$)(?![._-])(?!.*([._-]{2}|.com|.net|.org|libra|admin|lbrai))[a-zA-Z0-9._-]+(?<![._-])$/i,
			// 	t('validation:mixed.matches')
			// )
			.required(t('validation:mixed.required', { label }))
	)
}

const phoneValidation = (t) => {
	// const label = t('profiles:attributes.phone.label')

	return yup.string()
	// .min(3, t('validation:string.min', { label, min: 3 }))
	// .max(16, t('validation:string.max', { label, max: 30 }))
	// .matches(/^\+[1-9]\d{1,14}$/, t('profiles:attributes.phone.validation.e174Format'))
}

const reservedCombinationsPattern = `^(?!.*((libra.*(${keywords.join('|')}))|((${keywords.join(
	'|',
)}).*libra))).+$`

const nameValidation = (t) => {
	const label = t('profiles:attributes.name.label')

	return (
		yup
			.string()
			.min(3, t('validation:string.min', { label, min: 3 }))
			.max(92, t('validation:string.maxS', { label }))
			.matches(
				new RegExp(`^(?!(^(${reservedWords.join('|')})$|.*(libra.*diet|diet.*libra))).+$`, 'i'),
				t('profiles:attributes.name.validation.reservedWords'),
			)
			// .matches(/^(?=.{3,92}$)(?!(^(libra|admin|lbrai)$|.*(libra.*diet|diet.*libra))).+$/i, t('validation:mixed.matches'))
			.matches(
				new RegExp(reservedCombinationsPattern, 'i'),
				t('profiles:attributes.name.validation.reservedCombinations'),
			)
	)
	// .required(t('validation:mixed.required', { label }))
}

// const addressValidation = (t) => {
// 	const label = t('profiles:attributes.address.label')

// 	return yup
// 		.string()
// 		.min(12, t('validation:string.min', { label, min: 12 }))
// 		.max(255)
// 		.required(t('validation:mixed.required', { label }))
// }

export const givenNameValidation = (t) => {
	// const label = t('profiles:attributes.givenName.label')

	return yup.string()
	// .required(t('validation:mixed.required', { label }))
}

export const emailValidation = (t) => {
	const label = t('profiles:attributes.email.label')

	return yup.string().email().required(t('validation:mixed.required', { label }))
}

const profilesSchema = (t) =>
	yup.object().shape({
		profile: yup.object().shape({
			name: nameValidation(t),
			username: usernameValidation(t),
			phone: phoneValidation(t),
			// countryCode: countryCodeValidation(t),
			// address: addressValidation(t),
		}),
	})

export default profilesSchema
