import * as yup from 'yup'

import { convertCmToFt } from '../numbers'
import { joinWithQuotesAndAmpersand } from '../misc'

// Enduser maximum body height value in cm.
const maxValue = 272
// Enduser maximum body height value in ft.
export const maxValueInFt = convertCmToFt(maxValue)

const valueValidation = (t) => {
	const label = t('bodyHeights:attributes.value.label')

	return (
		yup
			.number()
			.positive(t('validation:number.positive', { label }))
			.max(maxValue, t('bodyHeights:attributes.value.validation.max', { value: maxValue }))
			.nullable()
			// .typeError(t('validation:number.typeError', { label }))
			.required(t('validation:mixed.required', { label }))
	)
}

const valueInFtValidation = (t) => {
	const label = t('bodyHeights:attributes.valueInFt.label')

	return (
		yup
			.number()
			.positive(t('validation:number.positive', { label }))
			.max(
				maxValueInFt,
				t('bodyHeights:attributes.valueInFt.validation.max', { value: maxValueInFt }),
			)
			.nullable()
			// .typeError(t('validation:number.typeError', { label }))
			.required(t('validation:mixed.required', { label }))
	)
}

export const sleepings = ['after', 'before']

export const sleepingValidation = (t) => {
	const label = t('bodyHeights:attributes.sleeping.label')
	const valuesLabels = sleepings.map((v) => t(`bodyHeights:attributes.sleepings.${v}.label`))
	const valuesLabelsList = joinWithQuotesAndAmpersand(valuesLabels)

	return yup
		.mixed()
		.oneOf(sleepings, t('validation:mixed.oneOf', { label, values: valuesLabelsList }))
	// .required(t('validation:mixed.required', { label }))
}

const bodyHeightsSchema = (t) =>
	yup.object().shape({
		value: valueValidation(t),
		valueInFt: valueInFtValidation(t),
		sleeping: sleepingValidation(t),
	})

export default bodyHeightsSchema
