// Function component (container, using React, Redux, React Router, i18next & React Responsive hooks):

// React Effect hooks.
import React, { useEffect, useCallback } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hook for routing.
import { useSearchParams } from 'react-router-dom'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
// import { useMediaQuery } from 'react-responsive'

import parse from 'html-react-parser'
import build from 'redux-object'

import { subscribersShow } from '../../ducks/subscribers'
import { speakWithSound } from '../../ducks/libra'

// import deviceMinWidth from '../../styles/deviceMinWidth'

import Referral from './Referral'

import { App as AntApp } from 'antd'

const ReferralContainer = () => {
	let [searchParams, setSearchParams] = useSearchParams()

	const paramsSubscriberKey = searchParams.get('subscriberKey')
	const subscriberKey = paramsSubscriberKey ?? ''

	const initialValues = { key: subscriberKey }

	const {
		i18n: { language },
		t,
	} = useTranslation(['referral', 'subscribers', 'validation'])

	const dispatch = useDispatch()

	const { message } = AntApp.useApp()

	const apiData = useSelector((state) => state.apiData)

	const subscriber = subscriberKey && build(apiData, 'subscriber', subscriberKey)

	const foundSubscriber = subscriber && Object.keys(subscriber)?.length
	const verifiedSubscriber = subscriber?.profile?.emailVerified

	const shouldViewSubscriber = subscriberKey && !foundSubscriber
	useEffect(() => {
		if (!shouldViewSubscriber) return

		const viewSubscriber = async (subKey) => {
			try {
				const r = await dispatch(subscribersShow(language, subKey))
				return r
			} catch (e) {
				const { errors } = e
				const status = errors[0]?.status
				const content = errors[0]?.detail

				if (['404'].includes(status)) {
					message.error({ content, key: 'idError' })
				}
				// throw e
			}
		}

		viewSubscriber(subscriberKey)
	}, [shouldViewSubscriber, language, dispatch, message, subscriberKey])

	const libraStart = useCallback(
		(path, l10nKeys = {}) =>
			dispatch(speakWithSound({ path, speech: t(path, l10nKeys), language })),
		[t, language, dispatch],
	)

	const {
		shareKey,
		referralsCount,
		rank,
		nextReferralsCount,
		nextRank,
		profile: { emailVerified = false } = {},
	} = subscriber || {}

	const shouldLibraStart = foundSubscriber
	useEffect(() => {
		if (!shouldLibraStart) return

		const commentStats = () => {
			if (!emailVerified) {
				libraStart('referral:notVerified.speech.verifyEmail')
			} else if (referralsCount === 0) {
				libraStart('referral:stats.speech.noReferral')
			} else if (rank === 1) {
				libraStart('referral:stats.speech.first')
			} else {
				libraStart('referral:stats.speech.next', {
					rank,
					addedReferrals: nextReferralsCount - referralsCount,
					nextRank,
				})
			}
		}

		commentStats()
	}, [
		shouldLibraStart,
		referralsCount,
		emailVerified,
		rank,
		nextReferralsCount,
		nextRank,
		libraStart,
	])

	const pt = (path) => parse(t(path))

	// const isLaptop = useMediaQuery({
	// 	// query: '(min-width: 1024px)'
	// 	query: `${deviceMinWidth.laptop}`,
	// })

	return (
		<Referral
			initialValues={initialValues}
			setSearchParams={setSearchParams}
			shareKey={shareKey}
			referralsCount={referralsCount}
			rank={rank}
			foundSubscriber={foundSubscriber}
			verifiedSubscriber={verifiedSubscriber}
			t={t}
			pt={pt}
			// isLaptop={isLaptop}
		/>
	)
}

export default ReferralContainer
