import styled from 'styled-components'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'

const StyledNavDateMenu = styled(FloatMenu).attrs((props) => ({
	// Defining static props.
	$top: 0,

	// Defining dynamic props.
	$right: props.$isDashboardOpen ? 0 : undefined,
	$isCentered: !props.$isDashboardOpen ? true : undefined,
}))`
	width: ${(props) => (props.$isDashboardOpen ? '368px' : undefined)};

	justify-content: space-between;

	z-index: 98;
`

export default StyledNavDateMenu
