// Function component (presentational):

import React from 'react'

import dayjs from 'dayjs'
import _ from 'lodash'

import { Formik } from 'formik'
import {
	Form,
	// Radio,
	// Checkbox,
	TimePicker,
	SubmitButton,
} from 'formik-antd'

import exertionFormSchema from '../../utils/yupSchema/exertionFormSchema'
import { dotwOptions, disabledTime } from '../../utils/yupSchema/repliesSchema'
// import { ynValues } from '../../utils/yupSchema/repliesSchema'

import { midnight, timeFormat } from '../../utils/dates'
import { transformRoutineReplies } from '../../utils/lifestyle'
import { transformInvalidAttrErrors } from '../../utils/api'

import FormItem from '../shared/styledComponents/form/FormItem'
import { radioGroupStyle } from '../shared/styledComponents/form/RadioGroup'
import RadioGroup from '../shared/styledComponents/form/RadioGroup'
import RadioButton from '../shared/styledComponents/form/RadioButton'
import { dotwCheckboxGroupStyle } from '../shared/styledComponents/form/DotwCheckboxGroup'
import CheckboxGroup from '../shared/styledComponents/form/DotwCheckboxGroup'
import { timePickerStyle } from '../shared/styledComponents/form/TimePicker'

import Collapse from '../shared/styledComponents/collapse/Collapse'

import SubmitWrapper from '../shared/styledComponents/form/SubmitWrapper'
import SubmitContent from '../shared/styledComponents/form/SubmitContent'

import renderQuestionLabel from './renderQuestionLabel'

import { UpOutlined, DownOutlined } from '@ant-design/icons'
import { App as AntApp, Button, Segmented } from 'antd'

// const { Group: RadioGroup, Button: RadioButton } = Radio
// const { Group: CheckboxGroup } = Checkbox

const ExertionForm = ({
	initialValues,
	isOnboarding,
	exertionTitles,
	isPalResponse,
	timeUseReplies,
	exertionReplies,
	createQuestionnaireResponse,
	goBack,
	isPersonalized,
	goGeneric,
	endSubmit,
	libraStart,
	t,
}) => {
	const { message } = AntApp.useApp()

	const handleSubmit = async (values, { setErrors }) => {
		const [, tValues, eValues] = values
		const { replies: tReplies } = tValues
		const { replies: eReplies } = eValues

		if (_.isEqual(tReplies, timeUseReplies) && _.isEqual(eReplies, exertionReplies)) {
			message.warning(t('lifestyle:exertionForm.message.unchangedWarning'))

			endSubmit(values)
			return
		}

		const replies = transformRoutineReplies(eReplies)
		const attrs = _.merge({}, eValues, { replies })

		try {
			const { response } = await createQuestionnaireResponse(attrs)

			if (response) {
				message.success(t('lifestyle:exertionForm.message.success'))

				libraStart('lifestyle:exertionForm.speech.1', {
					sequenceType: 'ordered',
					sequenceEnd: 2,
				})

				endSubmit(values)
			}
		} catch (e) {
			const errors = transformInvalidAttrErrors(e.errors)
			const q3Errors = [null, null, errors]

			// Async (server-side) validation.
			setErrors(q3Errors)

			const { replies = [] } = errors

			if (replies.length) {
				const msg = []
				replies.forEach((r) => {
					if (!r.value) {
						// Could aggregate same message for different days of the week...
						msg.push(r)
					}
				})
				if (msg.length) {
					const msgDuration = msg.length * 3
					message.error(msg.join(' '), msgDuration)
				}
			}
		}
	}

	const {
		1: {
			replies: {
				0: { value: workYn },
				1: { value: workDotw = [] },
				2: { value: workValue },
				3: { value: transportValue },
				4: { value: sleepValue },
			},
		},
	} = initialValues
	const isWorking = workYn === 'yes'

	const workDd = workValue ? dayjs(workValue) : midnight
	const transportDd = dayjs(transportValue)
	const sleepDd = dayjs(sleepValue)

	const workMinutes = workDd.diff(midnight, 'm')
	const transportMinutes = transportDd.diff(midnight, 'm')
	const sleepMinutes = sleepDd.diff(midnight, 'm')
	const sumMinutes = workMinutes + transportMinutes + sleepMinutes
	const residualMinutes = 1440 - 180 - sumMinutes
	const maxLeisure = midnight.add(residualMinutes, 'm')

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={exertionFormSchema(t)}
			onSubmit={handleSubmit}
		>
			{({
				values,
				values: {
					2: {
						replies: {
							0: { value: vigorousWorkYn },
							3: { value: moderateWorkYn },
							6: { value: moderateTransportYn },
							9: { value: vigorousLeisureYn },
							12: { value: moderateLeisureYn },
						},
					},
				},
				setFieldValue,
			}) => {
				const isVigorousWork = vigorousWorkYn === 'yes'
				const isModerateWork = moderateWorkYn === 'yes'
				const isModerateTransport = moderateTransportYn === 'yes'
				const isVigorousLeisure = vigorousLeisureYn === 'yes'
				const isModerateLeisure = moderateLeisureYn === 'yes'

				const items = []

				const renderYnRadioButtons = () => (
					<>
						<RadioButton value={'no'}>{t('replies:attributes.ynValues.no.label')}</RadioButton>
						<RadioButton value={'yes'}>{t('replies:attributes.ynValues.yes.label')}</RadioButton>
					</>
				)

				const renderVigorousWorkYn = () => (
					<FormItem
						name="[2].replies[0].value"
						label={renderQuestionLabel(exertionTitles[0])}
						help={false} // Temporary...
					>
						<RadioGroup
							name="[2].replies[0].value"
							onChange={(e) => {
								if (e.target.value === 'no') {
									setFieldValue('[2].replies[1].value', [])
									setFieldValue('[2].replies[2].value', null)
								}
							}}
							style={radioGroupStyle}
						>
							{renderYnRadioButtons()}
						</RadioGroup>
					</FormItem>
				)

				const renderVigorousWorkDotw = () => (
					<FormItem
						name="[2].replies[1].value"
						label={renderQuestionLabel(exertionTitles[1])}
						hidden={!isVigorousWork}
						$columnCheckboxes
					>
						<CheckboxGroup
							name="[2].replies[1].value"
							options={dotwOptions(t, workDotw)}
							style={dotwCheckboxGroupStyle}
						/>
					</FormItem>
				)

				const renderVigorousWorkDd = () => (
					<FormItem
						name="[2].replies[2].value"
						label={renderQuestionLabel(exertionTitles[2])}
						hidden={!isVigorousWork}
					>
						<TimePicker
							name="[2].replies[2].value"
							placeholder={t('replies:attributes.ddValue.placeholder')}
							format={timeFormat}
							minuteStep={5}
							changeOnScroll
							disabledTime={() => disabledTime(workDd)}
							showNow={false}
							needConfirm={false}
							style={timePickerStyle}
						/>
					</FormItem>
				)

				const renderModerateWorkYn = () => (
					<FormItem
						name="[2].replies[3].value"
						label={renderQuestionLabel(exertionTitles[3])}
						help={false} // Temporary...
					>
						<RadioGroup
							name="[2].replies[3].value"
							onChange={(e) => {
								if (e.target.value === 'no') {
									setFieldValue('[2].replies[4].value', [])
									setFieldValue('[2].replies[5].value', null)
								}
							}}
							style={radioGroupStyle}
						>
							{renderYnRadioButtons()}
						</RadioGroup>
					</FormItem>
				)

				const renderModerateWorkDotw = () => (
					<FormItem
						name="[2].replies[4].value"
						label={renderQuestionLabel(exertionTitles[4])}
						hidden={!isModerateWork}
						$columnCheckboxes
					>
						<CheckboxGroup
							name="[2].replies[4].value"
							options={dotwOptions(t, workDotw)}
							style={dotwCheckboxGroupStyle}
						/>
					</FormItem>
				)

				const renderModerateWorkDd = () => (
					<FormItem
						name="[2].replies[5].value"
						label={renderQuestionLabel(exertionTitles[5])}
						hidden={!isModerateWork}
					>
						<TimePicker
							name="[2].replies[5].value"
							placeholder={t('replies:attributes.ddValue.placeholder')}
							format={timeFormat}
							minuteStep={5}
							changeOnScroll
							disabledTime={() => disabledTime(workDd)}
							showNow={false}
							needConfirm={false}
							style={timePickerStyle}
						/>
					</FormItem>
				)

				const workChildren = (
					<>
						{renderVigorousWorkYn()}
						{renderVigorousWorkDotw()}
						{renderVigorousWorkDd()}
						{renderModerateWorkYn()}
						{renderModerateWorkDotw()}
						{renderModerateWorkDd()}
					</>
				)

				const workItem = {
					key: 'work',
					label: t('lifestyle:exertionForm.panel.work'),
					children: workChildren,
					collapsible: !isWorking && 'disabled',
				}

				const renderModerateTransportYn = () => (
					<FormItem
						name="[2].replies[6].value"
						label={renderQuestionLabel(exertionTitles[6])}
						help={false} // Temporary...
					>
						<RadioGroup
							name="[2].replies[6].value"
							onChange={(e) => {
								if (e.target.value === 'no') {
									setFieldValue('[2].replies[7].value', [])
									setFieldValue('[2].replies[8].value', null)
								}
							}}
							style={radioGroupStyle}
						>
							{renderYnRadioButtons()}
						</RadioGroup>
					</FormItem>
				)

				const renderModerateTransportDotw = () => (
					<FormItem
						name="[2].replies[7].value"
						label={renderQuestionLabel(exertionTitles[7])}
						hidden={!isModerateTransport}
						$columnCheckboxes
					>
						<CheckboxGroup
							name="[2].replies[7].value"
							options={dotwOptions(t)}
							style={dotwCheckboxGroupStyle}
						/>
					</FormItem>
				)

				const renderModerateTransportDd = () => (
					<FormItem
						name="[2].replies[8].value"
						label={renderQuestionLabel(exertionTitles[8])}
						hidden={!isModerateTransport}
					>
						<TimePicker
							name="[2].replies[8].value"
							placeholder={t('replies:attributes.ddValue.placeholder')}
							format={timeFormat}
							minuteStep={5}
							changeOnScroll
							disabledTime={() => disabledTime(transportDd)}
							showNow={false}
							needConfirm={false}
							style={timePickerStyle}
						/>
					</FormItem>
				)

				const transportChildren = (
					<>
						{renderModerateTransportYn()}
						{renderModerateTransportDotw()}
						{renderModerateTransportDd()}
					</>
				)

				const transportItem = {
					key: 'transport',
					label: t('lifestyle:exertionForm.panel.transport'),
					children: transportChildren,
				}

				const renderVigorousLeisureYn = () => (
					<FormItem
						name="[2].replies[9].value"
						label={renderQuestionLabel(exertionTitles[9])}
						help={false} // Temporary...
					>
						<RadioGroup
							name="[2].replies[9].value"
							onChange={(e) => {
								if (e.target.value === 'no') {
									setFieldValue('[2].replies[10].value', [])
									setFieldValue('[2].replies[11].value', null)
								}
							}}
							style={radioGroupStyle}
						>
							{renderYnRadioButtons()}
						</RadioGroup>
					</FormItem>
				)

				const renderVigorousLeisureDotw = () => (
					<FormItem
						name="[2].replies[10].value"
						label={renderQuestionLabel(exertionTitles[10])}
						hidden={!isVigorousLeisure}
						$columnCheckboxes
					>
						<CheckboxGroup
							name="[2].replies[10].value"
							options={dotwOptions(t)}
							style={dotwCheckboxGroupStyle}
						/>
					</FormItem>
				)

				const renderVigorousLeisureDd = () => (
					<FormItem
						name="[2].replies[11].value"
						label={renderQuestionLabel(exertionTitles[11])}
						hidden={!isVigorousLeisure}
					>
						<TimePicker
							name="[2].replies[11].value"
							placeholder={t('replies:attributes.ddValue.placeholder')}
							format={timeFormat}
							minuteStep={5}
							changeOnScroll
							disabledTime={() => disabledTime(maxLeisure)}
							showNow={false}
							needConfirm={false}
							style={timePickerStyle}
						/>
					</FormItem>
				)

				const renderModerateLeisureYn = () => (
					<FormItem
						name="[2].replies[12].value"
						label={renderQuestionLabel(exertionTitles[12])}
						help={false} // Temporary...
					>
						<RadioGroup
							name="[2].replies[12].value"
							onChange={(e) => {
								if (e.target.value === 'no') {
									setFieldValue('[2].replies[13].value', [])
									setFieldValue('[2].replies[14].value', null)
								}
							}}
							style={radioGroupStyle}
						>
							{renderYnRadioButtons()}
						</RadioGroup>
					</FormItem>
				)

				const renderModerateLeisureDotw = () => (
					<FormItem
						name="[2].replies[13].value"
						label={renderQuestionLabel(exertionTitles[13])}
						hidden={!isModerateLeisure}
						$columnCheckboxes
					>
						<CheckboxGroup
							name="[2].replies[13].value"
							options={dotwOptions(t)}
							style={dotwCheckboxGroupStyle}
						/>
					</FormItem>
				)

				const renderModerateLeisureDd = () => (
					<FormItem
						name="[2].replies[14].value"
						label={renderQuestionLabel(exertionTitles[14])}
						hidden={!isModerateLeisure}
					>
						<TimePicker
							name="[2].replies[14].value"
							placeholder={t('replies:attributes.ddValue.placeholder')}
							format={timeFormat}
							minuteStep={5}
							changeOnScroll
							disabledTime={() => disabledTime(maxLeisure)}
							showNow={false}
							needConfirm={false}
							style={timePickerStyle}
						/>
					</FormItem>
				)

				const recreationChildren = (
					<>
						{renderVigorousLeisureYn()}
						{renderVigorousLeisureDotw()}
						{renderVigorousLeisureDd()}
						{renderModerateLeisureYn()}
						{renderModerateLeisureDotw()}
						{renderModerateLeisureDd()}
					</>
				)

				const recreationItem = {
					key: 'recreation',
					label: t('lifestyle:exertionForm.panel.recreation'),
					children: recreationChildren,
				}

				const renderSedentaryLeisureDd = () => (
					<FormItem name="[2].replies[15].value" label={renderQuestionLabel(exertionTitles[15])}>
						<TimePicker
							name="[2].replies[15].value"
							placeholder={t('replies:attributes.ddValue.placeholder')}
							format={timeFormat}
							minuteStep={5}
							changeOnScroll
							disabledTime={() => disabledTime(maxLeisure)}
							showNow={false}
							needConfirm={false}
							style={timePickerStyle}
						/>
					</FormItem>
				)

				const sedentaryItem = {
					key: 'sedentary',
					label: t('lifestyle:exertionForm.panel.sedentary'),
					children: renderSedentaryLeisureDd(),
				}

				items.push(workItem, transportItem, recreationItem, sedentaryItem)

				return (
					<Form
						// id="exertion-form"
						layout="vertical"
						colon={false}
					>
						<Collapse
							items={items}
							defaultActiveKey={[isWorking ? 'work' : 'transport']}
							accordion
							// bordered={false}
							ghost
							style={{ marginBottom: 16 }}
						/>

						<SubmitWrapper>
							<Button shape="circle" icon={<UpOutlined />} onClick={() => goBack(values)} />

							<SubmitContent>
								<Segmented
									options={[
										{ value: true, label: t('lifestyle:formSwitch.personalized') },
										{ value: false, label: t('lifestyle:formSwitch.generic') },
									]}
									value={isPersonalized}
									size="small"
									onChange={() => goGeneric(values)}
								/>
							</SubmitContent>

							<SubmitButton
								// htmlType="submit"
								shape="circle"
								icon={<DownOutlined />}
								disabled={!isOnboarding && !isPalResponse && !exertionReplies}
							/>
						</SubmitWrapper>
					</Form>
				)
			}}
		</Formik>
	)
}

export default ExertionForm
